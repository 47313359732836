import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './rca-composite-modal.module.scss';
import {ExtendedRCACompositeSegments} from '../../rca-composite-segments-viewer.component';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import TransKeys from 'translations';
import {useTranslation} from '../../../../../../../../../core/translations/use-translation';
import {Sorting} from 'front-core';
import pluralize from 'pluralize';
import {
  ColumnType,
  CommandType,
  DocumentElementType,
  RCACompositeSegmentsFigureOptions,
  SPECIAL_SMART_TABLE_DATA_KEYS,
} from '../../../../../../types';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {ModelType} from '../../../../../../../../../consts/model-type';

interface OwnProps {
  composite: ExtendedRCACompositeSegments;
  options: RCACompositeSegmentsFigureOptions;
  populationTrend: number;
  onClose: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const RCACompositeModal: React.FC<AllProps> = (props: AllProps) => {
  const {composite, populationTrend, onClose, options, className} = props;
  const {t} = useTranslation();

  const defaultSort: Sorting = useMemo(
    () => ({
      orderBy: 'uplift',
      order: options.sortOrder ? options.sortOrder : options.higherIsBetter ? 'desc' : 'asc',
    }),
    [options]
  );
  const tableData = useMemo(() => {
    const data: any[] = composite.segments.map(s => ({
      ...s,
      contributionToChange: s.contributionToChange * 100,
      populationTrend: populationTrend * 100,
      share: s.share * 100,
      expectedShare: s.expectedShare * 100,
      value: options.isPercentageValue ? s.value * 100 : s.value,
      expectedValue: options.isPercentageValue ? s.expectedValue * 100 : s.expectedValue,
      command: {
        type: CommandType.REF,
        payload: {
          type: ModelType.SIGNAL,
          refId: s.signalId,
        },
      },
      [SPECIAL_SMART_TABLE_DATA_KEYS.ROW_CLASS_NAME]: classes.SegmentRow,
    }));
    data.push({
      name: 'Combined Composite Segments',
      share: composite.share * 100,
      expectedShare: composite.expectedShare * 100,
      value: options.isPercentageValue ? composite.value * 100 : composite.value,
      expectedValue: options.isPercentageValue
        ? composite.expectedValue * 100
        : composite.expectedValue,
      count: composite.count,
      expectedCount: composite.expectedCount,
      contributionToChange: composite.contributionToChange * 100,
      populationTrend: populationTrend * 100,
      [SPECIAL_SMART_TABLE_DATA_KEYS.ROW_CLASS_NAME]: classes.CompositeRow,
    });
    return data;
  }, [composite, populationTrend, options]);
  const tableColumns = useMemo(
    () => [
      {
        key: 'segment',
        type: ColumnType.LABEL_VALUE,
        typeOptions: {
          labelDataKey: 'groupName',
          labelDataTitle: t(
            TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SEGMENT_GROUP.TITLE
          ),
          valueDataKey: 'name',
          valueDataTitle: t(
            TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SEGMENT.TITLE
          ),
          commandDataKey: 'command',
        },
        options: {
          width: '30%',
          sortable: true,
        },
      },
      {
        key: 'switch_share',
        type: ColumnType.SWITCH_COLUMN,
        typeOptions: {
          columns: [
            {
              key: 'share',
              type: ColumnType.VERSUS,
              title: t(
                TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_X.TITLE,
                {
                  entity: pluralize(options.entity),
                }
              ),
              options: {
                sortable: true,
                filterable: true,
                unit: '%',
                helperText: t(
                  TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_X
                    .HELPER_TEXT,
                  {
                    entity: pluralize(options.entity),
                  }
                ),
              },
              typeOptions: {
                valueDataKey: 'share',
                valueDataTitle: t(
                  TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_X.TITLE,
                  {
                    entity: pluralize(options.entity),
                  }
                ),
                versusDataKey: 'expectedShare',
                versusDataTitle: t(
                  TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_X
                    .SUB_TITLE,
                  {
                    entity: pluralize(options.entity),
                  }
                ),
              },
            },
            {
              key: 'share_abs',
              type: ColumnType.VERSUS,
              title: t(
                TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_ABS.TITLE,
                {
                  entity: pluralize(options.entity),
                }
              ),
              options: {
                sortable: true,
                filterable: true,
                helperText: t(
                  TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_ABS
                    .HELPER_TEXT,
                  {
                    entity: pluralize(options.entity),
                  }
                ),
              },
              typeOptions: {
                hideIcon: true,
                valueDataKey: 'count',
                valueDataTitle: t(
                  TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_ABS.TITLE,
                  {
                    entity: pluralize(options.entity),
                  }
                ),
                versusDataKey: 'expectedCount',
                versusDataTitle: t(
                  TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_ABS
                    .SUB_TITLE,
                  {
                    entity: pluralize(options.entity),
                  }
                ),
              },
            },
          ],
        },
      },
      {
        key: 'value',
        type: ColumnType.VERSUS,
        options: {
          sortable: true,
          filterable: true,
          unit: options.isPercentageValue ? '%' : undefined,
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_VALUE.HELPER_TEXT,
            {
              entity: pluralize(options.entity),
            }
          ),
        },
        typeOptions: {
          valueDataKey: 'value',
          valueDataTitle: t(
            TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_VALUE.TITLE,
            {
              entity: pluralize(options.entity),
            }
          ),
          versusDataKey: 'expectedValue',
          versusDataTitle: t(
            TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_VALUE.SUB_TITLE,
            {
              entity: pluralize(options.entity),
            }
          ),
        },
      },
      {
        key: 'contributionToChange',
        type: ColumnType.OUT_OF,
        options: {
          sortable: true,
          filterable: true,
          unit: options.isPercentageValue ? '%' : undefined,
          helperText: t(
            TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.CONTRIBUTION_TO_CHANGE
              .HELPER_TEXT,
            {
              entity: pluralize(options.entity),
            }
          ),
        },
        typeOptions: {
          valueDataKey: 'contributionToChange',
          valueDataTitle: t(
            TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.CONTRIBUTION_TO_CHANGE
              .TITLE
          ),
          outOfDataKey: 'populationTrend',
          outOfDataTitle: t(
            TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.CONTRIBUTION_TO_CHANGE
              .SUB_TITLE
          ),
        },
      },
    ],
    [t, options]
  );

  const tableFigure = useMemo(
    () => ({
      id: `kpi_segment_group_table`,
      dataKey: 'key',
      type: DocumentElementType.SMART_TABLE,
      columns: tableColumns,
      data: tableData,
      options: {
        defaultSort: defaultSort,
        pagination: false,
        stickyFooter: true,
      },
    }),
    [tableData, tableColumns, defaultSort]
  );

  return (
    <ModalWrapper isOpen={composite} onClose={onClose} width={'65vw'} height={'auto'}>
      <div className={classNames(classes.RCACompositeModal, className)}>
        <div className={classes.Title}>
          {t(TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.COMPOSITE_MODAL.TITLE)}
        </div>
        <ChildRenderer children_={tableFigure} />
      </div>
    </ModalWrapper>
  );
};
