import {ArrowRightLongSolidIcon, CloseRegularIcon} from 'ui-components';
import {ImagesResource} from '../../../../../../assets/images';
import {useTranslation} from 'react-i18next';
import classes from './workflows-banner.module.scss';
import TransKeys from '../../../../../../constants/translation-keys';

const WORKFLOW_STEPS = [
  {
    titleKey: TransKeys.WORKFLOWS.BANNER.STEPS.STEP_1.TITLE,
    contentKey: TransKeys.WORKFLOWS.BANNER.STEPS.STEP_1.CONTENT,
  },
  {
    titleKey: TransKeys.WORKFLOWS.BANNER.STEPS.STEP_2.TITLE,
    contentKey: TransKeys.WORKFLOWS.BANNER.STEPS.STEP_2.CONTENT,
  },
  {
    titleKey: TransKeys.WORKFLOWS.BANNER.STEPS.STEP_3.TITLE,
    contentKey: TransKeys.WORKFLOWS.BANNER.STEPS.STEP_3.CONTENT,
  },
];

export type AllProps = {
  onClose?: VoidFunction;
};

export const WorkflowsBanner = (props: AllProps) => {
  const {onClose} = props;
  const {t} = useTranslation();

  return (
    <div className={classes.WorkflowsBanner}>
      <CloseRegularIcon onClick={onClose} className={classes.CloseButton} />
      <div className={classes.Title}>{t(TransKeys.WORKFLOWS.BANNER.TITLE)}</div>
      <img src={ImagesResource.app.workflowsBannerIllustration} className={classes.Illustration} />
      <div className={classes.StepContainers}>
        {WORKFLOW_STEPS.map((step, index) => (
          <>
            <div key={index} className={classes.StepContainer}>
              <div className={classes.StepHeader}>
                <div className={classes.StepNumber}>{index + 1}</div>
                <div className={classes.StepTitle}>{t(step.titleKey)}</div>
              </div>
              <div className={classes.StepContent}>{t(step.contentKey)}</div>
            </div>
            {index < WORKFLOW_STEPS.length - 1 && (
              <ArrowRightLongSolidIcon className={classes.Arrow} />
            )}
          </>
        ))}
      </div>
    </div>
  );
};
