import * as React from 'react';
import {MetricSampleData, RCASegmentationFigureOptions} from '../../../../../../types';
import {RCASegmentationViewMode} from '../../../rca-figure/rca-figure-viewer.types';
import {UnifiedRCASegmentationItem} from '../../rca-segmentation-extended.types';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {useCallback, useMemo, useState} from 'react';
import {
  ChartMode,
  useSegmentChartOvertimeChartData,
} from '../segment-chart-overtime-modal/segment-chart-overtime-modal.component';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import classes from './composite-chart-overtime-modal.module.scss';
import {SegmentChartItemSelector} from '../segment-chart-overtime-modal/components/segment-chart-item-selector/segment-chart-item-selector.component';
import classNames from 'classnames';
import {SwitchActions} from '../../../../../../../../simple/controls/switch-actions/switch-actions.component';
import {LineChart} from '../../../../../../../charts/charts/line-chart/line-chart.component';
import {UsersIcon} from '../../../../../../../../simple/controls/icons/icons.component';
import TransKeys from 'translations';
import {keyBy} from 'lodash';
import pluralize from 'pluralize';

type Composite = {
  segments: UnifiedRCASegmentationItem[];
} & UnifiedRCASegmentationItem;

interface OwnProps {
  granularity: string;
  metricSamples: MetricSampleData[];
  composite: Composite;
  viewMode: RCASegmentationViewMode;
  options: RCASegmentationFigureOptions;
  onClose: () => void;
}

type AllProps = OwnProps;

const TransPrefix = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.COMPOSITE_OVERTIME_MODAL;

export const CompositeChartOvertimeModal: React.FC<AllProps> = (props: AllProps) => {
  const {metricSamples, composite, granularity, viewMode, options, onClose} = props;
  const {t} = useDocumentTranslation();
  const [chartMode, setChartMode] = useState(ChartMode.KPI_VALUE);
  const [selectedSegmentKeys, setSelectedSegmentKeys] = useState<string[]>([composite.key]);
  const [showAllPopulation, setShowAllPopulation] = useState(true);
  const segments = useMemo(() => {
    const base = composite.segments.map(s => ({
      ...s,
      name: `${s.segmentGroupName} → ${s.segmentName}`,
    }));
    return [
      {
        ...composite,
        name: t(TransPrefix.COMPOSITE_ITEM_TEXT),
      },
      ...base,
    ];
  }, [composite, t]);
  const segmentsByKey = useMemo(() => keyBy(segments, 'key'), [segments]);
  const selectedSegmentKeySet = useMemo(() => new Set(selectedSegmentKeys), [selectedSegmentKeys]);
  const onSegmentClicked = useCallback(
    (key: string) => {
      if (selectedSegmentKeySet.has(key)) {
        selectedSegmentKeySet.delete(key);
      } else {
        selectedSegmentKeySet.add(key);
      }
      setSelectedSegmentKeys(Array.from(selectedSegmentKeySet));
    },
    [selectedSegmentKeySet, setSelectedSegmentKeys]
  );
  const chartData = useSegmentChartOvertimeChartData({
    selectedSegmentKeys,
    segmentsByKey,
    options,
    metricSamples,
    chartMode,
    granularity,
    showAllPopulation,
  });
  const chartOptions = useMemo(
    () => [
      {
        label: 'KPI Value',
        onClick: () => setChartMode(ChartMode.KPI_VALUE),
        isActive: chartMode === ChartMode.KPI_VALUE,
      },
      {
        label: `# ${pluralize(options.entity)}`,
        onClick: () => setChartMode(ChartMode.ENTITIES_COUNT),
        isActive: chartMode === ChartMode.ENTITIES_COUNT,
      },
    ],
    [chartMode, options.entity]
  );

  return (
    <ModalWrapper isOpen={true} onClose={onClose} width={'110rem'} height={'60rem'}>
      <div className={classes.CompositeChartOvertimeModal}>
        <div className={classes.Title}>{t(TransPrefix.TITLE)}</div>
        <div className={classes.Main}>
          <SegmentChartItemSelector
            segments={segments}
            selectedSegmentKeySet={selectedSegmentKeySet}
            onSegmentClicked={onSegmentClicked}
            showAllPopulation={showAllPopulation}
            setShowAllPopulation={setShowAllPopulation}
            viewMode={viewMode}
            options={options}
            className={classes.List}
          />
          <div
            className={classNames(
              classes.ChartWrapper,
              options.hasDenominator && classes.HasOptions
            )}
          >
            {options.hasDenominator && (
              <div className={classes.Options}>
                <SwitchActions actions={chartOptions} showActionsLabel />
              </div>
            )}
            <div className={classes.Chart}>
              {chartData && <LineChart {...chartData} key={selectedSegmentKeys.join('.')} />}
              {chartData === null && (
                <div className={classes.EmptyState}>
                  <UsersIcon className={classes.EmptyStateIcon} />
                  {t(
                    TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.SEGMENT_OVER_TIME_MODAL
                      .EMPTY_STATE
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
