import * as React from 'react';
import {useContext, useMemo} from 'react';
import classNames from 'classnames';
import {AnalysisHeaderLabel, DocumentIconType} from '../../../../../types';
import classes from './analysis-content-title.module.scss';
import {DocumentIcon} from '../../../../internal-viewers/document-icon.component';
import {Tooltip} from '@material-ui/core';
import {
  CircleInfoRegularIcon,
  SparklesSolidIcon,
} from '../../../../../../../simple/controls/icons/icons.component';
import {groupBy} from 'lodash';
import {DocumentCommandEmitterContext} from '../../../../../contexts/document-command-emitter.context';
import {Chip, ChipVariant} from '../../../../../../../simple/data-display/chip/chip.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import {ContentOption} from '../analysis-content-renderer/analysis-content-renderer.component';
import {Select} from '../../../../../../../forms/inputs/select/select.component';
import {TooltipIfOverflow} from '../../../../../../../simple/generic/tooltips/tooltips.component';

interface OwnProps {
  contentKey?: string;
  title: string;
  icon?: any;
  helperText?: any;
  headerLabels?: AnalysisHeaderLabel[];
  contentOptions?: ContentOption[];
  onContentSelected?: (key: string) => void;
  isCausal?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const ANALYSIS_CONTENT_TITLE_CTA_ELEMENT_ID = 'analysis-content-title-cta-element-id';

export const AnalysisContentTitle: React.FC<AllProps> = (props: AllProps) => {
  const {
    contentKey,
    title,
    icon: Icon,
    helperText,
    isCausal,
    contentOptions: contentOptionsFromProps,
    onContentSelected,
    headerLabels = [],
    className,
  } = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {t} = useDocumentTranslation();

  const headerLabelsGroupByPosition = useMemo(
    () => groupBy(headerLabels, 'position'),
    [headerLabels]
  );
  const contentOptions = useMemo(() => {
    if (!contentOptionsFromProps) {
      return;
    }
    return contentOptionsFromProps.map(c => ({
      ...c,
      labelRenderer: o => (
        <div className={classes.Option}>
          <TooltipIfOverflow title={o.label}>
            <span className={classes.Label}>{o.label}</span>
          </TooltipIfOverflow>
          {o.isCausal && (
            <Chip
              label={t(TransKeys.DOCUMENT_VIEWER.LABELS.CAUSAL)}
              variant={ChipVariant.CAUSAL}
              className={classes.Causal}
              size={'xsmall'}
              bold
            />
          )}
        </div>
      ),
    }));
  }, [contentOptionsFromProps]);
  const renderHeaderLabel = (hl: AnalysisHeaderLabel, idx: number) => {
    return (
      <div className={classes.HeaderLabel} key={idx}>
        <div className={classes.Label}>{hl.label}</div>
        <div
          className={classNames(classes.Value, hl.command && classes.Clickable)}
          onClick={hl.command ? () => emitEvent(hl.command) : undefined}
        >
          {hl.value}
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(classes.AnalysisContentTitle, className)}>
      <div className={classes.TitleWrapper}>
        <div className={classes.TitleContent}>
          {typeof Icon === 'string' && (
            <DocumentIcon className={classes.Icon} icon={DocumentIconType[Icon]} />
          )}
          {typeof Icon === 'object' && Icon !== null && <Icon className={classes.Icon} />}
          <span className={classes.Title}>{title}</span>
          {contentOptions && onContentSelected && (
            <>
              <span className={classes.Separator}>/</span>
              <Select
                value={contentKey}
                options={{options: contentOptions}}
                onChange={onContentSelected}
                searchable={contentOptions.length > 5}
                clearable={false}
                sortValues={false}
                className={classes.ContentOptions}
                dropdownButtonClassName={classes.ContentOptionsButton}
                disabled={contentOptions.length <= 1}
              />
            </>
          )}
          {isCausal && (
            <Chip
              label={t(TransKeys.DOCUMENT_VIEWER.LABELS.CAUSAL)}
              variant={ChipVariant.CAUSAL}
              className={classes.Causal}
              size={'small'}
              icon={SparklesSolidIcon}
              bold
            />
          )}
          {helperText && (
            <Tooltip title={helperText} placement={'top'}>
              <CircleInfoRegularIcon className={classes.HelperIcon} />
            </Tooltip>
          )}
        </div>
        {headerLabelsGroupByPosition['bottom']?.length > 0 && (
          <div className={classes.BottomHeaderLabels}>
            {headerLabelsGroupByPosition['bottom'].map((hl, idx) => renderHeaderLabel(hl, idx))}
          </div>
        )}
      </div>
      <div className={classes.RightHeaderLabels}>
        {headerLabelsGroupByPosition['right']?.length > 0 && (
          <div className={classes.AnalysisHeaderLabels}>
            {headerLabelsGroupByPosition['right'].map((hl, idx) => renderHeaderLabel(hl, idx))}
          </div>
        )}
        <div
          id={ANALYSIS_CONTENT_TITLE_CTA_ELEMENT_ID}
          className={classes.InjectedHeaderElement}
          key={'simulator-placeholder'}
        />
      </div>
    </div>
  );
};
