import * as React from 'react';
import {RemoteFigureElement} from '../../../types';
import {useCallback, useContext, useEffect, useState} from 'react';
import {DocumentViewerContext} from '../../../contexts/document-viewer.context';
import {ChildRenderer} from '../../core/child-renderer.component';
import {GenericLoading} from '../../../../../simple/utils/generic-loading/generic-loading.component';
import classes from './remote-figure-resolver.module.scss';
import classNames from 'classnames';
import {toSnakeCase} from 'front-core';

export interface OwmProps extends RemoteFigureElement {
  className?: string;
}

export const RemoteFigureResolver: React.FC<OwmProps> = (props: OwmProps) => {
  const {id, type, parameters, className} = props;
  const {remoteElementResolver} = useContext(DocumentViewerContext);
  const [resolvedElement, setResolvedElement] = useState<any>(null);
  const [isLoadingFigure, setIsLoadingFigure] = useState<boolean>(false);

  const resolveElement = useCallback(async () => {
    if (remoteElementResolver) {
      setIsLoadingFigure(true);
      const resolved = await remoteElementResolver(id, type, toSnakeCase(parameters));
      setResolvedElement(resolved);
      setIsLoadingFigure(false);
    }
  }, [remoteElementResolver, id, type, parameters]);

  useEffect(() => {
    resolveElement();
  }, [resolveElement]);

  if (resolvedElement && !isLoadingFigure) {
    return <ChildRenderer children_={resolvedElement} />;
  }

  // This component is only being rendered in the App
  return (
    <div className={classNames(classes.RemoteFigureResolver, className)}>
      {isLoadingFigure && <GenericLoading />}
    </div>
  );
};
