import {
  SegmentSentenceExplainer,
  SpikeDropOverUnderPerformingIncreasedDecreasedKPI,
} from '../rca-insights.model.ts';
import {
  ChangeInValueSentencePart,
  ScrollToSectionSentencePart,
  SegmentNameSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import {
  RCAExplainersIcon,
  RCAExplainersIconVariant,
} from '../../components/rca-explainers-icon/rca-explainers-icon.component.tsx';
import {RCAElementType} from 'ui-components';
import sharedClasses from '../../insights.module.scss';
import * as React from 'react';
import {RCAExplainersExplainabilityLabelDisplay} from '../../components/rca-explainers-explainability-label-display/rca-explainers-explainability-label-display.component.tsx';

interface OwnProps extends SegmentSentenceExplainer {
  formattedSentenceParts: SpikeDropOverUnderPerformingIncreasedDecreasedKPI;
}

type AllProps = OwnProps;

const SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter: React.FC<AllProps> = (
  props: AllProps
) => {
  const {formattedSentenceParts, sentenceParts, onViewOnPage, explainabilityLabel} = props;
  const {pastTensePerformanceVsXox} = formattedSentenceParts;
  const {segmentViewData, changeInValue, higherIsBetter} = sentenceParts;

  return (
    <SentenceContainer className={sharedClasses.Spaced}>
      {explainabilityLabel && (
        <RCAExplainersExplainabilityLabelDisplay label={explainabilityLabel} />
      )}
      <RCAExplainersIcon variant={RCAExplainersIconVariant.SEGMENT_EXPLAINER} />
      <SegmentNameSentencePart data={segmentViewData} />
      <SentencePart text={`${pastTensePerformanceVsXox} vs the analyzed period by`} />
      <ChangeInValueSentencePart changeInValue={changeInValue} higherIsBetter={higherIsBetter} />
      {onViewOnPage && (
        <ScrollToSectionSentencePart
          onViewOnPage={() => onViewOnPage(RCAElementType.SEGMENTATION)}
        />
      )}
    </SentenceContainer>
  );
};
export {SpikeDropOverUnderPerformingIncreasedDecreasedKpiFormatter};
