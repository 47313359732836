import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './kpi-segmentation-viewer.module.scss';
import {
  DocumentElementType,
  KPISegmentationFigure,
  KPISegmentationItem,
  KPISegmentationType,
} from '../../../../types';
import {groupBy, keys} from 'lodash';
import TransKeys from 'translations';
import {useDocumentTracking} from '../../../../hooks/use-document-tracking.hook';
import {exists} from 'front-core';
import {SimulationMode, useSimulatorMode} from '../../../../hooks/use-simulator-mode.hook';
import {SimulatorItem} from '../../../../hooks/use-simulator.hook';
import {extendKPISegmentationItems} from './kpi-segmentation-viewer.utils';
import {KPISegmentationDataModeTable} from './components/kpi-segmentation-tables/kpi-segmentation-data-mode-table.component';
import {KPISegmentationModal} from './components/kpi-segment-modal/kpi-segment-modal.component';
import {KPISegmentationSimulatorMode} from './components/kpi-segmentation-tables/kpi-segmentation-simulator-mode.component';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {
  ExtractSegmentData,
  SegmentFilters,
  SegmentFiltersValue,
  useFilterSegmentItems,
  useTeamAutoSetterForSegments,
} from '../../../shared/segmentation/segment-filters/segment-filters.component';
import {GlobalDocumentDataContext} from '../../../../contexts/global-document-data/global-document-data.context';

interface OwnProps extends KPISegmentationFigure {
  className?: string;
}

type AllProps = OwnProps;

export interface ExtendedKPISegmentationItem extends KPISegmentationItem, SimulatorItem {
  key: string;
  actualType: KPISegmentationType;
  // vs non segment
  uplift: number;
  // vs informative non segment
  informativeUplift?: number;
  // vs overall KPI
  avgUplift?: number;
  share: number;
  groupInsignificant?: boolean;
}

export interface KPISegmentGroup {
  name: string;
  segments: ExtendedKPISegmentationItem[];
}

const EXTRACT_SEGMENT_DATA: ExtractSegmentData = {
  signalIdDataKey: 'signalId.0',
  segmentGroupNameDataKey: 'segmentGroupName.0',
  segmentNameDataKey: 'segmentName.0',
  shareDataKey: 'share',
};
const DEFAULT_FILTERS = {};

export const KPISegmentationViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data: dataFromProps, options, className} = props;
  const {t} = useDocumentTranslation();
  const {teamId} = useContext(GlobalDocumentDataContext);
  const [selectedGroup, setSelectedGroup] = useState<KPISegmentGroup>(null);
  const {trackModalOpened, trackFilter} = useDocumentTracking(
    id,
    DocumentElementType.KPI_SEGMENTATION_FIGURE
  );
  const {button: simulatorButton, mode} = useSimulatorMode({
    figureId: id,
    elementType: DocumentElementType.KPI_SEGMENTATION_FIGURE,
    allowDataMode: true,
    disabled: options.isPercentageValue !== true,
    disabledTooltip: 'Soon',
    label: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.MODES.SIMULATOR_MODE),
    helperText: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.MODES.SIMULATOR_MODE_HELPER),
  });
  const data = useMemo(
    () => ({
      ...dataFromProps,
      items: extendKPISegmentationItems(
        dataFromProps.items,
        dataFromProps.totalEntities,
        dataFromProps.goalValue,
        options.higherIsBetter
      ),
    }),
    [dataFromProps]
  );
  useTeamAutoSetterForSegments(data.items, EXTRACT_SEGMENT_DATA);
  const {query: filters, setQuery: setFilters} = useDocQuery<SegmentFiltersValue>(
    id,
    DEFAULT_FILTERS,
    'filters'
  );
  const tableData = useFilterSegmentItems(data.items, teamId, filters, EXTRACT_SEGMENT_DATA);
  const itemsGroupedBySegmentGroup = useMemo(
    () => groupBy(data.items, EXTRACT_SEGMENT_DATA.segmentGroupNameDataKey),
    [data.items]
  );
  const groupNames = useMemo(() => keys(itemsGroupedBySegmentGroup), [itemsGroupedBySegmentGroup]);
  const onGroupSelected = useCallback(
    (groupName: string) => {
      if (!exists(groupName)) {
        setSelectedGroup(null);
        return;
      }
      const group = itemsGroupedBySegmentGroup[groupName];
      setSelectedGroup({
        name: groupName,
        segments: group,
      });
      trackModalOpened('view_segment_group', {name: groupName});
    },
    [itemsGroupedBySegmentGroup]
  );
  const onFilterChange = useCallback(
    (newFilters: SegmentFiltersValue) => {
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      }));
      const filterKey = keys(newFilters)[0];
      trackFilter(filterKey, {
        filter: newFilters[filterKey],
      });
    },
    [setFilters, trackFilter]
  );

  return (
    <div className={classNames(classes.KPISegmentationViewer, className)}>
      <div className={classes.TabsWrapper}>
        <SegmentFilters
          segments={data.items}
          extract={EXTRACT_SEGMENT_DATA}
          filtersValue={filters}
          onFiltersChange={onFilterChange}
          entity={options.entity}
        />
        {simulatorButton}
      </div>
      <div className={classes.Content}>
        {mode === SimulationMode.DATA && (
          <KPISegmentationDataModeTable
            figureId={id}
            options={options}
            items={tableData}
            totalEntities={data.totalEntities}
            onSeeAll={onGroupSelected}
          />
        )}
        {mode === SimulationMode.SIMULATOR && (
          <KPISegmentationSimulatorMode
            figureId={id}
            options={options}
            items={tableData}
            goalValue={data.goalValue}
          />
        )}
      </div>
      {selectedGroup && (
        <KPISegmentationModal
          group={selectedGroup}
          allPopulation={data.allPopulation}
          granularity={data.granularity}
          goalValue={data.goalValue}
          groupOptions={groupNames}
          onSelectGroup={onGroupSelected}
          entity={options.entity}
          totalEntities={data.totalEntities}
          isPercentageValue={options.isPercentageValue}
          kpiName={options.kpiName}
          higherIsBetter={options.higherIsBetter}
          isSumMode={options.isSumMode}
          showOvertime={props.version >= 1.1}
        />
      )}
    </div>
  );
};
