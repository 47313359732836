import * as React from 'react';
import {DialogLayout} from '../../simple/generic/dialog-layout/dialog-layout.component';
import {SimpleSearch} from '../../simple/custom-inputs/simple-search/simple-search.component';
import classes from './dialog.module.scss';

interface OwnProps {
  className?: string;
  value?: string;
  label?: string;
  onSubmit?: (value: string) => void;
  onChange?: (value: string) => void;
  onKeyUp?: (e: any) => void;
  placeholder?: string;
}

type AllProps = OwnProps;

export const FreeTextDialog: React.FC<AllProps> = (props: AllProps) => {
  const {label, className} = props;

  return (
    <DialogLayout className={className}>
      <div className={classes.FreeTextDialog}>
        <SimpleSearch {...props} />
      </div>
    </DialogLayout>
  );
};
