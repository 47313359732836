import * as React from 'react';
import {DatePickerProps} from 'react-datepicker';
import {ChevronFullDownIcon, DateDialog, TextInput} from '../../../../index';
import {useMemo, useRef, useState} from 'react';
import moment from 'moment';
import {Popover} from '@material-ui/core';
import sharedClasses from '../../../shared.module.scss';
import classes from './date-picker-input.module.scss';
import classNames from 'classnames';

interface OwnProps extends Omit<DatePickerProps, 'onChange'> {
  onChange: (value: string) => void;
  value: string;
  renderValue?: (value: string) => string;
  dateFormat?: string;
  dateInputFormat?: string;
  placeholder?: string;
  fullWidth?: boolean;
  utc?: boolean;
  locale?: string;
  error?: boolean;
  icon?: any;
  className?: string;
  inputClassName?: string;
  clearable?: boolean;
  showMonthYearPicker?: boolean;
  showWeekPicker?: boolean;
  filterDate?: (date: Date) => boolean;
}

type AllProps = OwnProps;

export const DatePickerInput: React.FC<AllProps> = (props: AllProps) => {
  const {
    onChange,
    value,
    dateFormat = 'DD/MM/YYYY',
    dateInputFormat,
    renderValue,
    placeholder,
    fullWidth,
    utc,
    error,
    minDate,
    maxDate,
    disabled,
    className,
    inputClassName,
    clearable,
    icon,
    locale,
    filterDate,
  } = props;
  const inputRef = useRef<HTMLInputElement>();
  const wrapperRef = useRef<HTMLDivElement>();
  const [showPicker, setShowPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const valueDate = useMemo(
    () =>
      value
        ? dateInputFormat
          ? moment.utc(value, dateInputFormat).toDate()
          : moment.utc(value).toDate()
        : null,
    [dateInputFormat, value]
  );
  const valueLabel = useMemo(() => {
    if (renderValue) {
      return renderValue(value);
    }
    return valueDate ? moment.utc(valueDate).format(dateFormat) : '';
  }, [value, valueDate, dateFormat, renderValue]);

  const onClick = e => {
    if (disabled) {
      return;
    }
    setAnchorEl(wrapperRef.current);
    setShowPicker(true);
    inputRef.current.blur();
  };
  const onDateChange = (date: Date) => {
    if (date === null) {
      onChange(null);
    } else {
      onChange(
        dateInputFormat
          ? moment.utc(date).format(dateInputFormat)
          : moment.utc(date).toDate().toISOString()
      );
    }
    if (!props.showWeekPicker) {
      onClosePopover();
    } else {
      setTimeout(() => {
        onClosePopover();
      }, 0);
    }
  };
  const onClosePopover = () => {
    setShowPicker(false);
    inputRef.current.blur();
  };

  return (
    <>
      <div ref={wrapperRef}>
        <TextInput
          ref={inputRef}
          onClick={onClick}
          value={valueLabel}
          placeholder={placeholder}
          fullWidth={fullWidth}
          className={className}
          inputClassName={classNames(classes.Input, disabled && classes.Disabled, inputClassName)}
          error={error}
          disabled={disabled}
          onChange={onDateChange as any}
          clearable={clearable}
          icon={icon}
          leftIcon={ChevronFullDownIcon}
        />
      </div>
      <Popover
        className={sharedClasses.BlankPaperAllowOverflow}
        open={showPicker}
        anchorEl={anchorEl}
        onClose={onClosePopover}
        classes={{
          paper: sharedClasses.BlankPaperAllowOverflow,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DateDialog
          {...props}
          className={undefined}
          value={valueDate}
          onSubmit={onDateChange}
          utc={utc}
          minDate={minDate}
          maxDate={maxDate}
          clearable={clearable}
          filterDate={filterDate}
          showMonthYearPicker={props.showMonthYearPicker}
          showWeekPicker={props.showWeekPicker}
          locale={locale as any}
        />
      </Popover>
    </>
  );
};
