import {
  SegmentPerformance,
  SegmentPerformanceSummaryItem,
} from '../../../../../insights/rca/rca-insights.model.ts';
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {CHANGE_DESCRIPTION_ALIAS_MAP} from '../../../../../shared/core/document-viewer/viewers/app-executive-summery-viewer/analyses-executive-summaries/rca-executive-summery/components/rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component.tsx';
import {SampledModelXoXOverviewSection} from '../../../../../shared/core/document-viewer/viewers/app-executive-summery-viewer/analyses-executive-summaries/rca-executive-summery/components/sampled-model-overview-xox-section/sampled-model-xox-overview.component.tsx';
import {number2k, useRemoteSourceStated} from 'ui-components';
import {getSegmentPerformanceSummaryNetworkRequest} from '../../../../../../http/insights.network-requests.ts';
import {get} from 'lodash';
import {GenericLoading} from '../../../../../shared/components/general/generic-loading/generic-loading.component.tsx';
import classes from './segment-performance-summary.module.scss';
import {
  SentenceContainer,
  SentencePart,
} from '../../../../../insights/components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import classNames from 'classnames';
import {RCA_SERIALIZATION_ERROR_CODE} from '../../../../../insights/rca/rca-insights.consts.ts';

interface OwnProps {
  resultId: number;
  targetSegmentId: number;
  targetSegmentClasses: string[];
  className?: string;
}

type AllProps = OwnProps;

export const SegmentPerformanceSummary = (props: AllProps) => {
  const {resultId, targetSegmentId, targetSegmentClasses, className} = props;
  const [deserializationError, setDeserializationError] = useState<boolean | null>(null);

  const {source, isLoading, exec} = useRemoteSourceStated<SegmentPerformance>({
    type: 'source',
    networkRequest: getSegmentPerformanceSummaryNetworkRequest,
    initialValue: null,
    onError: err => {
      const errorCode = get(err, 'data.error_code');
      if (errorCode === RCA_SERIALIZATION_ERROR_CODE) {
        setDeserializationError(true);
      }
    },
  });

  const changeDescriptionAlias = useMemo(() => {
    if (!source?.sampledModelXoxOverview) {
      return '';
    }
    return CHANGE_DESCRIPTION_ALIAS_MAP[source.sampledModelXoxOverview.xoxChangeDescription];
  }, [source]);

  useEffect(() => {
    if (source !== null) {
      return;
    }
    exec({
      resultId,
      targetSegmentId,
      targetSegmentClasses,
    });
  }, [source, exec, resultId, targetSegmentId, targetSegmentClasses]);

  if (deserializationError || (!isLoading && !source)) {
    return null;
  }

  if (isLoading) {
    return <GenericLoading />;
  }

  return (
    <div className={classNames(classes.SegmentPerformanceSummaryContainer, className)}>
      <SampledModelXoXOverviewSection
        {...source.sampledModelXoxOverview}
        showSubTitle={false}
        changeDescriptionAlias={changeDescriptionAlias}
      />

      {source.segmentsPerformanceSummaries.map(
        (segmentSummary: SegmentPerformanceSummaryItem, index: number) => (
          <SentenceContainer
            key={`${segmentSummary.segmentGroupName}_${segmentSummary.segmentName}_${index}`}
            className={classes.SummaryItem}
          >
            <SentencePart text={`When is comes to`} />
            <SentencePart
              text={`${segmentSummary.segmentGroupName} → ${segmentSummary.segmentName}`}
              bold
            />
            <SentencePart
              text={`the ${source.sampledModelXoxOverview.sampledModelType} measured at`}
            />
            <SentencePart text={number2k(segmentSummary.segmentValue)} bold />
            <SentencePart text={'in the analyzed period, reflecting'} />
            <SentencePart text={segmentSummary.segmentFormattedUplift} bold />
            <SentencePart
              text={`${segmentSummary.segmentChangeDescription} ${segmentSummary.xoxGranularity}.`}
            />
          </SentenceContainer>
        )
      )}
    </div>
  );
};
