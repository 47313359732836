import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {SourceFiltersDTO} from '../objects/dto/source.dto.ts';
import {CreateSourceDTO} from '../objects/dto/source.dto.ts';
import {toFiltersDTO} from '../objects/dto/to-filters.dto.ts';

export const getSourceNetworkRequest: NRC<void, void> = (id: number) => ({
  method: RequestType.GET,
  relativeUrl: `/app/sources/${id}`,
  responseTransformer: res => toCamelCase(res.data),
});

export const createSourceNetworkRequest: NRC<CreateSourceDTO, void> = (
  payload: CreateSourceDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/sources`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: toCamelCase,
});

export const getSourcesNetworkRequest: NRC<void, SourceFiltersDTO> = (
  payload: SourceFiltersDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/sources`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});
