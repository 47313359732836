import * as React from 'react';
import classNames from 'classnames';
import classes from '../smart-table.module.scss';
import {useContext} from 'react';
import {SmartTableContext} from '../smart-table.context';
import TransKeys from 'translations';
import {Button} from '../../../../../../simple/controls/button/button.component';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import {TableEmptyState as TES} from '../../../../types';

interface OwnProps {
  className?: string;
}

type AllProps = OwnProps;

export const TableEmptyState: React.FC<AllProps> = (props: AllProps) => {
  const {className} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {columns, options} = useContext(SmartTableContext);
  const {emptyState} = options;
  const {t} = useDocumentTranslation();

  if (React.isValidElement(emptyState)) {
    return (
      <tr className={classNames(classes.TableEmptyStateRow, className)}>
        <td colSpan={columns.length + 1}>
          <div className={classes.TableEmptyState}>{emptyState}</div>
        </td>
      </tr>
    );
  }

  const es = emptyState as TES;

  return (
    <tr className={classNames(classes.TableEmptyStateRow, className)}>
      <td colSpan={columns.length + 1}>
        {emptyState && (
          <div className={classes.TableEmptyState}>
            <div className={classes.Title}>{es.title}</div>
            {es.subTitle && <div className={classes.SubTitle}>{es.subTitle}</div>}
            {es.buttonText && es.buttonCommand && (
              <div className={classes.Button}>
                <Button variant={'outlined'} onClick={() => emitEvent(es.buttonCommand)}>
                  {es.buttonText}
                </Button>
              </div>
            )}
          </div>
        )}
        {!emptyState && (
          <div className={classes.TableEmptyState}>
            <div className={classes.SubTitle}>
              {t(TransKeys.DOCUMENT_VIEWER.SMART_TABLE.EMPTY_STATE.TITLE)}
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};
