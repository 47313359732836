import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './analysis-content-item-viewer.module.scss';
import {AnalysisContentItem, DocumentElement, DocumentElementType} from '../../../../../types';
import {AnalysisContentTitle} from '../analysis-content-title/analysis-content-title.component';
import {InfoBoxViewer} from '../../../info-box-viewer/info-box-viewer.component';
import {ChildRenderer} from '../../../../core/child-renderer.component';
import TransKeys from 'translations';
import {MarkdownViewer} from '../../../markdown-viewer/markdown-viewer.component';
import {PopoverWrapper} from '../../../../../../../simple/generic/popover-wrapper/popover-wrapper.component';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import {ContentOption} from '../analysis-content-renderer/analysis-content-renderer.component';

type Renderer = () => any;

interface OwnProps extends Omit<AnalysisContentItem, 'data'> {
  id?: string;
  data: DocumentElement[] | Renderer;
  onViewAllPhenomenas?: () => void;
  hasPhenomenas?: boolean;
  contentOptions?: ContentOption[];
  onContentSelected?: (key: string) => void;
  className?: string;
}

type AllProps = OwnProps;

export const AnalysisContentItemViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, phenomenas, hasPhenomenas, onViewAllPhenomenas, className} = props;
  const {t} = useDocumentTranslation();
  const phenomenasFigure = useMemo(() => {
    if (!phenomenas || !hasPhenomenas) {
      return null;
    }
    return {
      type: DocumentElementType.PHENOMENAS_FIGURE,
      options: {filters: phenomenas.filters},
      emptyStateRenderer: () => (
        <div className={classes.PhenomenasEmptyState}>
          <div className={classes.Text}>
            {t(TransKeys.DOCUMENT_VIEWER.ANALYSIS_CONTENT_ITEM.NO_PHENOMENAS_EMPTY_STATE.TITLE)}
          </div>
          {onViewAllPhenomenas && (
            <div className={classes.ViewAllButton} onClick={onViewAllPhenomenas}>
              {t(
                TransKeys.DOCUMENT_VIEWER.ANALYSIS_CONTENT_ITEM.NO_PHENOMENAS_EMPTY_STATE
                  .SEE_ALL_BUTTON
              )}
            </div>
          )}
        </div>
      ),
    };
  }, [phenomenas, hasPhenomenas, onViewAllPhenomenas, t]);
  const renderContent = () => {
    if (typeof props.data === 'function') {
      return props.data();
    }
    return <ChildRenderer children_={props.data} className={classes.MarginBottom} />;
  };

  const renderPhenomenas = () => {
    if (!phenomenasFigure) {
      return null;
    }
    return (
      <div className={classes.PhenomenasWrapper}>
        <div className={classes.Title}>
          {phenomenas.title ||
            t(TransKeys.DOCUMENT_VIEWER.ANALYSIS_FIGURE.PHENOMENAS_IN_SECTION_TITLE)}
        </div>
        {phenomenas.description && (
          <div className={classes.Description}>
            {<MarkdownViewer type={DocumentElementType.MARKDOWN} data={phenomenas.description} />}
          </div>
        )}
        <ChildRenderer children_={phenomenasFigure} />
      </div>
    );
  };

  return (
    <div id={id} className={classNames(classes.AnalysisContentItem, className)}>
      {props.title && (
        <div className={classes.Header}>
          <AnalysisContentTitle
            contentKey={id}
            title={props.title}
            icon={props.icon}
            helperText={props.helperText}
            headerLabels={props.headerLabels}
            isCausal={props.isCausal}
            contentOptions={props.contentOptions}
            onContentSelected={props.onContentSelected}
          />
          {props.tipBox && (
            <div className={classes.Description}>
              <MarkdownViewer
                type={DocumentElementType.MARKDOWN}
                data={props.tipBox.data.content}
              />
            </div>
          )}
          {props.infoBox && (
            <PopoverWrapper
              buttonRenderer={({onClick}) => (
                <div onClick={onClick} className={classes.ViewInfoButton}>
                  {t(TransKeys.DOCUMENT_VIEWER.ANALYSIS_CONTENT_ITEM.SEE_INFOBOX_BUTTON_TEXT)}
                </div>
              )}
            >
              <InfoBoxViewer
                className={classes.InfoBox}
                type={DocumentElementType.INFO_BOX_FIGURE}
                data={props.infoBox.data}
                options={{collapsible: false}}
              />
            </PopoverWrapper>
          )}
        </div>
      )}
      {renderContent()}
      {renderPhenomenas()}
    </div>
  );
};
