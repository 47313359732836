import * as React from 'react';
import classNames from 'classnames';
import classes from './smart-tree-selector.module.scss';
import {TreeData, TreeSelectorValue} from './tree-selector.component';
import {TextInput} from '../../forms/inputs/text-input/text-input.component';
import {exists} from 'front-core';
import {
  ChevronDownRegularIcon,
  CloseIcon,
  CircleInfoRegularIcon,
} from '../../simple/controls/icons/icons.component';
import {useCallback, useMemo, useState} from 'react';
import {TreeSelector} from './tree-selector.component';

export interface SmartTreeSelectorGroup {
  key: string;
  name: string;
  helperText?: any;
  data: TreeData[];
  tab?: string;
  startOpen?: boolean;
  // disable selection from other root parent for selected values
  oneRootAllowed?: boolean;
  oneRootAllowedHelper?: string;
}

interface OwnProps {
  value?: TreeSelectorValue;
  groups: SmartTreeSelectorGroup[];
  onChange?: (v: TreeSelectorValue, originalValue?: TreeSelectorValue) => void;
  selectParentValue?: boolean;
  startRootOpen?: boolean;
  multi?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const createInitialOpenMap = (groups: SmartTreeSelectorGroup[]) => {
  if (groups.length === 1) {
    return {[groups[0].key]: true};
  }
  const res = {};
  for (const g of groups) {
    if (g.startOpen) {
      res[g.key] = true;
    }
  }
  return res;
};

export const SmartTreeSelector: React.FC<AllProps> = (props: AllProps) => {
  const {value, groups, onChange, className} = props;
  const [search, setSearch] = useState<string>('');
  const [isOpenMap, setIsOpenMap] = useState<any>(createInitialOpenMap(groups));

  // tabs
  const tabs = useMemo(() => {
    const tabsSet = new Set(groups.map(s => s.tab));
    if (tabsSet.size > 1) {
      return Array.from(tabsSet);
    }
  }, [groups]);
  const [selectedTab_, setSelectedTab] = useState<string>(tabs ? tabs[0] : undefined);
  const selectedTab = useMemo(() => {
    if (selectedTab_) {
      return selectedTab_;
    }
    return tabs ? tabs[0] : undefined;
  }, [selectedTab_, tabs]);
  const renderGroups = useMemo(() => {
    if (selectedTab) {
      return groups.filter(s => s.tab === selectedTab);
    }
    return groups;
  }, [groups, selectedTab]);
  const onOpenChange = useCallback(
    async (groupKey: string) => {
      setIsOpenMap(map => ({
        ...map,
        [groupKey]: !Boolean(map[groupKey]),
      }));
    },
    [setIsOpenMap]
  );
  const isOpenAll = exists(search);

  const renderGroup = (group: SmartTreeSelectorGroup) => {
    const isOpen = isOpenAll || isOpenMap[group.key];
    const singleGroup = renderGroups.length === 1;

    return (
      <div
        key={group.key}
        className={classNames(
          classes.Group,
          (singleGroup || isOpen) && classes.Open,
          singleGroup && classes.SingleGroup
        )}
      >
        {renderGroups.length > 1 && (
          <div onClick={_ => onOpenChange(group.key)} className={classes.Header}>
            <div className={classes.OpenBtn}>
              <ChevronDownRegularIcon className={classes.Icon} />
            </div>
            <div className={classes.LabelText}>{group.name}</div>
          </div>
        )}
        <div className={classes.Tree}>
          {group.helperText && (
            <div className={classes.Helper}>
              <div className={classes.HelperIconWrapper}>
                <CircleInfoRegularIcon className={classes.InfoIcon} />
              </div>
              {group.helperText}
            </div>
          )}
          <TreeSelector
            className={classes.TreeSelect}
            value={value}
            data={group.data}
            onChange={onChange}
            searchValue={search}
            multi={props.multi}
            oneRootAllowed={group.oneRootAllowed}
            oneRootAllowedHelper={group.oneRootAllowedHelper}
            selectParentValue={props.selectParentValue}
            startRootOpen={props.startRootOpen}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(classes.SmartTreeSelector, className)}>
      <div className={classes.Search}>
        <TextInput
          value={search}
          onChange={setSearch as any}
          className={classes.SearchInput}
          placeholder={'Search'}
          rounded
          autoFocus
        />
        {exists(search) && (
          <div onClick={() => setSearch('')} className={classes.ClearButton}>
            <CloseIcon className={classes.Icon} />
          </div>
        )}
      </div>
      {tabs && (
        <div className={classes.Tabs}>
          {tabs.map(t => (
            <div
              key={t}
              onClick={() => setSelectedTab(t)}
              className={classNames(classes.Tab, selectedTab === t && classes.Active)}
            >
              {t}
            </div>
          ))}
        </div>
      )}
      <div className={classes.List}>{renderGroups.map(g => renderGroup(g))}</div>
    </div>
  );
};
