import * as React from 'react';
import {PopulationSizeSentenceExplainer} from '../rca-insights.model.ts';
import {
  ChangeInValueSentencePart,
  ScrollToSectionSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import {
  RCAExplainersIcon,
  RCAExplainersIconVariant,
} from '../../components/rca-explainers-icon/rca-explainers-icon.component.tsx';
import {RCAElementType} from 'ui-components';
import sharedClasses from '../../insights.module.scss';
import {RCAExplainersExplainabilityLabelDisplay} from '../../components/rca-explainers-explainability-label-display/rca-explainers-explainability-label-display.component.tsx';

interface OwnProps extends PopulationSizeSentenceExplainer {}

type AllProps = OwnProps;

const SpikeDropPopulationSizeAndSampledModelIncreaseDecreaseFormatter: React.FC<AllProps> = (
  props: AllProps
) => {
  const {formattedSentenceParts: fsp, sentenceParts, onViewOnPage, explainabilityLabel} = props;
  const {trendChangeValue} = sentenceParts;

  return (
    <SentenceContainer className={sharedClasses.Spaced}>
      {explainabilityLabel && (
        <RCAExplainersExplainabilityLabelDisplay label={explainabilityLabel} />
      )}
      <RCAExplainersIcon variant={RCAExplainersIconVariant.POPULATION_EXPLAINER} />
      <SentencePart text={'The number of'} />
      <SentencePart bold text={fsp.sampledModelEntity} />
      <SentencePart text={`${fsp.populationChangeDescription} by`} />
      <ChangeInValueSentencePart higherIsBetter={true} changeInValue={trendChangeValue} />
      <SentencePart
        text={`making it a ${fsp.explainabilityLabelDescription} explanation for the ${fsp.sampledModelType} ${fsp.sampledModelChangeDescription}`}
      />
      {onViewOnPage && (
        <ScrollToSectionSentencePart
          onViewOnPage={() => onViewOnPage(RCAElementType.POPULATION_EXPLAINER)}
        />
      )}
    </SentenceContainer>
  );
};

export {SpikeDropPopulationSizeAndSampledModelIncreaseDecreaseFormatter};
