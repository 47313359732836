import * as React from 'react';
import classNames from 'classnames';
import classes from './funnel-chart-tooltip.module.scss';
import {exists, number2k} from 'front-core';
import {useTranslation} from '../../../../../../core/translations/use-translation';
import TransKeys from 'translations';
import {
  ArrowRightIcon,
  BackwardFastLightIcon,
  BackwardStepLightIcon,
  PulseIcon,
  UserGroupDuotoneIcon,
} from '../../../../../simple/controls/icons/icons.component';
import {FunnelTrendValue} from '../../../../charts/chart-data.types';
import {FunnelTrend} from '../funnel-trend/funnel-trend.component';

interface OwnProps {
  startStep: string;
  endStep: string;
  total?: number;
  conversionFromPrev?: number;
  conversionFromStart?: number;
  trend?: FunnelTrendValue;
  className?: string;
}

type AllProps = OwnProps;

export const FunnelChartTooltip: React.FC<AllProps> = (props: AllProps) => {
  const {startStep, endStep, conversionFromStart, conversionFromPrev, trend, total, className} =
    props;
  const {t} = useTranslation();

  return (
    <div className={classNames(classes.FunnelChartTooltip, className)}>
      <div className={classes.Header}>
        <span className={classes.Label}>{startStep}</span>
        <ArrowRightIcon className={classes.Arrow} />
        <span className={classes.Label}>{endStep}</span>
      </div>
      <div className={classes.Content}>
        <div className={classes.Item}>
          <div className={classes.IconWrapper}>
            <UserGroupDuotoneIcon className={classes.Icon} />
          </div>
          <div className={classes.Label}>
            {t(TransKeys.DOCUMENT_VIEWER.FUNNEL_CHART_FIGURE.TOOLTIP.TOTAL)}
          </div>
          <div className={classes.Value}>{total.toLocaleString()}</div>
        </div>
        {!isNaN(conversionFromStart) && (
          <div className={classes.Item}>
            <div className={classes.IconWrapper}>
              <BackwardFastLightIcon className={classes.Icon} />
            </div>
            <div className={classes.Label}>
              {t(TransKeys.DOCUMENT_VIEWER.FUNNEL_CHART_FIGURE.TOOLTIP.CONVERSION_FROM_INITIAL)}
            </div>
            <div className={classes.Value}>
              {conversionFromStart !== 0 ? number2k(conversionFromStart * 100) : 0}%
            </div>
          </div>
        )}
        {!isNaN(conversionFromPrev) && (
          <div className={classes.Item}>
            <div className={classes.IconWrapper}>
              <BackwardStepLightIcon className={classes.Icon} />
            </div>
            <div className={classes.Label}>
              {t(TransKeys.DOCUMENT_VIEWER.FUNNEL_CHART_FIGURE.TOOLTIP.CONVERSION_FROM_PREV)}
            </div>
            <div className={classes.Value}>
              {conversionFromPrev !== 0 ? number2k(conversionFromPrev * 100) : 0}%
            </div>
          </div>
        )}
        {exists(trend) && (
          <div className={classes.Item}>
            <div className={classes.IconWrapper}>
              <PulseIcon className={classes.Icon} />
            </div>
            <div className={classes.Label}>
              {t(TransKeys.DOCUMENT_VIEWER.FUNNEL_CHART_FIGURE.TOOLTIP.TREND)}
            </div>
            <div className={classes.Value}>
              <FunnelTrend trend={trend} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
