import * as React from 'react';
import classNames from 'classnames';
import {ArrowRightLongLightIcon} from '../../../../../simple/controls/icons/icons.component';
import classes from './shared-columns-content.module.scss';
import {TooltipIfOverflow} from '../../../../../simple/generic/tooltips/tooltips.component';
import {HoverHelperTip} from '../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';

interface OwnProps {
  group: string;
  segment: string;
  helperText?: string;
  onClick?: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const SegmentDisplay: React.FC<AllProps> = (props: AllProps) => {
  const {group, segment, helperText, onClick, className} = props;

  return (
    <div onClick={onClick} className={classNames(classes.SegmentDisplay, className)}>
      <TooltipIfOverflow title={group}>
        <span className={classes.Group}>{group}</span>
      </TooltipIfOverflow>
      <ArrowRightLongLightIcon className={classes.Arrow} />
      <TooltipIfOverflow title={segment}>
        <span className={classes.Segment}>{segment}</span>
      </TooltipIfOverflow>
      {helperText && <HoverHelperTip className={classes.Info} title={helperText} />}
    </div>
  );
};
