import {DocumentElementType} from './types';
import {RootDocumentViewer} from './components/internal-viewers/root-document-viewer.component';
import {SectionViewer} from './components/base-viewers/section-viewer/section-viewer.component';
import {BlockViewer} from './components/base-viewers/block-viewer/block-viewer.component';
import {ImageViewer} from './components/base-viewers/image-viewer/image-viewer.component';
import {MarkdownViewer} from './components/base-viewers/markdown-viewer/markdown-viewer.component';
import {ChartViewer} from './components/base-viewers/chart-viewer/chart-viewer.component';
import {SelectBlockViewer} from './components/base-viewers/select-block-viewer/select-block-viewer.component';
import {ChartType} from './types';
import {FunnelAreaChart} from '../charts/charts/funnel-area-chart/funnel-area-chart.component';
import {BarChart} from '../charts/charts/bar-chart/bar-chart.component';
import {DumbbellChart} from '../charts/charts/dumbbell-chart/dumbbell-chart.component';
import {LineChart} from '../charts/charts/line-chart/line-chart.component';
import {ScatterChart} from '../charts/charts/scatter-chart/scatter-chart.component';
import {SwitchBlockViewer} from './components/base-viewers/switch-block-viewer/switch-block-viewer.component';
import {WindowBlockViewer} from './components/internal-viewers/window-block-viewer.components';
import {PieChart} from '../charts/charts/pie-chart/pie-chart.component';
import {PercentageLineChart} from '../charts/charts/percentage-line-chart/percentage-line-chart.component';
import {SmartTableViewer} from './components/base-viewers/smart-table-viewer/smart-table-viewer.component';
import {SentenceViewer} from './components/base-viewers/sentence-viewer/sentence-viewer.component';
import {GridLayoutViewer} from './components/base-viewers/grid-layout-viewer/grid-layout-viewer.component';
import {ErrorViewer} from './components/base-viewers/error-viewer/error-viewer.component';
import {DocumentViewerMode} from './document-viewer.types';
import {PhenomenasViewer} from './components/base-viewers/phenomenas-viewer/phenomenas-viewer.component';
import {TimeToEventViewer} from './components/base-viewers/time-to-event-viewer/time-to-event-viewer.component';
import {TipBoxViewer} from './components/base-viewers/tip-box-viewer/tip-box-viewer.component';
import {RCAHeaderViewer} from './components/viewers/rca-analysis/deprecated/rca-header-viewer/rca-header-viewer.component';
import {RetentionTableViewer} from './components/base-viewers/retention-table-viewer/retention-table-viewer.component';
import {DataValidationViewer} from './components/base-viewers/data-validation-viewer/data-validation-viewer.component';
import {ContentWrapperViewer} from './components/base-viewers/content-wrapper-viewer/content-wrapper-viewer.component';
import {RCATrendsViewer} from './components/viewers/rca-analysis/rca-trends-viewer/rca-trends-viewer.component';
import {AnalysisFigureViewer} from './components/base-viewers/analysis-figure-viewer/analysis-figure-viewer.component';
import {InfoBoxViewer} from './components/base-viewers/info-box-viewer/info-box-viewer.component';
import {OptionsPlacerViewer} from './components/base-viewers/options-placer-viewer/options-placer-viewer.component';
import {RCAComparedHeaderViewer} from './components/viewers/rca-analysis/deprecated/rca-compared-header-viewer/rca-compared-header-viewer.component';
import {HabitMomentViewer} from './components/viewers/habit-moment-analysis/habit-moment-viewer/habit-moment-viewer.component';
import {HeatmapViewer} from './components/base-viewers/heatmap-viewer/heatmap-viewer.component';
import {FunnelChart} from '../charts-v2/funnel-chart/funnel-chart.component';
import {FunnelOverviewViewer} from './components/viewers/funnel-analysis/funnel-overview-viewer/funnel-overview-viewer.component';
import {KPISegmentationViewer} from './components/viewers/kpi-analysis/kpi-segmentation-viewer/kpi-segmentation-viewer.component';
import {FunnelSegmentationViewer} from './components/viewers/funnel-analysis/funnel-segmentation-viewer/funnel-segmentation-viewer.component';
import {CommandButtonsViewer} from './components/base-viewers/command-buttons-viewer/command-buttons-viewer.component';
import {ChecklistViewer} from './components/base-viewers/checklist-viewer/checklist-viewer.component';
import {CompositeSegmentsViewer} from './components/base-viewers/composite-segments-viewer/composite-segments-viewer.component';
import {FunnelCompositeSegmentsViewer} from './components/viewers/funnel-analysis/funnel-composite-segments-viewer/funnel-composite-segments-viewer.component';
import {BaseTreatmentAdoptionSimulationViewer} from './components/viewers/drivers-analysis/treatment-adoption-simulation-viewer/base-treatment-adoption-simulation-viewer/base-treatment-adoption-simulation-viewer.component';
import {GoalDriversSimulatorViewer} from './components/viewers/drivers-analysis/treatment-adoption-simulation-viewer/goal-drivers-simulator-viewer.component';
import {JourneyDriversSimulatorViewer} from './components/viewers/drivers-analysis/treatment-adoption-simulation-viewer/journey-drivers-simulator-viewer.component';
import {CorrelationDriversSimulatorViewer} from './components/viewers/drivers-analysis/treatment-adoption-simulation-viewer/correlation-drivers-simulator-viewer.component';
import {EngagementDriversSimulatorViewer} from './components/viewers/drivers-analysis/treatment-adoption-simulation-viewer/engagement-drivers-simulator-viewer.component';
import {RetentionOverviewViewer} from './components/viewers/retention-analysis/retention-overview-viewer/retention-overview-viewer.component';
import {RetentionSegmentationViewer} from './components/viewers/retention-analysis/retention-segmentation-viewer/retention-segmentation-viewer.component';
import {TrendCompositeSegmentsViewer} from './components/base-viewers/trend-composite-segments-viewer/trend-composite-segments-viewer.component';
import {RCACompositeSegmentsViewer} from './components/viewers/rca-analysis/rca-composite-segments-viewer/rca-composite-segments-viewer.component';
import {PowerUsersOverviewViewer} from './components/viewers/power-users-analysis/power-users-overview-viewer/power-users-overview-viewer.component';
import {PowerUsersSegmentationFigureViewer} from './components/viewers/power-users-analysis/power-users-segmentation-viewer/power-users-segmentation-viewer.component';
import {TopLevelSelectBlockViewer} from './components/base-viewers/top-level-select-block-viewer/top-level-select-block-viewer.component';
import {TreatmentAdoptionSimulationModeSwitchWrapperViewer} from './components/viewers/drivers-analysis/treatment-adoption-simulation-viewer/treatment-adoption-simulation-mode-switch-wrapper-viewer/treatment-adoption-simulation-mode-switch-wrapper-viewer.component';
import {ABTestSummaryViewer} from './components/viewers/ab-test-analysis/ab-test-summary-viewer/ab-test-summary-viewer.component';
import {ExperimentContextViewer} from './components/viewers/ab-test-analysis/experiment-context-viewer/experiment-context-viewer.component';
import {ExperimentConclusionViewer} from './components/viewers/ab-test-analysis/experiment-conclusion-viewer/experiment-conclusion-viewer.component';
import {ReleaseImpactSummaryViewer} from './components/viewers/release-impact-analysis/release-impact-summary-viewer/release-impact-summary-viewer.component';
import {ContentFunnelOverviewViewer} from './components/viewers/funnel-analysis/content-funnel-overview-viewer/content-funnel-overview-viewer.component';
import {ReleaseImpactFeaturesSummaryViewer} from './components/viewers/release-impact-analysis/release-impact-features-summary-viewer/release-impact-features-summary-viewer.component';
import {ReleaseImpactFeatureEffectOnMetricFigureViewer} from './components/viewers/release-impact-analysis/release-impact-feature-effect-on-metric-viewer/release-impact-feature-effect-on-metric-viewer.component';
import {TitleSectionViewer} from './components/base-viewers/title-section-viewer/title-section-viewer.component';
import {RCASegmentationViewer} from './components/viewers/rca-analysis/rca-segmentation-viewer/rca-segmentation-viewer.component';
import {ReleaseImpactSegmentationViewer} from './components/viewers/release-impact-analysis/release-impact-segmentation-viewer/release-impact-segmentation-viewer.component';
import {ReleaseImpactStepsViewer} from './components/viewers/release-impact-analysis/release-impact-steps-viewer/release-impact-steps-viewer.component';
import {RCAOverviewViewer} from './components/viewers/rca-analysis/rca-overview-viewer/rca-overview-viewer.component';
import {RCAFigureViewer} from './components/viewers/rca-analysis/rca-figure/rca-figure-viewer.component';
import {KPIOverviewViewer} from './components/viewers/kpi-analysis/kpi-overview-viewer/kpi-overview-viewer.component';
import {LoadingStateViewer} from './components/base-viewers/loading-state-viewer/loading-state-viewer.component';
import {RCAFunnelFigureViewer} from './components/viewers/rca-analysis/rca-funnel-figure/rca-funnel-figure-viewer.component';
import {RCAPopulationSizeExplainerViewer} from './components/viewers/rca-analysis/rca-population-size-explainer-viewer/rca-population-size-explainer-viewer.component';
import {RCAExperimentExplainerViewer} from './components/viewers/rca-analysis/rca-experiment-explainer-viewer/rca-experiment-explainer-viewer.component';
import {ExternalFigurePlaceholderViewer} from './components/internal-viewers/external-figure-placeholder-viewer.component';
import {RemoteFigureResolver} from './components/internal-viewers/remote-figure-resolver/remote-figure-resolver.component';
import {ReleaseImpactCalculationBreakdownViewer} from './components/viewers/release-impact-analysis/release-impact-calculation-breakdown-viewer/release-impact-calculation-breakdown-viewer.component';

export const DocumentViewerComponents: any = {
  [DocumentElementType.DOCUMENT]: RootDocumentViewer,
  [DocumentElementType.SECTION]: SectionViewer,
  [DocumentElementType.TITLE_SECTION]: TitleSectionViewer,
  [DocumentElementType.BLOCK]: BlockViewer,
  [DocumentElementType.CONTENT_WRAPPER]: ContentWrapperViewer,
  [DocumentElementType.IMAGE]: ImageViewer,
  [DocumentElementType.MARKDOWN]: MarkdownViewer,
  [DocumentElementType.SMART_TABLE]: SmartTableViewer,
  [DocumentElementType.CHART]: ChartViewer,
  [DocumentElementType.SELECT_BLOCK]: SelectBlockViewer,
  [DocumentElementType.TOP_LEVEL_SELECT_BLOCK]: TopLevelSelectBlockViewer,
  [DocumentElementType.SWITCH_BLOCK]: SwitchBlockViewer,
  [DocumentElementType.STEPS_BLOCK]: ReleaseImpactStepsViewer,
  [DocumentElementType.RELEASE_IMPACT_STEPS_FIGURE]: ReleaseImpactStepsViewer,
  [DocumentElementType.SENTENCE_FIGURE]: SentenceViewer,
  [DocumentElementType.GRID_LAYOUT]: GridLayoutViewer,
  [DocumentElementType.ERROR]: ErrorViewer,
  [DocumentElementType.LOADING_STATE_FIGURE]: LoadingStateViewer,
  [DocumentElementType.PHENOMENAS_FIGURE]: PhenomenasViewer,
  [DocumentElementType.TIME_TO_EVENT]: TimeToEventViewer,
  [DocumentElementType.TIP_BOX]: TipBoxViewer,
  [DocumentElementType.INFO_BOX_FIGURE]: InfoBoxViewer,
  [DocumentElementType.RCA_HEADER]: RCAHeaderViewer,
  [DocumentElementType.RCA_COMPARED_HEADER]: RCAComparedHeaderViewer,
  [DocumentElementType.RCA_TRENDS]: RCATrendsViewer,
  [DocumentElementType.RCA_OVERVIEW_FIGURE]: RCAOverviewViewer,
  [DocumentElementType.RETENTION_TABLE_FIGURE]: RetentionTableViewer,
  [DocumentElementType.DATA_VALIDATION]: DataValidationViewer,
  [DocumentElementType.ANALYSIS_FIGURE]: AnalysisFigureViewer,
  [DocumentElementType.OPTIONS_PLACER]: OptionsPlacerViewer,
  [DocumentElementType.KPI_SEGMENTATION_FIGURE]: KPISegmentationViewer,
  [DocumentElementType.FUNNEL_SEGMENTATION_FIGURE]: FunnelSegmentationViewer,
  [DocumentElementType.RETENTION_SEGMENTATION_FIGURE]: RetentionSegmentationViewer,
  [DocumentElementType.HABIT_MOMENT_FIGURE]: HabitMomentViewer,
  [DocumentElementType.FUNNEL_OVERVIEW_FIGURE]: FunnelOverviewViewer,
  [DocumentElementType.HEATMAP]: HeatmapViewer,
  [DocumentElementType.COMMAND_BUTTONS]: CommandButtonsViewer,
  [DocumentElementType.CHECKLIST]: ChecklistViewer,
  [DocumentElementType.RETENTION_OVERVIEW_FIGURE]: RetentionOverviewViewer,
  [DocumentElementType.POWER_USERS_OVERVIEW_FIGURE]: PowerUsersOverviewViewer,
  [DocumentElementType.POWER_USERS_SEGMENTATION_FIGURE]: PowerUsersSegmentationFigureViewer,
  [DocumentElementType.CONTENT_FUNNEL_OVERVIEW_FIGURE]: ContentFunnelOverviewViewer,
  [DocumentElementType.RCA_SEGMENTATION_FIGURE]: RCASegmentationViewer,
  [DocumentElementType.RCA_POPULATION_EXPLAINER_FIGURE]: RCAPopulationSizeExplainerViewer,
  [DocumentElementType.RELEASE_IMPACT_SEGMENTATION_FIGURE]: ReleaseImpactSegmentationViewer,
  [DocumentElementType.RCA_FIGURE]: RCAFigureViewer,
  [DocumentElementType.RCA_FUNNEL_FIGURE]: RCAFunnelFigureViewer,
  [DocumentElementType.RCA_EXPERIMENT_EXPLAINER_FIGURE]: RCAExperimentExplainerViewer,
  [DocumentElementType.KPI_OVERVIEW_FIGURE]: KPIOverviewViewer,
  // Composite segments variations
  [DocumentElementType.COMPOSITE_SEGMENTS_FIGURE]: CompositeSegmentsViewer,
  [DocumentElementType.FUNNEL_COMPOSITE_SEGMENTS_FIGURE]: FunnelCompositeSegmentsViewer,
  [DocumentElementType.TREND_COMPOSITE_SEGMENTS_FIGURE]: TrendCompositeSegmentsViewer,
  [DocumentElementType.RCA_COMPOSITE_SEGMENTS_FIGURE]: RCACompositeSegmentsViewer,
  // Treatment adoption simulation variations
  [DocumentElementType.TREATMENT_ADOPTION_SIMULATION_FIGURE]: BaseTreatmentAdoptionSimulationViewer,
  [DocumentElementType.GOAL_DRIVERS_SIMULATION_FIGURE]: GoalDriversSimulatorViewer,
  [DocumentElementType.JOURNEY_DRIVERS_SIMULATION_FIGURE]: JourneyDriversSimulatorViewer,
  [DocumentElementType.CORRELATION_DRIVERS_SIMULATION_FIGURE]: CorrelationDriversSimulatorViewer,
  [DocumentElementType.ENGAGEMENT_DRIVERS_SIMULATION_FIGURE]: EngagementDriversSimulatorViewer,
  [DocumentElementType.TREATMENT_ADOPTION_SIMULATION_MODE_SWITCH_WRAPPER_FIGURE]:
    TreatmentAdoptionSimulationModeSwitchWrapperViewer,
  // AB Tests
  [DocumentElementType.EXPERIMENT_CONTEXT_FIGURE]: ExperimentContextViewer,
  [DocumentElementType.EXPERIMENT_CONCLUSION_FIGURE]: ExperimentConclusionViewer,
  [DocumentElementType.AB_TEST_CONTEXT_WRAPPER_FIGURE]: ExperimentContextViewer, // Backward compatibility
  [DocumentElementType.AB_TEST_CONCLUSION_FIGURE]: ExperimentConclusionViewer, // Backward compatibility
  [DocumentElementType.AB_TEST_SUMMARY_FIGURE]: ABTestSummaryViewer,
  [DocumentElementType.RELEASE_IMPACT_SUMMARY_FIGURE]: ReleaseImpactSummaryViewer,
  [DocumentElementType.RELEASE_IMPACT_CALCULATION_BREAKDOWN_FIGURE]:
    ReleaseImpactCalculationBreakdownViewer,
  [DocumentElementType.RELEASE_IMPACT_FEATURES_SUMMARY_FIGURE]: ReleaseImpactFeaturesSummaryViewer,
  [DocumentElementType.RELEASE_IMPACT_FEATURE_EFFECT_ON_METRIC_FIGURE]:
    ReleaseImpactFeatureEffectOnMetricFigureViewer,
  // internal
  [DocumentElementType._WINDOW_BLOCK]: WindowBlockViewer,
  // external figures - rendered in app
  [DocumentElementType._FEEDBACK]: ExternalFigurePlaceholderViewer,
  [DocumentElementType._EXECUTIVE_SUMMARY_FIGURE]: ExternalFigurePlaceholderViewer,
  [DocumentElementType._KPI_TREE_FIGURE]: ExternalFigurePlaceholderViewer,
  [DocumentElementType._METRIC_SUGGESTIONS_FIGURE]: ExternalFigurePlaceholderViewer,
  [DocumentElementType.RCA_KPI_TREE_EXPLAINER_FIGURE]: ExternalFigurePlaceholderViewer,
  // remote figures
  [DocumentElementType.REMOTE_RCA_KPI_TREE_EXPLAINER_FIGURE]: RemoteFigureResolver,
};

export const ChartComponents: any = {
  [ChartType.FUNNEL_AREA]: FunnelAreaChart,
  [ChartType.BAR]: BarChart,
  [ChartType.DUMBBELL]: DumbbellChart,
  [ChartType.FUNNEL]: FunnelChart,
  [ChartType.LINE]: LineChart,
  [ChartType.PIE]: PieChart,
  [ChartType.SCATTER]: ScatterChart,
  [ChartType.PERCENTAGE_LINE]: PercentageLineChart,
};

export const MarkerableComponents = [DocumentElementType.CHART, DocumentElementType.SMART_TABLE];

export const EditorDocumentModes = [DocumentViewerMode.OPPORTUNITY_MODE, DocumentViewerMode.DEBUG];
