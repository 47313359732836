import * as React from 'react';
import classNames from 'classnames';
import classes from './funnel-trend.module.scss';
import {FunnelTrendValue} from '../../../../charts/chart-data.types';
import {
  ArrowTrendDownSolidIcon,
  ArrowTrendUpSolidIcon,
} from '../../../../../simple/controls/icons/icons.component';
import {number2k} from 'front-core';

interface OwnProps {
  className?: string;
  trend: FunnelTrendValue;
}

type AllProps = OwnProps;

export const FunnelTrend: React.FC<AllProps> = (props: AllProps) => {
  const {trend, className} = props;
  const isPositive = trend.value > 0;

  return (
    <div
      className={classNames(
        classes.FunnelTrend,
        trend.isSignificant && classes.Significant,
        isPositive ? classes.Positive : classes.Negative,
        className
      )}
    >
      {trend.isSignificant &&
        (isPositive ? (
          <ArrowTrendUpSolidIcon className={classes.Icon} />
        ) : (
          <ArrowTrendDownSolidIcon className={classes.Icon} />
        ))}
      {Math.sign(trend.value) === 1 ? '+' : ''}
      {number2k(trend.value * 100)}%
    </div>
  );
};
