import * as React from 'react';
import {useMemo} from 'react';
import {QueryBlock} from '../components/query-block/query-block.component';
import {DatePart, Part} from '../query-builder.types';
import {values} from 'lodash';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {InlineToken} from '../components/parts';
import {DatePartName} from '../query-builder.config';
import {queryBuilderClasses} from '../query-builder.component';
import {concatPath} from '../query-builder.utils';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {Select} from '../../../forms/inputs/select/select.component';
import {useQueryElement} from '../query-builder.hooks';
import {withViewMode} from '../core/with-view-mode.hoc';

interface OwnProps {
  data: DatePart;
  inline?: boolean;
  box?: boolean;
}

type AllProps = OwnProps & QueryBuilderComponent;

export const DatePartBuilderComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled, inline, box} = props;
  const {localErrors, actions} = useQueryElement(props);
  const options = useMemo(
    () =>
      values(Part).map(o => ({
        label: DatePartName[o],
        value: o,
      })),
    []
  );

  return (
    <QueryBlock
      disabled={disabled}
      className={className}
      label={'Date Part'}
      actions={actions}
      inline={inline}
      box={box}
    >
      <InlineToken>EQUAL</InlineToken>
      <QueryElementBuilder
        error={Boolean(localErrors.value)}
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'value')}
        data={data.value as any}
        box={false}
        inline
      />
      <Select
        className={queryBuilderClasses.Inline}
        dropdownButtonClassName={queryBuilderClasses.Button}
        placeholder={'Operator'}
        value={data.part}
        error={Boolean(localErrors.part)}
        onChange={v => onChange(concatPath(path, 'part'), v)}
        searchable={false}
        clearable={false}
        options={{options}}
        disabled={disabled}
      />
    </QueryBlock>
  );
};

export const DatePartViewerComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, className} = props;

  return (
    <QueryBlock
      className={className}
      label={'Date Part'}
      disabled={true}
      inline={true}
      box={false}
      fitContent
    >
      <QueryElementBuilder
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'value')}
        data={data.value as any}
        box={false}
        inline
      />
      <InlineToken>{DatePartName[data.part]}</InlineToken>
    </QueryBlock>
  );
};

export const DatePartBuilder = withViewMode(DatePartBuilderComponent, DatePartViewerComponent);
