import * as React from 'react';
import classes from './table-of-content.module.scss';
import classNames from 'classnames';
import {CommandType, DocumentIconType, SectionStatus} from '../../../../types';
import {ArrowRightIcon} from '../../../../../../simple/controls/icons/icons.component';
import {DocumentIcon} from '../../../internal-viewers/document-icon.component';
import {useCallback, useContext} from 'react';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';

export interface TableOfContentItem {
  slug: string;
  title: string;
  icon?: DocumentIconType;
  status?: SectionStatus;
}

export interface OwnProps {
  title?: string;
  items: TableOfContentItem[];
  className?: string;
}

// DEPRECATED
export const TableOfContent: React.FC<OwnProps> = (props: OwnProps) => {
  const {title, items, className} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const onLinkClicked = useCallback(
    link => {
      emitEvent({
        type: CommandType.DOCUMENT_ANCHOR,
        payload: {id: link.slug},
      });
    },
    [emitEvent]
  );

  return (
    <nav className={classNames(classes.TableOfContent, className)}>
      {title && <div className={classes.Title}>{title}</div>}
      <ul className={classes.List}>
        {items.map(item => (
          <li
            key={item.slug}
            onClick={() => onLinkClicked(item)}
            className={classNames(
              classes.Item,
              item.status === SectionStatus.NOT_MEANINGFUL && classes.Low
            )}
          >
            <span className={classes.Link}>
              <div className={classes.StatusContent}>
                <div className={classes.Circle} />
              </div>
              {item.icon && <DocumentIcon icon={item.icon} className={classes.Icon} />}
              <div className={classes.Label}>{item.title}</div>
            </span>
            <ArrowRightIcon className={classes.Arrow} />
          </li>
        ))}
      </ul>
    </nav>
  );
};
