import {GlobalDocumentDataContext, RCAFigureViewerContext, RCAOverviewViewer} from 'ui-components';
import {useCallback, useContext, useEffect, useState} from 'react';
import {getRCACompareDataNetworkRequest} from '../../../../../http/homepage.network-requests';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../../constants/time-formats';
import {HttpClientContext} from 'front-core';
import {useNavigate} from 'react-router';
import {
  METRIC_PAGE_DATE_QUERY_PARAM,
  METRIC_PAGE_GRANULARITY_QUERY_PARAM,
} from '../../../../metrics/pages/metric-page/metric-page.component.tsx';
import {AppRoutes, TEAM_ID_PATH_PARAM} from '../../../../../constants/app-routes.ts';
import {getSignalInfoNetworkRequest} from '../../../../../http/signals.network-requests.ts';

export const AppRCAOverviewViewer = (props: any) => {
  const http = useContext(HttpClientContext);
  const navigate = useNavigate();
  const {mode: rcaMode, granularity, signalId} = useContext(RCAFigureViewerContext);
  const {teamId} = useContext(GlobalDocumentDataContext);
  const [metricId, setMetricId] = useState<number>(null);

  const RCAMetricsResolver = useCallback(
    async (granularity: string, startDate: string, endDate: string) => {
      return await http.exec(
        getRCACompareDataNetworkRequest({
          granularity,
          fromDatetime: moment.utc(startDate).format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
          toDatetime: moment.utc(endDate).format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
        })
      );
    },
    [http]
  );

  const onSampleClicked = useCallback(
    sample => {
      const metricPageQueryParams = {
        rcaMode,
        [METRIC_PAGE_DATE_QUERY_PARAM]: moment
          .utc(sample.datetime)
          .format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
        [METRIC_PAGE_GRANULARITY_QUERY_PARAM]: granularity,
        [TEAM_ID_PATH_PARAM]: teamId,
      };
      return navigate(AppRoutes.viewMetric(metricId, metricPageQueryParams));
    },
    [granularity, metricId, navigate, rcaMode, teamId]
  );

  const loadMetricId = useCallback(async () => {
    const signalInfo = (await http.exec(getSignalInfoNetworkRequest(signalId))) as any;
    if (signalInfo.relatedTo.type === 'metric') {
      setMetricId(signalInfo.relatedTo.id);
    }
  }, [signalId, http, setMetricId]);

  useEffect(() => {
    loadMetricId();
  }, [loadMetricId]);

  return (
    <RCAOverviewViewer
      {...props}
      resolveRCAMetrics={RCAMetricsResolver}
      onSampleClicked={onSampleClicked}
    />
  );
};
