import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import classNames from 'classnames';
import classes from './retention-segmentation-tables.module.scss';
import {
  AnalysisAlgoMode,
  CommandType,
  DocumentElementType,
  RetentionSegmentationItemType,
  RetentionOverviewFigureOptions,
} from '../../../../../../types';
import {exists, Sorting} from 'front-core';
import {useDocumentTracking} from '../../../../../../hooks/use-document-tracking.hook';
import {SimulatorItemType, useSimulator} from '../../../../../../hooks/use-simulator.hook';
import {capitalize, flatten, get, groupBy, max, range} from 'lodash';
import {FlexibleTable} from '../../../../../shared/general/flexible-table/flexible-table.component';
import TransKeys from 'translations';
import {ModelType} from '../../../../../../../../../consts/model-type';
import {VersusDisplay} from '../../../../../shared/display-columns/versus-display.component';
import {GradientLabelDisplay} from '../../../../../shared/display-columns/gradient-label-display.component';
import pluralize from 'pluralize';
import {SegmentDisplay} from '../../../../../shared/display-columns/segment-display.component';
import {PercentageAbsDisplay} from '../../../../../shared/display-columns/percentage-abs-display.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {DocumentCommandEmitterContext} from '../../../../../../contexts/document-command-emitter.context';
import {SimulatorSlider} from '../../../../../shared/inputs/simulator-slider/simulator-slider.component';
import {PlaceholderTextDisplay} from '../../../../../shared/display-columns/placeholder-text-display.component';
import {getPercentageValueFormatter} from '../../../../../../../../../utils/formatters';
import {useDocQuery} from '../../../../../../hooks/use-doc-query.hook';
import {AppTabs} from '../../../../../../../../simple/navigation/app-tabs/app-tabs.component';
import {
  DocumentMessage,
  DocumentMessageType,
} from '../../../../../shared/general/document-message/document-message.component';
import {ExtendedRetentionSegmentationItem} from '../../retention-segmentation-viewer.component';

interface OwnProps {
  figureId: string;
  options: RetentionOverviewFigureOptions;
  buckets: string[];
  items: ExtendedRetentionSegmentationItem[];
  selectedBucketIndex: number;
  goalValue: number;
  // onFollowUp: (goal: number, refDate: number, segment: number, segmentClass: string) => void;
  className?: string;
}

type AllProps = OwnProps;

const DEFAULT_SORT: Sorting = {
  order: 'desc',
  orderBy: 'maxPotential',
};
const OTHER_TAB_SORT: Sorting = {
  order: 'desc',
  orderBy: 'goalInSegment',
};
const PRIMARY_SORT: Sorting = {
  order: 'desc',
  orderBy: 'isInformative',
};
const MINIMUM_SHARE = 0.0001;

const TAB_MAPPING = {
  [RetentionSegmentationItemType.POSITIVE]: RetentionSegmentationItemType.POSITIVE,
  [RetentionSegmentationItemType.NO_RECOMMENDATION]:
    RetentionSegmentationItemType.NO_RECOMMENDATION,
  [RetentionSegmentationItemType.INSIGNIFICANT_POSITIVE]:
    RetentionSegmentationItemType.INSIGNIFICANT,
};

const getInitialTab = itemsGroupByTab => {
  if (itemsGroupByTab[RetentionSegmentationItemType.POSITIVE]?.length > 0) {
    return RetentionSegmentationItemType.POSITIVE;
  }
  if (itemsGroupByTab[RetentionSegmentationItemType.INSIGNIFICANT]?.length > 0) {
    return RetentionSegmentationItemType.INSIGNIFICANT;
  }
  if (itemsGroupByTab[RetentionSegmentationItemType.NO_RECOMMENDATION]?.length > 0) {
    return RetentionSegmentationItemType.NO_RECOMMENDATION;
  }
};

export const RetentionSegmentationSimulatorMode: React.FC<AllProps> = React.memo(
  (props: AllProps) => {
    const {
      goalValue,
      items: itemsFromProps,
      buckets,
      selectedBucketIndex,
      options,
      figureId,
      // onFollowUp,
      className,
    } = props;
    const {t} = useDocumentTranslation();
    const {onSignalClick} = useContext(DocumentCommandEmitterContext);
    const {trackInput} = useDocumentTracking(
      figureId,
      DocumentElementType.FUNNEL_SEGMENTATION_FIGURE
    );
    const segmentPerBucket = useMemo(
      () =>
        flatten(
          itemsFromProps.map(s => {
            const res = [];
            const segment = {...s};
            for (const bucketIndex of range(buckets.length - 1)) {
              const type = segment.type[bucketIndex];
              res.push({
                ...segment,
                key: `${s.key}_${bucketIndex}`,
                simulationType: SimulatorItemType.POSITIVE,
                potentialLift: segment.potentialLift[bucketIndex],
                potentialLiftUpper: get(segment.potentialLiftUpper, bucketIndex),
                potentialLiftLower: get(segment.potentialLiftLower, bucketIndex),
                value: segment.goalInSegment[bucketIndex],
                goalInSegment: segment.goalInSegment[bucketIndex],
                goalInNonSegment: segment.goalInNonSegment[bucketIndex],
                tab: TAB_MAPPING[type],
                bucketIndex,
                segment: segment.signalId[0],
                segmentClass: segment.segmentName[0],
                helperText: segment.helperText[bucketIndex],
                info: segment.info ? segment.info[bucketIndex] : '',
              });
            }
            return res;
          })
        ).filter(s => {
          const isRelevantForBucket = exists(selectedBucketIndex)
            ? s.bucketIndex === selectedBucketIndex
            : true;
          const hasMinimumShare = s.share > MINIMUM_SHARE;
          return isRelevantForBucket && hasMinimumShare;
        }),
      [itemsFromProps, buckets, selectedBucketIndex]
    );
    const itemsGroupByTab = useMemo(() => groupBy(segmentPerBucket, 'tab'), [segmentPerBucket]);
    const {query: selectedType, setQuery: setSelectedType} =
      useDocQuery<RetentionSegmentationItemType>(figureId, getInitialTab(itemsGroupByTab), 'type');
    const {items, onItemChangeValue, changedValuesMap} = useSimulator({
      items: itemsGroupByTab[selectedType] || [],
      goalValue: goalValue,
    });
    const kpiMaxPotential = useMemo(() => max(items.map(i => i.maxPotential)), [items, goalValue]);
    const valueFormatter = useMemo(() => getPercentageValueFormatter(), [options]);
    const onDragComplete = useCallback(
      (controlType: 'master' | 'conversion', value) =>
        trackInput(controlType, {value, inputType: 'slider'}),
      [trackInput]
    );
    const tabs = useMemo(
      () =>
        [
          RetentionSegmentationItemType.POSITIVE,
          RetentionSegmentationItemType.INSIGNIFICANT,
          RetentionSegmentationItemType.NO_RECOMMENDATION,
        ].map(type => ({
          key: type,
          label: `${t(
            TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABS[type.toUpperCase()]
          )} (${itemsGroupByTab[type]?.length || 0})`,
          disabled: !exists(itemsGroupByTab[type]) || itemsGroupByTab[type].length === 0,
        })),
      [itemsGroupByTab]
    );
    const columns = useMemo(
      () =>
        [
          {
            key: ['segmentGroupName', 'segmentName'],
            title: [
              t(
                TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.GROUP_NAME
                  .LABEL
              ),
              t(
                TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.SEGMENT.LABEL
              ),
            ],
            helperText: t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.SEGMENT
                .HELPER_TEXT
            ),
            sortable: true,
            weight: 2,
            render: item => (
              <SegmentDisplay
                onClick={() => onSignalClick(item.signalId[0])}
                group={item.segmentGroupName[0]}
                segment={item.segmentName[0]}
                helperText={item.helperText}
              />
            ),
          },
          {
            key: 'bucketIndex',
            title: t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.BUCKET.LABEL
            ),
            helperText: t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.BUCKET
                .HELPER_TEXT
            ),
            weight: 0.8,
            sortable: true,
            render: item => {
              return capitalize(buckets[item.bucketIndex]);
            },
          },
          {
            key: 'share',
            title: t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.SHARE_OF_X
                .LABEL,
              {
                entity: pluralize(options.entity),
              }
            ),
            helperText: t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.SHARE_OF_X
                .HELPER_TEXT,
              {
                entity: pluralize(options.entity),
              }
            ),
            weight: 1.2,
            sortable: true,
            render: item => (
              <PercentageAbsDisplay
                percentage={item.share}
                abs={item.count}
                unit={pluralize(options.entity)}
              />
            ),
          },
          {
            key: 'goalInSegment',
            title: t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.GOAL_IN_SEGMENT
                .LABEL
            ),
            weight: 1,
            sortable: true,
            helperText: t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.GOAL_IN_SEGMENT
                .HELPER_TEXT
            ),
            render: item => (
              <VersusDisplay
                key={item.key}
                value={item.goalInSegment}
                vsValue={item.goalInNonSegment}
                isPercentageValue={true}
                vsLabel={t(
                  TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.TABLE.HEADERS
                    .GOAL_IN_NON_SEGMENT.LABEL
                )}
              />
            ),
          },
          {
            key: 'change',
            title: t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS
                .TARGET_RETENTION_RATE.LABEL,
              {entity: options.entity}
            ),
            helperText: t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS
                .TARGET_RETENTION_RATE.HELPER_TEXT,
              {entity: options.entity}
            ),
            types: [RetentionSegmentationItemType.POSITIVE],
            weight: 2,
            render: item => {
              if (exists(item.maxChange) && item.maxChange > 0) {
                return (
                  <SimulatorSlider
                    min={0}
                    max={1000}
                    displayValue={item.newValue * 100}
                    value={item.change * 100}
                    defaultValue={item.defaultChange * 100}
                    onChange={v => onItemChangeValue(item.key, exists(v) ? v / 100 : null)}
                    onChangeCommitted={v =>
                      onDragComplete('conversion', exists(v) ? v / 100 : null)
                    }
                    allowedMax={item.maxChange * 100}
                    suffix={'%'}
                    sign={'+'}
                    allowedMaxHelper={t(
                      TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SLIDER_MAX_HELPER
                    )}
                  />
                );
              }
              return (
                <PlaceholderTextDisplay>
                  {t(
                    TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE
                      .TARGET_RETENTION_RATE_0_OR_100
                  )}
                </PlaceholderTextDisplay>
              );
            },
          },
          {
            key: 'maxPotential',
            title: t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.POTENTIAL_KPI
                .LABEL
            ),
            helperText: t(
              TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS.POTENTIAL_KPI
                .HELPER_TEXT
            ),
            weight: 1.5,
            sortable: true,
            types: [
              RetentionSegmentationItemType.POSITIVE,
              RetentionSegmentationItemType.INSIGNIFICANT,
            ],
            render: item => {
              const isInsignificant = selectedType === RetentionSegmentationItemType.INSIGNIFICANT;
              return (
                <GradientLabelDisplay
                  value={item.potential * 100}
                  min={goalValue * 100}
                  max={kpiMaxPotential * 100}
                  tooltipLabel={t(
                    TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.LABELS
                      .CONFIDENCE_INTERVAL
                  )}
                  valueUpper={item.potentialUpper * 100}
                  valueLower={item.potentialLower * 100}
                  mode={isInsignificant ? 'range' : 'default'}
                  suffix={'%'}
                  showDiff
                />
              );
            },
          },
          {
            key: 'message',
            title: t(
              TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.HEADERS.INFO.LABEL
            ),
            helperText: t(
              TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.HEADERS.INFO
                .HELPER_TEXT
            ),
            weight: 3,
            types: [RetentionSegmentationItemType.NO_RECOMMENDATION],
            render: item => item.info,
          },
        ].filter(c => (c.types ? c.types.indexOf(selectedType) > -1 : true)),
      [options, valueFormatter, kpiMaxPotential, changedValuesMap, goalValue, selectedType, buckets]
    );

    const instructions = t(
      TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.INSTRUCTIONS[
        selectedType?.toUpperCase()
      ]
    );

    return (
      <>
        <AppTabs
          className={classes.Tabs}
          tabs={tabs}
          selected={selectedType}
          onChange={tab => setSelectedType(tab as any)}
          fullWidth
        />
        {instructions && <div className={classes.Instructions}>{instructions}</div>}
        <FlexibleTable
          // this key is used to re-trigger initial sort of FlexibleTable
          key={items.length}
          className={classNames(classes.FunnelSegmentationSimulatorMode, className)}
          rowClassName={classes.SimulatorRow}
          columns={columns}
          data={items}
          defaultSort={
            selectedType === RetentionSegmentationItemType.POSITIVE ? DEFAULT_SORT : OTHER_TAB_SORT
          }
          primarySort={PRIMARY_SORT}
          afterHeadersRenderer={() =>
            selectedType === RetentionSegmentationItemType.INSIGNIFICANT ? (
              <DocumentMessage
                text={t(
                  TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.LABELS.INSIGNIFICANT
                )}
                type={DocumentMessageType.WARN}
              />
            ) : null
          }
          // hiddenDrawer={item => (
          //   <div>
          //     <Button
          //       caps={false}
          //       variant={'outlined'}
          //       onClick={withStopPropagation(() =>
          //         onFollowUp(item.goal, item.refDate, item.segment, item.segmentClass)
          //       )}
          //     >
          //       {t(TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.ACTIONS.HOT_TO_IMPROVE)}
          //     </Button>
          //   </div>
          // )}
        />
      </>
    );
  }
);
