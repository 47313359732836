import * as React from 'react';
import {DocumentElementType, MetricSampleData, RCAMetric, RCAMode} from '../../../../../../types';
import {useCallback, useMemo} from 'react';
import {generateMainDSForChart} from '../../rca-overview-utils';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {FancyBlock} from '../../../../../../../../simple/data-display/fancy-block/fancy-block.component';
import pluralize from 'pluralize';
import moment from 'moment';
import {getFirstAndLastPartialSamples} from '../../../../utils/samples.utils';
import {capitalize} from 'lodash';

interface OwnProps {
  metric: RCAMetric;
  mode: RCAMode;
  analyzedDate: string;
  comparedDate: string;
  onSampleClicked?: (sample: MetricSampleData) => void;
  className?: string;
}

type AllProps = OwnProps;

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_OVERVIEW;

export const RCAOverviewMainView: React.FC<AllProps> = (props: AllProps) => {
  const {metric, analyzedDate, comparedDate, mode, onSampleClicked, className} = props;
  const {t} = useDocumentTranslation();

  const onDatasetPointClick = useCallback(
    pointData => onSampleClicked(pointData.point.metadata.sample),
    [onSampleClicked]
  );
  const mainChart = useMemo(() => {
    if (metric.samples.length === 0) {
      return;
    }
    const mainDS = generateMainDSForChart({
      samples: metric.samples,
      name: metric.name,
      isPercentageValue: metric.isPercentageValue,
      higherIsBetter: metric.higherIsBetter,
      analyzedDate: analyzedDate,
      comparedDate: comparedDate,
      showMarkColors: mode === RCAMode.LOOPS_ALGO,
    });
    const datasets: any[] = [mainDS];
    const displayedDSIds = [mainDS.id];
    const entityLabel = capitalize(pluralize(metric.entity));
    if (metric.hasEntityCount) {
      const entityCountDS = {
        id: 'entity-count',
        label: `#${entityLabel}`,
        yAxis: 'secondary',
        data: metric.samples.map(s => ({
          x: s.datetime,
          y: s.entityCount,
          dashed: s.isPartial,
          clickable: false,
        })),
      };
      datasets.push(entityCountDS);
    }

    let markArea: any;
    const {firstPartial, lastPartial} = getFirstAndLastPartialSamples(metric.samples);
    if (firstPartial && lastPartial) {
      markArea = {
        from: moment
          .utc(firstPartial.datetime)
          .subtract(1, metric.granularity)
          .format('YYYY-MM-DD'),
        to: lastPartial.datetime,
        title: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_TITLE),
        description: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_DESCRIPTION),
      };
    } else {
      const lastDate = mainDS.data[mainDS.data.length - 1].x;
      markArea = {
        from: lastDate,
        to: moment.utc(lastDate).add(1, metric.granularity).format('YYYY-MM-DD'),
        title: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_TITLE),
        description: t(TransKeys.HOMEPAGE.CHART.INCOMPLETE_DESCRIPTION),
      };
    }

    // Last year samples
    if (metric.lastYearSamples && metric.lastYearSamples.length > 0) {
      const samplesDatetimeSet = new Set(metric.samples.map(s => s.datetime));
      const lastYearSamples = metric.lastYearSamples.filter(s =>
        samplesDatetimeSet.has(s.matchingDatetime)
      );
      datasets.push({
        id: 'last-year-values',
        label: `${metric.name} (last year)`,
        data: lastYearSamples.map(d => ({
          x: d.matchingDatetime,
          actualX: d.datetime,
          y: metric.isPercentageValue ? d.value * 100 : d.value,
          clickable: false,
        })),
      });
      if (metric.hasEntityCount) {
        const lastYearEntityCountDS = {
          id: 'entity-count-last-year',
          label: `#${entityLabel} (last year)`,
          yAxis: 'secondary',
          data: lastYearSamples.map(d => ({
            x: d.matchingDatetime,
            actualX: d.datetime,
            y: d.entityCount,
            clickable: false,
          })),
        };
        datasets.push(lastYearEntityCountDS);
      }
    }

    return {
      labels: null,
      id: 'rca-overview-main',
      data: datasets,
      onDatasetPointClick: onDatasetPointClick,
      pointTooltipCta: t(TransKeys.HOMEPAGE.METRIC_CHART.TOOLTIP_CTA),
      pointTooltipTitle: 'Recommended Action',
      options: {
        markAreas: [markArea],
        xLabel: 'Date',
        yLabel: metric.name,
        secondaryYLabel: `#${capitalize(pluralize(metric.entity))}`,
        yLabelSuffix: metric.isPercentageValue ? '%' : undefined,
        labels: {
          timeUnit: metric.granularity,
          type: 'date',
          dateFormat: 'MMM D',
          capitalize: false,
        },
        yAxisMaxTicks: 5,
        maxHeight: 400,
        displayedDatasetIds: displayedDSIds,
        errorBar: mode === RCAMode.LOOPS_ALGO,
        showDots: true,
      },
      type: DocumentElementType.CHART,
      chartType: 'LINE',
    };
  }, [metric, analyzedDate, comparedDate, mode, onDatasetPointClick, t]);

  if (!mainChart) {
    return null;
  }

  return (
    <FancyBlock
      title={t(TRANS_KEYS_PREFIX.OVERVIEW_MAIN_VIEW.TITLE, {
        granularity: t(TransKeys.GENERAL.LABELS.GRANULARITY[metric.granularity.toUpperCase()]),
      })}
      className={className}
      addPadding
    >
      {mainChart && <ChildRenderer children_={mainChart} />}
    </FancyBlock>
  );
};
