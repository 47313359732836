import classNames from 'classnames';
import {
  GlobalDocumentDataContext,
  RCAMode,
  RemoteFigureElement,
  RemoteRCAKPITreeExplainerFigureParameters,
} from 'ui-components';
import classes from './remote-rca-kpi-tree-explainer-viewer.module.scss';
import {useContext} from 'react';
import {SeriesDatetimeAttribute} from '../../../../../../objects/models/model-sample-series.model.ts';
import {TreeVariant} from '../../../../../metrics/components/kpi-tree/kpi-tree.consts.ts';
import {KPITree} from '../../../../../metrics/components/kpi-tree/kpi-tree.component.tsx';

interface Props extends RemoteFigureElement {
  className?: string;
}

export const RemoteRCAKPITreeExplainerViewer = (props: Props) => {
  const {parameters, className} = props;
  const {teamId, setTeamId} = useContext(GlobalDocumentDataContext);

  return (
    <div className={classNames(classes.RemoteRCAKPITreeExplainerViewer, className)}>
      <KPITree
        metricId={parameters.metricId}
        toDate={parameters.analyzedDate}
        datetimeAttribute={SeriesDatetimeAttribute.DATETIME}
        variant={TreeVariant.SIMPLE}
        teamId={teamId}
        setTeamId={setTeamId}
        rcaParameters={parameters as RemoteRCAKPITreeExplainerFigureParameters}
        rcaMode={parameters.mode as RCAMode}
        autoLayout
      />
    </div>
  );
};
