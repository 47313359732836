import {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {SeriesDatetimeAttribute} from '../../../../objects/models/model-sample-series.model';
import {TreeMode, TreeVariant} from './kpi-tree.consts';
import {
  SetKPITreeMetricFormulaDTO,
  UpdateMetricPositionDTO,
} from '../../../../objects/dto/metric-tree.dto';
import {
  MetricTree,
  MetricTreeRelationshipType,
  NodePosition,
} from '../../../../objects/models/metric-tree.model';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {
  RCAMode,
  RemoteRCAKPITreeExplainerFigureParameters,
  useRemoteSourceStated,
} from 'ui-components';
import {getMetricTreeNetworkRequest} from '../../../../http/metric-tree.network-requests';
import {
  AppRoutes,
  METRIC_ID_PATH_PARAM,
  TEAM_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {
  addInputMetric,
  addMetricToTree,
  changeInputMetricRelationshipType,
  removeInputMetric,
  removeMetricFromTree,
  setKPITreeMetricFormula,
  setKPITreeMetricPositions,
} from '../../../../store/metric-tree/metric-tree.actions';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {StringParam, useQueryParam} from 'use-query-params';
import {ModelKey} from '../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {PanelsContext} from '../../../../core/contexts/panels.context.tsx';
import {PanelKey} from '../../../../constants/panels.ts';
import {exists, OnSuccessActionHook} from 'front-core';
import {useReactFlow} from '@xyflow/react';

export interface IKPITreeContext {
  metricTree: MetricTree;
  toDate?: string;
  setToDate: (date: string) => void;
  mode: TreeMode;
  setMode: (mode: TreeMode) => void;
  variant: TreeVariant;
  datetimeAttribute?: SeriesDatetimeAttribute;
  onAddKPI: (metricId: number, position?: NodePosition) => void;
  onRemoveKPI: (metricId: number) => void;
  onAddInputKPI: (parentMetricId: number, inputMetricId: number, position?: NodePosition) => void;
  onRemoveInputKPI: (parentMetricId: number, inputMetricId: number) => void;
  onChangeInputKPIRelationshipType: (
    parentMetricId: number,
    inputMetricId: number,
    type: MetricTreeRelationshipType
  ) => void;
  onDefineKPIFormula: (metricId: number) => void;
  onKPIClicked: (metricId: number) => void;
  onChangeMetricPositions: (metricPositions: UpdateMetricPositionDTO[]) => void;
  onViewKPIGroup: (parentId: number) => void;
  onExpandTree: (query?: any) => void;
  teamId: number;
  onChangeTeam: (teamId: number | null) => void;
  cleanEdges: boolean;
  rcaMode?: RCAMode;
  focusedMetricId?: number;
  containerRef: RefObject<HTMLDivElement>;
}

export const KPITreeContext = createContext<IKPITreeContext>({
  metricTree: undefined,
  toDate: undefined,
  setToDate: undefined,
  mode: undefined,
  setMode: undefined,
  variant: undefined,
  datetimeAttribute: undefined,
  onAddKPI: undefined,
  onRemoveKPI: undefined,
  onAddInputKPI: undefined,
  onRemoveInputKPI: undefined,
  onDefineKPIFormula: undefined,
  onChangeInputKPIRelationshipType: undefined,
  onChangeMetricPositions: undefined,
  onKPIClicked: undefined,
  onViewKPIGroup: undefined,
  onExpandTree: undefined,
  teamId: undefined,
  rcaMode: undefined,
  focusedMetricId: undefined,
  cleanEdges: undefined,
  onChangeTeam: undefined,
  containerRef: undefined,
});

interface OwnProps {
  metricId?: number;
  toDate?: string;
  onViewKPIGroup: (parentId: number) => void;
  onExpandTree: () => void;
  datetimeAttribute?: SeriesDatetimeAttribute;
  containerRef: RefObject<HTMLDivElement>;
  syncDateInParams?: boolean;
  teamId?: number;
  onChangeTeam?: (teamId: number) => void;
  rcaParameters?: RemoteRCAKPITreeExplainerFigureParameters;
  rcaMode?: RCAMode;
  variant: TreeVariant;
  focusedMetricId: number;
  children: any;
}

const formatDateForAPI = (date: string) =>
  moment.utc(date, 'YYYY-MM-DD').format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT);

export const KPITreeContextProvider = (props: OwnProps) => {
  const {
    metricId,
    toDate: toDateFromProps,
    datetimeAttribute,
    containerRef,
    onViewKPIGroup,
    onExpandTree,
    syncDateInParams = false,
    teamId,
    onChangeTeam,
    rcaParameters,
    rcaMode,
    variant,
    focusedMetricId,
    children,
  } = props;
  const {getNodes} = useReactFlow();
  const focusedMetricIdRef = useRef(focusedMetricId);
  const cleanEdges = exists(rcaParameters);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {openSecondaryPanel} = useContext(PanelsContext);
  const defaultDate = useMemo(
    () => moment.utc().isoWeekday(1).format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
    []
  );
  const [toDateFromParam, setToDateToParam] = useQueryParam('date', StringParam);
  const [toDate, setToDate_] = useState<string>(toDateFromProps || toDateFromParam || defaultDate);
  const setToDate = useCallback(
    toDate => {
      setToDate_(toDate);
      if (syncDateInParams) {
        setToDateToParam(toDate, 'replaceIn');
      }
    },
    [setToDate_, syncDateInParams, setToDateToParam]
  );
  const [mode, setMode] = useState(TreeMode.VIEW);
  const {source: metricTree, exec: getMetricTreeNR} = useRemoteSourceStated({
    networkRequest: getMetricTreeNetworkRequest,
  });
  const getMetricTree = useCallback(() => {
    const query = {
      toDate: formatDateForAPI(toDate),
      datetimeAttribute,
      teamId,
      rcaParameters,
      isRca: rcaMode !== undefined,
    };
    if (metricId) {
      query['rootMetricId'] = metricId;
    }
    getMetricTreeNR(query);
  }, [getMetricTreeNR, metricId, toDate, datetimeAttribute, teamId, rcaParameters, rcaMode]);
  const onKPIClicked = useCallback(
    metricId => navigate(AppRoutes.viewMetric(metricId)),
    [navigate]
  );
  const onAddKPI = useCallback(
    (metricId: number, position?: NodePosition) => {
      dispatch(
        addMetricToTree({
          metricId,
          teamId,
          position: position || {x: 0, y: 0},
        })
      );
    },
    [dispatch, teamId]
  );
  const onRemoveKPI = useCallback(
    (metricId: number) => dispatch(removeMetricFromTree({metricId, teamId})),
    [dispatch, teamId]
  );
  const onDefineKPIFormula = useCallback(
    (metricId: number) => {
      openSecondaryPanel(PanelKey.KPI_TREE_DEFINE_METRIC_FORMULA_PANEL, {
        [METRIC_ID_PATH_PARAM]: metricId,
        [TEAM_ID_PATH_PARAM]: teamId,
        onSubmit: (data: SetKPITreeMetricFormulaDTO, onSuccess: OnSuccessActionHook) =>
          dispatch(setKPITreeMetricFormula(data, onSuccess)),
      });
    },
    [openSecondaryPanel, dispatch, teamId]
  );
  const onAddInputKPI = useCallback(
    (parentMetricId: number, inputMetricId: number, position?: NodePosition) =>
      dispatch(
        addInputMetric({
          teamId,
          rootMetricId: parentMetricId,
          inputMetricId,
          position,
        })
      ),
    [dispatch, teamId]
  );
  const onRemoveInputKPI = useCallback(
    (parentMetricId: number, inputMetricId: number) =>
      dispatch(
        removeInputMetric({
          teamId,
          rootMetricId: parentMetricId,
          inputMetricId,
        })
      ),
    [dispatch, teamId]
  );
  const onChangeMetricPositions = useCallback(
    (metricPositions: UpdateMetricPositionDTO[]) =>
      dispatch(
        setKPITreeMetricPositions({
          items: metricPositions,
          teamId,
        })
      ),
    [dispatch, teamId]
  );
  const onChangeInputKPIRelationshipType = useCallback(
    (parentMetricId: number, inputMetricId: number, type: MetricTreeRelationshipType) => {
      dispatch(
        changeInputMetricRelationshipType({
          teamId,
          rootMetricId: parentMetricId,
          inputMetricId,
          relationshipType: type,
        })
      );
    },
    [dispatch, teamId]
  );

  useEffect(() => {
    getMetricTree();
  }, [getMetricTree]);
  useEffect(() => {
    const listener = action => {
      const {modelKey} = action.payload;
      if (
        modelKey === ModelKey.INPUT_METRIC ||
        modelKey === ModelKey.METRIC_TREE_NODE ||
        modelKey === ModelKey.USER_SETTINGS
      ) {
        getMetricTree();
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, getMetricTree]);

  const nodeLen = getNodes().length;
  useEffect(() => {
    if (nodeLen > 0) {
      focusedMetricIdRef.current = null;
    }
  }, [nodeLen]);
  const focusedMetricIdValue = focusedMetricIdRef.current;

  const contextValue = useMemo(
    () => ({
      metricTree,
      mode,
      toDate: formatDateForAPI(toDate),
      datetimeAttribute,
      setMode,
      onAddInputKPI,
      onAddKPI,
      onRemoveKPI,
      onRemoveInputKPI,
      onChangeMetricPositions,
      onChangeInputKPIRelationshipType,
      onKPIClicked,
      onViewKPIGroup,
      onExpandTree,
      onDefineKPIFormula,
      setToDate,
      teamId,
      onChangeTeam,
      cleanEdges,
      rcaMode,
      containerRef,
      variant,
      focusedMetricId: focusedMetricIdValue,
    }),
    [
      metricTree,
      mode,
      toDate,
      datetimeAttribute,
      onAddInputKPI,
      onRemoveInputKPI,
      onKPIClicked,
      onAddKPI,
      onRemoveKPI,
      onViewKPIGroup,
      onExpandTree,
      onDefineKPIFormula,
      setToDate,
      onChangeMetricPositions,
      onChangeInputKPIRelationshipType,
      setMode,
      teamId,
      onChangeTeam,
      cleanEdges,
      rcaMode,
      containerRef,
      variant,
      focusedMetricIdValue,
    ]
  );

  return (
    <KPITreeContext.Provider value={contextValue}>
      {!metricTree && <GenericLoading />}
      {metricTree && children}
    </KPITreeContext.Provider>
  );
};
