import {
  DocumentTitleWrapper,
  GlobalDocumentDataContext,
  NetworkWiredRegularIcon,
  RCAFigureViewerContext,
} from 'ui-components';
import {KPITree} from '../../../../../metrics/components/kpi-tree/kpi-tree.component';

import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {
  ModelSeriesGranularity,
  SeriesDatetimeAttribute,
} from '../../../../../../objects/models/model-sample-series.model';
import {TreeVariant} from '../../../../../metrics/components/kpi-tree/kpi-tree.consts';
import {getSignalInfoNetworkRequest} from '../../../../../../http/signals.network-requests.ts';
import {HttpClientContext} from 'front-core';

interface OwnProps {
  metricId: number;
  analyzedDate?: string;
  className?: string;
}

type AllProps = OwnProps;

export const AppKPITreeViewer = (props: AllProps) => {
  const {analyzedDate, className} = props;
  const {t} = useTranslation();
  const http = useContext(HttpClientContext);
  const {signalId, mode, granularity} = useContext(RCAFigureViewerContext);
  const {teamId, setTeamId} = useContext(GlobalDocumentDataContext);
  const [metricId, setMetricId] = useState<number>(null);

  const toDate = useMemo(
    () => moment(analyzedDate, 'YYYY-MM-DD').format(TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT),
    [analyzedDate]
  );
  const loadMetricId = useCallback(async () => {
    const signalInfo = (await http.exec(getSignalInfoNetworkRequest(signalId))) as any;
    if (signalInfo.relatedTo.type === 'metric') {
      setMetricId(signalInfo.relatedTo.id);
    }
  }, [signalId, http, setMetricId]);

  useEffect(() => {
    loadMetricId();
  }, [loadMetricId]);

  if (!metricId) {
    return null;
  }

  return (
    <DocumentTitleWrapper
      title={t(TransKeys.KPI_TREE.TITLE)}
      icon={NetworkWiredRegularIcon}
      className={className}
    >
      <KPITree
        metricId={metricId}
        toDate={toDate}
        granularity={granularity as ModelSeriesGranularity}
        datetimeAttribute={SeriesDatetimeAttribute.DATETIME}
        variant={TreeVariant.SIMPLE}
        autoLayout={true}
        teamId={teamId}
        setTeamId={setTeamId}
        rcaMode={mode}
      />
    </DocumentTitleWrapper>
  );
};
