import * as React from 'react';
import classNames from 'classnames';
import classes from './side-menu.module.scss';
import {useCallback, useMemo, useState} from 'react';
import {CaretDownIcon} from '../icons/icons.component';
import {TextInput} from '../../../forms/inputs/text-input/text-input.component';
import {exists} from 'front-core';
import {PendoAnchors, usePendoAnchor} from '../../../../hooks/use-pendo-anchor.hook';
import {TooltipIfOverflow} from '../../generic/tooltips/tooltips.component';

export interface SideMenuSubItem {
  label: string;
  value: string | number;
  icon?: any;
  [other: string]: any;
}

export interface SideMenuItem extends SideMenuSubItem {
  children?: SideMenuItem[];
  startOpen?: boolean;
}

interface OwnProps {
  items: SideMenuItem[];
  selected?: string | number;
  onSelect: (value: string | number, item: SideMenuItem) => void;
  searchable?: boolean;
  className?: string;
}

type AllProps = OwnProps;

const comp = (i, term) => i.label.toLowerCase().includes(term.toLowerCase());
const initialOpenState = (items: SideMenuItem[]) => {
  const res = {};
  const queue = [...items];
  for (const i of queue) {
    if (i.startOpen) {
      res[i.value] = true;
    }
    if (i.children?.length > 0) {
      queue.push(...i.children);
    }
  }
  return res;
};

export const SideMenu: React.FC<AllProps> = (props: AllProps) => {
  const {items: itemsFromProps, searchable, onSelect, selected, className} = props;
  const menuRef = usePendoAnchor(PendoAnchors.SIDE_MENU);
  const itemsRef = usePendoAnchor(PendoAnchors.SIDE_MENU_ITEMS);
  const [searchValue, setSearchValue] = useState('');
  const [openState, setOpenState] = useState(initialOpenState(itemsFromProps));
  const items = useMemo(() => {
    const extendItem = i => {
      const children = i.children ? i.children.map(extendItem) : undefined;
      const hasSelectedChild = (children || []).find(c => c.isSelected) !== undefined;
      return {
        ...i,
        hasChildren: (i.children || []).length > 0,
        isSelected: i.value === selected,
        children,
        hasSelectedChild,
      };
    };
    return itemsFromProps.map(i => extendItem(i));
  }, [itemsFromProps, selected]);

  const filteredItems = useMemo(() => {
    if (exists(searchValue)) {
      const res = [];
      for (const i of items) {
        if (i.children && i.children.length > 0) {
          const children = i.children.filter(c => comp(c, searchValue));
          if (children.length > 0) {
            res.push({
              ...i,
              children,
            });
          }
          continue;
        }
        if (comp(i, searchValue)) {
          res.push(i);
        }
      }
      return res;
    }
    return items;
  }, [items, searchValue]);
  const onItemClicked = useCallback(
    (item: SideMenuItem | SideMenuSubItem) => {
      if ((item as any).children) {
        setOpenState(openState => ({
          ...openState,
          [item.value]: !openState[item.value],
        }));
        return;
      }
      onSelect(item.value, item);
    },
    [setOpenState, onSelect]
  );

  return (
    <div className={classNames(classes.SideMenu, className)} ref={menuRef}>
      {searchable && (
        <div className={classes.Search}>
          <TextInput
            value={searchValue}
            onChange={setSearchValue as any}
            placeholder={'Search'}
            rounded
            className={classes.SearchInput}
          />
        </div>
      )}
      <div className={classes.List} ref={itemsRef}>
        {filteredItems.length === 0 && <div className={classes.EmptyState}>No results</div>}
        {filteredItems.map(i => (
          <div
            key={i.value}
            className={classNames(
              classes.MenuItem,
              (openState[i.value] ||
                searchValue ||
                (openState[i.value] === undefined && i.hasSelectedChild)) &&
                classes.Open
            )}
          >
            <div
              onClick={() => onItemClicked(i)}
              className={classNames(
                classes.Title,
                i.isSelected && classes.Selected,
                i.hasChildren && classes.HasChildren,
                i.hasSelectedChild && classes.HasSelectedChild
              )}
            >
              {i.icon && (
                <div className={classes.IconWrapper}>
                  <i.icon className={classes.Icon} />
                </div>
              )}
              <TooltipIfOverflow title={i.label}>
                <div className={classes.Label}>{i.label}</div>
              </TooltipIfOverflow>
              {i.hasChildren && <CaretDownIcon className={classes.Arrow} />}
            </div>
            {i.children && i.children.length > 0 && (
              <div className={classes.Children}>
                {i.children.map(c => (
                  <div
                    key={c.value}
                    onClick={() => onItemClicked(c)}
                    className={classNames(
                      classes.Title,
                      classes.Child,
                      c.isSelected && classes.Selected
                    )}
                  >
                    {c.icon && (
                      <div className={classes.IconWrapper}>
                        <c.icon className={classes.Icon} />
                      </div>
                    )}
                    <TooltipIfOverflow title={c.label}>
                      <div className={classes.Label}>{c.label}</div>
                    </TooltipIfOverflow>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
