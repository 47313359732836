import {useSelector} from 'react-redux';
import {CORE_STORE_KEY} from '../../store/core/core.store';
import {useCallback, useMemo} from 'react';
import {TableEntity} from '../../objects/models/table.model';
import {isEmpty, keys, values} from 'lodash';
import {exists} from 'front-core';
import {ProductEntities} from '../../objects/models/product-data.model';
import pluralize from 'pluralize';
import indefinite from 'indefinite';

export function useProductData() {
  const productData = useSelector(state => state[CORE_STORE_KEY].productData || {});

  const productEntities: ProductEntities = useMemo(() => {
    if (!exists(productData.productEntities) || isEmpty(productData.productEntities)) {
      return {[TableEntity.USER]: {name: 'User', order: 0}};
    }
    return productData.productEntities;
  }, [productData.productEntities]);
  const numberOfEntities = useMemo(
    () => values(productData.productEntities).length,
    [productData.productEntities]
  );

  const orderedProductEntities = useMemo(() => {
    return keys(productEntities)
      .sort((a, b) => productEntities[a].order - productEntities[b].order)
      .map(k => ({
        key: k as TableEntity,
        name: productEntities[k].name as string,
      }));
  }, [productEntities]);

  const defaultTableEntity = useMemo(() => {
    return orderedProductEntities[0].key;
  }, [orderedProductEntities]);

  const getEntityName = useCallback(
    (entity: TableEntity, plural?: boolean, article?: boolean) => {
      let name = productEntities[entity]?.name || '';
      if (plural) {
        name = pluralize(name);
      }
      if (article) {
        name = indefinite(name, {capitalize: true});
      }
      return name;
    },
    [productEntities]
  );

  const getSignalByTag = useCallback(
    (tag: string, entity?: TableEntity) => {
      if (!productData) {
        return undefined;
      }
      const signals = productData.topSignals;
      return signals.find(s => {
        const isMatchingTag = s.tags.indexOf(tag) > -1;
        if (entity) {
          return isMatchingTag && s.entity === entity;
        }
        return isMatchingTag;
      });
    },
    [productData]
  );

  const teams = useMemo(() => {
    if (!productData) {
      return [];
    }
    return (productData.teams || []).map(t => ({
      ...t,
      name: t.isDefaultTeam ? productData.name : t.name,
    }));
  }, [productData]);
  const demoResources = useMemo(
    () => (isEmpty(productData?.demoResources) ? null : productData.demoResources),
    [productData?.demoResources]
  );
  const actualTeams = useMemo(() => teams.filter(t => !t.isDefaultTeam), [teams]);
  const defaultTeamId = useMemo(() => {
    if (!exists(productData.defaultTeamId)) {
      return null;
    }
    return actualTeams.find(t => t.id === productData.defaultTeamId)?.id;
  }, [actualTeams, productData]);
  const defaultHomepageId = useMemo(() => {
    if (productData.isDemoProduct) {
      const defaultTeam = teams.find(t => t.isDefaultTeam);
      return defaultTeam?.homepageId || productData.userHomepageId;
    }
    return teams.find(team => team.id === defaultTeamId)?.homepageId || productData.userHomepageId;
  }, [teams, productData, defaultTeamId]);

  return {
    id: productData.id,
    name: productData.name,
    accountName: productData.accountName,
    logo: productData.logo,
    accountStatus: productData?.accountStatus,
    accountPocEndDate: productData?.accountPocEndDate,
    productId: productData.id,
    productEntities: orderedProductEntities,
    productEntitiesMap: productEntities,
    isDemoProduct: productData?.isDemoProduct,
    demoResources: demoResources,
    tableEntities: productData?.tableEntities,
    userCreatedAnalysis: productData?.userCreatedAnalysis,
    userCompletedQuestionnaire: productData?.userCompletedQuestionnaire,
    userQuestionnaireRole: productData?.userQuestionnaireRole,
    defaultSource: productData?.defaultSource,
    webhookSupported: productData.webhookSupported,
    isSourceActive:
      exists(productData?.defaultSource?.isActive) && productData.defaultSource.isActive,
    defaultTableEntity,
    numberOfEntities,
    experimentsCount: productData.experimentsCount,
    installedSlackToWorkspace: productData.installedSlackToWorkspace,
    sharedAnalysesCount: productData.sharedAnalysesCount,
    inviteToken: productData.inviteToken,
    defaultTeamId,
    getEntityName,
    getSignalByTag,
    teams,
    actualTeams,
    integrationStatus: productData.integrationStatus,
    rootPublicAnalysisFolder: productData.rootPublicAnalysisFolder,
    rootPrivateAnalysisFolder: productData.rootPrivateAnalysisFolder,
    defaultHomepageId,
    userHomepageId: productData.userHomepageId,
    userSettings: productData.userSettings,
    hasWriteAccess: productData.hasWriteAccess,
    sourcesWithWriteAccessCount: productData.sourcesWithWriteAccessCount,
    lastWeeklyReportDate: productData.lastWeeklyReportDate,
  };
}
