import * as React from 'react';
import {QueryAction, QueryActions} from '../query-actions/query-actions.component';
import classes from './inline-label.module.scss';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {debounce} from 'lodash';
import classNames from 'classnames';

interface OwnProps {
  label: string;
  icon?: any;
  actions?: QueryAction[];
  size?: 'small' | 'default';
  className?: string;
  labelClassName?: string;
}

type AllProps = OwnProps;

export const InlineLabel: React.FC<AllProps> = (props: AllProps) => {
  const {label, actions, icon: Icon, size, className, labelClassName} = props;
  const [style, setStyle] = useState({});
  const ref = useRef<HTMLDivElement>(null);
  const [showActions, setShowActions_] = useState(false);
  const setShowActions = useCallback(debounce(setShowActions_, 200, {leading: true}), [
    setShowActions_,
  ]);
  const hasActions = useMemo(() => actions?.filter(a => !a.hidden)?.length > 0, [actions]);
  useEffect(() => {
    if (label && ref.current) {
      hasActions &&
        setStyle({
          width: `${ref.current.clientWidth + 6}px`,
          maxWidth: `${ref.current.clientWidth + 6}px`,
        });
    }
  }, [ref.current, label, hasActions]);

  return (
    <div
      ref={ref}
      style={style}
      className={classNames(
        classes.InlineLabel,
        size && classes[size],
        hasActions && classes.HasActions,
        className
      )}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      {Icon && <Icon className={classes.Icon} />}
      <span className={classNames(classes.Label, labelClassName)}>{label}</span>
      {actions?.length > 0 && (
        <QueryActions iconButton={false} actions={actions} show={showActions} />
      )}
    </div>
  );
};
