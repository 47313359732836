import {memo, useContext} from 'react';
// @ts-ignore
import type {NodeProps} from '@xyflow/react/dist/esm/types';
import classes from './add-input-metric-node.module.scss';
import {Position} from '@xyflow/react';
import {PlusRegularIcon, PopoverWrapper} from 'ui-components';
import {KPITreeContext} from '../../kpi-tree.context';
import classNames from 'classnames';
import {TreeHandle} from '../tree-handle/tree-handle.component';
import TransKeys from 'translations';
import {MetricNodeSelector} from '../metric-node-selector/metric-node-selector.component.tsx';
import {useTranslation} from 'react-i18next';

interface OwnProps extends NodeProps {
  data: {
    rootMetricId: number;
  };
}

type AllProps = OwnProps;

export const AddInputMetricNode = memo((props: AllProps) => {
  const {data} = props;
  const {rootMetricId} = data;
  const {t} = useTranslation();
  const {onAddInputKPI} = useContext(KPITreeContext);

  return (
    <>
      <TreeHandle type="source" position={Position.Top} isConnectable={false} />
      <PopoverWrapper
        buttonRenderer={({onClick, isOpen}) => (
          <div
            onClick={onClick}
            className={classNames(classes.AddInputMetricNode, isOpen && classes.Active)}
          >
            <PlusRegularIcon className={classes.AddIcon} />
            <div className={classes.Text}>{t(TransKeys.KPI_TREE.ADD_INPUT_METRIC_NODE.TEXT)}</div>
          </div>
        )}
      >
        <MetricNodeSelector
          onChange={metricId => onAddInputKPI(rootMetricId, metricId)}
          relatedMetricId={rootMetricId}
        />
      </PopoverWrapper>
    </>
  );
});
