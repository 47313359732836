import * as React from 'react';
import classNames from 'classnames';
import classes from './fancy-header.module.scss';
import {CSSProperties} from 'react';
import {CloseRegularIcon} from '../../controls/icons/icons.component';

interface OwnProps {
  title: any;
  subTitle?: string;
  icon?: any;
  caps?: boolean;
  className?: string;
  onClose?: () => void;
  style?: CSSProperties;
}

type AllProps = OwnProps;

export const FancyHeader: React.FC<AllProps> = (props: AllProps) => {
  const {title, subTitle, onClose, icon: Icon, caps, className, style} = props;

  return (
    <div style={style} className={classNames(classes.FancyHeader, caps && classes.Caps, className)}>
      {Icon && <Icon className={classes.HeaderIcon} />}
      <div className={classes.TitleWrapper}>
        <div className={classes.Title}>{title}</div>
        {subTitle && <div className={classes.SubTitle}>{subTitle}</div>}
      </div>
      {onClose && (
        <div className={classes.Close} onClick={onClose}>
          <CloseRegularIcon className={classes.Icon} />
        </div>
      )}
    </div>
  );
};
