import * as React from 'react';
import classNames from 'classnames';
import classes from './sort-indicator.module.scss';
import {ArrowDownIcon} from '../../controls/icons/icons.component';

interface OwnProps {
  className?: string;
  order: 'asc' | 'desc';
}

type AllProps = OwnProps;

export const SortIndicator: React.FC<AllProps> = (props: AllProps) => {
  const {order, className} = props;

  return (
    <div className={classNames(classes.SortIndicator, order === 'asc' && classes.Flip, className)}>
      <ArrowDownIcon className={classes.SortIcon} />
    </div>
  );
};
