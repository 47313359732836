import * as React from 'react';
import {useMemo} from 'react';
import {QueryBlock} from '../components/query-block/query-block.component';
import {BinaryOperation, BinaryOperator} from '../query-builder.types';
import {values} from 'lodash';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {InlineToken} from '../components/parts';
import {BinaryOperatorName} from '../query-builder.config';
import {queryBuilderClasses} from '../query-builder.component';
import {concatPath} from '../query-builder.utils';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {Select} from '../../../forms/inputs/select/select.component';
import {useQueryElement} from '../query-builder.hooks';
import {withViewMode} from '../core/with-view-mode.hoc';

interface OwnProps {
  data: BinaryOperation;
  inline?: boolean;
}

type AllProps = OwnProps & QueryBuilderComponent;

export const BinaryOpBuilderComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled, inline} = props;
  const {localErrors, actions} = useQueryElement(props);
  const options = useMemo(
    () =>
      values(BinaryOperator).map(o => ({
        label: BinaryOperatorName[o],
        value: o,
      })),
    []
  );

  return (
    <QueryBlock
      disabled={disabled}
      className={className}
      label={'Binary Operation'}
      actions={actions}
      inline={inline}
    >
      <InlineToken>CALC</InlineToken>
      <QueryElementBuilder
        error={Boolean(localErrors.left)}
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'left')}
        data={data.left as any}
        box={false}
        inline
      />
      <Select
        className={queryBuilderClasses.Inline}
        dropdownButtonClassName={queryBuilderClasses.Button}
        placeholder={'Operator'}
        value={data.op}
        error={Boolean(localErrors.op)}
        onChange={v => onChange(concatPath(path, 'op'), v)}
        searchable={false}
        clearable={false}
        options={{options}}
        disabled={disabled}
      />
      <QueryElementBuilder
        error={Boolean(localErrors.right)}
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'right')}
        data={data.right as any}
        box={false}
        inline
      />
    </QueryBlock>
  );
};

export const BinaryOpViewerComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, className} = props;

  return (
    <QueryBlock
      label={'Binary Operation'}
      className={className}
      disabled={true}
      inline={true}
      box={false}
      collapsible={false}
      fitContent
    >
      <QueryElementBuilder
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'left')}
        data={data.left as any}
        box={false}
        inline
      />
      <InlineToken>{BinaryOperatorName[data.op]}</InlineToken>
      <QueryElementBuilder
        className={queryBuilderClasses.Inline}
        path={concatPath(path, 'right')}
        data={data.right as any}
        box={false}
        inline
      />
    </QueryBlock>
  );
};

export const BinaryOpBuilder = withViewMode(BinaryOpBuilderComponent, BinaryOpViewerComponent);
