import * as React from 'react';
import {useCallback, useContext, useLayoutEffect, useMemo, useRef} from 'react';
import classNames from 'classnames';
import classes from '../smart-table.module.scss';
import {TableColumn} from './table-column.component';
import {SmartTableContext} from '../smart-table.context';
import {TablePagination} from './table-pagination.component';
import {TableGroupByFilters} from './table-group-by-filters.component';
import {TableOptions} from './table-options.component';
import {TableBody} from './table-body.component';
import {exists} from 'front-core';
import {SmartTableFigurePaginationMode} from '../../../../types';
import {
  ChevronDownRegularIcon,
  FileArrowDown,
} from '../../../../../../simple/controls/icons/icons.component';
import {generateRowId} from './table-row.component';

interface OwnProps {
  className?: string;
  darkMode?: boolean;
}

export const getScrollParent = (node: any): any => {
  if (node == null) {
    return null;
  }
  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
};

type AllProps = OwnProps;

export const SmartTable: React.FC<AllProps> = (props: AllProps) => {
  const {className, darkMode} = props;
  const {
    columns,
    localData,
    displayData,
    pagination,
    options,
    isPartitionTable,
    setPagination,
    exportCSV,
    resetPagination,
    selectedKey,
  } = useContext(SmartTableContext);
  const tableRef = useRef<HTMLDivElement>(null);
  const paginationMode = useMemo(() => {
    if (!options.pagination) {
      return undefined;
    }
    if (options.pagination && !exists(options.paginationMode)) {
      return SmartTableFigurePaginationMode.PAGES;
    }
    if (
      options.paginationMode === SmartTableFigurePaginationMode.TOP_X &&
      options.perPage >= localData.length
    ) {
      return undefined;
    }
    return options.paginationMode;
  }, [options, localData.length]);
  const isAllDataShowed = localData.length === displayData.length;
  const showAllText = useMemo(() => {
    if (isAllDataShowed) {
      return `Show less`;
    }
    return `Show all (${options.perPage} / ${localData.length} displayed)`;
  }, [options.perPage, localData.length, isAllDataShowed]);
  const onShowMore = useCallback(() => {
    if (isAllDataShowed) {
      resetPagination();
      return;
    }
    setPagination({page: 0, perPage: localData.length});
  }, [isAllDataShowed, setPagination, resetPagination, localData.length]);
  const showBottomMessage = useMemo(() => {
    const isLastPage = pagination.page >= localData.length / pagination.perPage - 1;
    if (!options.bottomMessage) {
      return false;
    } else if (options.paginationMode === SmartTableFigurePaginationMode.TOP_X && isAllDataShowed) {
      return true;
    } else if (options.paginationMode === SmartTableFigurePaginationMode.PAGES && isLastPage) {
      return true;
    } else if (options.pagination === false) {
      return true;
    }
    return false;
  }, [
    options.bottomMessage,
    options.pagination,
    options.paginationMode,
    paginationMode,
    isAllDataShowed,
  ]);
  // Used to scroll into view when selected key is changed
  useLayoutEffect(() => {
    if (tableRef.current && selectedKey) {
      const elem = tableRef.current.querySelector(`#${generateRowId(selectedKey)}`);
      if (elem === null) {
        return;
      }
      // @ts-ignore
      elem.scrollIntoViewIfNeeded?.();
    }
  }, [selectedKey, tableRef.current]);

  return (
    <div
      className={classNames(classes.TableViewer, darkMode && classes.DarkMode, className)}
      ref={tableRef}
    >
      <TableGroupByFilters />
      <table className={classes.Table}>
        <thead>
          <tr>
            {columns.map((c, idx) => (
              <React.Fragment key={c.key}>
                {idx === 1 && <th className={classes.FakeTableHeader} />}
                <TableColumn column={c} index={idx} />
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody className={classes.TableBody}>
          {options.headMessage && (
            <tr>
              <td colSpan={columns.length + 1} className={classes.RootMessageTD}>
                {options.headMessage}
              </td>
            </tr>
          )}
          <TableBody />
          {paginationMode === SmartTableFigurePaginationMode.PAGES && !isPartitionTable && (
            <tr>
              <td
                colSpan={columns.length + 1}
                className={classNames(classes.FooterTD, options.stickyFooter && classes.Sticky)}
              >
                <div className={classes.TablePaginationFooter}>
                  <TableOptions className={classes.Options} onExport={exportCSV} />
                  <TablePagination
                    {...pagination}
                    total={localData.length}
                    onPageChange={page => setPagination({...pagination, page})}
                    onPerPageChange={perPage => setPagination({page: 0, perPage})}
                  />
                </div>
              </td>
            </tr>
          )}
          {showBottomMessage && (
            <tr>
              <td colSpan={columns.length + 1} className={classes.RootMessageTD}>
                {options.bottomMessage}
              </td>
            </tr>
          )}
          {paginationMode === SmartTableFigurePaginationMode.TOP_X && !isPartitionTable && (
            <tr>
              <td
                colSpan={columns.length + 1}
                className={classNames(classes.FooterTD, options.stickyFooter && classes.Sticky)}
              >
                <div
                  className={classNames(
                    classes.TableTopXFooter,
                    isAllDataShowed && classes.Extended
                  )}
                  onClick={onShowMore}
                >
                  <span className={classes.ShowButton}>{showAllText}</span>
                  <ChevronDownRegularIcon className={classes.Icon} />
                </div>
              </td>
            </tr>
          )}
          {options.pagination === false && (
            <tr>
              <td
                colSpan={columns.length + 1}
                className={classNames(classes.FooterTD, options.stickyFooter && classes.Sticky)}
              >
                <div className={classes.ExportFooter}>
                  <div className={classes.DownloadButton} onClick={exportCSV}>
                    <FileArrowDown className={classes.Icon} /> CSV
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
