import {useCallback, useContext, useMemo} from 'react';
import {
  ModelSample,
  ModelSampleSeries,
  ModelSampleSeriesType,
  ModelSeriesGranularity,
} from '../../../../../../../../objects/models/model-sample-series.model';
import {
  HomepageAnnotation,
  HomepageFunnel,
} from '../../../../../../../../objects/models/homepage.model';
import {HomepageMetricChart} from '../../homepage-metric-viewer/homepage-metric-chart/homepage-metric-chart.component';
import {ChartConfig, ChartMode} from '../../../../homepage-summary.types';
import {ConfidenceIntervalConfig} from '../../../../../../../../objects/models/user-settings.model';
import {invert, keyBy, take, takeRight, zip} from 'lodash';
import {FunnelChartMode} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {HomepageChartEmptyState} from '../../components/homepage-chart-empty-state/homepage-chart-empty-state.component';
import {useNavigate} from 'react-router';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  AppRoutes,
  TEAM_ID_PATH_PARAM,
} from '../../../../../../../../constants/app-routes';
import {
  FUNNEL_PAGE_DATE_QUERY_PARAM,
  SELECTED_RCA_FUNNEL_STEP_TARGET_SIGNAL_ID,
} from '../../../../../../../funnels/pages/funnel-page/funnel-page.consts';
import {exists, HttpClientContext} from 'front-core';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../../../constants/time-formats';
import {getRCAForFunnelByDate} from '../../../../../../../../http/funnels.network-requests';
import {useTeamFilter} from '../../../../../../../../core/contexts/team-filter.context.tsx';

interface OwnProps {
  funnel: HomepageFunnel;
  reviewedSignalId?: number;
  granularity: ModelSeriesGranularity;
  chartConfig: ChartConfig;
  annotations: HomepageAnnotation[];
  onAnnotationsClicked?: (annotations: HomepageAnnotation[]) => void;
  configuration: ConfidenceIntervalConfig;
  funnelChartMode: FunnelChartMode;
  chartMode: ChartMode;
  className?: string;
}

type AllProps = OwnProps;

const generateIdForSeries = (s: ModelSampleSeries) =>
  `${s.signalId}_${s.seriesType === ModelSampleSeriesType.FUNNEL_COMPLETION_RATE ? 'c' : ''}`;
const FUNNEL_CHART_MODE_TO_SERIES_TYPE = {
  [FunnelChartMode.TOTALS]: ModelSampleSeriesType.LINEAR_FUNNEL_STEP_COUNT_OVER_TIME,
  [FunnelChartMode.CONVERSION_FROM_INITIAL_STEP]:
    ModelSampleSeriesType.FUNNEL_CONVERSION_RATE_FROM_INITIAL_STEP,
  [FunnelChartMode.CONVERSION_FROM_PREVIOUS_STEP]:
    ModelSampleSeriesType.FUNNEL_CONVERSION_RATE_FROM_PREV_STEP,
};

export const HomepageFunnelOvertimeChart = (props: AllProps) => {
  const {
    funnel,
    reviewedSignalId,
    granularity,
    chartConfig,
    configuration,
    annotations,
    funnelChartMode,
    chartMode,
    onAnnotationsClicked,
    className,
  } = props;
  const {t} = useTranslation();
  const {teamId} = useTeamFilter();
  const navigate = useNavigate();
  const http = useContext(HttpClientContext);
  const funnelStepsMap = useMemo(() => keyBy(funnel.steps, 'id'), [funnel]);
  const funnelConversionSteps = useMemo(() => {
    const stepSignalIds = funnel.steps.map(s => s.id);
    const zipped = zip(
      take(stepSignalIds, stepSignalIds.length - 1),
      takeRight(stepSignalIds, stepSignalIds.length - 1)
    );
    let map = {};
    zipped.forEach(([from, to]) => {
      map[from] = to;
    });
    map = invert(map);
    return map;
  }, [funnel]);
  const isPercentage = useMemo(() => funnelChartMode !== FunnelChartMode.TOTALS, [funnelChartMode]);
  const chartData = useMemo(() => {
    const entry = funnel.series
      .filter(s => {
        const isSameGranularity = s.granularity === granularity;
        const isRelevantType = s.seriesType === FUNNEL_CHART_MODE_TO_SERIES_TYPE[funnelChartMode];
        const isFunnelCompilationRate =
          s.seriesType === ModelSampleSeriesType.FUNNEL_COMPLETION_RATE;
        if (funnelChartMode === FunnelChartMode.TOTALS) {
          return isSameGranularity && isRelevantType;
        }
        return isSameGranularity && (isRelevantType || isFunnelCompilationRate);
      })
      .map(s => ({
        ...s,
        order: funnel.steps.findIndex(step => step.id === s.signalId),
        isFunnelCompletionRate: s.seriesType === ModelSampleSeriesType.FUNNEL_COMPLETION_RATE,
      }))
      .sort((a, b) => {
        if (a.isFunnelCompletionRate) {
          return -1;
        }
        if (b.isFunnelCompletionRate) {
          return 1;
        }
        return a.order - b.order;
      });
    const reviewedSeries =
      funnel.series.find(
        s =>
          s.signalId === reviewedSignalId &&
          s.seriesType === FUNNEL_CHART_MODE_TO_SERIES_TYPE[funnelChartMode]
      ) || entry[0];
    const reviewedSeriesId = reviewedSeries ? generateIdForSeries(reviewedSeries) : undefined;
    let series = [];
    if (funnelChartMode === FunnelChartMode.TOTALS) {
      series = entry.map(s => ({
        ...s,
        name: funnelStepsMap[s.signalId].name,
        id: generateIdForSeries(s),
      }));
    } else {
      series = entry.map(s => ({
        ...s,
        name:
          s.seriesType === ModelSampleSeriesType.FUNNEL_COMPLETION_RATE
            ? t(TransKeys.HOMEPAGE.CHART.FUNNEL_COMPLETION_RATE_NAME)
            : `${funnelStepsMap[funnelConversionSteps[s.signalId]]?.name} → ${
                funnelStepsMap[s.signalId]?.name
              }`,
        id: generateIdForSeries(s),
      }));
    }
    return {
      series,
      reviewedSeriesId,
    };
  }, [
    funnel,
    granularity,
    funnelStepsMap,
    funnelConversionSteps,
    funnelChartMode,
    reviewedSignalId,
    t,
  ]);

  const rerouteToAnalysisResultPage = useCallback(
    (rcaAnalysisId: number, rcaAnalysisResultId: number) => {
      return navigate(
        AppRoutes.viewAnalysis(rcaAnalysisId, {
          [ANALYSIS_RESULT_ID_PATH_PARAM]: rcaAnalysisResultId,
          [TEAM_ID_PATH_PARAM]: teamId,
        })
      );
    },
    [navigate, teamId]
  );

  const rerouteToFunnelPage = useCallback(
    (funnelId: number, datetime: string, targetSignalId?: number) => {
      const metricPageQueryParams = {
        [FUNNEL_PAGE_DATE_QUERY_PARAM]: moment
          .utc(datetime)
          .format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
        [TEAM_ID_PATH_PARAM]: teamId,
        [SELECTED_RCA_FUNNEL_STEP_TARGET_SIGNAL_ID]: targetSignalId,
      };
      return navigate(AppRoutes.viewFunnel(funnelId, metricPageQueryParams));
    },
    [navigate, teamId]
  );

  const onRCAFollowUp = useCallback(
    async (sample: ModelSample) => {
      const isFunnelPage = granularity === ModelSeriesGranularity.WEEK;
      const relevantSeries = chartData.series.find(series =>
        exists(series.samples.find(s => s.id === sample.id))
      );
      const targetSignalId =
        exists(relevantSeries) && !relevantSeries.isFunnelCompletionRate
          ? relevantSeries.signalId
          : undefined;
      if (exists(sample.rcaAnalysisId) && exists(sample.rcaAnalysisResultId)) {
        if (isFunnelPage) {
          return rerouteToFunnelPage(funnel.id, sample.datetime, targetSignalId);
        } else {
          return rerouteToAnalysisResultPage(sample.rcaAnalysisId, sample.rcaAnalysisResultId);
        }
      }
      const res = (await http.exec(
        getRCAForFunnelByDate({
          funnelId: funnel.id,
          granularity: ModelSeriesGranularity.DAY,
          date: sample.datetime,
        })
      )) as any;

      if (res.analysisResultId) {
        if (isFunnelPage) {
          return rerouteToFunnelPage(funnel.id, sample.datetime);
        } else {
          return rerouteToAnalysisResultPage(res.analysisId, res.analysisResultId);
        }
      }
    },
    [
      chartData.series,
      funnel.id,
      granularity,
      http,
      rerouteToAnalysisResultPage,
      rerouteToFunnelPage,
    ]
  );

  if (chartData.series.length === 0) {
    return <HomepageChartEmptyState text={t(TransKeys.HOMEPAGE.METRIC_NO_SAMPLES_EMPTY_STATE)} />;
  }

  return (
    <HomepageMetricChart
      series={chartData.series}
      reviewedSeriesId={chartData.reviewedSeriesId}
      chartConfig={chartConfig}
      confidenceIntervalConfig={configuration}
      isPercentage={isPercentage}
      entity={funnel.entity}
      xLabel={granularity}
      hasCountEntities={false}
      annotations={annotations}
      onAnnotationsClicked={onAnnotationsClicked}
      onSampleClicked={onRCAFollowUp}
      showLegend={true}
      chartMode={chartMode}
      className={className}
    />
  );
};
