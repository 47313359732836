import * as React from 'react';
import classNames from 'classnames';
import classes from '../smart-table.module.scss';
import {IconButton} from '../../../../../../simple/controls/icon-button/icon-button.component';
import {FileArrowDown} from '../../../../../../simple/controls/icons/icons.component';

interface OwnProps {
  onExport: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const TableOptions: React.FC<AllProps> = (props: AllProps) => {
  const {onExport, className} = props;

  return (
    <div className={classNames(classes.TableOptions, className)}>
      <IconButton onClick={onExport} icon={FileArrowDown} tooltipText={'Download CSV'} />
    </div>
  );
};
