import {useCallback, useContext, useState} from 'react';
import classNames from 'classnames';
import classes from './power-users-overview-viewer.module.scss';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {FancyBlock} from '../../../../../../simple/data-display/fancy-block/fancy-block.component';
import {Link} from '../../../../../../simple/navigation/link/link.component';
import {PowerUsersOverviewFigure} from '../../../../types/figures.types';
import {number2k} from 'front-core';
import {
  PowerUsersChart,
  PowerUsersDataMode,
} from './components/power-users-chart/power-users-chart.component';
import {PowerUsersCalculationModal} from './components/power-users-calculation-modal/power-users-calculation-modal.component';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import {CommandType} from '../../../../types/commands.types';
import {ModelType} from '../../../../../../../consts/model-type';

interface OwnProps extends PowerUsersOverviewFigure {
  className?: string;
}

type AllProps = OwnProps;

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.POWER_USERS_OVERVIEW_FIGURE;

export const PowerUsersOverviewViewer: React.FC<AllProps> = (props: AllProps) => {
  const {data, options, className} = props;
  const {t} = useDocumentTranslation();
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleCalculationModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const {
    valueDistribution,
    cumulativeDistribution,
    powerN,
    powerType,
    powerPercentile,
    timeStrategy,
  } = data;
  const {entity, signalName, isValuesWeightsAveraged} = options;

  const onCreateSegment = useCallback(() => {
    emitEvent({
      type: CommandType.CREATION,
      payload: {
        type: ModelType.POWER_USERS_SEGMENT,
        parameters: {
          url: window.location.href,
        },
      },
    });
  }, [emitEvent]);

  return (
    <div className={classNames(classes.PowerUsersOverviewFigureViewer, className)}>
      <FancyBlock
        title={t(TRANS_KEYS_PREFIX.BOTTOM_LINE.HEADER)}
        renderRight={
          <Link
            onClick={toggleCalculationModal}
            underline={false}
            className={classes.CalculationLink}
          >
            {t(TRANS_KEYS_PREFIX.BOTTOM_LINE.CALCULATION)}
          </Link>
        }
        className={classes.FancyBlock}
      >
        <div className={classes.BottomLine}>
          <span className={classes.Label}>
            {t(TRANS_KEYS_PREFIX.BOTTOM_LINE.SENTENCE, {
              entity: entity.toLowerCase(),
            })}
          </span>
          <div className={classes.Value}>
            {t(TRANS_KEYS_PREFIX.POWER_TYPES[powerType?.toUpperCase()], {
              value: number2k(powerN),
            })}
            &nbsp;
            {t(TRANS_KEYS_PREFIX.TIME_STRATEGIES[timeStrategy?.toUpperCase()])}
          </div>
          <Link className={classes.Link} onClick={onCreateSegment} underline={false}>
            {t(TRANS_KEYS_PREFIX.BOTTOM_LINE.CREATE_SEGMENT)}
          </Link>
        </div>
      </FancyBlock>
      <PowerUsersChart
        dataMode={PowerUsersDataMode.DISTRIBUTION}
        items={valueDistribution}
        powerN={powerN}
        powerPercentile={powerPercentile}
        signalName={signalName}
        isValuesWeightsAveraged={isValuesWeightsAveraged}
      />
      <PowerUsersCalculationModal
        isOpen={isModalOpen}
        toggleOpen={toggleCalculationModal}
        items={cumulativeDistribution}
        powerN={powerN}
        powerPercentile={powerPercentile}
        signalName={signalName}
        isValuesWeightsAveraged={isValuesWeightsAveraged}
      />
    </div>
  );
};
