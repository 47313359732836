import * as React from 'react';
import classes from './minimal-info-box.module.scss';
import classNames from 'classnames';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

interface OwnProps {
  title: string;
  children?: any;
  onClick?: () => void;
  className?: string;
  isActive?: boolean;
  icon?: any;
  iconColor?: string;
}

type AllProps = OwnProps;

export const MinimalInfoBox: React.FC<AllProps> = (props: AllProps) => {
  const {title, children, onClick, className, isActive, icon: Icon, iconColor} = props;

  return (
    <div className={classNames(classes.MinimalInfoBox, isActive && classes.Active, className)}>
      <ArrowRightIcon onClick={_ => onClick()} className={classes.ExpendIcon} />
      <div onClick={_ => onClick()} className={classes.TitleWrapper}>
        <div className={classes.Title}>{title}</div>
        {Icon && (
          <div className={classNames(classes.IconWrapper, isActive && classes.Hide)}>
            <Icon style={{color: iconColor}} />
          </div>
        )}
      </div>
      {isActive && <div className={classes.Children}>{children}</div>}
    </div>
  );
};
