import * as React from 'react';
import classes from './total-count.module.scss';
import classNames from 'classnames';
import {useMemo} from 'react';
import {number2k} from '../../../../../core/konva/stage.utils';

interface OwnProps {
  count: number;
  model?: string;
  className?: string;
  color?: string;
}

type AllProps = OwnProps;

export const TotalCount: React.FC<AllProps> = (props: AllProps) => {
  const {count, model, className, color} = props;

  const text = useMemo(() => `${number2k(count)}${model ? ` ${model}` : ''}`, [count, model]);

  return (
    <div style={{backgroundColor: color}} className={classNames(classes.TotalCount, className)}>
      {text}
    </div>
  );
};
