import * as React from 'react';
import classNames from 'classnames';
import TransKeys from 'translations';
import {ActionsDropdown} from '../../../../../../../../simple/controls/actions-dropdown/actions-dropdown.component';
import {useMemo} from 'react';
import {values} from 'lodash';
import {HabitMomentViewMode} from '../../habit-moment-viewer.component';
import classes from './switch-habit-mode.module.scss';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {Chip, ChipVariant} from '../../../../../../../../simple/data-display/chip/chip.component';

interface OwnProps {
  setMode: (mode: HabitMomentViewMode) => void;
  mode: HabitMomentViewMode;
  modes?: HabitMomentViewMode[];
  className?: string;
}

type AllProps = OwnProps;

export const SwitchHabitMode: React.FC<AllProps> = (props: AllProps) => {
  const {setMode, mode, modes, className} = props;
  const {t} = useDocumentTranslation();

  const renderCausalChip = () => (
    <Chip
      label={t(TransKeys.DOCUMENT_VIEWER.LABELS.CAUSAL)}
      variant={ChipVariant.CAUSAL}
      className={classes.Chip}
      size={'xsmall'}
      bold
    />
  );

  const modeOptions = useMemo(
    () =>
      (modes || values(HabitMomentViewMode)).map(i => ({
        title: t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.VIEW_MODE[i.toUpperCase()]),
        onClick: () => setMode(i),
        renderRight: i === HabitMomentViewMode.CAUSATION ? renderCausalChip() : null,
      })),
    [setMode, t, modes]
  );

  return (
    <ActionsDropdown
      className={classNames(classes.SelectMode, className)}
      actions={modeOptions}
      disabled={modeOptions.length === 1}
      label={
        <div className={classes.ViewModeText}>
          <span className={classes.Label}>
            {t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.LABELS.VIEW_BY)}
          </span>
          <span className={classes.Value}>
            {t(TransKeys.DOCUMENT_VIEWER.HABIT_MOMENT_FIGURE.VIEW_MODE[mode.toUpperCase()])}
          </span>
          {mode === HabitMomentViewMode.CAUSATION && renderCausalChip()}
        </div>
      }
    />
  );
};
