import {InterestingXoXSentence, NotSignificantChangeKPI} from '../rca-insights.model.ts';
import {
  ChangeInValueSentencePart,
  SegmentNameSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';

interface OwnProps extends InterestingXoXSentence {
  formattedSentenceParts: NotSignificantChangeKPI;
}
type AllProps = OwnProps;

const NotSignificantChangeKPIFormatter = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {presentTensePerformance} = formattedSentenceParts;
  const {segmentViewData, changeInValue, higherIsBetter} = sentenceParts;

  return (
    <SentenceContainer>
      <SegmentNameSentencePart data={segmentViewData} />
      <SentencePart text={`${presentTensePerformance} vs the analyzed period by`} />
      <ChangeInValueSentencePart changeInValue={changeInValue} higherIsBetter={higherIsBetter} />
    </SentenceContainer>
  );
};

export {NotSignificantChangeKPIFormatter};
