import * as React from 'react';
import classNames from 'classnames';
import classes from './rca-composite-segments-viewer.module.scss';
import {
  DocumentElementType,
  RCACompositeSegments,
  RCACompositeSegmentsFigure,
  RCASegmentOfComposite,
} from '../../../../types';
import {useCallback, useContext, useMemo, useState} from 'react';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';
import {useDocumentTracking} from '../../../../hooks/use-document-tracking.hook';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {number2k, Sorting} from 'front-core';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {extendRCACompositeSegments} from './rca-composite-segments-viewer.utils';
import {
  filterCompositeSegments,
  useCompositeFilter,
} from '../../../base-viewers/composite-segments-viewer/use-composite-filter.hook';
import {keys} from 'lodash';
import {calculateMinMaxShare} from '../../utils/segmentation.utils';
import TransKeys from 'translations';
import {CompositeDisplay} from '../../../shared/display-columns/composite-display.component';
import {SegmentDisplay} from '../../../shared/display-columns/segment-display.component';
import pluralize from 'pluralize';
import {
  DatabaseIcon,
  DiagramVennLightIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {UpliftDisplay} from '../../../shared/display-columns/uplift-display.component';
import {VersusDisplay} from '../../../shared/display-columns/versus-display.component';
import {ShareOfUsersFilter} from '../../../shared/filters/share-of-users-filter/share-of-users-filter.component';
import {
  FlexibleTable,
  FlexibleTableClasses,
} from '../../../shared/general/flexible-table/flexible-table.component';
import {IconButton} from '../../../../../../simple/controls/icon-button/icon-button.component';
import {RCACompositeModal} from './components/rca-composite-modal/rca-composite-modal.component';
import {OutOfDisplay} from '../../../shared/display-columns/out-of-display.component';
import {ValueWithUpliftDisplay} from '../../../shared/display-columns/value-with-uplift-display.component';
import {ExtendedTrendCompositeSegments} from '../../../base-viewers/trend-composite-segments-viewer/trend-composite-segments-viewer.component';

interface OwnProps extends RCACompositeSegmentsFigure {
  className?: string;
}

type AllProps = OwnProps;

export interface ExtendedTrendSegmentOfComposite extends RCASegmentOfComposite {
  key: any;
  share: number;
  expectedShare: number;
  uplift: number;
}

export interface ExtendedRCACompositeSegments extends Omit<RCACompositeSegments, 'segments'> {
  key: string;
  share: number;
  expectedShare: number;
  uplift: number;
  segments: ExtendedTrendSegmentOfComposite[];
}

interface Filters {
  segments?: string[];
  shareOfUsers?: {min: number; max: number};
}

const DEFAULT_FILTERS = {
  segments: [],
  shareOfUsers: null,
};

export const RCACompositeSegmentsViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data, options, extraColumns, className} = props;
  const {onSignalClick} = useContext(DocumentCommandEmitterContext);
  const {trackFilter} = useDocumentTracking(id, DocumentElementType.RCA_COMPOSITE_SEGMENTS_FIGURE);
  const {t} = useDocumentTranslation();
  const {query: filters, setQuery: setFilters} = useDocQuery<Filters>(
    id,
    DEFAULT_FILTERS,
    'filters'
  );
  const [selectedComposite, setSelectedComposite] = useState<ExtendedRCACompositeSegments>();
  const composites = useMemo(
    () =>
      extendRCACompositeSegments(data.composites, data.totalEntities, data.expectedTotalEntities),
    [data]
  );
  const filteredComposites = useMemo(() => {
    let filtered = filterCompositeSegments(composites, filters.segments);
    if (filters.shareOfUsers) {
      filtered = filtered.filter(
        i => i.share >= filters.shareOfUsers.min / 100 && i.share <= filters.shareOfUsers.max / 100
      );
    }
    return filtered;
  }, [composites, filters.segments, filters.shareOfUsers]);
  const onSegmentDeepDive = useCallback(
    (item: ExtendedRCACompositeSegments) => {
      setSelectedComposite(item);
    },
    [setSelectedComposite]
  );
  const columns = useMemo(
    () => [
      {
        key: ['groupName', 'name'],
        title: [
          t(TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SEGMENT_GROUP.TITLE),
          t(TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SEGMENT.TITLE),
        ],
        helperText: [
          t(
            TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SEGMENT_GROUP.HELPER_TEXT
          ),
          t(TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SEGMENT.HELPER_TEXT),
        ],
        weight: 1.5,
        render: (item: ExtendedRCACompositeSegments) => {
          return (
            <CompositeDisplay>
              {item.segments.map(s => (
                <div key={s.signalId} className={classes.SegmentLine}>
                  <SegmentDisplay
                    segment={s.name}
                    group={s.groupName}
                    onClick={() => onSignalClick(s.signalId)}
                  />
                </div>
              ))}
            </CompositeDisplay>
          );
        },
      },
      // {
      //   key: 'share',
      //   title: t(TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_X.TITLE, {
      //     entity: pluralize(options.entity),
      //   }),
      //   subTitle: t(
      //     TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_X.SUB_TITLE,
      //     {
      //       entity: pluralize(options.entity),
      //     }
      //   ),
      //   helperText: t(
      //     TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_X.HELPER_TEXT,
      //     {
      //       entity: pluralize(options.entity),
      //     }
      //   ),
      //   weight: 1,
      //   render: (item: ExtendedRCACompositeSegments) => {
      //     return (
      //       <div className={classes.List}>
      //         {item.segments.map(s => (
      //           <div key={s.signalId} className={classes.SegmentLine}>
      //             <VersusDisplay
      //               value={s.share}
      //               vsValue={s.expectedShare}
      //               isPercentageValue={true}
      //             />
      //           </div>
      //         ))}
      //       </div>
      //     );
      //   },
      // },
      {
        key: 'value',
        title: t(TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_VALUE.TITLE),
        subTitle: t(
          TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_VALUE.SUB_TITLE
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_VALUE.HELPER_TEXT
        ),
        weight: 1,
        render: (item: ExtendedTrendCompositeSegments) => {
          return (
            <div>
              {item.segments.map(s => (
                <div key={s.signalId} className={classes.SegmentLine}>
                  <ValueWithUpliftDisplay
                    value={s.value}
                    uplift={s.uplift}
                    higherIsBetter={options.higherIsBetter}
                    isPercentageValue={options.isPercentageValue}
                  />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        key: 'share',
        title: t(
          TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_COMBINED.TITLE,
          {
            entity: pluralize(options.entity),
          }
        ),
        subTitle: t(
          TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_COMBINED
            .SUB_TITLE,
          {
            entity: pluralize(options.entity),
          }
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_COMBINED
            .HELPER_TEXT,
          {
            entity: pluralize(options.entity),
          }
        ),
        weight: 1,
        sortable: true,
        contentClassName: classes.ShareOfUsersContent,
        render: (item: ExtendedRCACompositeSegments) => (
          <VersusDisplay
            icon={DiagramVennLightIcon}
            value={item.share}
            vsValue={item.expectedShare}
            isPercentageValue={true}
          />
        ),
      },
      {
        key: 'value',
        title: t(TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_COMBINED.TITLE),
        subTitle: t(
          TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_COMBINED.SUB_TITLE
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_COMBINED.HELPER_TEXT
        ),
        weight: 1,
        sortable: true,
        contentClassName: classes.KPICombinedContent,
        render: (item: ExtendedRCACompositeSegments) => (
          <div className={classes.KPICombined}>
            <div className={classes.Value}>
              {number2k(options.isPercentageValue ? item.value * 100 : item.value)}
              {options.isPercentageValue ? '%' : ''}
            </div>
            <div className={classes.Uplift}>
              <UpliftDisplay uplift={item.uplift} higherIsBetter={options.higherIsBetter} />
            </div>
          </div>
        ),
      },
      {
        key: 'contributionToChange',
        title: t(
          TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS
            .CONTRIBUTION_TO_CHANGE_COMBINED.TITLE
        ),
        subTitle: t(
          TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS
            .CONTRIBUTION_TO_CHANGE_COMBINED.SUB_TITLE
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS
            .CONTRIBUTION_TO_CHANGE_COMBINED.HELPER_TEXT
        ),
        weight: 1,
        sortable: true,
        contentClassName: classes.ContributionToChangeContent,
        render: (item: ExtendedRCACompositeSegments) => (
          <OutOfDisplay value={item.contributionToChange} outOfValue={data.populationTrend} />
        ),
      },
      {
        key: 'actions',
        title: '',
        width: '6rem',
        render: (item: ExtendedRCACompositeSegments) => (
          <div className={classNames(classes.Action, FlexibleTableClasses.ShowOnHover)}>
            <IconButton
              tooltipText={t(
                TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.ACTIONS.COMPOSITE_DEEP_DIVE
              )}
              onClick={() => onSegmentDeepDive(item)}
              icon={DatabaseIcon}
            />
          </div>
        ),
      },
    ],
    [options, extraColumns]
  );
  const onFilterChange = useCallback(
    (newFilters: Filters) => {
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      }));
      const filterKey = keys(newFilters)[0];
      trackFilter(filterKey, {
        filter: newFilters[filterKey],
      });
    },
    [setFilters, trackFilter]
  );
  const shareOfUsersMinMaxValue = useMemo(() => calculateMinMaxShare(composites), [composites]);
  const {compositeFilter} = useCompositeFilter({
    composites,
    filter: filters.segments,
    setFilter: v => setFilters({segments: v}),
    disabled: composites.length === 0,
  });
  const defaultSort: Sorting = useMemo(
    () => ({
      orderBy: 'contributionToChange',
      order: options.sortOrder ? options.sortOrder : options.higherIsBetter ? 'desc' : 'asc',
    }),
    [options]
  );

  return (
    <div className={classNames(classes.RCACompositeSegmentsViewer, className)}>
      <div className={classes.Filters}>
        {compositeFilter}
        <ShareOfUsersFilter
          className={classes.Filter}
          {...shareOfUsersMinMaxValue}
          value={filters.shareOfUsers}
          onChange={v => onFilterChange({shareOfUsers: v})}
          placeholder={t(
            TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_COMBINED.TITLE,
            {
              entity: pluralize(options.entity).toLowerCase(),
            }
          )}
          disabled={composites.length === 0}
        />
      </div>
      <FlexibleTable
        columns={columns}
        data={filteredComposites}
        defaultSort={defaultSort}
        emptyState={t(TransKeys.DOCUMENT_VIEWER.RCA_COMPOSITE_SEGMENTS.EMPTY_STATE.NO_RESULTS)}
      />
      {selectedComposite && (
        <RCACompositeModal
          composite={selectedComposite}
          populationTrend={data.populationTrend}
          options={options}
          onClose={() => onSegmentDeepDive(null)}
        />
      )}
    </div>
  );
};
