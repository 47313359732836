import {NoticeMessage} from '../../../../../../simple/notice-message/notice-message.component';
import classes from './notice-popup.module.scss';
import {Popper, PopperPlacementType} from '@material-ui/core';

interface OwnProps {
  anchorEl: HTMLElement;
  placement: PopperPlacementType;
  show: boolean;
  icon?: any;
  message?: string | JSX.Element;
  confirmLabel?: string;
  onConfirm?: () => void;
  secondaryLabel?: string;
  onSecondary?: () => void;
  zIndex?: number;
}

type AllProps = OwnProps;

const MODIFIERS = {
  offset: {
    enabled: true,
    offset: '0, 8',
  },
  preventOverflow: {
    escapeWithReference: true,
  },
};

export const NoticePopup: React.FC<AllProps> = (props: AllProps) => {
  const {
    anchorEl,
    show,
    icon,
    message,
    placement,
    confirmLabel,
    onConfirm,
    secondaryLabel,
    onSecondary,
    zIndex = 1000,
  } = props;

  return (
    <Popper
      open={show}
      anchorEl={anchorEl}
      placement={placement}
      disablePortal
      modifiers={MODIFIERS}
      style={{zIndex}}
    >
      <NoticeMessage
        variant="warn"
        className={classes.Notice}
        icon={icon}
        message={message}
        confirmLabel={confirmLabel}
        onConfirm={onConfirm}
        secondaryLabel={secondaryLabel}
        onSecondary={onSecondary}
      />
    </Popper>
  );
};
