import * as React from 'react';
import {useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './release-impact-segmentation-modal.module.scss';
import TransKeys from 'translations';
import {ModalWrapper} from '../../../../shared/modal-wrapper/modal-wrapper.component';
import {SegmentationModalHeader} from '../../../../shared/segmentation/segmentation-modal-header/segmentation-modal-header.component';
import {Checkbox} from '../../../../../../../forms/inputs/checkbox/checkbox.component';
import {ChildRenderer} from '../../../../core/child-renderer.component';
import {NextPrevNavigator} from '../../../../shared/general/next-prev-navigator/next-prev-navigator.component';
import {ExtendedSegmentOfReleaseSegmentation} from '../release-impact-segmentation-viewer.component';
import {
  ColumnType,
  CommandType,
  DocumentElement,
  DocumentElementType,
  ReleaseImpactSegmentationFigureOptions,
} from '../../../../../types';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import pluralize from 'pluralize';
import {ModelType} from '../../../../../../../../consts/model-type';

interface OwnProps {
  segments: ExtendedSegmentOfReleaseSegmentation[];
  selectedGroupSignalId: number;
  postPopulationTrend: number;
  releaseImpactValue: number;
  groupOptions: {label: string; value: number}[];
  onSelectGroup: (signalId: number) => void;
  options: ReleaseImpactSegmentationFigureOptions;
  className?: string;
}

type AllProps = OwnProps;

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RELEASE_SEGMENTATION_FIGURE;

function getHelperText(isInformative: boolean, isSignificant: boolean, helperText: string) {
  if (isInformative === false) {
    return {
      nameHelperText: helperText,
    };
  }
  if (!isSignificant) {
    return {
      upliftHelperText: helperText,
    };
  }
  return {};
}

export const ReleaseImpactSegmentationModal: React.FC<AllProps> = (props: AllProps) => {
  const {
    segments,
    selectedGroupSignalId,
    postPopulationTrend,
    releaseImpactValue,
    groupOptions,
    onSelectGroup,
    options,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const [showInsignificant, setShowInsignificant] = useState(true);
  const defaultSort = useMemo(
    () => ({
      orderBy: 'postShare',
      // order: releaseImpactValue > 0 ? 'desc' : 'asc',
      order: 'desc',
    }),
    [releaseImpactValue]
  );
  const navigatorProps = useMemo(() => {
    if (groupOptions.length === 0) {
      return;
    }
    const currentIndex = groupOptions.findIndex(i => i.value === selectedGroupSignalId);
    const maxIndex = groupOptions.length - 1;
    return {
      currentIndex,
      maxIndex,
      onNextPrev: (isNext: boolean) =>
        onSelectGroup(groupOptions[currentIndex + (isNext ? 1 : -1)].value),
      nextLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.NEXT_LABEL),
      prevLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.PREV_LABEL),
    };
  }, [groupOptions, selectedGroupSignalId, onSelectGroup, t]);
  const tableData = useMemo(
    () =>
      segments
        .map(s => ({
          ...s,
          // We need to multiply by 100 so the table filters will work correctly
          // in the column renderers we will divide by 100 to show the correct value
          postShare: s.postShare * 100,
          preShare: s.preShare * 100,
          postValue: options.isPercentageValue ? s.postValue * 100 : s.postValue,
          preValue: options.isPercentageValue ? s.preValue * 100 : s.preValue,
          valueUplift: s.valueUplift * 100,
          postPopulationTrend,
          command: {
            type: CommandType.REF,
            payload: {
              type: ModelType.SIGNAL,
              refId: s.signalId,
            },
          },
          ...getHelperText(s.isInformative, s.isSignificant, s.helperText),
        }))
        .filter(s => (showInsignificant ? true : s.isSignificant)),
    [segments, postPopulationTrend, showInsignificant, options.isPercentageValue]
  );
  const inSignificantSegmentKeys = useMemo(
    () => segments.filter(s => s.isSignificant === false).map(s => s.key),
    [segments]
  );
  const tableColumns = useMemo(() => {
    const entityPlural = pluralize(options.entity);
    return [
      {
        key: 'segmentName',
        type: ColumnType.DEFAULT,
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.TITLE),
        options: {
          sortable: true,
          filterable: true,
          bold: true,
          width: '35%',
          helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SEGMENT_NAME.HELPER_TEXT),
          helperTextDataKey: 'nameHelperText',
        },
        typeOptions: {
          commandDataKey: 'command',
        },
      },
      {
        key: 'share_count',
        type: ColumnType.SWITCH_COLUMN,
        typeOptions: {
          columns: [
            {
              key: 'postShare',
              type: ColumnType.VERSUS,
              title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE_POST.TITLE, {
                entity: entityPlural,
              }),
              subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE_POST.SUB_TITLE, {
                entity: entityPlural,
              }),
              options: {
                sortable: true,
                filterable: true,
                helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE_POST.HELPER_TEXT, {
                  entity: entityPlural,
                }),
                unit: '%',
              },
              typeOptions: {
                valueDataKey: 'postShare',
                valueDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE_POST.TITLE, {
                  entity: entityPlural,
                }),
                versusDataKey: 'preShare',
                versusDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.SHARE_PRE.TITLE, {
                  entity: entityPlural,
                }),
                hideIcon: true,
              },
            },
            {
              key: 'postCount',
              type: ColumnType.VERSUS,
              title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT_POST.TITLE, {
                entity: entityPlural,
              }),
              subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT_POST.SUB_TITLE, {
                entity: entityPlural,
              }),
              options: {
                sortable: true,
                filterable: true,
                helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT_POST.HELPER_TEXT, {
                  entity: entityPlural,
                }),
              },
              typeOptions: {
                valueDataKey: 'postCount',
                valueDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT_POST.TITLE, {
                  entity: entityPlural,
                }),
                versusDataKey: 'preCount',
                versusDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.COUNT_POST.SUB_TITLE, {
                  entity: entityPlural,
                }),
                hideIcon: true,
              },
            },
          ],
        },
      },
      {
        key: 'value',
        type: ColumnType.VERSUS,
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE_POST.TITLE, {
          entity: entityPlural,
        }),
        subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE_POST.SUB_TITLE, {
          entity: options.entity,
        }),
        options: {
          sortable: true,
          filterable: true,
          helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE_POST.HELPER_TEXT),
          unit: options.isPercentageValue ? '%' : undefined,
        },
        typeOptions: {
          valueDataKey: 'postValue',
          valueDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE_POST.TITLE, {
            entity: entityPlural,
          }),
          versusDataKey: 'preValue',
          versusDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_VALUE_PRE.TITLE, {
            entity: entityPlural,
          }),
          hideIcon: true,
        },
      },
      {
        key: 'valueUplift',
        type: ColumnType.CHANGE,
        title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_UPLIFT.TITLE),
        subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_UPLIFT.SUB_TITLE),
        options: {
          sortable: true,
          filterable: true,
          helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.KPI_UPLIFT.HELPER_TEXT),
          helperTextDataKey: 'upliftHelperText',
        },
      },
      // {
      //   key: 'postCtcPp',
      //   type: ColumnType._RENDER_COLUMN,
      //   asType: ColumnType.VERSUS,
      //   title: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP_POST.TITLE),
      //   subTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP_POST.SUB_TITLE),
      //   options: {
      //     sortable: true,
      //     filterable: true,
      //     helperText: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP_POST.HELPER_TEXT),
      //   },
      //   typeOptions: {
      //     valueDataKey: 'postCtcPp',
      //     valueDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP_POST.TITLE, {
      //       entity: options.entity,
      //     }),
      //     versusDataKey: 'postPopulationTrend',
      //     versusDataTitle: t(TRANS_KEYS_PREFIX.TABLE.HEADERS.CTC_PP_POST.SUB_TITLE, {
      //       entity: options.entity,
      //     }),
      //   },
      //   render: segment => (
      //     <ImpactPPDisplay value={segment.postCtcPp} outOfValue={segment.postPopulationTrend} />
      //   ),
      // },
    ];
  }, [options, t]);

  const tableFigure: DocumentElement = useMemo(
    () =>
      ({
        id: 'release-impact-segmentation-modal',
        dataKey: 'key',
        type: DocumentElementType.SMART_TABLE,
        columns: tableColumns,
        data: tableData,
        options: {
          defaultSort: defaultSort,
          pagination: false,
          stickyFooter: true,
          significantDataKey: 'isInformative',
          data: inSignificantSegmentKeys.reduce((acc, curr) => {
            return {
              ...acc,
              [curr]: {
                columns: {
                  valueUplift: {
                    color: '#757C94',
                  },
                },
              },
            };
          }, {}),
        },
      }) as any,
    [tableColumns, tableData, inSignificantSegmentKeys, defaultSort]
  );

  return (
    <ModalWrapper
      isOpen={selectedGroupSignalId !== null}
      onClose={() => onSelectGroup(null)}
      width={'80vw'}
      height={'70rem'}
    >
      <div className={classNames(classes.ReleaseImpactSegmentationModal, className)}>
        <SegmentationModalHeader
          title={t(TRANS_KEYS_PREFIX.MODAL_TITLE)}
          selectedGroup={selectedGroupSignalId}
          groupOptions={groupOptions}
          onSelectGroup={onSelectGroup}
        />
        {inSignificantSegmentKeys.length > 0 && (
          <div className={classes.Filters}>
            <Checkbox
              label={t(TransKeys.DOCUMENT_VIEWER.GENERAL.SHOW_INSIGNIFICANT_SEGMENTS)}
              checked={showInsignificant}
              onChange={setShowInsignificant}
              border
            />
          </div>
        )}
        <div className={classes.Table}>
          <ChildRenderer children_={tableFigure} />
        </div>
        <div className={classes.Spacer} />
        {navigatorProps && (
          <div className={classes.Navigation}>
            <NextPrevNavigator {...navigatorProps} />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};
