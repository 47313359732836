import * as React from 'react';
import classNames from 'classnames';
import classes from './funnel-overview-data-mode.module.scss';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {useCallback, useContext, useMemo} from 'react';
import {
  ChartType,
  CommandType,
  DocumentElementType,
  FunnelOverviewFigureData,
  FunnelOverviewFigureOptions,
  FunnelOverviewFigureStep,
} from '../../../../../../types';
import {capitalize, flatten, sortBy} from 'lodash';
import pluralize from 'pluralize';
import TransKeys from 'translations';
import {FunnelChartMode} from '../../../../../../../charts-v2/funnel-chart/funnel-chart.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {DocumentCommandEmitterContext} from '../../../../../../contexts/document-command-emitter.context';
import {useDocumentTracking} from '../../../../../../hooks/use-document-tracking.hook';

interface OwnProps {
  figureId: string;
  data: FunnelOverviewFigureData;
  options: FunnelOverviewFigureOptions;
  className?: string;
}

type AllProps = OwnProps;

const generateLineChartConversionName = (
  startStepOrder: number,
  endStepOrder: number,
  steps: FunnelOverviewFigureStep[]
) => {
  if (startStepOrder === 0 && endStepOrder === steps.length - 1) {
    return 'Funnel completion';
  }
  return `${steps[startStepOrder].label} > ${steps[endStepOrder].label}`;
};

const generateLineChartStartedName = (
  stepOrder: number,
  steps: FunnelOverviewFigureStep[],
  entity: string = ''
) => {
  let entityName = entity ? capitalize(pluralize(entity)) : '';
  if (stepOrder === 0) {
    return `#${entityName}`;
  }
  return `${steps[stepOrder].label} #${entityName}`;
};

const TIME_UNIT = 'week';
const generateIdForDS = (ds, prefix?: string) =>
  `${prefix ? `${prefix}_` : ''}${ds.startStepOrder}-${ds.endStepOrder}`;

export const FunnelOverviewDataMode: React.FC<AllProps> = (props: AllProps) => {
  const {figureId, data, options, className} = props;
  const {t} = useDocumentTranslation();
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {trackItemClicked} = useDocumentTracking(
    figureId,
    DocumentElementType.FUNNEL_OVERVIEW_FIGURE
  );

  const steps = useMemo(() => sortBy(data.steps, 'order'), [data.steps]);

  const onExploreStep = useCallback(
    (step: number) => {
      emitEvent({
        type: CommandType.ANALYSIS_FIGURE_SWITCH_VIEW,
        payload: {
          rootContentKey: data.funnelSegmentationContentKey,
          parameters: {step},
        },
      });
      trackItemClicked(step.toString(), {
        name: 'explore_step',
      });
    },
    [emitEvent, trackItemClicked]
  );

  const funnelFigure = useMemo(() => {
    const labels = steps.map(s => s.label);
    return {
      labels,
      type: DocumentElementType.CHART,
      chartType: ChartType.FUNNEL,
      onExplore: onExploreStep,
      data: [
        {
          id: 'funnel',
          label: 'Funnel',
          data: data.funnelData,
        },
      ],
      options: {
        mode: FunnelChartMode.TOTALS,
      },
    };
  }, [steps, data.funnelData, data.funnelSegmentationContentKey, onExploreStep]);

  const lineChartData = useMemo(() => {
    const conversions: any[] = data.conversionOverTime
      .sort(s => (s.endStepOrder - s.startStepOrder > 1 ? -1 : s.startStepOrder))
      .map(ds => {
        return {
          order: ds.endStepOrder - ds.startStepOrder > 1 ? -1 : ds.startStepOrder,
          id: generateIdForDS(ds),
          label: generateLineChartConversionName(ds.startStepOrder, ds.endStepOrder, steps),
          data: ds.data.map(i => ({
            x: i.date,
            y: i.y * 100,
          })),
        };
      });
    let entities = [];
    if (data.entityCountOverTime) {
      entities = data.entityCountOverTime.map(ds => ({
        order: ds.stepOrder === 0 ? -0.5 : ds.stepOrder + 0.5,
        id: generateIdForDS(ds, 'started'),
        label: generateLineChartStartedName(ds.stepOrder, steps, options?.entity),
        data: ds.data.map(i => ({
          x: i.date,
          y: i.count,
          clickable: false,
        })),
        yAxis: 'secondary',
      }));
    }

    return flatten([conversions, entities]).sort((a, b) => a.order - b.order);
  }, [data.conversionOverTime, data.entityCountOverTime, steps, options]);

  // const datasetsInfo = useMemo(() => {
  //   const info = {};
  //   for (const ds of data.conversionOverTime) {
  //     info[generateIdForDS(ds)] = {
  //       fromSignal: steps[ds.startStepOrder].signalId,
  //       fromSignalLabel: steps[ds.startStepOrder].label,
  //       toSignal: steps[ds.endStepOrder].signalId,
  //       toSignalLabel: steps[ds.endStepOrder].label,
  //       startStepOrder: ds.startStepOrder,
  //       endStepOrder: ds.endStepOrder,
  //     };
  //   }
  //   return info;
  // }, [steps, data.conversionOverTime]);

  // const onDatasetPointClick = useCallback(
  //   (param: OnDatasetPointClickParam) => {
  //     const {point, dataset, additionalProperties} = param;
  //     if (!point.isDate || point.itemIndex === 0) {
  //       return null;
  //     }
  //     const signalName = `${datasetsInfo[dataset.id].toSignalLabel} from ${datasetsInfo[dataset.id].fromSignalLabel}`;
  //     const conversionSignalCandidateParameters = {
  //       goalSignalId: datasetsInfo[dataset.id].toSignal,
  //       refDateSignalId: datasetsInfo[dataset.id].fromSignal,
  //       signalName,
  //     };
  //     const previousPoint = dataset.data[point.itemIndex - 1] as ChartXYValue;
  //     const increasedFromPreviousPoint = point.y > previousPoint.y;
  //     const change = t(
  //       TransKeys.GENERAL.LABELS[increasedFromPreviousPoint ? 'INCREASED' : 'DECREASED']
  //     );
  //     const parameters = {
  //       ...conversionSignalCandidateParameters,
  //       fromToLabel: point.label,
  //       startDateAnomaly: point.x,
  //       timeAggregation: TIME_UNIT,
  //       title: t(TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.PANEL.TITLE, {change}),
  //     };
  //     const payload: FunnelAnalysisLineChartFollowUpCommandPayload = {
  //       type: FollowUpType.RCA_FROM_FUNNEL_ANALYSIS_LINE_CHART,
  //       parameters,
  //     };
  //     emitEvent({
  //       type: CommandType.FOLLOW_UP,
  //       payload,
  //     });
  //   },
  //   [datasetsInfo, steps, emitEvent]
  // );

  const lineChartFigure = useMemo(() => {
    const chart = {
      type: DocumentElementType.CHART,
      chartType: ChartType.LINE,
      data: lineChartData,
      options: {
        yLabelSuffix: '%',
        displayedDatasetIds: [`0-${steps.length - 1}`],
        xLabel: 'Date',
        yLabel: 'Conversion',
        secondaryYLabel: options?.entity ? `#${capitalize(pluralize(options?.entity))}` : '',
        labels: {
          dateFormat: 'DD MMM',
          type: 'date',
          timeUnit: TIME_UNIT,
        },
      },
      // onDatasetPointClick: onDatasetPointClick,
      // pointTooltipCta: t(
      //   TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.LINE_CHART.TOOLTIP.CTA.DEFAULT
      // ),
    };
    return {
      id: 'line-chart-content-wrapper',
      type: DocumentElementType.CONTENT_WRAPPER,
      title: t(TransKeys.DOCUMENT_VIEWER.FUNNEL_OVERVIEW_FIGURE.CONVERSION_OVER_TIME_TITLE),
      children: [chart],
    };
  }, [lineChartData, options, steps]);

  return (
    <div className={classNames(classes.FunnelOverviewDataMode, className)}>
      <ChildRenderer
        children_={funnelFigure}
        key={'funnel-chart'}
        className={classes.MarginBottom}
      />
      <ChildRenderer
        children_={lineChartFigure}
        key={'line-chart'}
        className={classes.MarginBottom}
      />
    </div>
  );
};
