import {ModelSeriesGranularity} from '../../../../objects/models/model-sample-series.model';
import {KPINode} from './components/kpi-node/kpi-node.component';
import {KPIEdge} from './components/kpi-edge/kpi-edge.component';
import {KPIGroup} from './components/kpi-group/kpi-group.component';
import {MarkerType, SnapGrid} from '@xyflow/react';
import {AddInputMetricNode} from './components/add-input-metric-node/add-input-metric-node.component';
import {MetricTreeRelationshipType} from '../../../../objects/models/metric-tree.model.ts';
import {ArrowsRepeatLightIcon, CircleMinusLightIcon, CirclePlusLightIcon} from 'ui-components';

export enum TreeVariant {
  FULL = 'full',
  SIMPLE = 'simple',
}
export enum TreeMode {
  VIEW = 'view',
  EDIT = 'edit',
}

export enum NodeType {
  METRIC = 'KPIBox',
  METRIC_GROUP = 'KPIGroup',
  ADD_INPUT_METRIC = 'AddInputMetric',
}

export enum EdgeType {
  METRIC_CONNECTION = 'KPIEdge',
}

export const MAX_CORRELATION_GOOD_COLOR = '#01C366';
export const MAX_CORRELATION_BAD_COLOR = '#F13E3E';
export const INSIGNIFICANT_CORRELATION_COLOR = 'rgba(197, 201, 219, 1)';
export const INSIGNIFICANT_TEXT_COLOR = 'rgba(137, 142, 168, 1)';

export const NODE_PLACEHOLDER_PORTAL_CLASS_NAME = 'node-placeholder-portal';
export const SNAP_GRID: SnapGrid = [5, 5];
export const DEFAULT_GRANULARITY = ModelSeriesGranularity.WEEK;
export const KPI_NODE_WIDTH = 260;
export const KPI_NODE_HEIGHT = 112;
export const KPI_GROUP_NODE_WIDTH = 112;
export const KPI_GROUP_NODE_HEIGHT = 54;
export const NUMBER_OF_METRICS_FOR_ADD_INPUT_METRIC = 2;

export const DEFAULT_MARKER_END = {
  type: MarkerType.ArrowClosed,
  width: 12,
  height: 12,
  color: INSIGNIFICANT_CORRELATION_COLOR,
};

export const nodeTypes = {
  [NodeType.METRIC]: KPINode,
  [NodeType.METRIC_GROUP]: KPIGroup,
  [NodeType.ADD_INPUT_METRIC]: AddInputMetricNode,
};

export const edgeTypes = {
  [EdgeType.METRIC_CONNECTION]: KPIEdge,
};

export const ICON_FOR_RELATIONSHIP_TYPE = {
  [MetricTreeRelationshipType.CORRELATION]: ArrowsRepeatLightIcon,
  [MetricTreeRelationshipType.ADD]: CirclePlusLightIcon,
  [MetricTreeRelationshipType.SUBTRACT]: CircleMinusLightIcon,
};

export const DISABLE_REACT_FLOW_INTERACTION_PROPS = {
  nodesDraggable: false,
  nodesConnectable: false,
  nodesFocusable: false,
  elementsSelectable: false,
  zoomOnDoubleClick: false,
  preventScrolling: false,
  zoomOnScroll: false,
};

export const HIDE_REACT_FLOW_ATTRIBUTION = {
  proOptions: {hideAttribution: true},
};
