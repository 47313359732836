import {
  SegmentSentenceExplainer,
  SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease,
} from '../rca-insights.model.ts';
import {
  ChangeInShareOfEntitiesSentencePart,
  ScrollToSectionSentencePart,
  SegmentNameSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import {RCAExplainersIconVariant} from '../../components/rca-explainers-icon/rca-explainers-icon.component.tsx';
import {RCAExplainersIcon} from '../../components/rca-explainers-icon/rca-explainers-icon.component.tsx';
import {RCAElementType} from 'ui-components';
import sharedClasses from '../../insights.module.scss';
import * as React from 'react';
import {RCAExplainersExplainabilityLabelDisplay} from '../../components/rca-explainers-explainability-label-display/rca-explainers-explainability-label-display.component.tsx';
interface OwnProps extends SegmentSentenceExplainer {
  formattedSentenceParts: SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease;
}

type AllProps = OwnProps;

const SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter: React.FC<AllProps> = (
  props: AllProps
) => {
  const {formattedSentenceParts, sentenceParts, onViewOnPage, explainabilityLabel} = props;
  const {entity, entityChangeDescription, changeInShareOfEntities, presentTensePerformanceVsAvg} =
    formattedSentenceParts;
  const {segmentViewData} = sentenceParts;

  return (
    <SentenceContainer className={sharedClasses.Spaced}>
      {explainabilityLabel && (
        <RCAExplainersExplainabilityLabelDisplay label={explainabilityLabel} />
      )}
      <RCAExplainersIcon variant={RCAExplainersIconVariant.SEGMENT_EXPLAINER} />
      <SegmentNameSentencePart data={segmentViewData} />
      <SentencePart text={`share of ${entity} ${entityChangeDescription}d by`} />
      <ChangeInShareOfEntitiesSentencePart changeInShareOfEntities={changeInShareOfEntities} />
      <SentencePart
        text={`vs the analyzed period. This segment is ${presentTensePerformanceVsAvg} vs the average.`}
      />
      {onViewOnPage && (
        <ScrollToSectionSentencePart
          onViewOnPage={() => onViewOnPage(RCAElementType.SEGMENTATION)}
        />
      )}
    </SentenceContainer>
  );
};

export {SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseFormatter};
