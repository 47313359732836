import * as React from 'react';
import classNames from 'classnames';
import classes from '../smart-table.module.scss';
import {min} from 'lodash';
import {Select} from '../../../../../../forms/inputs/select/select.component';

interface OwnProps {
  page: number;
  perPage: number;
  total: number;
  onPerPageChange: (perPage: number) => void;
  onPageChange: (page: number) => void;
  className?: string;
}

type AllProps = OwnProps;

export const TablePagination: React.FC<AllProps> = (props: AllProps) => {
  const {page, perPage, total, onPerPageChange, onPageChange, className} = props;
  const prevDisabled = page <= 0;
  const nextDisabled = page >= total / perPage - 1;

  return (
    <div className={classNames(classes.TablePagination, className)}>
      <div className={classes.Pagination}>
        {onPerPageChange && (
          <div className={classes.ItemsPerPage}>
            <span className={classes.Label}>Rows:</span>
            <Select
              placeholder={'Select'}
              value={perPage}
              onChange={value => onPerPageChange(value as any)}
              dropdownButtonClassName={classes.PerPageButton}
              searchable={false}
              sortValues={false}
              clearable={false}
              options={{
                options: [
                  {
                    label: '5',
                    value: 5,
                  },
                  {
                    label: '10',
                    value: 10,
                  },
                  {
                    label: '50',
                    value: 50,
                  },
                  {
                    label: '100',
                    value: 100,
                  },
                  {
                    label: 'All',
                    value: total,
                  },
                ],
              }}
            />
          </div>
        )}
        <div className={classes.Status}>{`${total > 0 ? 1 + page * perPage : 0}-${min([
          (page + 1) * perPage,
          total,
        ])} of ${total}`}</div>
        <div className={classes.Navigation}>
          <span
            onClick={e => !prevDisabled && onPageChange(page - 1)}
            className={classNames(classes.Button, prevDisabled && classes.Disabled)}
          >
            {'<'}
          </span>
          <span
            onClick={e => !nextDisabled && onPageChange(page + 1)}
            className={classNames(classes.Button, nextDisabled && classes.Disabled)}
          >
            {'>'}
          </span>
        </div>
      </div>
    </div>
  );
};
