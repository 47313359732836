import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {QueryTree} from '../components/query-tree/query-tree.component';
import {
  AndCondition,
  ExtendedSqlElementType,
  OrCondition,
  QueryBuilderElementType,
  SqlElementType,
} from '../query-builder.types';
import {QueryElementBuilder} from '../core/query-element-builder.component';
import {concatPath, QueryBuilderFactory} from '../query-builder.utils';
import {ActionItem} from '../../../simple/controls/actions-dropdown/actions-dropdown.component';
import classNames from 'classnames';
import {queryBuilderClasses} from '../query-builder.component';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {ArrowDownIcon, ArrowUpIcon, CopyIcon} from '../../../simple/controls/icons/icons.component';
import {useQueryArray, useQueryElement} from '../query-builder.hooks';
import {get} from 'lodash';
import {CONDITION_TYPES} from '../query-builder.config';

interface OwnProps extends Omit<QueryBuilderComponent, 'data'> {
  data: AndCondition | OrCondition;
}

type AllProps = OwnProps;

export const AndOrConditionBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, config, onChange, disabled, className} = props;
  const {accept, componentsMap} = config;
  const {actions, localErrors} = useQueryElement(props);
  const conditions = useMemo(() => data.conditions || [], [data]);
  const onAdd = useCallback(
    (which: QueryBuilderElementType) => QueryBuilderFactory.create(which),
    []
  );
  const {addElement, cloneElement, moveElement} = useQueryArray(
    conditions,
    data => onChange(concatPath(path, 'conditions'), data),
    onAdd
  );
  const acceptedSet = useMemo(() => (accept ? new Set(accept) : undefined), [accept]);
  const addOptions: ActionItem[] = useMemo(
    () =>
      CONDITION_TYPES.filter(t => (acceptedSet ? acceptedSet.has(t) : true)).map(t => ({
        key: t,
        title: componentsMap[t].name,
        onClick: _ => addElement(t),
      })),
    [acceptedSet, componentsMap, addElement]
  );

  return (
    <QueryTree
      disabled={disabled}
      addOptions={addOptions}
      actions={actions}
      label={data.type === SqlElementType.OR_CONDITION ? 'Or' : 'And'}
      className={classNames(queryBuilderClasses.QueryTree, className)}
    >
      {conditions.map((c, idx) => (
        <QueryElementBuilder
          key={idx}
          data={c}
          path={concatPath(path, `conditions.${idx}`)}
          actions={[
            {
              icon: ArrowUpIcon,
              label: 'Up',
              onClick: () => moveElement(idx, idx - 1),
              hidden: idx === 0,
            },
            {
              icon: ArrowDownIcon,
              label: 'Down',
              onClick: () => moveElement(idx, idx + 1),
              hidden: idx === conditions.length - 1,
            },
            {
              icon: CopyIcon,
              label: 'Duplicate',
              onClick: () => cloneElement(idx),
            },
          ]}
          accept={[
            SqlElementType.AND_CONDITION,
            SqlElementType.OR_CONDITION,
            SqlElementType.CONDITION,
          ]}
          error={Boolean(get(localErrors, `conditions.${idx}`))}
        />
      ))}
    </QueryTree>
  );
};
