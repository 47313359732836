import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {
  AnalysisContentItem,
  AnalysisContentItemSelection,
  AnalysisContentItemType,
  AnalysisFigure,
  CommandType,
  DocumentCommand,
  DocumentElementType,
  DocumentIconType,
} from '../../../types';
import {ChildRendererInjectedProps} from '../../../document-viewer.types';
import classes from './analysis-figure-viewer.module.scss';
import {SideMenu} from '../../../../../simple/controls/side-menu/side-menu.component';
import {DocumentIcon} from '../../internal-viewers/document-icon.component';
import {flatten} from 'lodash';
import {NextPrevNavigator} from '../../shared/general/next-prev-navigator/next-prev-navigator.component';
import {DocumentPhenomenasContext} from '../../../contexts/document-phenomenas.context';
import {AnalysisContentTitle} from './components/analysis-content-title/analysis-content-title.component';
import {useDocumentTracking} from '../../../hooks/use-document-tracking.hook';
import classNames from 'classnames';
import {sharedViewerClasses} from '../../viewers';
import {ChildRenderer} from '../../core/child-renderer.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../hooks/use-document-translation.hook';
import {
  DocumentCommandEmitterContext,
  DocumentCommandEmitterContextProvider,
} from '../../../contexts/document-command-emitter.context';
import {AnalysisFigureContextProvider} from '../../../contexts/analysis-figure.context';
import {useDocQuery} from '../../../hooks/use-doc-query.hook';
import {AnalysisContentRenderer} from './components/analysis-content-renderer/analysis-content-renderer.component';

export interface OwnProps extends AnalysisFigure, Partial<ChildRendererInjectedProps> {
  className?: string;
}

const slugifyTitle = (title: string) => title.toLowerCase().replace(/ /g, '_');
const PHENOMENAS_KEY = 'phenomenas';
const OVERVIEW_KEY = 'overview';

interface SelectedState {
  rootContentKey: string;
  innerContentKey?: string; // used for AnalysisContentItemSelection to select the inner content
}

export const getKeyFromContentItem = (item: AnalysisContentItem | AnalysisContentItemSelection) =>
  item.key || slugifyTitle(item.title);

export const toMenuItem = (item: AnalysisContentItem | AnalysisContentItemSelection) => {
  const menuItem: any = {
    value: getKeyFromContentItem(item),
    label: item.title,
    icon: item.icon
      ? props => <DocumentIcon {...props} icon={DocumentIconType[item.icon]} />
      : undefined,
    _internalValue: {rootContentKey: getKeyFromContentItem(item)},
  };
  if (item.type === AnalysisContentItemType.SELECTION && item.showContentOnMenu) {
    menuItem.children = item.data.map(d => ({
      value: getKeyFromContentItem(d),
      label: d.title,
      _internalValue: {
        rootContentKey: getKeyFromContentItem(item),
        innerContentKey: getKeyFromContentItem(d),
      },
    }));
  }
  return menuItem;
};

export const AnalysisFigureViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {id, data: dataFromProps, options} = props;
  const [parameters, setParameters] = useState<any>();
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {trackNavigation} = useDocumentTracking(id, DocumentElementType.ANALYSIS_FIGURE);
  const {t} = useDocumentTranslation();
  const {total: numberOfPhenomenas, isLoading: isLoadingPhenomenas} =
    useContext(DocumentPhenomenasContext);

  const data = useMemo(
    () => ({
      ...dataFromProps,
      overview: dataFromProps.overview
        ? {
            ...dataFromProps.overview,
            key: dataFromProps.overview.key || OVERVIEW_KEY,
          }
        : null,
    }),
    [dataFromProps]
  );
  // root items mapping from key to its content (can be selection or content item)
  const renderMap: {
    [key: string]: AnalysisContentItem | AnalysisContentItemSelection;
  } = useMemo(() => {
    const map = {};
    if (data.overview) {
      map[getKeyFromContentItem(data.overview)] = data.overview;
    }
    if (data.hasPhenomenas) {
      map[PHENOMENAS_KEY] = {
        key: PHENOMENAS_KEY,
        data: [
          {
            type: DocumentElementType._WINDOW_BLOCK,
            render: () => (
              <AnalysisContentTitle
                title={t(TransKeys.DOCUMENT_VIEWER.ANALYSIS_FIGURE.ALL_PHENOMENA_LABEL, {
                  count: numberOfPhenomenas,
                })}
                className={classes.ContentTitle}
              />
            ),
          },
          {
            type: DocumentElementType.PHENOMENAS_FIGURE,
            options: {
              pagination: true,
              perPage: 10,
            },
          },
        ],
      } as any;
    }
    for (const v of [...data.deepDive, ...data.appendix]) {
      map[getKeyFromContentItem(v)] = v;
    }
    return map;
  }, [data, emitEvent, numberOfPhenomenas, t]);
  const defaultSelected: SelectedState = useMemo(() => {
    const firstContentItem = data.overview || (data.deepDive || [])[0];

    const rootContentKey = getKeyFromContentItem(firstContentItem);
    const innerContentKey =
      renderMap[rootContentKey].type === AnalysisContentItemType.SELECTION
        ? getKeyFromContentItem(renderMap[rootContentKey].data[0] as AnalysisContentItem)
        : undefined;
    return {rootContentKey, innerContentKey};
  }, [data]);
  // the actual selected key - no matter hierarchy
  const {query: selectedContentKeys, setQuery: setSelectedContentKeys_} =
    useDocQuery<SelectedState>(id, defaultSelected, '', v => {
      // backward compatibility
      if (typeof v === 'string') {
        return {rootContentKey: v};
      }
      return v;
    });
  const setSelectedContentKeys = useCallback(
    (state: SelectedState) => {
      const newState = {...selectedContentKeys, ...state};
      // handle the case where the inner is not set or not found in the selection
      if (
        newState.rootContentKey &&
        renderMap[newState.rootContentKey].type === AnalysisContentItemType.SELECTION &&
        (newState.innerContentKey === undefined ||
          (renderMap[state.rootContentKey] as AnalysisContentItemSelection).data.find(
            d => getKeyFromContentItem(d) === newState.innerContentKey
          ) === undefined)
      ) {
        newState.innerContentKey = getKeyFromContentItem(
          (renderMap[state.rootContentKey] as AnalysisContentItemSelection).data[0]
        );
      } else if (
        newState.rootContentKey &&
        (renderMap[newState.rootContentKey].type === AnalysisContentItemType.ITEM ||
          renderMap[newState.rootContentKey].type === undefined)
      ) {
        newState.innerContentKey = undefined;
      }
      // update state
      setSelectedContentKeys_(newState);
      // track navigation
      trackNavigation(state.innerContentKey || state.rootContentKey);
      // This is used to scroll the content back to the top
      // setTimeout 0 is used so the "id" of the AnalysisContentItemViewer will be set before call
      setTimeout(() => {
        emitEvent({
          type: CommandType.DOCUMENT_ANCHOR,
          payload: {id: newState.innerContentKey || newState.rootContentKey},
        });
      }, 0);
    },
    [selectedContentKeys, emitEvent, trackNavigation, renderMap]
  );
  const navItems = useMemo(() => {
    let items = [];
    if (data.overview) {
      items = [toMenuItem(data.overview)];
    }
    items.push(...data.deepDive.map(v => toMenuItem(v)));
    if (data.appendix.length > 0) {
      items.push({
        label: 'Appendix',
        value: 'appendix',
        startOpen: false,
        _internalValue: {rootContentKey: 'appendix'},
        children: data.appendix.map(v => ({
          value: getKeyFromContentItem(v),
          label: v.linkText || v.title,
          icon: v.icon
            ? props => <DocumentIcon {...props} icon={DocumentIconType[v.icon]} />
            : undefined,
          _internalValue: {rootContentKey: getKeyFromContentItem(v)},
        })),
      });
    }
    if (data.hasPhenomenas && (numberOfPhenomenas > 0 || isLoadingPhenomenas)) {
      items.push({
        label: t(TransKeys.DOCUMENT_VIEWER.ANALYSIS_FIGURE.ALL_PHENOMENA_LABEL, {
          count: isLoadingPhenomenas ? 'loading...' : numberOfPhenomenas,
        }),
        value: PHENOMENAS_KEY,
        _internalValue: {rootContentKey: PHENOMENAS_KEY},
      });
    }
    return items;
  }, [data, numberOfPhenomenas, isLoadingPhenomenas, t]);
  const navItemsKeyList = useMemo(() => {
    const items = navItems.map(ni => (ni.children ? ni.children.map(c => c.value) : ni.value));
    return flatten(items);
  }, [navItems]);
  const currentSelectedNavItemIndex = useMemo(
    () => navItemsKeyList.indexOf(selectedContentKeys.rootContentKey),
    [selectedContentKeys.rootContentKey, navItemsKeyList]
  );
  const feedbackChildFigureConfig = useMemo(
    () => ({
      type: DocumentElementType._FEEDBACK,
      data: {key: selectedContentKeys.rootContentKey},
    }),
    [selectedContentKeys.rootContentKey]
  );
  const onNextPrev = useCallback(
    (next?: boolean) => {
      const addition = next ? 1 : -1;
      const newIndex = currentSelectedNavItemIndex + addition;
      if (newIndex > navItemsKeyList.length - 1 || newIndex < 0) {
        return;
      }
      setSelectedContentKeys({rootContentKey: navItemsKeyList[newIndex]});
    },
    [currentSelectedNavItemIndex, navItemsKeyList, setSelectedContentKeys]
  );
  const onViewAllPhenomenas = useCallback(
    () => setSelectedContentKeys({rootContentKey: PHENOMENAS_KEY}),
    [setSelectedContentKeys]
  );
  // override the onEvent to handle the ANALYSIS_FIGURE_SWITCH_VIEW command
  const onEvent = useCallback(
    (event: DocumentCommand<any>) => {
      if (event.type === CommandType.ANALYSIS_FIGURE_SWITCH_VIEW) {
        const newState: any = {};
        if (event.payload.contentKey) {
          newState.rootContentKey = event.payload.contentKey;
        }
        if (event.payload.rootContentKey) {
          newState.rootContentKey = event.payload.rootContentKey;
        }
        if (event.payload.innerContentKey) {
          newState.innerContentKey = event.payload.innerContentKey;
        }
        setSelectedContentKeys(newState);
        setParameters(event.payload.parameters);
        return;
      }
      emitEvent(event);
    },
    [emitEvent, setParameters, setSelectedContentKeys]
  );
  const rootSelectedItem = renderMap[selectedContentKeys.rootContentKey];
  const selectedSideMenu = useMemo(() => {
    if (
      renderMap[selectedContentKeys.rootContentKey] &&
      renderMap[selectedContentKeys.rootContentKey].type === AnalysisContentItemType.SELECTION &&
      // @ts-ignore
      renderMap[selectedContentKeys.rootContentKey].showContentOnMenu !== true
    ) {
      return selectedContentKeys.rootContentKey;
    }
    return selectedContentKeys.innerContentKey || selectedContentKeys.rootContentKey;
  }, [renderMap, selectedContentKeys]);
  const showMenu = navItems.length > 1;

  return (
    <DocumentCommandEmitterContextProvider onEvent={onEvent}>
      <AnalysisFigureContextProvider parameters={parameters || {}} setParameters={setParameters}>
        <div className={classNames(classes.AnalysisFigureViewer, sharedViewerClasses.FullViewer)}>
          {showMenu && (
            <div className={classes.Nav}>
              <SideMenu
                className={classes.SideMenu}
                items={navItems}
                selected={selectedSideMenu}
                onSelect={(v, item) => setSelectedContentKeys(item._internalValue)}
              />
            </div>
          )}
          <div className={classNames(classes.Content, showMenu && classes.HasMenu)}>
            {rootSelectedItem && (
              <AnalysisContentRenderer
                id={selectedContentKeys.rootContentKey}
                selectedContentKey={selectedContentKeys.innerContentKey}
                content={rootSelectedItem}
                hasPhenomenas={props.data.hasPhenomenas && numberOfPhenomenas > 0}
                onViewAllPhenomenas={onViewAllPhenomenas}
                onContentSelected={innerContentKey =>
                  setSelectedContentKeys({
                    rootContentKey: selectedContentKeys.rootContentKey,
                    innerContentKey,
                  })
                }
              />
            )}
            <div className={classes.Footer}>
              <div className={classes.Feedback}>
                <ChildRenderer
                  key={selectedContentKeys.innerContentKey || selectedContentKeys.rootContentKey}
                  children_={feedbackChildFigureConfig}
                />
              </div>
              {!options.hideNextPrev && (
                <div className={classes.InnerNavigation}>
                  <NextPrevNavigator
                    onNextPrev={onNextPrev}
                    currentIndex={currentSelectedNavItemIndex}
                    maxIndex={navItemsKeyList.length - 1}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </AnalysisFigureContextProvider>
    </DocumentCommandEmitterContextProvider>
  );
};
