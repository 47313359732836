import {DocumentElement, DocumentElementType} from './document.types';
import {FigureElement} from './figures.types';
import {FunnelAreaChartOptions} from '../../charts/charts/funnel-area-chart/funnel-area-chart.component';
import {
  ChartXYValue,
  DumbbellDataset,
  FunnelDataset,
  NumberDataset,
  ScatterDataset,
} from '../../charts/chart-data.types';
import {BarChartOptions} from '../../charts/charts/bar-chart/bar-chart.component';
import {DumbbellChartOptions} from '../../charts/charts/dumbbell-chart/dumbbell-chart.component';
import {LineChartOptions} from '../../charts/charts/line-chart/line-chart.component';
import {ScatterChartOptions} from '../../charts/charts/scatter-chart/scatter-chart.component';
import {PercentageChartOptions} from '../../charts/charts/percentage-line-chart/percentage-line-chart.component';
import {FunnelChartOptions} from '../../charts-v2/funnel-chart/funnel-chart.component';

export enum ChartType {
  FUNNEL_AREA = 'FUNNEL_AREA',
  BAR = 'BAR',
  DUMBBELL = 'DUMBBELL',
  FUNNEL = 'FUNNEL',
  LINE = 'LINE',
  PIE = 'PIE',
  SCATTER = 'SCATTER',
  PERCENTAGE_LINE = 'PERCENTAGE_LINE',
}

export interface ChartFigure<D, O> extends FigureElement<D, O> {
  type: DocumentElementType.CHART;
  chartType: ChartType;
}

export interface ChartOptions {
  maxHeight?: number;
  displayedDatasetIds?: string[];
}

export interface FunnelAreaChartFigure
  extends ChartFigure<NumberDataset[], FunnelAreaChartOptions & ChartOptions> {
  chartType: ChartType.FUNNEL_AREA;
  labels: string[];
}

export interface BarChartFigure
  extends ChartFigure<NumberDataset[], BarChartOptions & ChartOptions> {
  chartType: ChartType.BAR;
  labels: string[];
}

export interface DumbbellChartFigure
  extends ChartFigure<DumbbellDataset[], DumbbellChartOptions & ChartOptions> {
  chartType: ChartType.DUMBBELL;
}

export interface FunnelChartFigure
  extends ChartFigure<NumberDataset[] | FunnelDataset[], FunnelChartOptions & ChartOptions> {
  chartType: ChartType.FUNNEL;
  labels: string[];
}

export interface LineChartFigure
  extends ChartFigure<NumberDataset[] | ChartXYValue[], LineChartOptions & ChartOptions> {
  chartType: ChartType.LINE;
  labels: string[];
}

export interface ScatterChartFigure
  extends ChartFigure<ScatterDataset[], ScatterChartOptions & ChartOptions> {
  chartType: ChartType.DUMBBELL;
}

export interface PercentageChartFigure
  extends ChartFigure<NumberDataset[], PercentageChartOptions & ChartOptions> {
  chartType: ChartType.PERCENTAGE_LINE;
}
