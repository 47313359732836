import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import {FilterComponentProps} from './filter-component.types';
import classes from './filters.module.scss';
import {FilterWrapper} from './filter-wrapper.component';
import {exists} from 'front-core';
import {TableFilterType, TableTreeFilter} from '../../smart-table.types';
import {flatten, groupBy, isArray, isEmpty, keys, mapValues, pick, uniqBy, values} from 'lodash';
import {CaretDownIcon, CloseIcon} from '../../../../../../../simple/controls/icons/icons.component';
import {Checkbox} from '../../../../../../../forms/inputs/checkbox/checkbox.component';

interface OwnProps extends FilterComponentProps {
  onChange: (filterKey: string, filter: TableTreeFilter) => void;
  dataKey: string;
  parentDataKey: string;
  className?: string;
}

type AllProps = OwnProps;
const MAX_OPTIONS_COUNT_TO_SEARCH = 7;

const createInitialGroupsOpenState = (groupedValues: string[], filterValue) =>
  groupedValues.reduce((acc, curr) => {
    acc[curr] = filterValue[curr]?.length > 0;
    return acc;
  }, {});

// indicates that all children are selected
export const TREE_FILTER_ALL_CHILDREN = '__all__';

export const TreeFilter: React.FC<AllProps> = (props: AllProps) => {
  const {
    label,
    filter,
    filteredData,
    onClear,
    filterKey,
    dataKey,
    parentDataKey,
    onChange: onChangeFromProps,
    className,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const filterValue = filter?.value || {};
  const filterValueAsSet = useMemo(() => mapValues(filterValue, v => new Set(v)), [filterValue]);
  const filterData = useMemo(
    () =>
      flatten(
        filteredData.map(item => {
          const res = pick(item, dataKey, parentDataKey);
          // this is used to handle a case where the dataKey / parentDataKey values are arrays
          if (isArray(res[dataKey]) && isArray(res[parentDataKey])) {
            return res[dataKey].map((_, idx) => ({
              [dataKey]: res[dataKey][idx],
              [parentDataKey]: res[parentDataKey][idx],
            }));
          }
          return res;
        })
      ),
    [dataKey, filteredData]
  );
  const groupedValues = useMemo(() => {
    const res = groupBy(filterData, parentDataKey);
    for (const k in res) {
      res[k] = uniqBy(res[k], dataKey);
    }
    return res;
  }, [filterData]);
  const [groupsOpenState, setGroupOpenState] = useState(
    createInitialGroupsOpenState(keys(groupedValues), filterValue)
  );
  const filteredGroupedValues = useMemo(() => {
    const res = {...groupedValues};
    for (const k of keys(groupedValues)) {
      res[k] = groupedValues[k].filter(o =>
        ('' + o[dataKey]).toLowerCase().includes(searchValue.toLowerCase())
      );
      if (res[k].length === 0) {
        delete res[k];
      }
    }
    return res;
  }, [groupedValues, dataKey, searchValue]);
  const onChange = useCallback(
    value => {
      onChangeFromProps(filterKey, {
        value,
        type: TableFilterType.TREE,
        properties: {dataKey, parentDataKey},
      });
    },
    [filterKey, dataKey, parentDataKey, onChangeFromProps]
  );
  const onUpdateFilter = useCallback(
    newFilterValue => {
      for (const key of keys(newFilterValue)) {
        if (newFilterValue[key].length === 0) {
          delete newFilterValue[key];
        }
      }
      if (isEmpty(newFilterValue)) {
        onClear(filterKey);
        return;
      }
      onChange(newFilterValue);
    },
    [onChange]
  );
  const onChildClicked = useCallback(
    (v: any) => {
      let newFilterValue = {...filterValue};
      const parentValue = v[parentDataKey];
      const childValue = v[dataKey];

      if (!(parentValue in newFilterValue)) {
        newFilterValue[parentValue] = [];
      }
      let set;
      if (newFilterValue[parentValue] === TREE_FILTER_ALL_CHILDREN) {
        set = new Set(groupedValues[parentValue].map(i => i[dataKey]));
      } else {
        set = new Set(newFilterValue[parentValue]);
      }
      if (set.has(childValue)) {
        set.delete(childValue);
      } else {
        set.add(childValue);
      }
      newFilterValue[parentValue] =
        set.size === groupedValues[parentValue].length ? TREE_FILTER_ALL_CHILDREN : Array.from(set);
      onUpdateFilter(newFilterValue);
    },
    [filterValue, onUpdateFilter, groupedValues]
  );
  const onParentClicked = useCallback(
    (parentValue: string) => {
      let newFilterValue = {...filterValue};
      if (
        !newFilterValue[parentValue] ||
        newFilterValue[parentValue] !== TREE_FILTER_ALL_CHILDREN
      ) {
        newFilterValue[parentValue] = TREE_FILTER_ALL_CHILDREN;
      } else {
        newFilterValue[parentValue] = [];
      }
      onUpdateFilter(newFilterValue);
    },
    [filterValue, groupedValues, onUpdateFilter]
  );
  const changeOpenGroup = useCallback(
    (parentValue: string) =>
      setGroupOpenState(d => ({
        ...d,
        [parentValue]: !d[parentValue],
      })),
    [setGroupOpenState]
  );
  const onClearSearch = useCallback(() => setSearchValue(''), []);
  const showSearch = useMemo(
    () => flatten(values(groupedValues)).length > MAX_OPTIONS_COUNT_TO_SEARCH || searchValue,
    [groupedValues, searchValue]
  );
  const showNoResults = useMemo(
    () => flatten(values(filteredGroupedValues)).length === 0,
    [filteredGroupedValues]
  );

  const renderGroupName = (g: any) => {
    if (g === 'null' && filteredGroupedValues[g].length === 1) {
      return filteredGroupedValues[g][0][dataKey];
    }
    return g;
  };

  return (
    <FilterWrapper
      clearable={!isEmpty(filterValue)}
      onClear={() => onClear(filterKey)}
      label={`${label}`}
      className={classNames(classes.TreeFilter, className)}
    >
      {showSearch && (
        <div className={classes.Search}>
          <input
            placeholder={'Search...'}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            className={classes.SearchInput}
          />
          {searchValue && (
            <div onClick={onClearSearch} className={classes.ClearSearchButton}>
              <CloseIcon className={classes.Icon} />
            </div>
          )}
        </div>
      )}
      <div className={classes.Groups}>
        {keys(filteredGroupedValues).map(g => (
          <div key={g} className={classNames(classes.Group)}>
            <div
              className={classNames(
                classes.Parent,
                (filterValue[g]?.length === groupedValues[g].length ||
                  filterValue[g] === TREE_FILTER_ALL_CHILDREN) &&
                  classes.Active,
                groupsOpenState[g] && classes.Open
              )}
            >
              <CaretDownIcon onClick={() => changeOpenGroup(g)} className={classes.DropdownIcon} />
              <Checkbox
                onChange={() => onParentClicked(g)}
                checked={
                  filterValue[g]?.length === groupedValues[g].length ||
                  filterValue[g] === TREE_FILTER_ALL_CHILDREN
                }
                className={classes.Checkbox}
                multi
              />
              <div onClick={() => changeOpenGroup(g)} className={classes.ParentLabel}>
                {renderGroupName(g)}
              </div>
            </div>
            {(groupsOpenState[g] || exists(searchValue)) &&
              filteredGroupedValues[g].map((v, idx) => (
                <div
                  key={`${idx}_${v}`}
                  className={classNames(classes.Child)}
                  onClick={() => onChildClicked(v)}
                >
                  <Checkbox
                    onChange={() => onChildClicked(v)}
                    checked={
                      filterValueAsSet[g]?.has(v[dataKey]) ||
                      filterValue[g] === TREE_FILTER_ALL_CHILDREN
                    }
                    className={classes.Checkbox}
                    multi
                  />
                  <div className={classes.ChildLabel}>{'' + v[dataKey]}</div>
                </div>
              ))}
          </div>
        ))}
        {searchValue && showNoResults && (
          <div className={classes.NoResults}>
            <div className={classes.Text}>No results</div>
          </div>
        )}
      </div>
    </FilterWrapper>
  );
};
