import * as React from 'react';
import classNames from 'classnames';
import classes from './checklist-viewer.module.scss';
import {CheckListFigure} from '../../../types';
import {useMemo} from 'react';
import {
  CircleCheckRegularIcon,
  CloseIcon,
} from '../../../../../simple/controls/icons/icons.component';
import {HoverHelperTip} from '../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
interface OwnProps extends CheckListFigure {
  className?: string;
}

type AllProps = OwnProps;

export const ChecklistViewer: React.FC<AllProps> = (props: AllProps) => {
  const {data, className} = props;
  const {title, subTitle, items} = data;

  return (
    <div className={classNames(classes.ChecklistViewer, className)}>
      <div className={classes.Title}>{title}</div>
      {subTitle && <div className={classes.SubTitle}>{subTitle}</div>}
      <div className={classes.List}>
        {items.map((i, idx) => (
          <div key={idx} className={classNames(classes.Item, i.isCompleted && classes.Completed)}>
            {i.isCompleted === true && <CircleCheckRegularIcon className={classes.Icon} />}
            {i.isCompleted === false && <CloseIcon className={classes.Icon} />}
            <span className={classes.Text}>{i.text}</span>
            {i.helperText && <HoverHelperTip title={i.helperText} className={classes.Helper} />}
          </div>
        ))}
      </div>
    </div>
  );
};
