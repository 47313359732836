import classes from './kpi-tree-page.module.scss';
import {KPITree} from '../../components/kpi-tree/kpi-tree.component';

interface OwnProps {}

type AllProps = OwnProps;

export const KPITreePage = (props: AllProps) => {
  return (
    <div className={classes.KPITreePage}>
      <KPITree showMinimap syncDateInParams />
    </div>
  );
};
