import * as React from 'react';
import classNames from 'classnames';
import classes from './weekly-report-page.module.scss';
import {StringParam, useQueryParam} from 'use-query-params';
import {
  ArrowNarrowUpIcon,
  ArrowRightLightIcon,
  ChevronUpRegularIcon,
  getPercentageValueFormatter,
  LoopsIconPurple,
  TooltipIfOverflow,
  useRemoteSourceStated,
  WavePulseRegularIcon,
} from 'ui-components';
import {getWeeklyInsightsNetworkRequest} from '../../../../http/insights.network-requests.ts';
import {useCallback, useEffect, useMemo} from 'react';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component.tsx';
import moment from 'moment/moment';
import {TIME_FORMATS} from '../../../../constants/time-formats.ts';
import {values} from 'lodash';
import {GridTable} from '../../../shared/components/general/grid-table/grid-table.component.tsx';
import {useProductData} from '../../../../core/hooks/use-product-data.hook.ts';
import {WeeklyReportKPIItem} from './weekly-report-kpi-item/weekly-report-kpi-item.component.tsx';
import {
  formatDateRange,
  generateIdForMetric,
  ROOT_ELEMENT_ID,
  toWeeklySummaryRowItem,
} from './weekly-report-page.utils.ts';
import {WeeklyReport, WeeklySummaryItem} from './weekly-report-page.types.ts';
import {TableColumn} from '../../../shared/components/general/grid-table/grid-table.types.ts';
import {sortData, Sorting} from 'front-core';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {CircularProgress} from '@material-ui/core';
import {PDF_READY_ELEMENT_ID} from '../../../../constants/pdf-generation.consts.ts';

interface Props {
  className?: string;
}

export const WEEKLY_REPORT_DATE_QUERY_PARAM = 'date';
export const WEEKLY_REPORT_SUBSCRIPTION_ID_PARAM = 'sid';
const PRIMARY_SORT: Sorting = {
  orderBy: 'isAnomaly',
  order: 'desc',
};
const SECONDARY_SORT: Sorting = {
  orderBy: 'upliftAbs',
  order: 'desc',
};

export const WeeklyReportPage = (props: Props) => {
  const {className} = props;
  const [date] = useQueryParam<string>(WEEKLY_REPORT_DATE_QUERY_PARAM, StringParam);
  const [subscriptionId] = useQueryParam<string>(WEEKLY_REPORT_SUBSCRIPTION_ID_PARAM, StringParam);
  const {logo, accountName} = useProductData();
  const {t} = useTranslation();
  const {
    source: data,
    exec,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getWeeklyInsightsNetworkRequest,
  });
  const weeklyReport: WeeklyReport = data as WeeklyReport;
  const {items: itemsFromData, teamId} = weeklyReport || {};
  const formattedDate = useMemo(
    () => date && moment(date).format(TIME_FORMATS.READABLE_DATE),
    [date]
  );
  const summaryItems = useMemo(() => values(itemsFromData || {}), [itemsFromData]);
  const items = useMemo(() => {
    if (!summaryItems) {
      return [] as WeeklySummaryItem[];
    }
    return sortData(
      summaryItems.map(toWeeklySummaryRowItem),
      PRIMARY_SORT,
      SECONDARY_SORT
    ) as WeeklySummaryItem[];
  }, [summaryItems]);
  const itemsToDisplay = useMemo(() => items.filter(i => i.isReady), [items]);
  const hasAnomaly = useMemo(() => (items || []).filter(i => i.isAnomaly).length > 0, [items]);
  const columns: TableColumn<WeeklySummaryItem>[] = useMemo(
    () => [
      {
        title: '',
        width: '4rem',
        key: 'anomaly',
        hidden: !hasAnomaly,
        render: item =>
          item.isAnomaly && (
            <div
              className={classNames(
                classes.AnomalyColumn,
                !item.positiveUplift && classes.Negative
              )}
            >
              <WavePulseRegularIcon />
            </div>
          ),
      },
      {
        title: t(TransKeys.WEEKLY_REPORT_PAGE.SUMMARY_TABLE.HEADERS.NAME),
        width: '1.5fr',
        key: 'name',
        render: item => (
          <div className={classes.NameColumn}>
            <TooltipIfOverflow title={item.name}>
              <span className={classes.Name}>{item.name}</span>
            </TooltipIfOverflow>
          </div>
        ),
      },
      {
        title: t(TransKeys.WEEKLY_REPORT_PAGE.SUMMARY_TABLE.HEADERS.LAST_VALUE),
        key: 'value',
        cellClassName: item =>
          item.isReady ? undefined : classNames(classes.NotReady, hasAnomaly && classes.HasAnomaly),
        render: item =>
          item.isReady ? (
            <div className={classes.ValueColumn}>
              <span className={classes.Date}>
                {formatDateRange(item.datetime, item.granularity)}:
              </span>
              <span className={classes.Value}>
                {getPercentageValueFormatter(item.isPercentageValue)(item.value)}
              </span>
            </div>
          ) : (
            <div className={classes.WorkingOnIt}>
              <CircularProgress
                variant={'indeterminate'}
                disableShrink
                size={16}
                thickness={4}
                color={'inherit'}
              />
              <div>{t(TransKeys.WEEKLY_REPORT_PAGE.WORKING_ON_IT)}</div>
            </div>
          ),
      },
      {
        title: t(TransKeys.WEEKLY_REPORT_PAGE.SUMMARY_TABLE.HEADERS.PREV_VALUE),
        key: 'expectedValue',
        shouldRender: item => item.isReady,
        render: item => (
          <div className={classes.ValueColumn}>
            <span className={classes.Date}>
              {formatDateRange(item.datetime, item.granularity, true)}:
            </span>
            <span className={classes.Value}>
              {getPercentageValueFormatter(item.isPercentageValue)(item.expectedValue)}
            </span>
          </div>
        ),
      },
      {
        title: t(TransKeys.WEEKLY_REPORT_PAGE.SUMMARY_TABLE.HEADERS.CHANGE),
        key: 'uplift',
        width: '12rem',
        shouldRender: item => item.isReady,
        render: item => (
          <div className={classes.ChangeColumn}>
            <ArrowNarrowUpIcon
              className={classNames(
                classes.Arrow,
                item.uplift < 0 && classes.Flip,
                !item.positiveUplift && classes.Negative
              )}
            />
            <span className={classes.Value}>{getPercentageValueFormatter(true)(item.uplift)}</span>
          </div>
        ),
      },
      {
        title: t(TransKeys.WEEKLY_REPORT_PAGE.SUMMARY_TABLE.HEADERS.EXPLAINERS),
        key: 'explainersCount',
        width: '18rem',
        shouldRender: item => item.isReady,
        render: item => (
          <div
            className={classNames(
              classes.ExplainersColumn,
              item.explainersCount === 0 && classes.NoExplainers
            )}
          >
            {item.explainersText}
          </div>
        ),
      },
      {
        title: '',
        key: 'link',
        align: 'right',
        width: '12rem',
        shouldRender: item => item.isReady,
        render: item => (
          <div className={classes.ActionColumn}>
            <a href={`#${generateIdForMetric(item.metricId)}`} className={classes.MoreInfo}>
              <span>{t(TransKeys.WEEKLY_REPORT_PAGE.SEE_MORE_INFO)}</span>
              <ArrowRightLightIcon />
            </a>
          </div>
        ),
      },
    ],
    [hasAnomaly, t]
  );
  const onGoToTopClick = useCallback(() => {
    document.getElementById(ROOT_ELEMENT_ID).scrollIntoView({behavior: 'smooth'});
  }, []);

  useEffect(() => {
    exec({subscriptionId, date});
  }, [subscriptionId, date, exec]);

  return (
    <div className={classNames(classes.WeeklyReportPage, className)}>
      {weeklyReport && (
        <div className={classes.GoToTop} onClick={onGoToTopClick}>
          <ChevronUpRegularIcon />
        </div>
      )}
      <div className={classes.Page}>
        <div className={classes.Header} id={ROOT_ELEMENT_ID}>
          <div className={classes.ProductLogo}>
            <img src={logo} alt={'logo'} />
          </div>
          <div className={classes.Title}>
            {t(TransKeys.WEEKLY_REPORT_PAGE.TITLE, {
              account_name: accountName,
              date: formattedDate,
            })}
          </div>
          <div className={classes.SubTitle}>
            <LoopsIconPurple className={classes.LoopsIcon} />
            <span>{t(TransKeys.WEEKLY_REPORT_PAGE.LOOPS_SUBTITLE)}</span>
          </div>
        </div>
        {isLoading && <GenericLoading />}
        {weeklyReport && (
          <>
            <div className={classes.MainTable}>
              <div className={classes.Title}>
                {t(TransKeys.WEEKLY_REPORT_PAGE.SUMMARY_TABLE_TITLE)}
              </div>
              <GridTable
                columns={columns}
                data={items}
                dataKey={'metricId'}
                rowClassName={classes.ZebraRow}
              />
              {hasAnomaly && (
                <div className={classes.Legend}>
                  <div className={classNames(classes.LegendItem, classes.AnomalyColumn)}>
                    <WavePulseRegularIcon />
                    <span className={classes.Text}>
                      - {t(TransKeys.WEEKLY_REPORT_PAGE.LEGEND.POSITIVE_ANOMALY)}
                    </span>
                  </div>
                  <div
                    className={classNames(
                      classes.LegendItem,
                      classes.Negative,
                      classes.AnomalyColumn
                    )}
                  >
                    <WavePulseRegularIcon />
                    <span className={classes.Text}>
                      - {t(TransKeys.WEEKLY_REPORT_PAGE.LEGEND.NEGATIVE_ANOMALY)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {itemsToDisplay.map((item, index) => (
        <div key={item.metricId} className={classes.Page}>
          <WeeklyReportKPIItem
            data={weeklyReport.items[item.metricId]}
            teamId={teamId}
            id={generateIdForMetric(item.metricId)}
            className={classes.MetricItem}
          />
        </div>
      ))}
      {weeklyReport && <div id={PDF_READY_ELEMENT_ID} />}
    </div>
  );
};
