import * as React from 'react';
import classes from './inline-button.module.scss';
import classNames from 'classnames';

interface OwnProps {
  label?: string;
  onClick?: () => void;
  className?: string;
}

type AllProps = OwnProps;

export const InlineButton: React.FC<AllProps> = (props: AllProps) => {
  const {onClick, label, className} = props;

  return (
    <div onClick={onClick} className={classNames(classes.InlineButton, className)}>
      <span className={classes.Label}>{label}</span>
    </div>
  );
};
