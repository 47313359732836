import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import classes from './release-impact-summary-viewer.module.scss';
import {
  ReleaseImpactSummaryFigure,
  ReleaseImpactSummaryItem,
  ReleaseImpactVariantKey,
} from '../../../../types';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {SwitchActions} from '../../../../../../simple/controls/switch-actions/switch-actions.component';
import {
  ClockRotateLeftLightIcon,
  LoopsIcon,
  WarningIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {Chip, ChipVariant} from '../../../../../../simple/data-display/chip/chip.component';
import {ExperimentContext} from '../../../../contexts/experiment.context';
import {exists} from 'front-core';
import {NoticePopup} from '../../../shared/general/notice-popup/notice-popup.component';
import {
  ExperimentSummaryVariantItem,
  ExperimentVariantsTable,
} from '../../../shared/general/experiment-variants-table/experiment-variants-table.component';
import classNames from 'classnames';
import {DocumentTitleWrapper} from '../../../base-viewers/title-section-viewer/title-section-viewer.component';

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_SUMMARY_FIGURE;

interface OwnProps extends ReleaseImpactSummaryFigure {
  className?: string;
}

type AllProps = OwnProps;

const hasSyntheticGoalValues = (items: ReleaseImpactSummaryItem[]) =>
  items.some(
    item => item.variantKey === ReleaseImpactVariantKey.SYNTHETIC_CONTROL && exists(item.goalValue)
  );

export const ReleaseImpactSummaryViewer: React.FC<AllProps> = (props: AllProps) => {
  const {
    data: {items, validations},
    options,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const switchRef = useRef(null);
  const {metrics} = useContext(ExperimentContext);
  const [isComparedAgainstSyntheticControl, setIsComparedAgainstSyntheticControl] =
    useState<boolean>(hasSyntheticGoalValues(items));
  const [showNotice, setShowNotice] = useState<boolean>(!hasSyntheticGoalValues(items));

  const hideNotice = useCallback(() => {
    setShowNotice(false);
  }, []);

  const compareModeOptions = useMemo(
    () => [
      {
        helperText: t(TRANS_KEYS_PREFIX.VS_TOGGLE.SYNTHETIC_CONTROL.HELPER_TEXT),
        label: (
          <span className={classes.CompareSwitchLabel}>
            <LoopsIcon className={classes.CompareSwitchIcon} />
            {t(TRANS_KEYS_PREFIX.VS_TOGGLE.SYNTHETIC_CONTROL.LABEL)}
            <Chip
              label={t(TransKeys.DOCUMENT_VIEWER.LABELS.CAUSAL)}
              variant={ChipVariant.CAUSAL}
              className={classes.Chip}
              size={'xsmall'}
              bold
            />
          </span>
        ),
        onClick: () => setIsComparedAgainstSyntheticControl(true),
        isActive: isComparedAgainstSyntheticControl,
      },
      {
        helperText: t(TRANS_KEYS_PREFIX.VS_TOGGLE.NAIVE_CONTROL.HELPER_TEXT),
        label: t(TRANS_KEYS_PREFIX.VS_TOGGLE.NAIVE_CONTROL.LABEL),
        icon: ClockRotateLeftLightIcon,
        onClick: () => setIsComparedAgainstSyntheticControl(false),
        isActive: !isComparedAgainstSyntheticControl,
      },
    ],
    [isComparedAgainstSyntheticControl, setIsComparedAgainstSyntheticControl, t]
  );

  const tableData: ExperimentSummaryVariantItem[] = useMemo(() => {
    const allowedVariantKeys = isComparedAgainstSyntheticControl
      ? [ReleaseImpactVariantKey.SYNTHETIC_CONTROL, ReleaseImpactVariantKey.POST_RELEASE]
      : [ReleaseImpactVariantKey.NAIVE_CONTROL, ReleaseImpactVariantKey.POST_RELEASE];
    return items
      .filter(item => allowedVariantKeys.includes(item.variantKey))
      .map(item => ({
        ...item,
        isWinner: isComparedAgainstSyntheticControl
          ? item.isWinnerVsSynthetic
          : item.isWinnerVsNaive,
        probability: isComparedAgainstSyntheticControl
          ? item.probabilityVsSynthetic
          : item.probabilityVsNaive,
        improvement: isComparedAgainstSyntheticControl
          ? item.improvementVsSynthetic
          : item.improvementVsNaive,
      }));
  }, [items, isComparedAgainstSyntheticControl]);

  return (
    <DocumentTitleWrapper
      title={t(TRANS_KEYS_PREFIX.TITLE)}
      className={classNames(classes.ReleaseImpactSummaryFigure, className)}
      renderRight={
        <span className={classes.SwitchActionsWrapper} ref={switchRef}>
          <SwitchActions showActionsLabel actions={compareModeOptions} />
        </span>
      }
    >
      <NoticePopup
        anchorEl={switchRef.current}
        show={showNotice}
        placement={'top-start'}
        icon={WarningIcon}
        message={t(TRANS_KEYS_PREFIX.NO_CAUSAL_RESULTS.MESSAGE)}
        confirmLabel={t(TRANS_KEYS_PREFIX.NO_CAUSAL_RESULTS.OK_BUTTON)}
        onConfirm={hideNotice}
      />
      <ExperimentVariantsTable
        data={tableData}
        validations={isComparedAgainstSyntheticControl ? validations : undefined}
        transKeysPrefix={TRANS_KEYS_PREFIX}
        transKeysModifier={isComparedAgainstSyntheticControl ? 'VS_SYNTHETIC' : 'VS_NAIVE'}
        showExpectedLoss={false}
        showActions={options.showActions}
      />
    </DocumentTitleWrapper>
  );
};
