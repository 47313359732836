import {getPercentageValueFormatter} from '../../../../../../../utils/formatters';
import {
  ReleaseImpactCalculationBreakdownFigure,
  ReleaseImpactCalculationBreakdownItem,
} from '../../../../types/figures.types';
import {VersusWithUpliftDisplay} from '../../../shared/display-columns/versus-with-uplift-display.component';
import {
  AcceptedFlexibleTableColumn,
  FlexibleTable,
  FlexibleTableClasses,
} from '../../../shared/general/flexible-table/flexible-table.component';
import pluralize from 'pluralize';
import classNames from 'classnames';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {useMemo} from 'react';
import TransKeys from 'translations';
import {Tooltip} from '@material-ui/core';
import {
  CircleInfoLightIcon,
  HashtagIcon,
  PercentageIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {FancyCollapsibleContainer} from '../../../shared/general/fancy-collapsible-container/fancy-collapsible-container.component';
import classes from './release-impact-calculation-breakdown-viewer.module.scss';
import {uniqueId} from 'lodash';

const MISSING_VALUE_PLACEHOLDER = '-';
const transKeysPrefix = TransKeys.DOCUMENT_VIEWER.RELEASE_IMPACT_CALCULATION_BREAKDOWN_FIGURE;

interface OwnProps extends ReleaseImpactCalculationBreakdownFigure {}

type AllProps = OwnProps;

type TableItem = ReleaseImpactCalculationBreakdownItem & {key: string; isSignificant: boolean};

export const ReleaseImpactCalculationBreakdownViewer = (props: AllProps) => {
  const {
    data: {items: itemsFromProps, preTotal, postTotal},
    options: {isPercentageValue, entity},
  } = props;

  const {t} = useDocumentTranslation();

  const valueFormatter = useMemo(() => getPercentageValueFormatter(true), []);

  const items: TableItem[] = useMemo(
    () =>
      itemsFromProps.map((item, index) => ({
        ...item,
        key: uniqueId(),
        isSignificant:
          (item.releaseImpactValueLower < 0 && item.releaseImpactValueUpper < 0) ||
          (item.releaseImpactValueLower > 0 && item.releaseImpactValueUpper > 0),
      })),
    [itemsFromProps]
  );

  const tableColumns = useMemo<AcceptedFlexibleTableColumn<TableItem>[]>(
    () => [
      {
        key: 'segment',
        title: t(transKeysPrefix.TABLE.HEADERS.SEGMENTS.TITLE),
        weight: 1,
        render: item => (
          <div className={classes.Segment}>
            <div>{item.groupName}</div>
            <Tooltip title={<div className={classes.SegmentTooltip}>{item.helperText}</div>}>
              <CircleInfoLightIcon className={classes.InfoIcon} />
            </Tooltip>
          </div>
        ),
      },
      [
        {
          key: 'share',
          icon: PercentageIcon,
          title: t(transKeysPrefix.TABLE.HEADERS.SHARE.TITLE, {entity: pluralize(entity)}),
          weight: 1,
          subTitle: t(transKeysPrefix.TABLE.HEADERS.SHARE.SUBTITLE),
          render: item => (
            <VersusWithUpliftDisplay
              value={item.postCount / postTotal}
              vsValue={item.preCount / preTotal}
              isPercentageValue={true}
            />
          ),
        },
        {
          key: 'count',
          icon: HashtagIcon,
          title: t(transKeysPrefix.TABLE.HEADERS.COUNT.TITLE, {entity: pluralize(entity)}),
          weight: 1,
          subTitle: t(transKeysPrefix.TABLE.HEADERS.COUNT.SUBTITLE),
          render: item => (
            <VersusWithUpliftDisplay
              value={item.postCount}
              vsValue={item.preCount}
              isPercentageValue={false}
            />
          ),
        },
      ],
      {
        key: 'metric-value',
        title: t(transKeysPrefix.TABLE.HEADERS.METRIC_VALUE.TITLE),
        subTitle: t(transKeysPrefix.TABLE.HEADERS.METRIC_VALUE.SUBTITLE),
        weight: 1,
        render: item => (
          <VersusWithUpliftDisplay
            value={item.postGoalValue}
            vsValue={item.preGoalValue}
            isPercentageValue={isPercentageValue}
          />
        ),
      },
      {
        key: 'impact',
        title: t(transKeysPrefix.TABLE.HEADERS.RELEASE_IMPACT.TITLE),
        weight: 1,
        render: item => (
          <div>
            {item.releaseImpactValue && (
              <div className={classes.ReleaseImpactValue}>
                {valueFormatter(item.releaseImpactValue)}
                {item.releaseImpactValueLower !== null && item.releaseImpactValueUpper !== null && (
                  <span className={classNames(FlexibleTableClasses.ShowOnHover, classes.Secondary)}>
                    {t(transKeysPrefix.TABLE.VALUE_RANGE, {
                      lower:
                        item.releaseImpactValueLower !== null
                          ? valueFormatter(item.releaseImpactValueLower)
                          : MISSING_VALUE_PLACEHOLDER,
                      upper:
                        item.releaseImpactValueUpper !== null
                          ? valueFormatter(item.releaseImpactValueUpper)
                          : MISSING_VALUE_PLACEHOLDER,
                    })}
                  </span>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        key: 'significant',
        title: '',
        weight: 1,
        render: item =>
          item.isSignificant ? null : (
            <div className={classes.InsignificantChip}>INSIGNIFICANT</div>
          ),
      },
    ],
    [t, postTotal, preTotal, valueFormatter, entity, isPercentageValue]
  );

  return (
    <FancyCollapsibleContainer title={t(transKeysPrefix.TITLE)}>
      <div className={classes.Description}>{t(transKeysPrefix.DESCRIPTION)}</div>
      <FlexibleTable className={classes.Table} columns={tableColumns} data={items} />
    </FancyCollapsibleContainer>
  );
};
