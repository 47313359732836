import * as React from 'react';
import {Select, SelectComponentProps} from '../../../../forms/inputs/select/select.component';
import {SqlElementModel} from '../../query-builder.types';
import {useContext, useMemo} from 'react';
import {QueryBuilderContext} from '../../query-builder.context';
import {MultiSelect} from '../../../../forms/inputs/multi-select/multi-select.component';
import {capitalize} from 'front-core';

interface OwnProps extends Omit<SelectComponentProps, 'options' | 'value' | 'onChange'> {
  model: SqlElementModel;
  multi?: boolean;
  value: any;
  onChange: (v: any) => void;
}

type AllProps = OwnProps;

export const ModelSelector: React.FC<AllProps> = (props: AllProps) => {
  const {multi, model} = props;
  const {config} = useContext(QueryBuilderContext);
  const selectOptions = config.modelConfig?.[model];
  const Component = useMemo(() => (multi ? MultiSelect : Select), [multi]);
  const modelName = useMemo(() => capitalize(model.toLowerCase()), [model]);

  if (!selectOptions) {
    return <span>UNSUPPORTED! Missing model config for SIGNAL</span>;
  }

  return (
    <Component
      placeholder={`Select ${modelName}`}
      value={props.value}
      onChange={props.onChange}
      error={props.error}
      options={selectOptions}
      disabled={props.disabled}
      clearable={props.clearable}
      fullWidth={props.fullWidth}
    />
  );
};
