import * as React from 'react';
import classNames from 'classnames';
import classes from './shared-columns-content.module.scss';
import {FlexibleTableClasses} from '../general/flexible-table/flexible-table.component';
import {Tooltip} from '@material-ui/core';
import {useMemo} from 'react';
import {exists} from 'front-core';
import {getPercentageValueFormatter} from '../../../../../../utils/formatters';

interface OwnProps {
  value: number;
  outOfValue: number;
  isPercentageValue?: boolean;
  icon?: any;
  className?: string;
}

type AllProps = OwnProps;

export const OutOfDisplay: React.FC<AllProps> = (props: AllProps) => {
  const {value, outOfValue, isPercentageValue, icon: Icon, className} = props;
  const valueFormatter = useMemo(
    () => getPercentageValueFormatter(isPercentageValue),
    [isPercentageValue]
  );

  return (
    <div className={classNames(classes.OutOfDisplay, className)}>
      {Icon && <Icon className={classes.Icon} />}
      {valueFormatter(value)}
      {exists(outOfValue) ? (
        <span className={classNames(FlexibleTableClasses.ShowOnHover, classes.Secondary)}>
          <span className={classes.VS}>/</span>
          <span>{valueFormatter(outOfValue)}</span>
        </span>
      ) : null}
    </div>
  );
};
