import classes from './rca-date-picker.module.scss';
import {DatePickerInput, Select} from 'ui-components';
import {useCallback, useMemo} from 'react';
import {TIME_FORMATS} from '../../../../../../constants/time-formats';
import {createStartDateComparingProps, dateToLabel} from '../../../../../../utils/dates.utils';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model';
import moment from 'moment';
import {capitalize} from 'lodash';
import {Divider} from '@material-ui/core';
import classNames from 'classnames';

interface OwnProps {
  selected: string;
  maxDate: string;
  granularity: ModelSeriesGranularity;
  granularityOptions?: ModelSeriesGranularity[];
  onChangeDate: (date: string) => void;
  onChangeGranularity?: (granularity: ModelSeriesGranularity) => void;
  isLoading?: boolean;
}

type AllProps = OwnProps;

export const RCADatePicker = (props: AllProps) => {
  const {
    selected,
    onChangeDate,
    onChangeGranularity,
    maxDate,
    granularity,
    granularityOptions,
    isLoading,
  } = props;

  const datePickerProps = useMemo(() => {
    const max = moment.utc(maxDate);
    if (granularity === ModelSeriesGranularity.WEEK) {
      max.add(6, 'days');
    }
    return {
      ...createStartDateComparingProps(granularity as any),
      maxDate: max.toDate(),
    };
  }, [maxDate, granularity]);
  const renderValue = useCallback(
    (value: string) => (isLoading ? 'Loading...' : dateToLabel(value, granularity as any)),
    [granularity, isLoading]
  );
  const granularityOptionsForSelect = useMemo(() => {
    if (!granularityOptions) {
      return;
    }
    return granularityOptions.map(granularity => ({
      label: capitalize(granularity),
      value: granularity,
    }));
  }, [granularityOptions]);

  const granularityDisabled = isLoading || granularityOptions?.length === 1;

  return (
    <div className={classes.DatePickerContainer}>
      {granularityOptions && (
        <>
          <Select
            dropdownButtonClassName={classNames(
              classes.SelectGranularity,
              granularityDisabled && classes.Disabled
            )}
            value={granularity}
            options={{options: granularityOptionsForSelect}}
            sortValues={false}
            disabled={granularityDisabled}
            clearable={false}
            searchable={false}
            onChange={value => onChangeGranularity(value as ModelSeriesGranularity)}
          />
          <Divider orientation={'vertical'} />
        </>
      )}
      <DatePickerInput
        className={classes.Picker}
        value={selected}
        onChange={onChangeDate}
        disabled={isLoading}
        utc
        {...datePickerProps}
        icon={null}
        renderValue={renderValue}
        dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
      />
    </div>
  );
};
