import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './retention-overview-viewer.module.scss';
import {useDocQuery} from '../../../../hooks/use-doc-query.hook';
import {DocumentElementType, RetentionOverviewFigure} from '../../../../types';
import {SimulationMode, useSimulatorMode} from '../../../../hooks/use-simulator-mode.hook';
import {Checkbox} from '../../../../../../forms/inputs/checkbox/checkbox.component';
import {capitalize, takeRight} from 'lodash';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {RetentionOverviewDataMode} from './components/retention-overview-data-mode/retention-overview-data-mode.component';
import {RetentionOverviewSimulatorMode} from './components/retention-overview-simulator-mode/retention-overview-simulator-mode.components';

interface OwnProps extends RetentionOverviewFigure {
  className?: string;
}

type AllProps = OwnProps;

interface Filters {
  bucket0?: boolean;
}

const DEFAULT_FILTERS: Filters = {
  bucket0: true,
};

export const RetentionOverviewViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data: data, options, className} = props;
  const {t} = useDocumentTranslation();
  const {button: simulatorButton, mode} = useSimulatorMode({
    figureId: id,
    elementType: DocumentElementType.RETENTION_OVERVIEW_FIGURE,
    allowDataMode: true,
    isCasual: true,
    disabled: options.simulatorMode === false,
    disabledTooltip: 'Soon',
    queryPrefix: 'overview',
    label: t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SIMULATOR_MODE),
    helperText: t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.SIMULATOR_MODE_HELPER),
  });
  const {query: filters, setQuery: setFilters} = useDocQuery<Filters>(
    id,
    DEFAULT_FILTERS,
    'filters'
  );
  const filteredDataItems = useMemo(() => {
    if (filters.bucket0) {
      return data.items;
    }
    return data.items.map(i => ({
      ...i,
      values: takeRight(i.values, i.values.length - 1),
    }));
  }, [data.items, filters.bucket0]);
  const filteredPotentialLifts = useMemo(() => {
    if (filters.bucket0) {
      return data.potentialLifts;
    }
    return takeRight(data.potentialLifts, data.potentialLifts.length - 1);
  }, [data.potentialLifts, filters.bucket0]);
  // texts
  const includeToggleText = useMemo(() => capitalize(`${data.timeUnit} 0`), [data.timeUnit]);
  const sharedProps = {
    figureId: id,
    items: filteredDataItems,
    timeUnit: data.timeUnit,
    timeUnitN: data.timeUnitN,
    entity: options.entity,
    bucket0: filters.bucket0,
  };

  return (
    <div className={classNames(classes.RetentionOverviewFigureViewer, className)}>
      <div className={classes.Controls}>
        {simulatorButton}
        <Checkbox
          className={classes.Include0}
          checked={filters.bucket0 === true}
          onChange={v => setFilters({bucket0: v})}
          label={t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.FILTERS.INCLUDE_BUCKET_0, {
            text: includeToggleText,
          })}
          multi={true}
          border
        />
      </div>
      {mode === SimulationMode.DATA && <RetentionOverviewDataMode {...sharedProps} />}
      {mode === SimulationMode.SIMULATOR && (
        <RetentionOverviewSimulatorMode
          {...sharedProps}
          potentialLifts={filteredPotentialLifts}
          stableValue={data.stableValue}
        />
      )}
    </div>
  );
};
