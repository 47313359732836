import {
  ConditionOperator,
  DatePickerInput,
  LiteralValueType,
  Select,
  TextInput,
} from 'ui-components';
import classes from './shared-builder-parts.module.scss';
import classNames from 'classnames';
import {TIME_FORMATS} from '../../../../../constants/time-formats.ts';

interface OwnProps {
  type: LiteralValueType;
  op?: ConditionOperator;
  value: any;
  error?: boolean;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

type AllProps = OwnProps;

const MAX_DATE = new Date();

const BOOLEAN_OPTIONS = {
  options: [
    {label: 'true', value: true},
    {label: 'false', value: false},
  ],
};

export const LiteralValueSelector = (props: AllProps) => {
  const {type, value, onChange, onBlur, op, error, placeholder, disabled, className} = props;

  const renderInput = () => {
    let render = null;
    const sharedProps = {
      error,
      value,
      disabled,
      onChange,
    };

    switch (type) {
      case LiteralValueType.INTEGER:
      case LiteralValueType.FLOAT:
      case LiteralValueType.STRING:
        const isNumericType = type === LiteralValueType.FLOAT || type === LiteralValueType.INTEGER;
        const multiple =
          op === ConditionOperator.IN ||
          op === ConditionOperator.NIN ||
          op === ConditionOperator.NIN_OR_NULL;
        if (disabled && !multiple) {
          return <div className={classes.DisabledValue}>{value}</div>;
        }
        render = (
          <TextInput
            {...sharedProps}
            className={classes.TableValue}
            type={isNumericType ? 'number' : 'text'}
            placeholder={placeholder || (isNumericType ? 'Number' : 'Value')}
            multiple={multiple}
            onBlur={onBlur}
          />
        );
        break;
      case LiteralValueType.BOOLEAN:
        render = (
          <Select
            {...sharedProps}
            options={BOOLEAN_OPTIONS as any}
            placeholder={placeholder || 'Value'}
            searchable={false}
            clearable={false}
          />
        );
        break;
      case LiteralValueType.DATE:
        if (op !== ConditionOperator.BETWEEN) {
          render = (
            <DatePickerInput
              {...sharedProps}
              className={classes.TableValue}
              placeholder={placeholder || 'Date'}
              dateFormat={'DD/MM/YYYY'}
              dateInputFormat={TIME_FORMATS.PARAMETER_DATE_FORMAT}
              maxDate={MAX_DATE}
              utc
            />
          );
        }
        break;
    }

    return <div className={classNames(classes.Item, className)}>{render}</div>;
  };

  return renderInput();
};
