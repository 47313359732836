import {RCAElementType, RCAMode} from 'ui-components';
import TransKeys from 'translations';
import classes from '../rca-insights-section.module.scss';
import {RCASentence} from '../../../../../../../../../../insights/rca/rca-insights.model.ts';
import * as React from 'react';
import {NoExplainersMsg} from './no-explainers-msg/no-explainers-msg.component.tsx';
import {RCAInsightsSectionHeader} from './rca-insights-section-header.component';
import {RCAInsightSentenceRenderer} from './rca-insights-section-sentence-renderer.component.tsx';
import {ScrollToSectionSentencePart} from '../../../../../../../../../../insights/components/shared-sentence-parts/shared-sentence-parts.components.tsx';

interface OwnProps {
  interestingXoxSentences: RCASentence[];
  rcaMode: RCAMode;
  granularity: string;
  onViewOnPage?: (titleId: string) => void;
}

type AllProps = OwnProps;

export const InterestingXoxSection: React.FC<AllProps> = (props: AllProps) => {
  const {interestingXoxSentences, rcaMode, granularity, onViewOnPage} = props;

  const renderWithBullet = (key: string, children: React.ReactNode) => (
    <React.Fragment key={key}>
      <span className={classes.Bullet} />
      {children}
    </React.Fragment>
  );

  return (
    <div className={classes.RCAInsightsSectionContainer}>
      <RCAInsightsSectionHeader
        rcaMode={rcaMode}
        granularity={granularity}
        titlePartOneTxPath={
          TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.HIGHLIGHTED_TITLE.PART_ONE
        }
      />
      <NoExplainersMsg />
      <div className={classes.SentencesList}>
        {interestingXoxSentences.map((sentence, index) => (
          <RCAInsightSentenceRenderer
            key={sentence.key}
            sentence={sentence}
            renderWrapper={children => renderWithBullet(`${sentence.key}_${index}`, children)}
          />
        ))}
      </div>
      {onViewOnPage && (
        <ScrollToSectionSentencePart
          onViewOnPage={() => onViewOnPage(RCAElementType._CHANGING_SEGMENTS)}
          text={'View on page'}
        />
      )}
    </div>
  );
};
