import * as React from 'react';
import {makeStyles, TextField} from '@material-ui/core';
import {TextFieldProps} from '@material-ui/core/TextField/TextField';
import {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react';

const styles = makeStyles(theme => ({
  root: {
    borderTop: '1px solid rgba(215, 218, 235, 1)',
    borderLeft: '1px solid rgba(215, 218, 235, 1)',
    borderBottom: '1px solid rgba(215, 218, 235, 1)',
    borderRight: '1px solid rgba(215, 218, 235, 1)',
    borderRadius: '4px',
    padding: '0 0.8rem',
    transition: 'all 0.25s',
    '&:focus-within': {
      borderTop: '1px solid rgba(52, 131, 255, 1)',
      borderLeft: '1px solid rgba(52, 131, 255, 1)',
      borderBottom: '1px solid rgba(52, 131, 255, 1)',
      borderRight: '1px solid rgba(52, 131, 255, 1)',
    },
  },
}));

const inputStyles = makeStyles(theme => ({
  root: {
    padding: '0.4rem 0',
    fontSize: '1.4rem',
    fontFamily: 'Manrope',
    minHeight: '2.8rem',
  },
  input: {
    color: '#293164',
    lineHeight: 1.5,
    '&::placeholder': {
      color: 'rgba(195, 198, 216, 1)',
      opacity: 1,
    },
  },
}));

export const Textarea: ForwardRefExoticComponent<
  PropsWithoutRef<TextFieldProps> & RefAttributes<any>
> = forwardRef<any>((props: TextFieldProps, ref: ForwardedRef<any>) => {
  const classes = styles();
  const inputClasses = inputStyles();
  return (
    // @ts-ignore
    <TextField
      {...props}
      ref={ref}
      InputProps={{
        disableUnderline: true,
        classes: inputClasses,
      }}
      classes={classes}
      multiline
    />
  );
});
