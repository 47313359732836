import * as React from 'react';
import {useContext, useEffect, useMemo, useState} from 'react';
import {get, isArray, set, values} from 'lodash';
import {BOOLEAN_OPTIONS} from '../query-builder.config';
import {useQueryArray} from '../query-builder.hooks';
import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CloseIcon,
  CopyIcon,
} from '../../../simple/controls/icons/icons.component';
import {QueryTree} from '../components/query-tree/query-tree.component';
import {ActionItem} from '../../../simple/controls/actions-dropdown/actions-dropdown.component';
import {Select} from '../../../forms/inputs/select/select.component';
import {TextInput} from '../../../forms/inputs/text-input/text-input.component';
import {InlineToken, MutedText} from '../components/parts';
import classes from '../query-builder.module.scss';
import {QueryActions} from '../components/query-actions/query-actions.component';
import {ModelSelector} from '../components/model-selector/model-selector.component';
import {TreeLabel} from '../components/tree-label/tree-label.component';
import {capitalize, exists} from 'front-core';
import {EnumInputOption} from '../../../forms/inputs.types';
import {MultiSelect} from '../../../forms/inputs/multi-select/multi-select.component';
import {SqlElementModel} from '../query-builder.types';
import {QueryBuilder} from '../query-builder.component';
import {QueryBuilderContext} from '../query-builder.context';

interface OwnProps {
  data: Array<any>;
  onChange: (data: any) => void;
  errors?: any;
  disabled?: boolean;
  parametersDescriptor?: ParameterDescriptor[];
}

type AllProps = OwnProps;

export enum ParametersBuilderParameterType {
  NUMBER = 'number',
  STRING = 'string',
  QUERY = 'query',
  BOOLEAN = 'boolean',
  NULL = 'null',
  NUMBER_LIST = 'number_list',
  STRING_LIST = 'string_list',
  SIGNAL = 'signal',
  SIGNAL_LIST = 'signal_list',
  TABLE = 'table',
  TABLE_LIST = 'table_list',
  QUERY_LIST = 'query_list',
}

export interface ParameterDescriptor {
  name: string;
  type: ParametersBuilderParameterType;
  default: any;
  options?: EnumInputOption[];
  index: number;
}

const switchParam = (p): ParametersBuilderParameterType => {
  switch (typeof p) {
    case 'boolean':
      return ParametersBuilderParameterType.BOOLEAN;
    case 'string':
      return ParametersBuilderParameterType.STRING;
    case 'number':
      return ParametersBuilderParameterType.NUMBER;
    case 'object':
      if (p === null) {
        return ParametersBuilderParameterType.NULL;
      }
      if (isArray(p)) {
        if (typeof p[0] === 'number') {
          return ParametersBuilderParameterType.NUMBER_LIST;
        }
        if (typeof p[0] === 'string') {
          return ParametersBuilderParameterType.STRING_LIST;
        }
      }
      return ParametersBuilderParameterType.QUERY;
  }
};

const DEFAULT_VALUES = {
  [ParametersBuilderParameterType.NUMBER]: null,
  [ParametersBuilderParameterType.STRING]: null,
  [ParametersBuilderParameterType.NUMBER_LIST]: [],
  [ParametersBuilderParameterType.STRING_LIST]: [],
  [ParametersBuilderParameterType.NULL]: null,
  [ParametersBuilderParameterType.BOOLEAN]: false,
  [ParametersBuilderParameterType.QUERY]: {},
  [ParametersBuilderParameterType.QUERY_LIST]: [{}],
  [ParametersBuilderParameterType.TABLE]: null,
  [ParametersBuilderParameterType.TABLE_LIST]: [],
  [ParametersBuilderParameterType.SIGNAL]: null,
  [ParametersBuilderParameterType.SIGNAL_LIST]: [],
};

const INPUT_TYPE = {
  [ParametersBuilderParameterType.NUMBER]: 'number',
  [ParametersBuilderParameterType.STRING]: 'text',
  [ParametersBuilderParameterType.NUMBER_LIST]: 'number',
  [ParametersBuilderParameterType.STRING_LIST]: 'text',
};

export const ParametersBuilder: React.FC<AllProps> = (props: AllProps) => {
  const {data, onChange, errors, parametersDescriptor, disabled} = props;
  const [paramTypeArr, setParamTypeArr] = useState(data.map(switchParam) || []);
  const {config} = useContext(QueryBuilderContext);

  const {
    addElement: addElementType,
    cloneElement: cloneElementType,
    moveElement: moveElementType,
    removeElement: removeElementType,
  } = useQueryArray(
    paramTypeArr,
    arr => setParamTypeArr(arr),
    p => p
  );

  const {addElement, cloneElement, moveElement, removeElement} = useQueryArray(
    data,
    data => onChange(data),
    (type: ParametersBuilderParameterType) => {
      addElementType(type);
      return DEFAULT_VALUES[type];
    }
  );

  const addOptions: ActionItem[] = useMemo(
    () =>
      values(ParametersBuilderParameterType).map(t => ({
        key: t,
        title: capitalize(t.replace('_', ' ')),
        onClick: _ => addElement(t),
      })),
    [addElement]
  );

  const onValueChange = (idx: any, v: any) => {
    let newData = [...data];
    newData = set(newData, idx, v);
    onChange(newData);
  };

  const onRemoveParameter = (idx: number) => {
    removeElement(idx);
    removeElementType(idx);
  };

  const onMoveParameter = (idx: number, newIdx: number) => {
    moveElement(idx, newIdx);
    moveElementType(idx, newIdx);
  };

  const onCloneParameter = (idx: number) => {
    cloneElement(idx);
    cloneElementType(idx);
  };

  useEffect(() => {
    if (!parametersDescriptor || parametersDescriptor.length === 0) {
      !exists(data) && onChange([]);
      return;
    }
    const sortedParametersDescriptor = [...parametersDescriptor].sort((a, b) => a.index - b.index);
    const newData = sortedParametersDescriptor.map((p, idx) => data[p.index || idx] || p.default);
    const newParamTypeArr: any = sortedParametersDescriptor.map(p => p.type);
    setParamTypeArr(newParamTypeArr);
    onChange(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametersDescriptor]);

  const renderParameter = (p: any, idx: number) => {
    const type = paramTypeArr[idx];
    const sharedProps = {
      error: Boolean(get(errors, idx)),
      key: idx,
      disabled,
    };
    const actions = [
      {
        icon: ArrowUpIcon,
        label: 'Up',
        onClick: () => onMoveParameter(idx, idx - 1),
        hidden: idx === 0,
      },
      {
        icon: ArrowDownIcon,
        label: 'Down',
        onClick: () => onMoveParameter(idx, idx + 1),
        hidden: idx === data.length - 1,
      },
      {
        icon: CopyIcon,
        label: 'Duplicate',
        onClick: () => onCloneParameter(idx),
      },
    ];
    const parameterDescriptor = parametersDescriptor?.find((p, pIndex) => {
      if (exists(p.index) && p.index === idx) {
        return true;
      }
      return !exists(p.index) && pIndex === idx;
    });

    const actionsWithDelete = [
      ...actions,
      {
        icon: CloseIcon,
        label: 'Remove',
        onClick: () => onRemoveParameter(idx),
      },
    ];

    let render = null;
    switch (type) {
      case ParametersBuilderParameterType.QUERY_LIST:
        const arrValue = isArray(p) ? p : [p];
        return (
          <QueryTree
            key={idx}
            small
            label={parameterDescriptor?.name || 'Query List'}
            actions={[
              {
                label: 'Add',
                icon: AddIcon,
                onClick: () => onValueChange(idx, [...arrValue, {}]),
              },
              ...actions,
            ]}
            disabled={disabled}
          >
            {arrValue.map((pp, pIdx) => (
              <div className={classes.InlineRow} key={pIdx}>
                <QueryBuilder
                  key={pIdx}
                  {...sharedProps}
                  config={config}
                  query={pp}
                  disabled={disabled}
                  onChange={value => onValueChange(`${idx}.${pIdx}`, value)}
                />
                {!exists(pp) && (
                  <QueryActions
                    actions={[
                      {
                        icon: CloseIcon,
                        label: 'Remove',
                        onClick: () =>
                          onValueChange(
                            idx,
                            arrValue.filter((_, idx) => idx !== pIdx)
                          ),
                      },
                    ]}
                  />
                )}
              </div>
            ))}
          </QueryTree>
        );
      case ParametersBuilderParameterType.QUERY:
        render = (
          <QueryBuilder
            key={idx}
            {...sharedProps}
            config={config}
            query={p as any}
            disabled={disabled}
            onChange={value => onValueChange(idx, value)}
          />
        );
        break;
      case ParametersBuilderParameterType.BOOLEAN:
        render = (
          <Select
            {...sharedProps}
            value={p}
            onChange={value => onValueChange(idx, value)}
            options={{options: BOOLEAN_OPTIONS as any}}
            searchable={false}
          />
        );
        break;
      case ParametersBuilderParameterType.SIGNAL_LIST:
        render = (
          <ModelSelector
            {...sharedProps}
            value={isArray(p) ? p : [p]}
            onChange={value => onValueChange(idx, value)}
            model={SqlElementModel.SIGNAL}
            multi
            clearable
          />
        );
        break;
      case ParametersBuilderParameterType.SIGNAL:
        render = (
          <ModelSelector
            {...sharedProps}
            value={p}
            onChange={value => onValueChange(idx, value)}
            model={SqlElementModel.SIGNAL}
            clearable
          />
        );
        break;
      case ParametersBuilderParameterType.TABLE_LIST:
      case ParametersBuilderParameterType.TABLE:
        render = (
          <ModelSelector
            {...sharedProps}
            value={p}
            onChange={value => onValueChange(idx, value)}
            multi={type === ParametersBuilderParameterType.TABLE_LIST}
            model={SqlElementModel.TABLE}
            clearable
          />
        );
        break;
      case ParametersBuilderParameterType.STRING:
      case ParametersBuilderParameterType.STRING_LIST:
      case ParametersBuilderParameterType.NUMBER:
      case ParametersBuilderParameterType.NUMBER_LIST:
        if (parameterDescriptor?.options) {
          let Comp: any = Select;
          if (type === ParametersBuilderParameterType.STRING_LIST) {
            Comp = MultiSelect;
          }
          render = (
            <Comp
              {...sharedProps}
              onChange={value => onValueChange(idx, value)}
              value={p}
              options={{options: parameterDescriptor.options}}
              searchable={false}
            />
          );
        } else {
          render = (
            <TextInput
              {...sharedProps}
              value={p}
              onChange={value => onValueChange(idx, value)}
              type={INPUT_TYPE[type]}
              placeholder={`Enter ${type.replace('_', ' ')}`}
              multiple={type.includes('_list')}
            />
          );
        }
        break;
      case 'null':
        render = <InlineToken>Null</InlineToken>;
        break;
    }

    return (
      <div className={classes.InlineRow} key={idx}>
        <TreeLabel label={parameterDescriptor?.name}>{render}</TreeLabel>
        {!disabled && !parametersDescriptor && <QueryActions actions={actionsWithDelete} />}
      </div>
    );
  };

  const renderParameters = () => {
    if (!parametersDescriptor) {
      return data.map((p, idx) => renderParameter(p, idx));
    }
    return parametersDescriptor.map((p, idx) => {
      const actualIndex = exists(p.index) ? p.index : idx;
      return renderParameter(data[actualIndex], actualIndex);
    });
  };

  return (
    <QueryTree
      label={'Parameters'}
      addOptions={parametersDescriptor ? undefined : addOptions}
      disabled={disabled}
    >
      {renderParameters()}
      {data.length === 0 && <MutedText>No Parameters</MutedText>}
    </QueryTree>
  );
};
