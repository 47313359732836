import {useCallback, useContext, useEffect, useMemo} from 'react';
import classes from './metric-page.module.scss';
import {
  ANALYSIS_TYPE_ID_PATH_PARAM,
  AppRoutes,
  METRIC_ID_PATH_PARAM,
} from '../../../../constants/app-routes';
import {useParams} from 'react-router';
import PageLayout from '../../../shared/components/layout/page-layout/index';
import {PageHeader} from '../../../shared/components/layout/page-header/page-header.component';
import {useDispatch} from 'react-redux';
import {ModelKey} from '../../../../constants/model-key';
import {getMetricPageNetworkRequest} from '../../../../http/metrics.network-requests';
import {GenericLoading} from '../../../shared/components/general/generic-loading/generic-loading.component';
import {MetricPageHeader} from './components/metric-page-header/metric-page-header.component';
import {setMetricOwner} from '../../../../store/metrics/metrics.actions';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../store/core/core.actions';
import {
  DocumentElementType,
  useRemoteSourceStated,
  RCAFigureViewer,
  RootDocumentViewer,
  MoreIcon,
  EditIcon,
} from 'ui-components';
import {PanelKey} from '../../../../constants/panels';
import {PanelsContext} from '../../../../core/contexts/panels.context';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../constants/time-formats';
import {StringParam, useQueryParam} from 'use-query-params';
import {AnalysisResults} from '../../../shared/core/document-viewer/analysis-results.component';
import {getAnalysisResultNetworkRequest} from '../../../../http/analysis-results.network-requests';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {AppRCAOverviewViewer} from '../../../shared/core/document-viewer/viewers/app-rca-overview-viewer.component';
import {RCADatePicker} from './components/rca-date-picker/rca-date-picker.component';
import usePermissions from '../../../../core/hooks/use-permissions.hook';
import {MetricSuggestionViewer} from '../../../shared/core/document-viewer/viewers/metric-suggestion-viewer/metric-suggestion-viewer.component';
import {Action, Subject} from '../../../../constants/permissions';
import {ModelActionsDropdown} from '../../../shared/core/model-actions-dropdown/model-actions-dropdown.component.tsx';
import {EmptyState} from '../../../shared/components/general/override.tsx';

interface OwnProps {
  [METRIC_ID_PATH_PARAM]: number;
}

type AllProps = OwnProps;

export const METRIC_PAGE_DATE_QUERY_PARAM = 'date';
export const METRIC_PAGE_GRANULARITY_QUERY_PARAM = 'granularity';

export const MetricPageComponent = (props: AllProps) => {
  const {metricId} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {can} = usePermissions();
  const {openSecondaryPanel} = useContext(PanelsContext);

  const [rcaDateFromQuery, setRCADateFromQuery] = useQueryParam<string>(
    METRIC_PAGE_DATE_QUERY_PARAM,
    StringParam
  );
  const [granularityFromQuery, setGranularityFromQuery] = useQueryParam<string>(
    METRIC_PAGE_GRANULARITY_QUERY_PARAM,
    StringParam
  );
  const {
    source: metric,
    exec: execGetMetric,
    isLoading: isLoadingMetric,
  } = useRemoteSourceStated({
    networkRequest: getMetricPageNetworkRequest,
    onSuccess: metric => {
      metric.rcaDate &&
        setRCADateFromQuery(
          moment
            .utc(metric.rcaDate, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT)
            .format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
          'replaceIn'
        );
      metric.granularity && setGranularityFromQuery(metric.granularity, 'replaceIn');
    },
  });
  const {
    source: analysisResult,
    exec: getRCA,
    isLoading: isLoadingResult,
  } = useRemoteSourceStated({
    networkRequest: getAnalysisResultNetworkRequest,
  });

  const granularity = granularityFromQuery || metric?.granularity;
  const rcaDate = rcaDateFromQuery || metric?.rcaDate;
  const getMetric = useCallback(() => {
    if (
      metric &&
      metric.id == metricId &&
      metric.granularity === granularity &&
      metric.rcaDate == rcaDate
    ) {
      return;
    }
    execGetMetric(metricId, {
      granularity,
      refDate: rcaDate,
    });
  }, [metricId, granularity, rcaDate, metric, execGetMetric]);
  const onRequestAnalysis = useCallback(
    (analysisTypeId: number, parameters: any) => {
      openSecondaryPanel(PanelKey.ANALYSIS_FORM_PANEL, {
        [ANALYSIS_TYPE_ID_PATH_PARAM]: analysisTypeId,
        parameters,
      });
    },
    [openSecondaryPanel]
  );
  const onOwnerChange = useCallback(
    (userId: number) =>
      dispatch(
        setMetricOwner({
          metricId: metric.id,
          userId,
        })
      ),
    [dispatch, metric]
  );
  const onSampleClicked = useCallback(
    sample =>
      setRCADateFromQuery(
        moment
          .utc(sample.datetime, TIME_FORMATS.DEFAULT_INPUT_DATE_FORMAT)
          .format(TIME_FORMATS.PARAMETER_DATE_FORMAT),
        'replaceIn'
      ),
    [setRCADateFromQuery]
  );
  const documentComponentsMap = useMemo(
    () => ({
      [DocumentElementType.RCA_FIGURE]: props => (
        <RCAFigureViewer
          {...props}
          renderSideMenu={false}
          renderHeaderName={false}
          renderKPITree={true}
          showMetricSuggestion
        />
      ),
      [DocumentElementType.RCA_OVERVIEW_FIGURE]: props => (
        <AppRCAOverviewViewer {...props} onSampleClicked={onSampleClicked} />
      ),
      [DocumentElementType.DOCUMENT]: props => (
        <RootDocumentViewer {...props} className={classes.Document} />
      ),
      [DocumentElementType._METRIC_SUGGESTIONS_FIGURE]: props => (
        <MetricSuggestionViewer
          {...props}
          metric={metric}
          onRequestAnalysis={onRequestAnalysis}
          granularity={granularity}
        />
      ),
    }),
    [onSampleClicked, granularity, onRequestAnalysis, metric]
  );
  const hasEditPermission = can(Subject.METRIC, Action.EDIT);
  const onEdit = useCallback(
    () =>
      hasEditPermission &&
      openSecondaryPanel(PanelKey.METRIC_FORM_PANEL, {
        [METRIC_ID_PATH_PARAM]: metric?.id,
      }),
    [openSecondaryPanel, hasEditPermission, metric?.id]
  );
  const showMetric = useCallback(() => {
    metric &&
      openSecondaryPanel(PanelKey.VIEW_METRIC_PANEL, {
        [METRIC_ID_PATH_PARAM]: metric.id,
      });
  }, [metric, openSecondaryPanel]);
  const granularityOptions = useMemo(
    () => (metric?.series || []).map(s => s.granularity),
    [metric]
  );

  useEffect(() => {
    getMetric();
  }, [getMetric]);
  useEffect(() => {
    if (metric?.rcaAnalysisResultId) {
      getRCA(metric.rcaAnalysisResultId);
    }
  }, [metric, getRCA]);
  useEffect(() => {
    setGranularityFromQuery(granularity, 'replaceIn');
  }, [granularity, setGranularityFromQuery]);
  useEffect(() => {
    setRCADateFromQuery(rcaDate, 'replaceIn');
  }, [rcaDate, setRCADateFromQuery]);
  useEffect(() => {
    const listener = action => {
      const {modelKey, data} = action.payload;
      if (modelKey === ModelKey.METRIC && data.id === metricId) {
        getMetric();
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, metricId, getMetric]);

  if (!metric) {
    return <GenericLoading />;
  }

  return (
    <PageLayout.Layout>
      <PageHeader
        title={metric.name}
        crumbs={[
          {
            name: 'KPIs',
            navigateTo: AppRoutes.homepage(),
          },
        ]}
        actions={[
          metric && metric.series.length > 0 && (
            <RCADatePicker
              selected={rcaDate}
              granularityOptions={granularityOptions}
              onChangeDate={date => setRCADateFromQuery(date, 'replaceIn')}
              onChangeGranularity={granularity => setGranularityFromQuery(granularity, 'replaceIn')}
              maxDate={metric.maxSampleDate}
              granularity={granularity as any}
              isLoading={isLoadingResult}
            />
          ),
          <ModelActionsDropdown
            label={t(TransKeys.GENERAL.LABELS.MORE_DOTS)}
            icon={MoreIcon}
            iconDropdown
            iconSize={'large'}
            actions={[
              {
                key: 'edit',
                icon: EditIcon,
                title: t(TransKeys.GENERAL.ACTIONS.EDIT),
                onClick: onEdit,
              },
            ]}
          />,
        ]}
      />
      <PageLayout.Body noPadding isLoading={false}>
        <div className={classes.MetricPage}>
          {metric.series.length > 0 && (
            <div className={classes.Main}>
              {(isLoadingMetric || isLoadingResult) && <GenericLoading />}
              <div className={classes.ContentSection}>
                <MetricPageHeader
                  onViewDefinition={showMetric}
                  metric={metric}
                  onOwnerChange={onOwnerChange}
                />
              </div>
              <div className={classNames(classes.ContentSection, classes.RCAContent)}>
                {analysisResult && !isLoadingMetric && !isLoadingResult && (
                  <AnalysisResults
                    key={analysisResult.id}
                    analysisResult={analysisResult}
                    componentsMap={documentComponentsMap}
                    scrollable={false}
                    navigationDisabled
                  />
                )}
              </div>
            </div>
          )}
          {metric.series.length === 0 && (
            <div className={classes.EmptyState}>
              <EmptyState title={'This KPI is being updated, please try again later'} />
            </div>
          )}
        </div>
      </PageLayout.Body>
    </PageLayout.Layout>
  );
};

export const MetricPage = props => {
  const {[METRIC_ID_PATH_PARAM]: metricIdFromParams} = useParams<any>();

  return (
    <MetricPageComponent
      {...props}
      key={metricIdFromParams}
      metricId={Number(metricIdFromParams)}
    />
  );
};
