import * as React from 'react';
import {useMemo} from 'react';
import {
  FunnelSegmentationFigureOptions,
  FunnelSegmentationItemType,
  KPISegmentationType,
} from '../../../../../../types';
import {ExtendedFunnelSegmentationItem} from '../../funnel-segmentation-viewer.component';
import {exists} from 'front-core';
import {KPISegmentationDataModeTable} from '../../../../kpi-analysis/kpi-segmentation-viewer/components/kpi-segmentation-tables/kpi-segmentation-data-mode-table.component';
import TransKeys from 'translations';

interface OwnProps {
  figureId: string;
  selectedStepIndex: number;
  options: FunnelSegmentationFigureOptions;
  items: ExtendedFunnelSegmentationItem[];
  totalEntities: number;
  onSeeAll: (segmentGroupName: string) => void;
  className?: string;
}

type AllProps = OwnProps;

export const FunnelSegmentationDataModeTable: React.FC<AllProps> = (props: AllProps) => {
  const {items, options, totalEntities, selectedStepIndex, figureId, onSeeAll, className} = props;

  const tableData: any[] = useMemo(
    () =>
      items.map(i => {
        const isInformative = exists(i.isInformative) ? i.isInformative : true;
        const isSignificant = exists(selectedStepIndex)
          ? i.type[selectedStepIndex] !== FunnelSegmentationItemType.INSIGNIFICANT_POSITIVE
          : i.funnelCompletionType !== FunnelSegmentationItemType.INSIGNIFICANT_POSITIVE;
        return {
          ...i,
          isInformative: isInformative,
          count: i.countInSegment[0],
          groupInsignificant: isSignificant === false || isInformative === false,
          uplift: exists(selectedStepIndex)
            ? i.uplift[selectedStepIndex]
            : i.funnelCompletionUplift,
          goalInSegment: exists(selectedStepIndex)
            ? i.segmentConversions[selectedStepIndex]
            : i.segmentFunnelCompletionConversion,
          goalInNonSegment: exists(selectedStepIndex)
            ? i.nonSegmentConversions[selectedStepIndex]
            : i.nonSegmentFunnelCompletionConversion,
          type: isSignificant ? KPISegmentationType.POSITIVE : KPISegmentationType.INSIGNIFICANT,
          helperText: exists(selectedStepIndex)
            ? i.helperText[selectedStepIndex]
            : i.helperTextFunnelCompletion,
        };
      }),
    [items, selectedStepIndex]
  );

  return (
    <KPISegmentationDataModeTable
      className={className}
      figureId={figureId}
      options={{
        ...options,
        higherIsBetter: true,
        isPercentageValue: true,
      }}
      items={tableData}
      totalEntities={totalEntities}
      onSeeAll={onSeeAll}
      transKeysPrefix={TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.TABLE.HEADERS}
    />
  );
};
