import {DocumentElementType, DocumentCommand, DocumentElement} from './types';

export type RemoteElementResolver = (
  figureId: string,
  type: string,
  parameters: any
) => Promise<DocumentElement<any, any, any>>;

export interface ChildRendererInjectedProps {
  emitEvent: (event: DocumentCommand<any>) => void;
  componentMode: ViewerComponentMode;
  darkMode: boolean;
  changeParameters: (params: any) => void;
  index: number;
}

export type BaseDocumentComponentsMap = {
  [key in DocumentElementType]?: any;
};

export interface DocumentComponentsMap extends BaseDocumentComponentsMap {
  [other: string]: any;
}

export interface ViewerComponentsParameters {
  isHidden?: boolean;
  isPreview?: boolean;
  notes?: string;
  override?: any;
}

export interface DocumentViewerParams {
  [documentElementId: string]: ViewerComponentsParameters;
}

export enum DocumentViewerMode {
  VIEW_MODE = 'VIEW_MODE',
  OPPORTUNITY_MODE = 'OPPORTUNITY_MODE',
  DEBUG = 'DEBUG',
}

export enum ViewerComponentMode {
  DEFAULT_MODE = 'DEFAULT_MODE',
  HIGHLIGHT_MODE = 'HIGHLIGHT_MODE',
}
