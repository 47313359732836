import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {GetModelSampleSeriesDTO, ResampleModelDTO} from '../objects/dto/model-sample-series.dto';

export const getModelSampleSeriesNetworkRequest: NRC<void, GetModelSampleSeriesDTO> = (
  payload: GetModelSampleSeriesDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/model-sample-series`,
  query: payload,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const resampleModelNetworkRequest: NRC<ResampleModelDTO, void> = (
  payload: ResampleModelDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/model-sample-series/resample`,
  body: payload,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});
