import * as React from 'react';
import classNames from 'classnames';
import classes from './input-button.module.scss';
import {Tooltip} from '@material-ui/core';

interface OwnProps {
  text?: string;
  icon: any;
  className?: string;
  onClick: (e) => void;
}

type AllProps = OwnProps;

export const InputButton: React.FC<AllProps> = (props: AllProps) => {
  const {icon: Icon, text, className, onClick} = props;

  return (
    <Tooltip title={text || ''} placement={'top'}>
      <div className={classNames(classes.InputIconButton, className)}>
        <Icon onClick={onClick} className={classes.Icon} />
      </div>
    </Tooltip>
  );
};
