import * as React from 'react';
import classNames from 'classnames';
import classes from './comment.module.scss';
import {useState} from 'react';
import {CircleInfoRegularIcon} from '../../../../../../simple/controls/icons/icons.component';

interface OwnProps {
  className?: string;
  comment: string;
}

type AllProps = OwnProps;

export const ViewerComment: React.FC<AllProps> = (props: AllProps) => {
  const {className} = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classes.Comment}>
      <div className={classNames(classes.CommentModal, isOpen && classes.Open, className)}>
        <div className={classes.IconWrapper}>
          <CircleInfoRegularIcon className={classes.Icon} />
        </div>
        <div onClick={e => setIsOpen(!isOpen)} className={classes.CommentPreview}>
          {props.comment}
        </div>
      </div>
    </div>
  );
};
