import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import classes from './filters.module.scss';
import {FilterWrapper} from './filter-wrapper.component';
import {Checkbox} from '../../../../../../../forms/inputs/checkbox/checkbox.component';
import {FilterComponentProps} from './filter-component.types';
import classNames from 'classnames';
import {CloseIcon} from '../../../../../../../simple/controls/icons/icons.component';
import {TableFilterType} from '../../smart-table.types';
import {getColumnOptions} from '../../smart-table.utils';
import {TooltipIfOverflow} from '../../../../../../../simple/generic/tooltips/tooltips.component';
import {withStopPropagation} from 'front-core';

interface OwnProps extends FilterComponentProps {
  dataKey: string;
  placeholderText: string;
  className?: string;
}

type AllProps = OwnProps;

const MAX_OPTIONS_COUNT_TO_SEARCH = 7;

export const ListFilter: React.FC<AllProps> = (props: AllProps) => {
  const {
    label,
    onChange: onChangeFromProps,
    onClear,
    filter,
    filterKey,
    filteredData,
    dataKey,
    placeholderText,
    className,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const value = filter?.value;
  const onChange = useCallback(
    value => {
      onChangeFromProps(filterKey, {
        type: TableFilterType.LIST,
        value,
        properties: {dataKey},
      });
    },
    [onChangeFromProps, filterKey, dataKey]
  );
  const filterValue = useMemo(() => new Set(value || []), [value]);
  const allOptions = useMemo(
    () => getColumnOptions(filteredData, dataKey, placeholderText),
    [filteredData, dataKey]
  );
  const onOptionClicked = useCallback(
    option => {
      if (filterValue.has(option.value)) {
        filterValue.delete(option.value);
      } else {
        filterValue.add(option.value);
      }
      if (filterValue.size === 0) {
        return onClear(dataKey);
      }
      onChange(Array.from(filterValue));
    },
    [filterValue, onChange, onClear]
  );
  const onClearSearch = useCallback(() => setSearchValue(''), []);
  const options = useMemo(() => {
    if (searchValue) {
      return allOptions.filter(o => o.label.toLowerCase().includes(searchValue.toLowerCase()));
    }
    return allOptions;
  }, [allOptions, searchValue]);
  const showSearch = useMemo(
    () => options.length > MAX_OPTIONS_COUNT_TO_SEARCH || searchValue,
    [options.length, searchValue]
  );

  return (
    <FilterWrapper
      clearable={filterValue.size > 0}
      onClear={() => onClear(dataKey)}
      label={`${label} (${options.length})`}
      className={classNames(classes.StringListFilter, className)}
    >
      {showSearch && (
        <div className={classes.Search}>
          <input
            placeholder={'Search...'}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            className={classes.SearchInput}
          />
          {searchValue && (
            <div onClick={onClearSearch} className={classes.ClearSearchButton}>
              <CloseIcon className={classes.Icon} />
            </div>
          )}
        </div>
      )}
      <div className={classes.List}>
        {options.map((o, idx) => (
          <div onClick={() => onOptionClicked(o)} key={idx} className={classes.Option}>
            <div className={classes.CheckboxWrapper}>
              <Checkbox
                checked={filterValue.has(o.value)}
                className={classes.Checkbox}
                onChange={() => onOptionClicked(o)}
                multi
              />
            </div>
            <TooltipIfOverflow title={o.label}>
              <div className={classes.OptionLabel}>{o.label}</div>
            </TooltipIfOverflow>
          </div>
        ))}
        {searchValue && options.length === 0 && (
          <div className={classes.NoResults}>
            <div className={classes.Text}>No results</div>
          </div>
        )}
      </div>
    </FilterWrapper>
  );
};
