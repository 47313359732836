import * as React from 'react';
import classes from './rca-header-viewer.module.scss';
import {RCAHeaderFigure} from '../../../../../types';
import {useContext, useMemo, useRef} from 'react';
import {exists, number2k} from 'front-core';
import classNames from 'classnames';
import moment from 'moment';
import {TrendChip} from '../../../../../../../simple/data-display/trend-chip/trend-chip.component';
import {DEFAULT_INPUT_DATE_FORMAT} from '../../../../../../../../consts/ui';
import {Tooltip} from '@material-ui/core';
import {DocumentCommandEmitterContext} from '../../../../../contexts/document-command-emitter.context';
import {HoverHelperTip} from '../../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';

export interface OwnProps extends RCAHeaderFigure {}

export const RCAHeaderViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {data, options} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {value, expectedValue: expectedValueFromData, expectedUpper, expectedLower} = data;
  const {t} = useDocumentTranslation();

  const titleRef = useRef<HTMLDivElement>(null);
  const groupRef = useRef<HTMLDivElement>(null);
  const expectedValue = useMemo(() => {
    if (exists(expectedValueFromData)) {
      return expectedValueFromData;
    }
    return (expectedUpper + expectedLower) / 2;
  }, [expectedValueFromData, expectedUpper, expectedLower]);
  const trendValue = useMemo(
    () => (expectedValue !== 0 ? (value - expectedValue) / expectedValue : 0),
    [value, expectedValue]
  );
  const isSignificant = useMemo(
    () => value < expectedLower || value > expectedUpper,
    [value, expectedLower, expectedUpper]
  );
  const valueString = useMemo(
    () => `${number2k(data.value)}${options.unit || ''}`,
    [data.value, options.unit]
  );
  const vsValueString = useMemo(
    () => `${number2k(expectedValue)}${options.unit || ''}`,
    [expectedValue, options.unit]
  );
  const formattedDates = useMemo(() => {
    let res = `${moment(data.startDate, DEFAULT_INPUT_DATE_FORMAT).format('DD MMM YY')}`;
    if (data.endDate) {
      res += ` - ${moment(data.endDate, DEFAULT_INPUT_DATE_FORMAT).format('DD MMM YY')}`;
    }
    return res;
  }, [data.startDate, data.endDate]);
  const positiveTrend = trendValue > 0;
  const noticeParams = useMemo(() => {
    let range_text = 'within';
    if (value < expectedLower) {
      range_text = 'below';
    }
    if (value > expectedUpper) {
      range_text = 'above';
    }
    return {
      kpi_name: data.title,
      range_text: range_text,
      trend_text: positiveTrend ? 'increase' : 'decline', // decline/increase,
      statistically_text: (isSignificant ? '' : 'not ') + 'statistically ',
    };
  }, [t, data.title, isSignificant, value, expectedLower, expectedUpper]);

  const refDateCommand = data.refDateCommand;
  const titleCommand = data.titleCommand;
  const groupCommand = data.groupCommand;
  const showTitleTooltip = titleRef.current?.offsetWidth < titleRef.current?.scrollWidth;
  const showGroupTooltip = groupRef.current?.offsetWidth < groupRef.current?.scrollWidth;

  return (
    <div className={classes.RCAHeaderViewer}>
      <div className={classes.TitleWrapper}>
        <Tooltip title={showTitleTooltip ? data.title : ''} placement={'top'}>
          <div
            ref={titleRef}
            onClick={titleCommand ? () => emitEvent(titleCommand) : undefined}
            className={classNames(classes.Title, titleCommand && classes.Clickable)}
          >
            {data.title}
          </div>
        </Tooltip>
        {data.groupName && (
          <>
            <div className={classes.In}>in</div>
            <Tooltip title={showGroupTooltip ? data.groupName : ''} placement={'top'}>
              <div
                ref={groupRef}
                onClick={groupCommand ? () => emitEvent(groupCommand) : undefined}
                className={classNames(classes.Group, titleCommand && classes.Clickable)}
              >
                {data.groupName}
              </div>
            </Tooltip>
          </>
        )}
      </div>
      <div className={classes.Infos}>
        <div className={classes.Info}>
          <span className={classes.Label}>value</span>
          <span className={classNames(classes.Value, classes.Bold)}>{valueString}</span>
          <span className={classes.Label}>vs.</span>
          <span className={classes.Value}>{vsValueString}</span>
        </div>
        <div className={classes.Info}>
          <span
            onClick={refDateCommand ? () => emitEvent(refDateCommand) : undefined}
            className={classNames(classes.Value, refDateCommand && classes.Clickable)}
          >
            {data.refDateName}
          </span>
          <span className={classNames(classes.Value, classes.Bold)}>{formattedDates}</span>
        </div>
      </div>
      <div className={classes.Trend}>
        <span className={classes.Text}>{positiveTrend ? 'Increased' : 'Declined'} by</span>
        <TrendChip
          value={trendValue * 100}
          higherIsBetter={options.higherIsBetter}
          isSignificant={isSignificant}
        />
        <span className={classes.Text}>VS. expected</span>
      </div>
      <div className={classes.Notice}>
        {t(TransKeys.DOCUMENT_VIEWER.RCA_HEADER.NOTICE_TEXT, noticeParams)}
        <HoverHelperTip
          className={classes.Helper}
          title={t(TransKeys.DOCUMENT_VIEWER.RCA_HEADER.NOTICE_HELPER_TEXT, noticeParams)}
          small
        />
      </div>
    </div>
  );
};
