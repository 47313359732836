import * as React from 'react';
import classNames from 'classnames';
import {
  ExtendedRetentionSegmentationItem,
  RetentionSegmentGroup,
} from '../../retention-segmentation-viewer.component';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {useDocumentTracking} from '../../../../../../hooks/use-document-tracking.hook';
import {
  AllPopulationRetention,
  ChartType,
  CommandType,
  DocumentElementType,
  KPISegmentationUpliftMode,
  RetentionSegmentationItemType,
} from '../../../../../../types';
import {useCallback, useMemo, useState} from 'react';
import {keyBy} from 'lodash';
import TransKeys from 'translations';
import {capitalize, exists} from 'front-core';
import {ModelType} from '../../../../../../../../../consts/model-type';
import classes from './retention-segmentation-modal.module.scss';
import pluralize from 'pluralize';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {NextPrevNavigator} from '../../../../../shared/general/next-prev-navigator/next-prev-navigator.component';
import {MatrixTable} from '../../../../../shared/general/matrix-table/matrix-table.component';
import {KPISegmentGroupTable} from '../../../../kpi-analysis/kpi-segmentation-viewer/components/kpi-segment-group-table/kpi-segment-group-table.component';
import {
  SegmentationModalHeader,
  SegmentationModalMode,
} from '../../../../../shared/segmentation/segmentation-modal-header/segmentation-modal-header.component';
import {CompareSegmentsButton} from '../../../../../shared/segmentation/compare-segments-button/compare-segments-button.component';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import {Checkbox} from '../../../../../../../../forms/inputs/checkbox/checkbox.component';

interface OwnProps {
  figureId: string;
  group: RetentionSegmentGroup;
  goalValue: number;
  allPopulation: AllPopulationRetention;
  buckets: string[];
  selectedBucketIndex: number;
  groupOptions: string[];
  onSelectGroup: (group: string) => void;
  entity: string;
  totalEntities: number;
  className?: string;
}

type AllProps = OwnProps;

function getHelperText(isInformative: boolean, isSignificant: boolean, helperText: string) {
  if (isInformative === false) {
    return {
      nameHelperText: helperText,
    };
  }
  if (!isSignificant) {
    return {
      upliftHelperText: helperText,
    };
  }
  return {};
}

export const RetentionSegmentationModal: React.FC<AllProps> = (props: AllProps) => {
  const {
    figureId,
    group,
    buckets,
    goalValue,
    selectedBucketIndex: selectedBucketIndexFromProps,
    groupOptions: groupOptionsFromProps,
    onSelectGroup,
    allPopulation,
    entity,
    totalEntities,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const {trackNavigation} = useDocumentTracking(
    figureId,
    DocumentElementType.RETENTION_SEGMENTATION_FIGURE
  );
  const [upliftMode, setUpliftMode] = useState<KPISegmentationUpliftMode>(
    KPISegmentationUpliftMode.VS_OTHERS
  );
  const [bucket0, setBucket0] = useState(true);
  const [mode, setMode] = useState(SegmentationModalMode.DEFAULT);
  const [selectedBucketIndex, setSelectedBucketIndex] = useState(selectedBucketIndexFromProps);
  const [reviewedSegment, setReviewedSegment] = useState<ExtendedRetentionSegmentationItem>(null);
  const [comparedSegmentKeys, setComparedSegmentKeys] = useState<string[]>([]);
  const segmentsByKey = useMemo(() => keyBy(group.segments, 'key'), [group.segments]);
  const groupOptions = useMemo(
    () =>
      groupOptionsFromProps.map(g => ({
        value: g,
        label: g,
      })),
    [groupOptionsFromProps]
  );
  const segmentOptions = useMemo(() => {
    return group.segments.map(s => ({
      value: s.key,
      label: s.segmentName[0],
    }));
  }, [reviewedSegment]);
  const bucketOptions = useMemo(
    () =>
      buckets.map((label, index) => ({
        label: capitalize(label),
        value: index,
      })),
    [buckets]
  );
  const includeToggleText = useMemo(() => capitalize(buckets[0]), [buckets]);
  const navigatorProps = useMemo(() => {
    if (groupOptions.length === 0) {
      return;
    }
    const currentIndex = groupOptions.findIndex(i => i.value === group?.name);
    const maxIndex = groupOptions.length - 1;
    return {
      currentIndex,
      maxIndex,
      onNextPrev: (isNext: boolean) =>
        onSelectGroup(groupOptions[currentIndex + (isNext ? 1 : -1)].value),
      nextLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.NEXT_LABEL),
      prevLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.PREV_LABEL),
    };
  }, [groupOptions, group.name, onSelectGroup, t]);
  const tableData = useMemo(
    () =>
      group.segments.map(s => {
        const isSignificant =
          s.type[selectedBucketIndex] !== RetentionSegmentationItemType.INSIGNIFICANT;
        const helperText = s.helperText[selectedBucketIndex];
        const isInformative = exists(s.isInformative) ? s.isInformative : true;
        return {
          key: s.key,
          name: s.segmentName[0],
          signalId: s.signalId[0],
          count: s.count,
          uplift: exists(s.uplift[selectedBucketIndex])
            ? s.uplift[selectedBucketIndex] * 100
            : null,
          avgUplift: exists(s.avgUplift[selectedBucketIndex])
            ? s.avgUplift[selectedBucketIndex] * 100
            : null,
          goalInSegment: exists(s.goalInSegment[selectedBucketIndex])
            ? s.goalInSegment[selectedBucketIndex] * 100
            : null,
          goalInNonSegment: exists(s.goalInNonSegment[selectedBucketIndex])
            ? s.goalInNonSegment[selectedBucketIndex] * 100
            : null,
          goalValue: goalValue * 100,
          share: s.share * 100,
          isSignificant: isSignificant,
          isInformative: isInformative,
          command: {
            type: CommandType.REF,
            payload: {
              type: ModelType.SIGNAL,
              refId: s.signalId[0],
            },
          },
          ...getHelperText(isInformative, isSignificant, helperText),
        };
      }),
    [group.segments, selectedBucketIndex, goalValue]
  );
  const onDeepDive = useCallback(
    (segmentKey: string) => {
      setReviewedSegment(segmentsByKey[segmentKey]);
      setMode(SegmentationModalMode.DEEP_DIVE);
      trackNavigation('segment_deep_dive');
    },
    [segmentsByKey, setReviewedSegment, setMode]
  );
  const onCompareClicked = useCallback(() => {
    setMode(SegmentationModalMode.COMPARE);
    trackNavigation('compare_segments');
  }, [setMode]);
  const onBack = useCallback(() => {
    setMode(SegmentationModalMode.DEFAULT);
    setReviewedSegment(null);
  }, [setMode, setReviewedSegment]);

  const chartFigure = useMemo(() => {
    const labels = buckets.map(b => b.toUpperCase());
    if (bucket0 === false) {
      labels.shift();
    }
    let segments = [];
    // DEEP DIVE MODE
    if (mode === SegmentationModalMode.DEEP_DIVE) {
      segments.push(reviewedSegment);
    }
    // COMPARE MODE
    if (mode === SegmentationModalMode.COMPARE) {
      segments = comparedSegmentKeys.map(k => segmentsByKey[k]);
    }
    const datasets = segments.map(s => ({
      id: s.key,
      label: s.segmentName[0],
      data: s.goalInSegment.map((y, idx) => ({x: idx, y: y * 100})),
    }));
    datasets.unshift({
      id: 'all',
      label: `All ${pluralize(entity)}`,
      data: allPopulation.buckets.map((y, idx) => ({
        x: idx,
        y: y.value * 100,
      })),
    });
    if (bucket0 === false) {
      for (const d of datasets) {
        d.data.shift();
      }
    }
    const chart = {
      id: 'main-chart',
      type: DocumentElementType.CHART,
      chartType: ChartType.LINE,
      labels,
      data: datasets,
      options: {
        yLabelSuffix: '%',
        xLabel: t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.CHART.X_LABEL),
        yLabel: t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.CHART.Y_LABEL),
        maxHeight: 240,
        yAxisMaxTicks: 5,
      },
    };
    return {
      id: 'retention-chart-wrapper',
      type: DocumentElementType.CONTENT_WRAPPER,
      title: t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.CHART.TITLE),
      children: [chart],
    };
  }, [mode, buckets, reviewedSegment, comparedSegmentKeys, segmentsByKey, allPopulation, bucket0]);
  const stableRetentionTable = useMemo(() => {
    const labels = [...buckets];
    if (bucket0 === false) {
      labels.shift();
    }
    let segments = [];
    // DEEP DIVE MODE
    if (mode === SegmentationModalMode.DEEP_DIVE) {
      segments.push(reviewedSegment);
    }
    // COMPARE MODE
    if (mode === SegmentationModalMode.COMPARE) {
      segments = comparedSegmentKeys.map(k => segmentsByKey[k]);
    }
    const datasets = segments.map(s => ({
      key: s.key,
      name: s.segmentName[0],
      highlight: false,
      values: s.goalInSegment.map(y => (exists(y) ? y * 100 : y)),
      absValues: s.goalInSegment.map((y, idx) => Math.round(y * s.bucketCount[idx])),
      mainValue: s.stableValue * 100,
    }));
    datasets.unshift({
      key: 'all',
      name: `All ${pluralize(entity)}`,
      highlight: true,
      values: allPopulation.buckets.map(x => (exists(x.value) ? x.value * 100 : x.value)),
      absValues: allPopulation.buckets.map(x => x.count),
      mainValue: allPopulation.stableValue * 100,
    });
    if (bucket0 === false) {
      for (const d of datasets) {
        d.values.shift();
      }
    }
    return {
      title: t(
        TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.RETENTION_COHORTS_BY_BUCKETS
      ),
      datasets,
      labels,
      allowSwitchAbs: true,
    };
  }, [
    mode,
    buckets,
    reviewedSegment,
    comparedSegmentKeys,
    segmentsByKey,
    allPopulation,
    bucket0,
    t,
  ]);

  const renderListContent = () => {
    return (
      <>
        <KPISegmentGroupTable
          tableData={tableData}
          entity={entity}
          totalEntities={totalEntities}
          transKeysPrefix={TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE}
          onClick={onDeepDive}
          selectedKeys={comparedSegmentKeys}
          onChangedSelectedKeys={setComparedSegmentKeys}
          multiSelection={true}
          upliftMode={upliftMode}
          onChangeUpliftMode={setUpliftMode}
          buttonText={t(
            TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.ACTIONS.RETENTION_BY_SEGMENT
          )}
        />
        <div className={classes.CompareLine}>
          <CompareSegmentsButton
            onClick={() => onCompareClicked()}
            count={comparedSegmentKeys.length}
          />
        </div>
        <div className={classes.Spacer} />
        {navigatorProps && (
          <div className={classes.Navigation}>
            <NextPrevNavigator {...navigatorProps} />
          </div>
        )}
      </>
    );
  };

  const renderDeepDive = () => {
    if (!chartFigure) {
      return null;
    }
    return (
      <>
        <div className={classes.Filters}>
          <Checkbox
            checked={bucket0}
            onChange={setBucket0}
            label={t(TransKeys.DOCUMENT_VIEWER.RETENTION_OVERVIEW_FIGURE.FILTERS.INCLUDE_BUCKET_0, {
              text: includeToggleText,
            })}
            multi={true}
            border
          />
        </div>
        <div className={classes.ChartWrapper}>
          <ChildRenderer children_={chartFigure} key={'line-chart'} />
        </div>
        <MatrixTable {...stableRetentionTable} />
      </>
    );
  };

  return (
    <ModalWrapper isOpen={group} onClose={() => onSelectGroup(null)} width={'70vw'} height={'80vh'}>
      <div className={classNames(classes.RetentionSegmentationModal, className)}>
        <SegmentationModalHeader
          title={t(TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.MODAL.TITLE.PREFIX)}
          mode={mode as any}
          onBack={onBack}
          compareSegmentsSuffix={t(
            TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.MODAL.TITLE
              .COMPARE_SEGMENTS_PREFIX
          )}
          selectedGroup={group?.name}
          groupOptions={groupOptions}
          onSelectGroup={onSelectGroup}
          selectedStep={selectedBucketIndex}
          stepsOptions={bucketOptions}
          onSelectStep={setSelectedBucketIndex as any}
          stepsPrefix={t(
            TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.MODAL.TITLE.BUCKETS_PREFIX
          )}
          selectedSegment={reviewedSegment?.key}
          segmentOptions={segmentOptions}
          onSelectSegment={onDeepDive}
          segmentHelperText={reviewedSegment?.helperText?.[selectedBucketIndex]}
        />
        <div className={classes.Content}>
          {mode === SegmentationModalMode.DEFAULT && renderListContent()}
          {(mode === SegmentationModalMode.DEEP_DIVE || mode === SegmentationModalMode.COMPARE) &&
            renderDeepDive()}
        </div>
      </div>
    </ModalWrapper>
  );
};
