import * as React from 'react';
import {RCASegmentationFigureOptions} from '../../../../../../types';
import classes from './segment-explainers-modal.module.scss';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {useMemo, useState} from 'react';
import TransKeys from 'translations';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';
import {SegmentationModalHeader} from '../../../../../shared/segmentation/segmentation-modal-header/segmentation-modal-header.component';
import {NextPrevNavigator} from '../../../../../shared/general/next-prev-navigator/next-prev-navigator.component';
import {Checkbox} from '../../../../../../../../forms/inputs/checkbox/checkbox.component';
import {useTopExplainersSmartTable} from '../../hooks/use-top-explainers-smart-table.hook';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {RCASegmentationViewMode} from '../../../rca-figure/rca-figure-viewer.types';
import {UnifiedRCASegmentationItem} from '../../rca-segmentation-extended.types';

interface OwnProps {
  segments: UnifiedRCASegmentationItem[];
  selectedGroupSignalId: number;
  populationTrend: number;
  viewMode: RCASegmentationViewMode;
  groupOptions: {label: string; value: number}[];
  onSelectGroup: (signalId: number) => void;
  options: RCASegmentationFigureOptions;
}

type AllProps = OwnProps;

export const SegmentExplainersModal: React.FC<AllProps> = (props: AllProps) => {
  const {
    segments,
    selectedGroupSignalId,
    populationTrend,
    viewMode,
    groupOptions,
    onSelectGroup,
    options,
  } = props;
  const {t} = useDocumentTranslation();
  const [showInsignificant, setShowInsignificant] = useState(true);

  const navigatorProps = useMemo(() => {
    if (groupOptions.length === 0) {
      return;
    }
    const currentIndex = groupOptions.findIndex(i => i.value === selectedGroupSignalId);
    const maxIndex = groupOptions.length - 1;
    return {
      currentIndex,
      maxIndex,
      onNextPrev: (isNext: boolean) =>
        onSelectGroup(groupOptions[currentIndex + (isNext ? 1 : -1)].value),
      nextLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.NEXT_LABEL),
      prevLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.PREV_LABEL),
    };
  }, [groupOptions, selectedGroupSignalId, onSelectGroup, t]);
  const allInSignificant = useMemo(
    () => segments.map(s => s.isSignificant === false).every(Boolean),
    [segments]
  );
  const tableFigure = useTopExplainersSmartTable({
    groupName: false,
    options,
    segments,
    populationTrend,
    showInsignificant,
    viewMode,
    key: 'top-segment-explainers-modal',
  });

  return (
    <ModalWrapper
      isOpen={selectedGroupSignalId !== null}
      onClose={() => onSelectGroup(null)}
      width={'100rem'}
      height={'60rem'}
    >
      <div className={classes.SegmentExplainersModal}>
        <SegmentationModalHeader
          title={t(TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.TOP_EXPLAINERS.MODEL_TITLE)}
          selectedGroup={selectedGroupSignalId}
          groupOptions={groupOptions}
          onSelectGroup={onSelectGroup}
        />
        {!allInSignificant && (
          <div className={classes.Filters}>
            <Checkbox
              label={t(TransKeys.DOCUMENT_VIEWER.GENERAL.SHOW_INSIGNIFICANT_SEGMENTS)}
              checked={showInsignificant}
              onChange={setShowInsignificant}
              border
            />
          </div>
        )}
        <div className={classes.Table}>
          <ChildRenderer children_={tableFigure} />
        </div>
        <div className={classes.Spacer} />
        {navigatorProps && (
          <div className={classes.Navigation}>
            <NextPrevNavigator {...navigatorProps} />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};
