import classNames from 'classnames';
import classes from './journey-drivers-scope-events-filters.module.scss';
import {useCallback, useMemo} from 'react';
import {Select} from '../../../../../../../../../forms/inputs/select/select.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../../../hooks/use-document-translation.hook';
import Slider from '@material-ui/core/Slider';
import {withStyles} from '@material-ui/core/styles';
import {number2k} from 'front-core';
import {
  FILTER_EVENTS_MODE_OPTIONS,
  FilterEventsMode,
  IEventsFilters,
} from '../../journey-drivers-scope.consts';
import Tooltip from '@material-ui/core/Tooltip';

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.JOURNEY_DRIVERS_SCOPE_FIGURE;

interface OwnProps {
  filters: IEventsFilters;
  onChange: (filters: IEventsFilters) => void;
  eventOptions: {value: string; label: string}[];
  adoptionRangeMin: number;
  adoptionRangeMax: number;
}

type AllProps = OwnProps;

const SliderTooltip: any = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'transparent !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    maxWidth: 'unset !important',
    border: '0 !important',
    padding: '0 !important',
    borderRadius: 'unset !important',
    fontSize: 'unset !important',
    boxShadow: 'unset !important',
    margin: '4px',
  },
}))(Tooltip);

const DesignedSlider = withStyles({
  root: {
    color: '#3483FF',
    // fix show marks on hover
    '&::after': {
      content: "''",
      width: '100%',
      height: '1.6rem',
      position: 'absolute',
      bottom: '-1.6rem',
    },
  },
  thumb: {
    height: 14,
    width: 14,
    minHeight: 14,
    minWidth: 14,
    backgroundColor: '#3483FF',
    border: '0',
    marginTop: `-5px !important`,
    marginLeft: `-7px !important`,
    zIndex: 2,
    '&:focus, &:hover, &$active': {
      boxShadow: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '&$focusVisible': {
      boxShadow: 'none',
    },
  },
  focusVisible: {
    boxShadow: 'none',
  },
  active: {},
  valueLabel: {
    left: 'calc(-50%)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
    backgroundColor: '#D7DAE9',
  },
  marked: {
    marginBottom: '16px',
  },
  mark: {
    display: 'none',
  },
  markActive: {
    display: 'none',
  },
  markLabel: {
    fontSize: '12px',
    transition: 'all 0.25s',
    color: '#757C96',
    fontFamily: 'Manrope',
  },
})(Slider);

const popperProps = {disablePortal: true};

function ValueLabelComponent(props) {
  const {children, open, value} = props;

  return (
    <SliderTooltip
      open={open}
      placement="top"
      title={<div className={classes.Tooltip}>{value}</div>}
      PopperProps={popperProps}
      interactive={false}
    >
      {children}
    </SliderTooltip>
  );
}

export const JourneyDriversScopeEventsFilters: React.FC<AllProps> = (props: AllProps) => {
  const {filters, onChange, eventOptions, adoptionRangeMin, adoptionRangeMax} = props;
  const {t} = useDocumentTranslation();

  const onFilteredEventsModeChange = useCallback(
    (mode: FilterEventsMode) => {
      onChange({...filters, eventsFilterMode: mode});
    },
    [filters, onChange]
  );

  const onFilteredEventsChange = useCallback(
    (events: string[]) => {
      onChange({...filters, filteredEvents: events});
    },
    [filters, onChange]
  );

  const onAdoptionRangeChange = useCallback(
    (_, newValue) => {
      const adoptionRange = newValue as [number, number];
      onChange({...filters, adoptionRange});
    },
    [filters, onChange]
  );

  const ADOPTION_RANGE_MARKS = [
    {value: adoptionRangeMin, label: `${number2k(adoptionRangeMin)}%`},
    {value: adoptionRangeMax, label: `${number2k(adoptionRangeMax)}%`},
  ];

  const adoptionsRangeFormatted = useMemo(() => {
    const min = filters.adoptionRange[0];
    const max = filters.adoptionRange[1];
    return `${number2k(min)}% - ${number2k(max)}%`;
  }, [filters.adoptionRange]);

  return (
    <div className={classes.JourneyDriversScopeEventsFilters}>
      <div className={classes.Input}>
        <span className={classes.Header}>{t(TRANS_KEYS_PREFIX.FILTERS.INPUTS.EVENTS.TITLE)}</span>
        <div className={classNames(classes.Controller, classes.EventsFilters)}>
          <Select
            options={{options: FILTER_EVENTS_MODE_OPTIONS}}
            value={filters.eventsFilterMode}
            onChange={v => onFilteredEventsModeChange(v as FilterEventsMode)}
            searchable={false}
            clearable={false}
          />
          <Select
            className={classes.EventsSelect}
            options={{options: eventOptions}}
            placeholder={t(
              filters.eventsFilterMode === FilterEventsMode.INCLUDED
                ? TRANS_KEYS_PREFIX.FILTERS.INPUTS.INCLUDED_EVENTS.PLACEHOLDER
                : TRANS_KEYS_PREFIX.FILTERS.INPUTS.EXCLUDED_EVENTS.PLACEHOLDER
            )}
            value={filters.filteredEvents as any}
            onChange={v => onFilteredEventsChange(v as any)}
            searchable
            clearable
            fullWidth
            multi
          />
        </div>
      </div>
      <div className={classes.Input}>
        <span className={classes.Header}>{t(TRANS_KEYS_PREFIX.FILTERS.INPUTS.ADOPTION.TITLE)}</span>
        <div className={classNames(classes.Controller, classes.Outlined, classes.AdoptionFilter)}>
          <div className={classes.Label}>{adoptionsRangeFormatted}</div>
          <DesignedSlider
            value={filters.adoptionRange}
            onChange={onAdoptionRangeChange}
            ValueLabelComponent={ValueLabelComponent}
            valueLabelFormat={v => `${number2k(v)}%`}
            min={adoptionRangeMin}
            max={adoptionRangeMax}
            marks={ADOPTION_RANGE_MARKS}
          />
        </div>
      </div>
    </div>
  );
};
