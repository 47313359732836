import {RCAElementType, RCAMode} from '../../../../../types';
import {getExplainableSegments} from '../rca-segmentation-viewer.utils';
import TransKeys from 'translations';
import {useCallback, useContext, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';
import {WarningIcon} from '../../../../../../../simple/controls/icons/icons.component';
import {RCAFigureViewerContext} from '../../rca-figure/rca-figure-viewer.context';
import classes from '../rca-segmentation-viewer.module.scss';
import {RCASegmentationViewMode} from '../../rca-figure/rca-figure-viewer.types';
import {scrollRCAElementIntoView} from '../../rca-figure/rca-figure-viewer.utils';
import {UnifiedRCASegmentationItem} from '../rca-segmentation-extended.types';
import {exists} from 'front-core';
import {getHeaderId} from '../../rca-figure/rca-figure-viewer-elements.component';

enum SegmentationNoticeMessageType {
  YES_SIGNIFICANT_CHANGE_NO_EXPLAINERS_YES_HIGHLIGHTED = 'yes_significant_change_no_explainers_yes_highlighted',
  NO_SIGNIFICANT_CHANGE_NO_EXPLAINERS_YES_HIGHLIGHTED = 'no_significant_change_no_explainers_yes_highlighted',
  NO_SIGNIFICANT_CHANGE_NO_EXPLAINERS_NO_HIGHLIGHTED = 'no_significant_change_no_explainers_no_highlighted',
}

const getSegmentationNoticeMessageType = (
  segments: UnifiedRCASegmentationItem[],
  isSignificantChange: boolean
) => {
  const explainableSegments = getExplainableSegments(segments);
  const hasExplainers = explainableSegments[RCASegmentationViewMode.EXPLAIN].length > 0;
  const hasHighlighted = explainableSegments[RCASegmentationViewMode.CHANGE].length > 0;
  if (isSignificantChange && !hasExplainers && hasHighlighted) {
    return SegmentationNoticeMessageType.YES_SIGNIFICANT_CHANGE_NO_EXPLAINERS_YES_HIGHLIGHTED;
  } else if (!isSignificantChange && !hasExplainers && hasHighlighted) {
    return SegmentationNoticeMessageType.NO_SIGNIFICANT_CHANGE_NO_EXPLAINERS_YES_HIGHLIGHTED;
  } else if (!isSignificantChange && !hasExplainers && !hasHighlighted) {
    return SegmentationNoticeMessageType.NO_SIGNIFICANT_CHANGE_NO_EXPLAINERS_NO_HIGHLIGHTED;
  } else {
    return null;
  }
};

const TransKeysPrefix = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE;

export type UseRCASegmentationViewerNoticeProps = {
  segments: UnifiedRCASegmentationItem[];
};

export function useRCASegmentationViewerNotice(props: UseRCASegmentationViewerNoticeProps) {
  const {segments} = props;
  const {t} = useDocumentTranslation();
  const noExplainersRef = useRef(null);

  useLayoutEffect(() => {
    const el = document.getElementById(getHeaderId(RCAElementType.SEGMENTATION));
    if (el) {
      noExplainersRef.current = el;
    }
  }, []);

  const {renderMode, isExpectedTrendSignificantChange, isComparedSignificantChange, uplift} =
    useContext(RCAFigureViewerContext);

  const isSignificantChange = useMemo(() => {
    if (renderMode === RCAMode.LOOPS_ALGO) {
      return isExpectedTrendSignificantChange;
    } else if (renderMode === RCAMode.COMPARE_TO_DATE) {
      return isComparedSignificantChange;
    } else {
      return false;
    }
  }, [renderMode, isExpectedTrendSignificantChange, isComparedSignificantChange]);
  const initialSegmentationNoticeMessageType = useMemo(
    () => getSegmentationNoticeMessageType(segments, isSignificantChange),
    [segments, isSignificantChange]
  );
  const [segmentationNoticeMessageType, setSegmentationNoticeMessageType] =
    useState<SegmentationNoticeMessageType | null>(initialSegmentationNoticeMessageType);

  const noExplainersNoticeTxPath = useMemo(() => {
    if (
      segmentationNoticeMessageType ===
      SegmentationNoticeMessageType.YES_SIGNIFICANT_CHANGE_NO_EXPLAINERS_YES_HIGHLIGHTED
    ) {
      return TransKeysPrefix.YES_SIGNIFICANT_NO_EXPLAINERS_NOTICE.NEW_MESSAGE;
    } else if (
      segmentationNoticeMessageType ===
      SegmentationNoticeMessageType.NO_SIGNIFICANT_CHANGE_NO_EXPLAINERS_YES_HIGHLIGHTED
    ) {
      return TransKeysPrefix.NO_SIGNIFICANT_NO_EXPLAINERS_YES_HIGHLIGHTED_NOTICE.NEW_MESSAGE;
    } else {
      return null;
    }
  }, [segmentationNoticeMessageType]);

  const onConfirm = useCallback(
    () => setSegmentationNoticeMessageType(null),
    [setSegmentationNoticeMessageType]
  );

  return {
    noExplainersRef: noExplainersRef,
    noExplainersNotice: {
      anchorEl: noExplainersRef.current,
      show: exists(noExplainersRef.current) && exists(noExplainersNoticeTxPath),
      message: (
        <div>
          {t(noExplainersNoticeTxPath, {
            change: uplift > 0 ? 'spike' : 'drop',
          })}{' '}
          <span
            className={classes.NoticeLink}
            onClick={() => scrollRCAElementIntoView(RCAElementType._CHANGING_SEGMENTS)}
          >
            here
          </span>
        </div>
      ),
      confirmLabel: t(TransKeysPrefix.NO_EXPLAINERS_NOTICE.OK_BUTTON),
      icon: WarningIcon,
      onConfirm,
    },
  };
}
