import {getNextSort, Sorting} from 'front-core';
import {useEffect, useMemo, useState} from 'react';
import {RCASegmentationViewMode} from '../../../../rca-figure/rca-figure-viewer.types';

export const useSegmentGroupsSortingState = (viewMode: RCASegmentationViewMode) => {
  const defaultSort: Sorting = useMemo(() => {
    return {
      orderBy:
        viewMode === RCASegmentationViewMode.EXPLAIN ? 'explainabilityScore' : 'interestScore',
      order: 'desc',
    };
  }, [viewMode]);

  useEffect(() => {
    // Reset sorting to default when changing view modes
    setSortingState(defaultSort);
  }, [defaultSort]);

  const [sortingState, setSortingState] = useState<Sorting>(defaultSort);
  const onSort = (columnKey: string) => {
    const nextSorting = getNextSort(columnKey, sortingState);
    setSortingState(nextSorting);
  };
  return {onSort, sortingState, setSortingState};
};
