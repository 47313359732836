import * as React from 'react';
import classNames from 'classnames';
import {MetricSampleData, RCAMetric, RCAOverviewFigure} from '../../../../types';
import classes from './rca-overview-viewer.module.scss';
import {DocumentTitleWrapper} from '../../../base-viewers/title-section-viewer/title-section-viewer.component';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useDocumentTranslation} from '../../../../hooks/use-document-translation.hook';
import {
  ChartLineRegularIcon,
  DiagramSankeyRegularIcon,
  GraphIcon,
} from '../../../../../../simple/controls/icons/icons.component';
import {SwitchActions} from '../../../../../../simple/controls/switch-actions/switch-actions.component';
import {RCAOverviewMainView} from './components/rca-overview-main-view/rca-overview-main-view.component';
import TransKeys from 'translations';
import {RCAOverviewCompareView} from './components/rca-overview-compare-view/rca-overview-compare-view.component';
import {DocumentCommandEmitterContext} from '../../../../contexts/document-command-emitter.context';

interface OwnProps extends RCAOverviewFigure {
  className?: string;
  resolveRCAMetrics: (
    granularity: string,
    startDate: string,
    endDate: string
  ) => Promise<RCAMetric[]>;
  onSampleClicked?: (sample: MetricSampleData) => void;
}

type AllProps = OwnProps;

enum ViewMode {
  VIEW_CURRENT_METRIC,
  COMPARE_TO_OTHER,
}

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_OVERVIEW;

export const RCAOverviewViewer: React.FC<AllProps> = (props: AllProps) => {
  const {data, options, resolveRCAMetrics, onSampleClicked, className} = props;
  const {metric, analyzedDate, comparedDate} = data;
  const {t} = useDocumentTranslation();
  const {onSignalClick} = useContext(DocumentCommandEmitterContext);
  const [mode, setMode] = useState<ViewMode>(ViewMode.VIEW_CURRENT_METRIC);
  const [resolvedMetrics, setResolvedMetrics] = useState<RCAMetric[]>([]);

  const modeOptions = useMemo(() => {
    return [
      {
        label: t(TRANS_KEYS_PREFIX.CHANGE_VIEW.VIEW_CURRENT_METRIC),
        icon: ChartLineRegularIcon,
        onClick: () => setMode(ViewMode.VIEW_CURRENT_METRIC),
        isActive: mode === ViewMode.VIEW_CURRENT_METRIC,
      },
      {
        label: t(TRANS_KEYS_PREFIX.CHANGE_VIEW.COMPARE_TO_OTHER),
        icon: DiagramSankeyRegularIcon,
        onClick: () => setMode(ViewMode.COMPARE_TO_OTHER),
        isActive: mode === ViewMode.COMPARE_TO_OTHER,
        hidden: resolvedMetrics.length === 0,
      },
    ];
  }, [setMode, mode, resolvedMetrics, t]);
  const getRCAMetrics = useCallback(async () => {
    if (!resolveRCAMetrics) {
      return;
    }
    const metrics = await resolveRCAMetrics(metric.granularity, data.startDate, data.endDate);
    setResolvedMetrics(metrics);
  }, [resolveRCAMetrics, data.startDate, data.endDate, metric, setResolvedMetrics]);

  useEffect(() => {
    if (options.showCompare) {
      getRCAMetrics();
    }
  }, [getRCAMetrics, options.showCompare]);

  return (
    <DocumentTitleWrapper
      title={t(TRANS_KEYS_PREFIX.TITLE)}
      icon={GraphIcon}
      className={classNames(classes.RCAOverviewFigure, className)}
      renderRight={
        options.showCompare ? <SwitchActions actions={modeOptions} showActionsLabel /> : undefined
      }
    >
      {mode === ViewMode.VIEW_CURRENT_METRIC && (
        <RCAOverviewMainView
          metric={metric}
          analyzedDate={analyzedDate}
          comparedDate={comparedDate}
          mode={data.mode}
          onSampleClicked={onSampleClicked}
        />
      )}
      {resolvedMetrics.length > 0 && mode === ViewMode.COMPARE_TO_OTHER && (
        <RCAOverviewCompareView
          metric={metric}
          compareMetrics={resolvedMetrics}
          analyzedDate={analyzedDate}
          comparedDate={comparedDate}
          mode={data.mode}
          hasPopulationFilter={data.hasPopulationFilter}
          onSignalClicked={onSignalClick}
        />
      )}
    </DocumentTitleWrapper>
  );
};
