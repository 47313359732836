import * as React from 'react';
import {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {FilterComponentProps} from './filter-component.types';
import {DatePicker} from '../../../../../../../forms/inputs/date-picker/date-picker.component';
import classes from './filters.module.scss';
import {FilterWrapper} from './filter-wrapper.component';
import {exists} from 'front-core';
import {TableDateFilter, TableFilterType} from '../../smart-table.types';
import {max, min} from 'lodash';

interface OwnProps extends FilterComponentProps {
  onChange: (filterKey: string, filter: TableDateFilter) => void;
  dataKey: string;
  className?: string;
}

type AllProps = OwnProps;

export const DateFilter: React.FC<AllProps> = (props: AllProps) => {
  const {
    label,
    filter,
    filteredData,
    onClear,
    filterKey,
    dataKey,
    onChange: onChangeFromProps,
    className,
  } = props;
  const value = filter?.value;
  const filterData = useMemo(
    () => filteredData.map(item => item[dataKey]),
    [dataKey, filteredData]
  );
  const minValue = useMemo(() => min(filterData), [filterData]);
  const maxValue = useMemo(() => max(filterData), [filterData]);
  const onChange = useCallback(
    value => {
      onChangeFromProps(filterKey, {
        value,
        type: TableFilterType.DATE,
        properties: {dataKey},
      });
    },
    [filterKey, onChangeFromProps]
  );

  return (
    <FilterWrapper
      clearable={exists(value)}
      onClear={() => onClear(filterKey)}
      label={`${label}`}
      className={classNames(classes.DateFilter, className)}
    >
      <DatePicker
        // @ts-ignore
        selectsRange
        variant={'light'}
        selected={value?.[0]}
        startDate={value?.[0]}
        endDate={value?.[1]}
        minDate={minValue}
        maxDate={maxValue}
        onChange={onChange}
        inline
        utc
      />
    </FilterWrapper>
  );
};
