import * as React from 'react';
import {EnhancedCircle, EnhancedGroup, EnhancedText} from '../../../../core/konva/components';
import {ShapeProps} from '../../../../core/konva/hoc/enhanced-shape.hoc';
import {useContext, useMemo} from 'react';
import {StageContext} from '../../../../core/konva/stage-context.component';

interface OwnProps {
  label: string;
}

type AllProps = ShapeProps & OwnProps;

export const LayoutLabel: React.FC<AllProps> = (props: AllProps) => {
  const {label} = props;
  const context = useContext(StageContext);
  const {controller, style} = context;
  const textWidth = useMemo(() => {
    const textWidth = controller?.measureTextWidth(label, style.fontName, 12, 700);
    return textWidth + 10;
  }, [controller, label, style.fontName]);

  return (
    <EnhancedGroup x={props.x} y={props.y} width={props.width || textWidth}>
      {label ? (
        <EnhancedCircle x={props.width / 2 - textWidth / 2} y={10} radius={2} fill={'#03AC9A'} />
      ) : null}
      <EnhancedText
        x={props.width / 2}
        y={10}
        width={textWidth}
        height={10}
        fill={'#898EA8'}
        fontSize={12}
        fontFamily={style.fontName}
        align={'center'}
        text={label}
        center
      />
    </EnhancedGroup>
  );
};
