import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {
  RCAInsightsExecutiveSummeryDTO,
  SegmentPerformanceSummaryDTO,
  WeeklyInsightsDTO,
} from '../objects/dto/insights.dto';

export const getWeeklyInsightsNetworkRequest: NRC<void, WeeklyInsightsDTO> = (
  payload: WeeklyInsightsDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/insights/weekly`,
  query: payload,
  queryTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const getRCAInsightsExecutiveSummeryNetworkRequest: NRC<
  void,
  RCAInsightsExecutiveSummeryDTO
> = (payload: RCAInsightsExecutiveSummeryDTO) => ({
  method: RequestType.GET,
  relativeUrl: `/app/insights/rca-insights`,
  query: payload,
  queryTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const getSegmentPerformanceSummaryNetworkRequest: NRC<void, SegmentPerformanceSummaryDTO> = (
  payload: SegmentPerformanceSummaryDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `/app/insights/rca-segment-performance`,
  query: payload,
  queryTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});
