import * as React from 'react';
import {Slider, withStyles, SliderProps} from '@material-ui/core';

const SliderDesign = withStyles({
  root: {
    color: '#3483FF',
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: '#3483FF',
    border: '0',
    marginTop: -5,
    marginLeft: -7,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
    backgroundColor: '#D7DAE9',
  },
})(Slider);

export const RangeSliderInput: React.FC<SliderProps> = (props: SliderProps) => {
  return <SliderDesign {...props} />;
};
