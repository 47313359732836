import * as React from 'react';
import classNames from 'classnames';
import classes from './heatmap-viewer.module.scss';
import {HeatmapFigure} from '../../../types';
import {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {get, keyBy, max, min} from 'lodash';
import {getColorGradientAt} from '../../../../../../utils/colors';
import {DocumentIcon} from '../../internal-viewers/document-icon.component';
import {exists, number2k, safeDivision} from 'front-core';
import {TriangleIcon} from '../../../../../simple/controls/icons/icons.component';
import {Tooltip} from '@material-ui/core';
import {TooltipIfOverflow} from '../../../../../simple/generic/tooltips/tooltips.component';

interface OwnProps extends HeatmapFigure {
  className?: string;
  selectedKey?: string;
  onClick?: (key: string) => void;
}

type AllProps = OwnProps;

const END_COLOR = '#01C366';
const START_COLOR = '#B8F8D9';
const DEFAULT_ICON_FONT_SIZE = '1.6rem';
const getValueFormatter = (isPercentage: boolean) => {
  return (value: number) => {
    if (!isPercentage) {
      return number2k(value, value < 1 ? 3 : undefined);
    }
    return `${number2k(value * 100)}%`;
  };
};

export const HeatmapViewer: React.FC<AllProps> = (props: AllProps) => {
  const {data, options, selectedKey: selectedKeyFromProps, onClick, className} = props;
  const {values, xTicks, yTicks} = data;
  const mainRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const firstCellRef = useRef<HTMLDivElement>(null);
  const [selectedKey, setSelectedKey] = useState(selectedKeyFromProps);
  const colors = useMemo(
    () => (options.higherIsBetter ? [START_COLOR, END_COLOR] : [END_COLOR, START_COLOR]),
    [options.higherIsBetter]
  );
  const valueFormatter = useMemo(
    () => getValueFormatter(options.isPercentage),
    [options.isPercentage]
  );
  const onCellClicked = useCallback(
    (key: string) => {
      onClick && onClick(key);
      setSelectedKey(key);
    },
    [onClick, setSelectedKey]
  );
  const gridStyle: CSSProperties = useMemo(() => {
    const res: CSSProperties = {
      display: 'grid',
      gridTemplateColumns: `minmax(max-content, auto) repeat(${xTicks.length}, 1fr)`,
      gridTemplateRows: `minmax(2.8rem, auto) repeat(${yTicks.length}, 1fr)`,
    };
    return res;
  }, [xTicks, yTicks]);
  const {width: firstCellWidth} = firstCellRef.current
    ? firstCellRef.current.getBoundingClientRect()
    : {width: 0};
  const iconFontSize = useMemo(() => {
    if (firstCellRef.current === null) {
      return DEFAULT_ICON_FONT_SIZE;
    }
    const size = max([Math.round(firstCellWidth * 0.2), 16]);
    return `${size}px`;
  }, [firstCellWidth]);
  const valuesByKey = useMemo(() => keyBy(values, 'key'), [values]);
  const actualRange = useMemo(() => {
    const allValues = values.map(i => i.value);
    const minValue = min(allValues);
    const maxValue = max(allValues);
    return {
      min: minValue,
      max: maxValue,
      range: Math.abs(maxValue - minValue),
    };
  }, [values]);
  const totalRange = useMemo(() => {
    const totalMin = min([get(options, 'rangeMin', actualRange.min), actualRange.min]);
    const totalMax = max([get(options, 'rangeMax', actualRange.max), actualRange.max]);
    return {
      min: totalMin,
      max: totalMax,
      range: Math.abs(totalMax - totalMin),
    };
  }, [actualRange, options]);
  const totalRangeText = useMemo(
    () => ({
      start: valueFormatter(totalRange.min),
      end: valueFormatter(totalRange.max),
    }),
    [totalRange, valueFormatter]
  );
  const cells = useMemo(() => {
    const res = [];
    const valuesMap = values.reduce((acc, curr) => {
      acc[`${curr.x}_${curr.y}`] = curr;
      return acc;
    }, {});
    for (const [yIdx, y] of yTicks.entries()) {
      for (const [xIdx, x] of xTicks.entries()) {
        const v: any = get(valuesMap, `${x}_${y}`);
        let bg;
        if (v && actualRange.range > 0) {
          bg = getColorGradientAt(
            colors[0],
            colors[1],
            ((v.value - actualRange.min) / actualRange.range) * 100
          );
        } else if (actualRange.range === 0) {
          bg = colors[1];
        }
        res.push({
          key: v?.key,
          selected: exists(selectedKey) && v?.key === selectedKey,
          icon: v?.icon,
          valueText: v ? valueFormatter(v.value) : undefined,
          bg,
          xIdx,
          yIdx,
        });
      }
    }
    return res;
  }, [values, yTicks, xTicks, actualRange, selectedKey, valueFormatter, colors]);
  const gradientStyle: CSSProperties = useMemo(() => {
    let width = `${safeDivision(actualRange.range, totalRange.range) * 100}%`;
    let left = `${safeDivision(actualRange.min - totalRange.min, totalRange.range) * 100}%`;
    let backgroundImage = `linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 100%)`;
    let backgroundColor = undefined;

    if (actualRange.range === 0) {
      width = '100%';
      backgroundImage = undefined;
      backgroundColor = colors[1];
    }

    return {
      width,
      left,
      backgroundImage,
      backgroundColor,
    };
  }, [actualRange, totalRange, colors]);
  const maxTopHeight = useMemo(() => {
    if (!topRef.current || !firstCellRef.current) {
      return;
    }
    const ch = Array.from(document.querySelectorAll('.__x_label'));
    const maxHeight = max(ch.map(c => c.getBoundingClientRect().width));
    if (maxHeight > firstCellRef.current?.getBoundingClientRect().width) {
      return maxHeight;
    }
  }, [topRef.current, firstCellRef.current]);

  useEffect(() => {
    if (mainRef.current && selectedKey) {
      const v = valuesByKey[selectedKey];
      if (!v) {
        return;
      }
      const elem = mainRef.current.querySelector(`#${v.key}`);
      if (elem === null) {
        return;
      }
      // @ts-ignore
      elem.scrollIntoViewIfNeeded?.();
    }
  }, [selectedKey, valuesByKey]);
  useEffect(() => {
    selectedKey !== selectedKeyFromProps && setSelectedKey(selectedKeyFromProps);
  }, [selectedKey, selectedKeyFromProps]);

  return (
    <div className={classNames(classes.HeatmapViewer, className)}>
      <div className={classes.YLabelWrapper}>
        <div className={classes.YLabel}>
          <div className={classes.Label}>{options.yLabel || 'Y'}</div>
        </div>
        <div className={classes.XLabelWrapper}>
          <div className={classes.XLabel}>{options.xLabel || 'X'}</div>
          <div className={classes.ScrollableView}>
            <div className={classes.Main} style={gridStyle} ref={mainRef}>
              <div className={classes.Top} ref={topRef} style={{height: maxTopHeight}} />
              {xTicks.map((k, idx) => (
                <div
                  style={{gridColumn: idx + 2, gridRow: 1}}
                  className={classNames(
                    classes.TickLabel,
                    classes.XTickLabel,
                    maxTopHeight && classes.Rotate
                  )}
                  key={`x_${k}`}
                >
                  <TooltipIfOverflow title={k}>
                    <div className={classNames(classes.Label, '__x_label')}>{k}</div>
                  </TooltipIfOverflow>
                </div>
              ))}
              {yTicks.map((k, idx) => (
                <div
                  style={{gridColumn: 1, gridRow: idx + 2}}
                  className={classNames(classes.TickLabel, classes.YTickLabel)}
                  key={`y_${k}`}
                >
                  <TooltipIfOverflow title={k}>
                    <div className={classes.Label}>
                      {!isNaN(+k) ? Number(k).toLocaleString() : k}
                    </div>
                  </TooltipIfOverflow>
                </div>
              ))}
              {cells.map((v, idx) => (
                <Tooltip
                  title={
                    v?.valueText ? (
                      <div className={classes.Tooltip}>
                        <span className={classes.Label}>{options.rangeLabel || 'Value'}</span>
                        <span className={classes.Value}>{v.valueText}</span>
                        <span className={classes.Label}>{options.xLabel || 'X'}</span>
                        <span className={classes.Value}>{xTicks[v.xIdx]}</span>
                        <span className={classes.Label}>{options.yLabel || 'Y'}</span>
                        <span className={classes.Value}>{yTicks[v.yIdx]}</span>
                      </div>
                    ) : (
                      ''
                    )
                  }
                  placement={'top'}
                  key={v.key || idx}
                  interactive={false}
                >
                  <div
                    id={v.key}
                    ref={idx === 0 ? firstCellRef : undefined}
                    onClick={v.key ? () => onCellClicked(v.key) : undefined}
                    style={{
                      gridColumn: v.xIdx + 2,
                      gridRow: v.yIdx + 2,
                      backgroundColor: v.bg,
                    }}
                    className={classNames(
                      classes.Value,
                      v.selected && classes.Selected,
                      v.key && classes.Clickable
                    )}
                  >
                    {v.icon && (
                      <DocumentIcon
                        icon={v.icon}
                        className={classes.Icon}
                        style={{fontSize: iconFontSize}}
                      />
                    )}
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.RangeDisplay}>
        <div className={classes.Label}>{options.rangeLabel || 'Range'}</div>
        <div className={classes.Range}>
          <div className={classes.Line} />
          <div className={classNames(classes.Gradient)} style={gradientStyle}>
            {selectedKey && valuesByKey[selectedKey] && actualRange.range > 0 && (
              <div
                className={classes.Indication}
                style={{
                  left: `${
                    safeDivision(
                      valuesByKey[selectedKey]?.value - actualRange.min,
                      actualRange.range
                    ) * 100
                  }%`,
                }}
              >
                <div className={classes.Value}>
                  {valueFormatter(valuesByKey[selectedKey]?.value)}
                </div>
                <TriangleIcon className={classes.Icon} />
              </div>
            )}
          </div>
          <div className={classes.Labels}>
            <div className={classes.Tick} style={{left: '0%'}}>
              {totalRangeText.start}
            </div>
            <div className={classNames(classes.Tick, classes.EndTick)} style={{left: '100%'}}>
              {totalRangeText.end}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
