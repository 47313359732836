import {useContext, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './segment-explainers-table.module.scss';
import {RCASegmentationFigureOptions} from '../../../../../../types';
import {groupBy, keyBy, some, values} from 'lodash';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import TransKeys from 'translations';
import {exists, sortData} from 'front-core';
import {
  SegmentFilters,
  SegmentFiltersValue,
  useFilterSegmentItems,
  useTeamAutoSetterForSegments,
} from '../../../../../shared/segmentation/segment-filters/segment-filters.component';
import {useSegmentGroupsSortingState} from './hooks/use-segment-groups-sorting-state.hook';
import {
  RCASegmentationUsersMode,
  RCASegmentationViewMode,
} from '../../../rca-figure/rca-figure-viewer.types';
import {GlobalDocumentDataContext} from '../../../../../../contexts/global-document-data/global-document-data.context';
import {EXTRACT_SEGMENT_DATA, PRIMARY_SORT} from './segment-explainers-table.consts';
import {fixSegmentsForExplainers} from './segment-explainers-table.utils';
import {UnifiedRCASegmentationItem} from '../../rca-segmentation-extended.types';
import {ExplainersTable} from './components/explainers-table/explainers-table.component';
import {
  ExplainersTableItem,
  ExplainerTableItem,
} from './components/explainers-table/components/explainers-table-item/explainers-table-item.component';
import VirtualScroll from 'react-dynamic-virtual-scroll';

interface OwnProps {
  segments: UnifiedRCASegmentationItem[];
  composites: UnifiedRCASegmentationItem[];
  populationTrend: number;
  viewMode: RCASegmentationViewMode;
  isValidForExplainers: boolean;
  showOvertimeChart: boolean;
  options: RCASegmentationFigureOptions;
  onViewGroupSegments: (signalId: number) => void;
  onViewGroupChart: (signalId: number, segmentKey: string) => void;
  onViewCompositeBreakdown: (compositeKey: string) => void;
  onViewCompositeChart: (compositeKey: string) => void;
  onSignalClicked: (signalId: number) => void;
  className?: string;
}

type AllProps = OwnProps;

const TRANS_KEYS_PREFIX = TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE;

const DEFAULT_FILTERS = {};

const fixItems = (items: UnifiedRCASegmentationItem[], viewMode: RCASegmentationViewMode) =>
  fixSegmentsForExplainers(items, viewMode)
    .sort((a, b) => Number(b.isPrimaryInGroup) - Number(a.isPrimaryInGroup))
    .filter(s => {
      if (viewMode === RCASegmentationViewMode.EXPLAIN) {
        return true;
      }
      return s.interestTypes.length > 0 && s.isInterestSignificant;
    });

export const SegmentExplainersTable: React.FC<AllProps> = (props: AllProps) => {
  const {
    segments: segmentsFromProps,
    composites: compositesFromProps,
    isValidForExplainers,
    populationTrend,
    options,
    showOvertimeChart,
    onSignalClicked,
    onViewGroupSegments,
    onViewGroupChart,
    onViewCompositeBreakdown,
    onViewCompositeChart,
    viewMode,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const {teamId} = useContext(GlobalDocumentDataContext);
  const [filters, setFilters] = useState<SegmentFiltersValue>(DEFAULT_FILTERS);
  const {sortingState, onSort} = useSegmentGroupsSortingState(viewMode);
  const [usersMode, setUsersMode] = useState<RCASegmentationUsersMode>(
    RCASegmentationUsersMode.SHARE
  );
  useTeamAutoSetterForSegments(segmentsFromProps, EXTRACT_SEGMENT_DATA);

  const segments = useMemo(
    () => fixItems(segmentsFromProps, viewMode),
    [segmentsFromProps, viewMode]
  );
  const composites = useMemo(
    () => fixItems(compositesFromProps, viewMode),
    [compositesFromProps, viewMode]
  );
  const filteredSegments: UnifiedRCASegmentationItem[] = useFilterSegmentItems(
    segments,
    teamId,
    filters,
    EXTRACT_SEGMENT_DATA
  );
  // mapping
  const segmentsByKeys = useMemo(() => keyBy(segments, 'key'), [segments]);
  const filteredComposites: UnifiedRCASegmentationItem[] = useMemo(() => {
    const filteredSegmentsKeys = new Set(filteredSegments.map(s => s.key));
    const res = [];
    for (const composite of composites) {
      const segments = composite.segmentKeys.map(key => segmentsByKeys[key]).filter(s => s);
      if (segments.length !== composite.segmentKeys.length) {
        continue;
      }
      const isRelevant = segments.some(s => filteredSegmentsKeys.has(s.key));
      if (!isRelevant) {
        continue;
      }
      res.push({
        ...composite,
        segments,
      });
    }
    return res;
  }, [segmentsByKeys, filteredSegments, composites]);
  const similarItems = useMemo(
    () => groupBy([...filteredSegments, ...filteredComposites], 'groupKey'),
    [filteredSegments, filteredComposites]
  );
  const tableData: ExplainerTableItem[] = useMemo(() => {
    const res: ExplainerTableItem[] = [];
    for (const similarGroup of values(similarItems)) {
      const selected = similarGroup.find(s => s.isPrimaryInGroup) || similarGroup[0];
      res.push({
        ...selected,
        primary: selected,
        group: sortData(similarGroup, PRIMARY_SORT, sortingState) as UnifiedRCASegmentationItem[],
      });
    }
    return sortData(res, PRIMARY_SORT, sortingState) as ExplainerTableItem[];
  }, [similarItems, sortingState]);
  const hasFilters = useMemo(
    () => some(values(filters).map(exists)) || exists(teamId),
    [filters, teamId]
  );
  const emptyStateTransKeys = useMemo(() => {
    if (hasFilters) {
      return TRANS_KEYS_PREFIX.TOP_EXPLAINERS.EMPTY_STATE_HAS_FILTERS;
    }
    if (viewMode === RCASegmentationViewMode.CHANGE) {
      return TRANS_KEYS_PREFIX.TOP_CHANGED.EMPTY_STATE;
    }
    if (isValidForExplainers) {
      return TRANS_KEYS_PREFIX.TOP_EXPLAINERS.EMPTY_STATE;
    }
    return TRANS_KEYS_PREFIX.TOP_EXPLAINERS.EMPTY_STATE_NOT_VALID_EXPLAINERS;
  }, [isValidForExplainers, hasFilters, viewMode]);

  return (
    <div className={classNames(classes.SegmentExplainersTable, className)}>
      <div className={classes.RootHelper}>
        {viewMode === RCASegmentationViewMode.EXPLAIN &&
          t(TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.TOP_EXPLAINERS.ROOT_HELPER_TEXT)}
        {viewMode === RCASegmentationViewMode.CHANGE &&
          t(TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.TOP_CHANGED.ROOT_HELPER_TEXT)}
      </div>
      <SegmentFilters
        className={classes.Filters}
        segments={segmentsFromProps}
        extract={EXTRACT_SEGMENT_DATA}
        filtersValue={filters}
        onFiltersChange={filters =>
          setFilters(preFilters => ({
            ...preFilters,
            ...filters,
          }))
        }
        entity={options.entity}
        shareOfUsersFilter={viewMode === RCASegmentationViewMode.EXPLAIN}
        groupFilter={viewMode === RCASegmentationViewMode.EXPLAIN}
      />
      <ExplainersTable
        onSort={onSort}
        sorting={sortingState}
        viewMode={viewMode}
        usersMode={usersMode}
        onChangeUsersMode={setUsersMode}
        options={options}
        variant={viewMode === RCASegmentationViewMode.CHANGE ? 'blue' : undefined}
      >
        <VirtualScroll
          className={classes.List}
          minItemHeight={54}
          totalLength={tableData.length}
          renderItem={index => (
            <ExplainersTableItem
              className={classes.Item}
              key={tableData[index].primary.key}
              item={tableData[index]}
              viewMode={viewMode}
              usersMode={usersMode}
              options={options}
              onSignalClicked={onSignalClicked}
              populationTrend={populationTrend}
              onViewGroupSegments={onViewGroupSegments}
              onViewGroupChart={onViewGroupChart}
              onViewCompositeBreakdown={onViewCompositeBreakdown}
              onViewCompositeChart={onViewCompositeChart}
              showOvertimeChart={showOvertimeChart}
              variant={viewMode === RCASegmentationViewMode.CHANGE ? 'blue' : undefined}
            />
          )}
        />
        {tableData.length === 0 && (
          <div className={classes.EmptyStateWrapper}>
            <div className={classes.EmptyState}>
              <div className={classes.Title}>{t(emptyStateTransKeys.TITLE)}</div>
              <div className={classes.SubTitle}>{t(emptyStateTransKeys.SUB_TITLE)}</div>
            </div>
          </div>
        )}
      </ExplainersTable>
    </div>
  );
};
