import {KPINodeData} from '../components/kpi-node/kpi-node.component.tsx';
import {useContext, useMemo} from 'react';
import {KPITreeContext} from '../kpi-tree.context.tsx';
import TransKeys from 'translations';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook.ts';
import {useTranslation} from 'react-i18next';
import {exists} from 'front-core';
import classes from '../components/kpi-node/kpi-node.module.scss';
import {useFeatureIsOn} from '@growthbook/growthbook-react';
import {FeatureFlag} from '../../../../../constants/feature-flags.ts';
import {TreeVariant} from '../kpi-tree.consts.ts';

export const useGetNodeNotifyMessage = (nodeData: KPINodeData) => {
  const {id, isTemporary, formula} = nodeData;
  const {metricTree, rcaMode, onDefineKPIFormula, variant} = useContext(KPITreeContext);
  const {teams} = useProductData();
  const {t} = useTranslation();
  const showFormulaMessage = useFeatureIsOn(FeatureFlag.METRIC_FORMULA as string);

  const kpiMissingInTreeRender = useMemo(() => {
    if (!isTemporary) {
      return null;
    }
    if (metricTree.rootMetricIdTeams && metricTree.rootMetricIdTeams.length > 0) {
      const teamNames = metricTree.rootMetricIdTeams
        .map(teamId => {
          if (teamId === null) {
            return '"All"';
          }
          return `"${teams.find(team => team.id === teamId)?.name}"`;
        })
        .join(', ');
      return t(TransKeys.KPI_TREE.KPI_NOT_IN_TREE_NOTICE_HAS_OTHER_TREES, {
        other_trees: teamNames,
        unit: metricTree.rootMetricIdTeams.length > 1 ? 'trees' : 'tree',
      });
    }
    return <span>{t(TransKeys.KPI_TREE.KPI_NOT_IN_TREE_NOTICE)}</span>;
  }, [isTemporary, metricTree.rootMetricIdTeams, t, teams]);

  const missingFormulaRender = useMemo(() => {
    if (
      exists(rcaMode) &&
      !exists(formula) &&
      metricTree.rootMetricId === id &&
      variant === TreeVariant.SIMPLE &&
      showFormulaMessage
    ) {
      return (
        <span className={classes.DefineFormulaNotice}>
          {t(TransKeys.KPI_TREE.KPI_MISSING_FORMULA_NOTICE)}{' '}
          <span className={classes.CTA} onClick={() => onDefineKPIFormula(id)}>
            {t(TransKeys.KPI_TREE.KPI_MISSING_FORMULA_NOTICE_CRA)}
          </span>
        </span>
      );
    }
  }, [
    rcaMode,
    formula,
    metricTree.rootMetricId,
    id,
    variant,
    showFormulaMessage,
    t,
    onDefineKPIFormula,
  ]);

  return kpiMissingInTreeRender || missingFormulaRender || null;
};
