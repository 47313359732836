import * as React from 'react';
import classNames from 'classnames';
import classes from './shared-columns-content.module.scss';
import {number2k} from 'front-core';
import {FlexibleTableClasses} from '../general/flexible-table/flexible-table.component';
import {TooltipIfOverflow} from '../../../../../simple/generic/tooltips/tooltips.component';

interface OwnProps {
  percentage: number; // [0-1]
  abs: number;
  unit?: string;
  icon?: any;
  className?: string;
}

type AllProps = OwnProps;

export const PercentageAbsDisplay: React.FC<AllProps> = (props: AllProps) => {
  const {percentage, abs, unit, icon: Icon, className} = props;

  return (
    <div className={classNames(classes.PercentageAbsDisplay, className)}>
      {Icon && <Icon className={classes.Icon} />}
      {number2k(percentage * 100)}%
      <TooltipIfOverflow title={`${number2k(abs)} ${unit}`}>
        <span className={classNames(FlexibleTableClasses.ShowOnHover, classes.Secondary)}>
          ({number2k(abs)} {unit})
        </span>
      </TooltipIfOverflow>
    </div>
  );
};
