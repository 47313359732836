import {RequestType, toSnakeCase} from 'front-core';
import {NRC} from './index';
import {toCamelCase} from 'front-core';
import {ResolveDocumentElementDTO} from '../objects/dto/document.dto';

export const getDocumentNetworkRequest: NRC<void, void> = (documentId: string) => ({
  method: RequestType.GET,
  relativeUrl: `/app/documents/${documentId}`,
  responseTransformer: res => toCamelCase(res.data, ['data']),
});

export const resolveDocumentElementNetworkRequest: NRC<ResolveDocumentElementDTO, void> = (
  data: ResolveDocumentElementDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `/app/documents/resolve`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data, ['data']),
});
