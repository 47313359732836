import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from '../smart-table.module.scss';
import {BarsFiltersLightIcon} from '../../../../../../simple/controls/icons/icons.component';
import {TableFilter} from '../smart-table.types';
import {FilterWrapper} from './filters/filter-wrapper.component';
import {SortIndicator} from '../../../../../../simple/data-display/sort-indicator/sort-indicator.component';
import {SmartTableFigureColumn} from '../../../../types';
import {ColumnStateAction, LocalFilter, SortOption} from '../logic/options';
import {Sorting} from 'front-core';
import {PopoverWrapper} from '../../../../../../simple/generic/popover-wrapper/popover-wrapper.component';
import {DialogLayout} from '../../../../../../simple/generic/dialog-layout/dialog-layout.component';

interface OwnProps {
  column: SmartTableFigureColumn<any>;
  sortByOptions: SortOption[];
  filterOptions: LocalFilter[];
  columnStateActions: ColumnStateAction[];
  hasFilters?: boolean;
  sorting: Sorting;
  onSort: (columnKey: string) => void;
  onFilter: (filterKey: string, filter: TableFilter<any, any>) => void;
  onClearFilter: (filterKey: string) => void;
  onColumnStateAction: (action: ColumnStateAction) => void;
  popoverLeft?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const TableColumnOptions: React.FC<AllProps> = (props: AllProps) => {
  const {
    sortByOptions,
    filterOptions,
    columnStateActions,
    column,
    onSort,
    onFilter,
    onClearFilter,
    onColumnStateAction,
    popoverLeft,
    sorting,
    hasFilters,
    className,
  } = props;
  const shouldRender = useMemo(
    () =>
      (column.options.sortable && sortByOptions.length > 1) ||
      (column.options.filterable && filterOptions.length > 0),
    [column.options.filterable, column.options.sortable, filterOptions.length, sortByOptions.length]
  );
  const renderFilter = (filter: LocalFilter) => {
    const FilterComponent = filter.component;
    return (
      <FilterComponent
        key={filter.props.filterKey}
        {...filter.props}
        onChange={onFilter}
        onClear={onClearFilter}
        className={classes.Filter}
      />
    );
  };

  if (!shouldRender) {
    return null;
  }

  const popoverDirection = popoverLeft ? 'left' : 'right';

  return (
    <PopoverWrapper
      popoverDirection={popoverDirection}
      buttonRenderer={props => (
        <div
          onClick={props.onClick}
          className={classNames(
            classes.TableColumnOptionsButton,
            props.isOpen && classes.Active,
            className
          )}
        >
          {hasFilters && <div className={classes.FiltersIndicator} />}
          <BarsFiltersLightIcon className={classes.Icon} />
        </div>
      )}
    >
      <DialogLayout className={classes.TableColumnOptionsPopover}>
        {columnStateActions.length > 0 && (
          <div className={classes.ColumnStateActions}>
            {columnStateActions.map((a, idx) => (
              <div
                key={idx}
                onClick={() => onColumnStateAction(a)}
                className={classes.ColumnStateAction}
              >
                <a.icon className={classes.ActionIcon} />
                {a.title}
              </div>
            ))}
          </div>
        )}
        {(sortByOptions.length > 1 || !column.title) && column.options.sortable && (
          <FilterWrapper className={classes.Filter} key={'sortBy'} label={`Sort by`}>
            <div className={classes.SortOptions}>
              {sortByOptions.map(so => (
                <div
                  key={so.value}
                  onClick={_ => onSort(so.value)}
                  className={classNames(
                    classes.Option,
                    sorting.orderBy === so.value && classes.Active
                  )}
                >
                  {so.label}
                  {so.value === sorting.orderBy && (
                    <SortIndicator className={classes.FilterSortIndicator} order={sorting.order} />
                  )}
                </div>
              ))}
            </div>
          </FilterWrapper>
        )}
        {filterOptions.length > 0 &&
          column.options.filterable &&
          filterOptions.map(fo => renderFilter(fo))}
      </DialogLayout>
    </PopoverWrapper>
  );
};
