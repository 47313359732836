import * as React from 'react';
import classNames from 'classnames';
import classes from './wizard-stepper.module.scss';
import {CheckSolidIcon} from '../icons/icons.component';

interface OwnProps {
  label: string;
  description?: any;
  stepNumber: number;
  isCompleted?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  onClick: (e) => void;
  className?: string;
}

type AllProps = OwnProps;

export const WizardStep: React.FC<AllProps> = (props: AllProps) => {
  const {label, description, stepNumber, onClick, isCompleted, isActive, isDisabled, className} =
    props;

  return (
    <div
      className={classNames(
        classes.WizardStep,
        isCompleted && classes.Completed,
        isDisabled && classes.Disabled,
        isActive && classes.Active,
        className
      )}
      onClick={isDisabled ? undefined : onClick}
    >
      <div className={classes.Prefix}>
        {isCompleted ? <CheckSolidIcon className={classes.Icon} /> : stepNumber}
      </div>
      <div className={classes.Title}>
        <div className={classes.Label}>{label}</div>
        {description && <div className={classes.Description}>{description}</div>}
      </div>
    </div>
  );
};
