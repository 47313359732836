import * as React from 'react';
import {RCA_INSIGHTS_SENTENCE_FORMATTERS_MAPPING} from '../../../../../../../../../../insights/rca/rca-insights.config';
import {RCASentence} from '../../../../../../../../../../insights/rca/rca-insights.model';

interface OwnProps {
  sentence: RCASentence;
  renderWrapper?: (children: React.ReactNode) => React.ReactNode;
  onViewOnPage?: (titleId: string) => void;
}

type AllProps = OwnProps;

export const RCAInsightSentenceRenderer: React.FC<AllProps> = (props: AllProps) => {
  const {sentence, renderWrapper, onViewOnPage} = props;

  const Formatter = RCA_INSIGHTS_SENTENCE_FORMATTERS_MAPPING[sentence.key];

  if (!Formatter) {
    return null;
  }

  const formattedSentence = (
    <Formatter
      key={sentence.key}
      sentenceParts={sentence.sentenceParts}
      formattedSentenceParts={sentence.formattedSentenceParts}
      explainabilityLabel={sentence.explainabilityLabel}
      onViewOnPage={onViewOnPage}
    />
  );

  return renderWrapper ? renderWrapper(formattedSentence) : formattedSentence;
};
