import classes from './rca-compared-header-viewer.module.scss';
import {DocumentCommand, RCAComparedHeaderFigure} from '../../../../../types';
import {useContext, useMemo} from 'react';
import {exists, number2k} from 'front-core';
import classNames from 'classnames';
import moment from 'moment';
import {TrendChip} from '../../../../../../../simple/data-display/trend-chip/trend-chip.component';
import {DEFAULT_INPUT_DATE_FORMAT} from '../../../../../../../../consts/ui';
import {TooltipIfOverflow} from '../../../../../../../simple/generic/tooltips/tooltips.component';
import {DocumentCommandEmitterContext} from '../../../../../contexts/document-command-emitter.context';
import TransKeys from 'translations';
import * as React from 'react';
import {useTranslation} from '../../../../../../../../core/translations/use-translation';

export interface OwnProps extends RCAComparedHeaderFigure {}

const formatDates = (startDate: string, endDate?: string) => {
  let res = `${moment(startDate, DEFAULT_INPUT_DATE_FORMAT).format('DD MMM YY')}`;
  if (endDate && endDate !== startDate) {
    res += ` - ${moment(endDate, DEFAULT_INPUT_DATE_FORMAT).format('DD MMM YY')}`;
  }
  return res;
};

const RCAPeriod = (props: {
  title: string;
  startDate: string;
  endDate?: string;
  refDateCommand: DocumentCommand<any>;
  refDateName: string;
  value: any;
  unit?: string;
  highlighted?: boolean;
}) => {
  const {title, startDate, endDate, refDateCommand, refDateName, value, unit, highlighted} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);

  const valueString = useMemo(() => `${number2k(value)}${unit || ''}`, [value, unit]);
  const formattedDates = useMemo(() => formatDates(startDate, endDate), [startDate, endDate]);

  return (
    <div className={classNames(classes.Period, highlighted && classes.Highlighted)}>
      <span className={classes.Title}>{title}</span>
      <div className={classes.Infos}>
        {refDateName && (
          <span
            onClick={refDateCommand ? () => emitEvent(refDateCommand) : undefined}
            className={classNames(classes.Info, refDateCommand && classes.Clickable)}
          >
            {refDateName}
          </span>
        )}
        <span className={classes.Info}>{formattedDates}</span>
      </div>
      <span className={classes.Value}>{valueString}</span>
    </div>
  );
};

export const RCAComparedHeaderViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {data, options} = props;
  const {emitEvent} = useContext(DocumentCommandEmitterContext);
  const {
    baselinePeriod,
    comparedPeriod,
    refDateCommand,
    titleCommand,
    groupCommand,
    title,
    groupName,
    refDateName,
  } = data;
  const {t} = useTranslation();

  const value = baselinePeriod.value;
  const vsValue = comparedPeriod.value;
  const trendValue = useMemo(() => (value !== 0 ? (vsValue - value) / value : 0), [value, vsValue]);
  const positiveTrend = useMemo(() => trendValue > 0, [trendValue]);
  const noticeParams = useMemo(() => {
    return {
      trend_text: positiveTrend ? 'increase' : 'decline', // decline/increase,
      statistically_text: (data.isSignificant ? '' : 'not ') + 'statistically ',
    };
  }, [t, data.title, data.isSignificant, value]);

  return (
    <div className={classes.RCAComparedHeaderViewer}>
      <div className={classes.TitleWrapper}>
        <TooltipIfOverflow title={title}>
          <div
            onClick={titleCommand ? () => emitEvent(titleCommand) : undefined}
            className={classNames(classes.Title, titleCommand && classes.Clickable)}
          >
            {title}
          </div>
        </TooltipIfOverflow>
        {groupName && (
          <>
            <div className={classes.In}>in</div>
            <TooltipIfOverflow title={groupName}>
              <div
                onClick={groupCommand ? () => emitEvent(groupCommand) : undefined}
                className={classNames(classes.Group, titleCommand && classes.Clickable)}
              >
                {groupName}
              </div>
            </TooltipIfOverflow>
          </>
        )}
      </div>
      <div className={classes.Periods}>
        <RCAPeriod
          title={'Baseline'}
          {...baselinePeriod}
          refDateCommand={refDateCommand}
          refDateName={refDateName}
          unit={options.unit}
        />
        <RCAPeriod
          title={'Compared'}
          {...comparedPeriod}
          refDateCommand={refDateCommand}
          refDateName={refDateName}
          unit={options.unit}
          highlighted
        />
      </div>
      <div className={classes.Trend}>
        <span className={classes.Text}>{positiveTrend ? 'Increased' : 'Declined'} by</span>
        <TrendChip
          value={trendValue * 100}
          higherIsBetter={options.higherIsBetter}
          className={!exists(data.isSignificant) ? classes.TrendChip : undefined}
          isSignificant={data.isSignificant}
        />
        <span className={classes.Text}>compared to baseline</span>
      </div>
      {exists(data.isSignificant) && (
        <div className={classes.Notice}>
          {t(TransKeys.DOCUMENT_VIEWER.RCA_COMPARED_HEADER.NOTICE_TEXT, noticeParams)}
        </div>
      )}
    </div>
  );
};
