import {CSSProperties, ReactNode, useMemo} from 'react';
import {ChildRenderer} from '../../core/child-renderer.component';
import {TitleSection} from '../../../types';
import classes from './title-section-viewer.module.scss';
import {ChildRendererInjectedProps} from '../../../document-viewer.types';
import classNames from 'classnames';
import {sanitize} from 'front-core';
import {HoverHelperTip} from '../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';

export interface OwnProps extends TitleSection, Partial<ChildRendererInjectedProps> {
  className?: string;
}

interface DocumentTitleWrapperProps {
  id?: string;
  headerId?: string;
  title: string;
  subtitle?: string;
  description?: string;
  icon?: any;
  helperText?: string;
  renderRight?: React.ReactNode;
  children: any;
  style?: CSSProperties;
  className?: string;
}

export const DocumentTitleWrapper: React.FC<DocumentTitleWrapperProps> = (
  props: DocumentTitleWrapperProps
) => {
  const {
    id,
    headerId,
    title,
    description,
    icon: Icon,
    helperText,
    children,
    renderRight,
    style,
    subtitle,
    className,
  } = props;

  const helperTextRender = useMemo(
    () =>
      helperText ? <div dangerouslySetInnerHTML={{__html: sanitize(helperText)}} /> : undefined,
    [helperText]
  );

  return (
    <div id={id} className={classNames(classes.TitleSection, className)} style={style}>
      {title && (
        <div id={headerId} className={classes.Header}>
          <div className={classes.TitleWrapper}>
            <div className={classes.Title}>
              {Icon && <Icon className={classes.Icon} />}
              <span className={classes.Text}>{title}</span>
              {subtitle && (
                <span className={classNames(classes.Text, classes.Subtitle)}>{subtitle}</span>
              )}
              {helperTextRender && <HoverHelperTip title={helperTextRender} />}
            </div>
            {renderRight && <div className={classes.Actions}>{renderRight}</div>}
          </div>
          {description && <div className={classes.Description}>{description}</div>}
        </div>
      )}
      <div className={classes.Content}>{children}</div>
    </div>
  );
};

export const TitleSectionViewer: React.FC<OwnProps> = (props: OwnProps) => {
  const {title, options, className} = props;
  const {helperText} = options;

  return (
    <DocumentTitleWrapper title={title} helperText={helperText} className={className}>
      <ChildRenderer children_={props.children} />
    </DocumentTitleWrapper>
  );
};

TitleSectionViewer.defaultProps = {
  options: {},
};
