import {BaseModel} from './base.model';
import {Team} from './team.model';

export enum WorkflowGranularity {
  DAILY = 'day',
  WEEKLY = 'week',
  MONTHLY = 'month',
}

export enum WorkflowType {
  KPI_REACH = 'kpi_reach',
  CUSTOM_SQL = 'custom_sql',
}

export enum WorkflowStatus {
  ACTIVE = 'active',
  STOPPED = 'stopped',
}

export enum WorkflowOutputType {
  EMAIL = 'email',
  SLACK = 'slack',
  DATABASE = 'database',
}

export interface EmailOutputParameters {
  emails: string[];
  user_ids: number[];
  send_when_empty: boolean;
}

export interface SlackOutputParameters {
  channels: string[];
  send_when_empty: boolean;
}

export interface DatabaseOutputParameters {
  source_id?: number;
  name: string;
  state: 'daily' | 'last';
}

export interface WebhookOutputParameters {
  webhook_url: string;
}

export interface WorkflowOutputDefinition {
  type: WorkflowOutputType;
  // according to the type, the parameters will be different
  parameters: EmailOutputParameters | SlackOutputParameters | DatabaseOutputParameters;
}

export interface Workflow extends BaseModel {
  teamId: number;
  ownerId: number;
  analysisId: number;
  lastResultId?: number;
  lastCompletedResultId?: number;
  name: string;
  entity: string;
  type: WorkflowType;
  status: WorkflowStatus;
  granularity: WorkflowGranularity;
  parameters: any;
  ownerUserName: string;
  lastRun: string;
  metric?: {
    id: number;
    name: string;
  };
  instances: Array<{
    analysisResultId: number;
    runParameters: {
      start_date: string;
      end_date: string;
    };
  }>;
  team: Team;
  // only on list resource
  outputDefinitions?: WorkflowOutputDefinition[];
  // only on detail resource
  emailOutput?: EmailOutputParameters;
  slackOutput?: SlackOutputParameters;
  databaseOutput?: DatabaseOutputParameters;
  webhookOutput?: WebhookOutputParameters;
}

export type WorkflowStatusCountsMap = Record<WorkflowStatus | 'all', number | undefined>;
export type WorkflowTypeCountsMap = Record<WorkflowType | 'all', number | undefined>;

export interface WorkflowCounts {
  status: WorkflowStatusCountsMap;
  type: WorkflowTypeCountsMap;
}
