import * as React from 'react';
import classNames from 'classnames';
import classes from './segment-chart-item-selector.module.scss';
import {Checkbox} from '../../../../../../../../../../forms/inputs/checkbox/checkbox.component';
import TransKeys from 'translations';
import {RCASegmentationViewMode} from '../../../../../rca-figure/rca-figure-viewer.types';
import {ExplainabilityLabelDisplay} from '../../../../../../../../../../simple/data-display/explainability-label-display/explainability-label-display.component';
import {take} from 'lodash';
import {MAX_INTEREST_CHIP_TO_SHOW} from '../../../../rca-segmentation-viewer.component';
import {InterestChipDisplay} from '../../../segment-explainers-table/components/explainers-table/components/interest-chip-display/interest-chip-display.component';
import {HoverHelperTip} from '../../../../../../../../../../simple/data-display/hover-helper-tip/hover-helper-tip.component';
import {useTranslation} from '../../../../../../../../../../../core/translations/use-translation';
import {RCASegmentationFigureOptions} from '../../../../../../../../types';
import {UnifiedRCASegmentationItem} from '../../../../rca-segmentation-extended.types';
import {TooltipIfOverflow} from '../../../../../../../../../../simple/generic/tooltips/tooltips.component';

interface OwnProps {
  segments: UnifiedRCASegmentationItem[];
  showAllPopulation: boolean;
  setShowAllPopulation: (v: boolean) => void;
  options: RCASegmentationFigureOptions;
  onSegmentClicked: (segmentKey: string) => void;
  selectedSegmentKeySet: Set<string>;
  viewMode: RCASegmentationViewMode;
  className?: string;
}

type AllProps = OwnProps;

export const SegmentChartItemSelector: React.FC<AllProps> = (props: AllProps) => {
  const {
    segments,
    showAllPopulation,
    setShowAllPopulation,
    onSegmentClicked,
    viewMode,
    selectedSegmentKeySet,
    options,
    className,
  } = props;
  const {t} = useTranslation();
  return (
    <div className={classNames(classes.SegmentChartItemSelector, className)}>
      <div
        key={'all_population'}
        onClick={() => setShowAllPopulation(!showAllPopulation)}
        className={classNames(classes.SegmentItem, showAllPopulation && classes.Selected)}
      >
        <Checkbox
          checked={showAllPopulation}
          onChange={() => setShowAllPopulation(!showAllPopulation)}
          multi
        />
        <span className={classes.Name}>
          {t(
            TransKeys.DOCUMENT_VIEWER.RCA_SEGMENTATION_FIGURE.SEGMENT_OVER_TIME_MODAL
              .MAIN_KPI_LABEL,
            {
              kpi_name: options.kpiName,
            }
          )}
        </span>
      </div>
      {segments.map(segment => {
        const helperText =
          viewMode === RCASegmentationViewMode.EXPLAIN
            ? segment.helperText
            : segment.interestHelperText;
        return (
          <div
            key={segment.key}
            onClick={() => onSegmentClicked(segment.key)}
            className={classNames(
              classes.SegmentItem,
              selectedSegmentKeySet.has(segment.key) && classes.Selected
            )}
          >
            <Checkbox
              checked={selectedSegmentKeySet.has(segment.key)}
              onChange={() => onSegmentClicked(segment.key)}
              multi
            />
            <TooltipIfOverflow title={segment.name}>
              <span className={classes.Name}>{segment.name}</span>
            </TooltipIfOverflow>
            {segment.explainabilityLabel && viewMode === RCASegmentationViewMode.EXPLAIN && (
              <ExplainabilityLabelDisplay
                label={segment.explainabilityLabel}
                isSignificant={segment.isSignificant}
              />
            )}
            {viewMode === RCASegmentationViewMode.CHANGE &&
              segment.interestTypes &&
              segment.interestTypes.length > 0 &&
              segment.isInterestSignificant &&
              take(segment.interestTypes, MAX_INTEREST_CHIP_TO_SHOW).map(interestType => (
                <InterestChipDisplay
                  key={interestType}
                  interestType={interestType}
                  data={segment}
                  isPercentageValue={options.isPercentageValue}
                  entity={options.entity}
                />
              ))}
            {helperText && <HoverHelperTip title={helperText} />}
          </div>
        );
      })}
    </div>
  );
};
