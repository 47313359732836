import {NotSignificantChangeKPIAndUsers, InterestingXoXSentence} from '../rca-insights.model.ts';
import {
  ChangeInTotalEntitiesSentencePart,
  ChangeInValueSentencePart,
  SegmentNameSentencePart,
  SentenceContainer,
  SentencePart,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';

interface OwnProps extends InterestingXoXSentence {
  formattedSentenceParts: NotSignificantChangeKPIAndUsers;
}

type AllProps = OwnProps;

const NotSignificantChangeKPIAndUsersFormatter = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {
    presentTensePerformance,
    changeInShareOfEntitiesDescription,
    changeInTotalEntities,
    entity,
  } = formattedSentenceParts;
  const {segmentViewData, changeInValue, higherIsBetter} = sentenceParts;

  return (
    <SentenceContainer>
      <SegmentNameSentencePart data={segmentViewData} />
      <SentencePart text={`${presentTensePerformance} vs the analyzed period by`} />
      <ChangeInValueSentencePart changeInValue={changeInValue} higherIsBetter={higherIsBetter} />
      <SentencePart text={`and the share of ${entity} ${changeInShareOfEntitiesDescription} by`} />
      <ChangeInTotalEntitiesSentencePart changeInTotalEntities={changeInTotalEntities} />
    </SentenceContainer>
  );
};

export {NotSignificantChangeKPIAndUsersFormatter};
