import * as React from 'react';
import classes from './small-info-box.module.scss';
import classNames from 'classnames';
import {CSSProperties} from 'react';

interface OwnProps {
  title: string;
  children?: any;
  onClick?: () => void;
  className?: string;
  color?: string;
  style?: CSSProperties;
  isActive?: boolean;
  actions?: any;
}

type AllProps = OwnProps;

export const SmallInfoBox: React.FC<AllProps> = (props: AllProps) => {
  const {title, children, onClick, className, isActive, style, actions} = props;

  return (
    <div
      style={style}
      className={classNames(classes.SmallInfoBox, isActive && classes.Active, className)}
    >
      <div onClick={_ => onClick()} className={classes.TitleWrapper}>
        <div className={classes.Title}>{title}</div>
        {actions && <div className={classes.Actions}>{actions}</div>}
      </div>
      <div className={classes.Children}>{children}</div>
    </div>
  );
};
