import * as React from 'react';
import classNames from 'classnames';
import {LoadingStateFigure} from '../../../types';
import classes from './loading-state-viewer.module.scss';
import {CircularProgress} from '@material-ui/core';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../hooks/use-document-translation.hook';

interface OwnProps extends LoadingStateFigure {
  className?: string;
}

type AllProps = OwnProps;

const TransKeysPrefix = TransKeys.DOCUMENT_VIEWER.LOADING_STATE_FIGURE;

export const LoadingStateViewer: React.FC<AllProps> = (props: AllProps) => {
  const {data, className} = props;
  const {t} = useDocumentTranslation();

  return (
    <div className={classNames(classes.LoadingStateViewer, className)}>
      <div className={classes.Container}>
        <CircularProgress
          variant={'indeterminate'}
          disableShrink
          size={20}
          thickness={4}
          color={'inherit'}
        />
        <div>{data.text || t(TransKeysPrefix.DEFAULT_TEXT)}</div>
      </div>
    </div>
  );
};
