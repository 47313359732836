import * as React from 'react';
import classes from './trend-composite-segments-viewer.module.scss';
import {
  DocumentElementType,
  TrendCompositeSegments,
  TrendCompositeSegmentsFigure,
  TrendSegmentOfComposite,
} from '../../../types';
import {useCallback, useContext, useMemo} from 'react';
import {extendTrendCompositeSegments} from './trend-composite-segments-viewer.utils';
import {SegmentDisplay} from '../../shared/display-columns/segment-display.component';
import {DocumentCommandEmitterContext} from '../../../contexts/document-command-emitter.context';
import {FlexibleTable} from '../../shared/general/flexible-table/flexible-table.component';
import {useDocumentTranslation} from '../../../hooks/use-document-translation.hook';
import pluralize from 'pluralize';
import {DiagramVennLightIcon} from '../../../../../simple/controls/icons/icons.component';
import {number2k, Sorting} from 'front-core';
import {UpliftDisplay} from '../../shared/display-columns/uplift-display.component';
import TransKeys from 'translations';
import classNames from 'classnames';
import {useDocQuery} from '../../../hooks/use-doc-query.hook';
import {CompositeDisplay} from '../../shared/display-columns/composite-display.component';
import {ValueWithUpliftDisplay} from '../../shared/display-columns/value-with-uplift-display.component';
import {
  filterCompositeSegments,
  useCompositeFilter,
} from '../composite-segments-viewer/use-composite-filter.hook';
import {PercentageAbsDisplay} from '../../shared/display-columns/percentage-abs-display.component';
import {keys} from 'lodash';
import {useDocumentTracking} from '../../../hooks/use-document-tracking.hook';
import {ShareOfUsersFilter} from '../../shared/filters/share-of-users-filter/share-of-users-filter.component';
import {calculateMinMaxShare} from '../../viewers/utils/segmentation.utils';

interface OwnProps extends TrendCompositeSegmentsFigure {
  className?: string;
}

type AllProps = OwnProps;

export interface ExtendedTrendSegmentOfComposite extends TrendSegmentOfComposite {
  share: number;
  expectedShare: number;
  uplift: number;
}

export interface ExtendedTrendCompositeSegments extends Omit<TrendCompositeSegments, 'segments'> {
  key: string;
  share: number;
  expectedShare: number;
  uplift: number;
  segments: ExtendedTrendSegmentOfComposite[];
}

interface Filters {
  segments?: string[];
  shareOfUsers?: {min: number; max: number};
}

const DEFAULT_FILTERS = {
  segments: [],
  shareOfUsers: null,
};

export const TrendCompositeSegmentsViewer: React.FC<AllProps> = (props: AllProps) => {
  const {id, data, options, extraColumns, className} = props;
  const {onSignalClick} = useContext(DocumentCommandEmitterContext);
  const {trackFilter} = useDocumentTracking(
    id,
    DocumentElementType.TREND_COMPOSITE_SEGMENTS_FIGURE
  );
  const {t} = useDocumentTranslation();
  const {query: filters, setQuery: setFilters} = useDocQuery<Filters>(
    id,
    DEFAULT_FILTERS,
    'filters'
  );
  const composites = useMemo(
    () =>
      extendTrendCompositeSegments(data.composites, data.totalEntities, data.expectedTotalEntities),
    [data]
  );
  const filteredComposites = useMemo(() => {
    let filtered = filterCompositeSegments(composites, filters.segments);
    if (filters.shareOfUsers) {
      filtered = filtered.filter(
        i => i.share >= filters.shareOfUsers.min / 100 && i.share <= filters.shareOfUsers.max / 100
      );
    }
    return filtered;
  }, [composites, filters.segments, filters.shareOfUsers]);
  const columns = useMemo(
    () => [
      {
        key: ['groupName', 'name'],
        title: [
          t(TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.SEGMENT_GROUP.TITLE),
          t(TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.SEGMENT.TITLE),
        ],
        helperText: [
          t(
            TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.SEGMENT_GROUP
              .HELPER_TEXT
          ),
          t(TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.SEGMENT.HELPER_TEXT),
        ],
        weight: 1.5,
        render: (item: ExtendedTrendCompositeSegments) => {
          return (
            <CompositeDisplay>
              {item.segments.map(s => (
                <div key={s.signalId} className={classes.SegmentLine}>
                  <SegmentDisplay
                    segment={s.name}
                    group={s.groupName}
                    onClick={() => onSignalClick(s.signalId)}
                  />
                </div>
              ))}
            </CompositeDisplay>
          );
        },
      },
      {
        key: 'share',
        title: t(
          TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_X.TITLE,
          {
            entity: pluralize(options.entity),
          }
        ),
        subTitle: t(
          TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_X.SUB_TITLE,
          {
            entity: pluralize(options.entity),
          }
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_X.HELPER_TEXT,
          {
            entity: pluralize(options.entity),
          }
        ),
        weight: 1,
        render: (item: ExtendedTrendCompositeSegments) => {
          return (
            <div className={classes.List}>
              {item.segments.map(s => (
                <div key={s.signalId} className={classes.SegmentLine}>
                  <PercentageAbsDisplay
                    percentage={s.share}
                    abs={s.count}
                    unit={pluralize(options.entity)}
                  />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        key: 'value',
        title: t(TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_VALUE.TITLE),
        subTitle: t(
          TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_VALUE.SUB_TITLE
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_VALUE.HELPER_TEXT
        ),
        weight: 1,
        render: (item: ExtendedTrendCompositeSegments) => {
          return (
            <div>
              {item.segments.map(s => (
                <div key={s.signalId} className={classes.SegmentLine}>
                  <ValueWithUpliftDisplay
                    value={s.value}
                    uplift={s.uplift}
                    higherIsBetter={options.higherIsBetter}
                    isPercentageValue={options.isPercentageValue}
                  />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        key: 'share',
        title: t(
          TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_COMBINED.TITLE,
          {
            entity: pluralize(options.entity),
          }
        ),
        subTitle: t(
          TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_COMBINED
            .SUB_TITLE,
          {
            entity: pluralize(options.entity),
          }
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_COMBINED
            .HELPER_TEXT,
          {
            entity: pluralize(options.entity),
          }
        ),
        weight: 1,
        sortable: true,
        contentClassName: classes.ShareOfUsersContent,
        render: (item: ExtendedTrendCompositeSegments) => (
          <PercentageAbsDisplay
            icon={DiagramVennLightIcon}
            percentage={item.share}
            abs={item.count}
            unit={pluralize(options.entity)}
          />
        ),
      },
      {
        key: 'uplift',
        title: t(
          TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_COMBINED.TITLE
        ),
        subTitle: t(
          TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_COMBINED.SUB_TITLE
        ),
        helperText: t(
          TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.KPI_COMBINED.HELPER_TEXT
        ),
        weight: 1,
        sortable: true,
        contentClassName: classes.KPICombinedContent,
        render: (item: ExtendedTrendCompositeSegments) => (
          <div className={classes.KPICombined}>
            <div className={classes.Value}>
              {number2k(options.isPercentageValue ? item.value * 100 : item.value)}
              {options.isPercentageValue ? '%' : ''}
            </div>
            <div className={classes.Uplift}>
              <UpliftDisplay uplift={item.uplift} higherIsBetter={options.higherIsBetter} />
            </div>
          </div>
        ),
      },
    ],
    [options, extraColumns]
  );

  const onFilterChange = useCallback(
    (newFilters: Filters) => {
      setFilters(filters => ({
        ...filters,
        ...newFilters,
      }));
      const filterKey = keys(newFilters)[0];
      trackFilter(filterKey, {
        filter: newFilters[filterKey],
      });
    },
    [setFilters, trackFilter]
  );
  const shareOfUsersMinMaxValue = useMemo(() => calculateMinMaxShare(composites), [composites]);
  const {compositeFilter} = useCompositeFilter({
    composites,
    filter: filters.segments,
    setFilter: v => setFilters({segments: v}),
    disabled: composites.length === 0,
  });
  const defaultSort: Sorting = useMemo(
    () => ({
      orderBy: 'uplift',
      order: options.sortOrder ? options.sortOrder : options.higherIsBetter ? 'desc' : 'asc',
    }),
    [options]
  );

  return (
    <div className={classNames(classes.TrendCompositeSegmentsViewer, className)}>
      <div className={classes.Filters}>
        {compositeFilter}
        <ShareOfUsersFilter
          className={classes.Filter}
          {...shareOfUsersMinMaxValue}
          value={filters.shareOfUsers}
          onChange={v => onFilterChange({shareOfUsers: v})}
          disabled={composites.length === 0}
          placeholder={t(
            TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.TABLE.HEADERS.SHARE_OF_COMBINED
              .TITLE,
            {
              entity: pluralize(options.entity).toLowerCase(),
            }
          )}
        />
      </div>
      <FlexibleTable
        columns={columns}
        data={filteredComposites}
        defaultSort={defaultSort}
        emptyState={t(TransKeys.DOCUMENT_VIEWER.TREND_COMPOSITE_SEGMENTS.EMPTY_STATE.NO_RESULTS)}
      />
    </div>
  );
};
