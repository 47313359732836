import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import classes from './funnel-segment-modal.module.scss';
import {
  ChartType,
  CommandType,
  DocumentElementType,
  FunnelSegmentationItemType,
  FunnelSegmentationStep,
  KPISegmentationUpliftMode,
} from '../../../../../../types';
import {NextPrevNavigator} from '../../../../../shared/general/next-prev-navigator/next-prev-navigator.component';
import {ChildRenderer} from '../../../../../core/child-renderer.component';
import {ModelType} from '../../../../../../../../../consts/model-type';
import TransKeys from 'translations';
import {
  ExtendedFunnelSegmentationItem,
  FunnelSegmentGroup,
} from '../../funnel-segmentation-viewer.component';
import {exists} from 'front-core';
import {keyBy} from 'lodash';
import {useDocumentTranslation} from '../../../../../../hooks/use-document-translation.hook';
import {useDocumentTracking} from '../../../../../../hooks/use-document-tracking.hook';
import {KPISegmentGroupTable} from '../../../../kpi-analysis/kpi-segmentation-viewer/components/kpi-segment-group-table/kpi-segment-group-table.component';
import {
  SegmentationModalHeader,
  SegmentationModalMode,
} from '../../../../../shared/segmentation/segmentation-modal-header/segmentation-modal-header.component';
import {CompareSegmentsButton} from '../../../../../shared/segmentation/compare-segments-button/compare-segments-button.component';
import {ModalWrapper} from '../../../../../shared/modal-wrapper/modal-wrapper.component';

interface StepOption {
  value: number;
  label: string;
}

interface OwnProps {
  figureId: string;
  group: FunnelSegmentGroup;
  stepsOptions: StepOption[];
  steps: FunnelSegmentationStep[];
  selectedStepIndex: number;
  groupOptions: string[];
  onSelectGroup: (group: string) => void;
  onFollowUp: (goal: number, refDate: number, segment: number, segmentClass: string) => void;
  entity: string;
  totalEntities: number;
  className?: string;
}

type AllProps = OwnProps;

function getHelperText(isInformative: boolean, isSignificant: boolean, helperText: string) {
  if (isInformative === false) {
    return {
      nameHelperText: helperText,
    };
  }
  if (!isSignificant) {
    return {
      upliftHelperText: helperText,
    };
  }
  return {};
}

export const FunnelSegmentationModal: React.FC<AllProps> = (props: AllProps) => {
  const {
    figureId,
    stepsOptions,
    steps,
    group,
    groupOptions: groupOptionsFromProps,
    onSelectGroup,
    entity,
    totalEntities,
    selectedStepIndex: selectedStepIndexFromProps,
    className,
  } = props;
  const {t} = useDocumentTranslation();
  const {trackNavigation} = useDocumentTracking(
    figureId,
    DocumentElementType.FUNNEL_SEGMENTATION_FIGURE
  );
  const [mode, setMode] = useState(SegmentationModalMode.DEFAULT);
  const [upliftMode, setUpliftMode] = useState<KPISegmentationUpliftMode>(
    KPISegmentationUpliftMode.VS_OTHERS
  );
  const [selectedStepIndex, setSelectedStepIndex] = useState(selectedStepIndexFromProps);
  const [reviewedSegment, setReviewedSegment] = useState<ExtendedFunnelSegmentationItem>(null);
  const [comparedSegmentKeys, setComparedSegmentKeys] = useState<string[]>([]);
  const segmentsByKey = useMemo(() => keyBy(group.segments, 'key'), [group.segments]);
  const groupOptions = useMemo(
    () =>
      groupOptionsFromProps.map(g => ({
        value: g,
        label: g,
      })),
    [groupOptionsFromProps]
  );
  const segmentOptions = useMemo(() => {
    return group.segments.map(s => ({
      value: s.key,
      label: s.segmentName[0],
    }));
  }, [reviewedSegment]);
  const navigatorProps = useMemo(() => {
    if (groupOptions.length === 0) {
      return;
    }
    const currentIndex = groupOptions.findIndex(i => i.value === group?.name);
    const maxIndex = groupOptions.length - 1;
    return {
      currentIndex,
      maxIndex,
      onNextPrev: (isNext: boolean) =>
        onSelectGroup(groupOptions[currentIndex + (isNext ? 1 : -1)].value),
      nextLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.NEXT_LABEL),
      prevLabel: t(TransKeys.DOCUMENT_VIEWER.KPI_SEGMENTATION_FIGURE.SEGMENT_MODAL.PREV_LABEL),
    };
  }, [groupOptions, group.name, onSelectGroup, t]);
  const tableData = useMemo(
    () =>
      group.segments.map(s => {
        const isSignificant = exists(selectedStepIndex)
          ? s.type[selectedStepIndex] !== FunnelSegmentationItemType.INSIGNIFICANT_POSITIVE
          : s.funnelCompletionType !== FunnelSegmentationItemType.INSIGNIFICANT_POSITIVE;
        const helperText = exists(selectedStepIndex)
          ? s.helperText[selectedStepIndex]
          : s.helperTextFunnelCompletion;
        const isInformative = exists(s.isInformative) ? s.isInformative : true;
        return {
          key: s.key,
          name: s.segmentName[0],
          signalId: s.signalId[0],
          count: s.countInSegment[0],
          uplift: exists(selectedStepIndex)
            ? s.uplift[selectedStepIndex] * 100
            : s.funnelCompletionUplift * 100,
          informativeUplift: s.informativeUplift
            ? exists(selectedStepIndex)
              ? s.informativeUplift[selectedStepIndex] * 100
              : s.informativeFunnelCompletionUplift * 100
            : undefined,
          avgUplift: exists(selectedStepIndex)
            ? s.allSegmentsUplift[selectedStepIndex] * 100
            : s.allSegmentsFunnelCompletionUplift * 100,
          goalInSegment: exists(selectedStepIndex)
            ? s.segmentConversions[selectedStepIndex] * 100
            : s.segmentFunnelCompletionConversion * 100,
          goalInNonSegment: exists(selectedStepIndex)
            ? s.nonSegmentConversions[selectedStepIndex] * 100
            : s.nonSegmentFunnelCompletionConversion * 100,
          goalInInformativeNonSegment: s.informativeNonSegmentConversions
            ? exists(selectedStepIndex)
              ? s.informativeNonSegmentConversions[selectedStepIndex] * 100
              : s.informativeNonSegmentFunnelCompletionConversion * 100
            : undefined,
          goalValue: exists(selectedStepIndex)
            ? s.allSegmentFunnelCompletionConversion[selectedStepIndex] * 100
            : s.allSegmentFunnelCompletionConversion * 100,
          share: s.share * 100,
          isSignificant: isSignificant,
          isInformative: isInformative,
          command: {
            type: CommandType.REF,
            payload: {
              type: ModelType.SIGNAL,
              refId: s.signalId[0],
            },
          },
          ...getHelperText(isInformative, isSignificant, helperText),
        };
      }),
    [group.segments, selectedStepIndex]
  );
  const onDeepDive = useCallback(
    (segmentKey: string) => {
      setReviewedSegment(segmentsByKey[segmentKey]);
      setMode(SegmentationModalMode.DEEP_DIVE);
      trackNavigation('segment_deep_dive');
    },
    [segmentsByKey, setReviewedSegment, setMode]
  );
  const onCompareClicked = useCallback(() => {
    setMode(SegmentationModalMode.COMPARE);
    trackNavigation('compare_segments');
  }, [setMode]);
  const onBack = useCallback(() => {
    setMode(SegmentationModalMode.DEFAULT);
    setReviewedSegment(null);
  }, [setMode, setReviewedSegment]);
  const funnelFigure = useMemo(() => {
    const labels = steps.map(s => s.name);
    // DEEP DIVE MODE
    if (mode === SegmentationModalMode.DEEP_DIVE) {
      let nonSegmentData = reviewedSegment.countInNonSegment;
      let label =
        TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.MODAL.NON_SEGMENT_FUNNEL_LABEL_TEXT;
      if (upliftMode === KPISegmentationUpliftMode.VS_INFORMATIVE_OTHERS) {
        nonSegmentData = reviewedSegment.countInInformativeNonSegment;
        label =
          TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.MODAL
            .INFORMATIVE_NON_SEGMENT_FUNNEL_LABEL_TEXT;
      } else if (upliftMode === KPISegmentationUpliftMode.VS_AVERAGE) {
        nonSegmentData = reviewedSegment.allSegmentCount;
        label =
          TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.MODAL
            .ALL_POPULATION_FUNNEL_LABEL_TEXT;
      }
      return {
        type: DocumentElementType.CHART,
        chartType: ChartType.FUNNEL,
        data: [
          {
            id: 'segment',
            label: `${reviewedSegment.segmentName[0]}`,
            data: reviewedSegment.countInSegment,
          },
          {
            id: 'non-segment',
            label: t(label),
            data: nonSegmentData,
          },
        ],
        options: {
          sortDatasets: false,
        },
        labels,
      };
    }
    // COMPARE MODE
    if (mode === SegmentationModalMode.COMPARE) {
      const comparedSegments = comparedSegmentKeys
        .map(k => segmentsByKey[k])
        // validation
        .filter(s => s !== undefined)
        .map(s => ({
          id: s.key,
          label: s.segmentName,
          data: s.countInSegment,
        }));
      const allSegmentsDataset = group.segments.reduce((acc, curr) => {
        for (const index in curr.countInSegment) {
          acc[index] = (acc[index] || 0) + curr.countInSegment[index];
        }
        return acc;
      }, []);

      return {
        type: DocumentElementType.CHART,
        chartType: ChartType.FUNNEL,
        data: [
          {
            id: 'all',
            label: t(
              TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.MODAL
                .ALL_SEGMENT_GROUP_FUNNEL_LABEL
            ),
            data: allSegmentsDataset,
          },
          ...comparedSegments,
        ],
        options: {
          sortDatasets: false,
        },
        labels,
      };
    }
  }, [reviewedSegment, mode, t, upliftMode, comparedSegmentKeys, segmentsByKey, group.segments]);
  const reviewedSegmentHelperText = useMemo(() => {
    if (!reviewedSegment) {
      return;
    }
    return exists(selectedStepIndex)
      ? reviewedSegment.helperText[selectedStepIndex]
      : reviewedSegment.helperTextFunnelCompletion;
  }, [reviewedSegment, selectedStepIndex]);

  const renderListContent = () => {
    return (
      <>
        <KPISegmentGroupTable
          tableData={tableData}
          entity={entity}
          totalEntities={totalEntities}
          transKeysPrefix={TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE}
          onClick={onDeepDive}
          selectedKeys={comparedSegmentKeys}
          onChangedSelectedKeys={setComparedSegmentKeys}
          multiSelection={true}
          upliftMode={upliftMode}
          onChangeUpliftMode={setUpliftMode}
          buttonText={t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.ACTIONS.FUNNEL_BY_SEGMENT
          )}
        />
        <div className={classes.CompareLine}>
          <CompareSegmentsButton
            onClick={() => onCompareClicked()}
            count={comparedSegmentKeys.length}
          />
        </div>
        <div className={classes.Spacer} />
        {navigatorProps && (
          <div className={classes.Navigation}>
            <NextPrevNavigator {...navigatorProps} />
          </div>
        )}
      </>
    );
  };
  const renderDeepDive = () => {
    if (!funnelFigure) {
      return null;
    }
    return <ChildRenderer children_={funnelFigure} key={'funnel-chart'} />;
  };

  return (
    <ModalWrapper isOpen={group} onClose={() => onSelectGroup(null)} width={'70vw'} height={'80vh'}>
      <div className={classNames(classes.FunnelSegmentationModal, className)}>
        <SegmentationModalHeader
          title={t(TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.MODAL.TITLE.PREFIX)}
          mode={mode as any}
          onBack={onBack}
          compareSegmentsSuffix={t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.MODAL.TITLE.COMPARE_SEGMENTS_PREFIX
          )}
          selectedGroup={group?.name}
          groupOptions={groupOptions}
          onSelectGroup={onSelectGroup}
          selectedStep={selectedStepIndex}
          stepsOptions={stepsOptions}
          onSelectStep={setSelectedStepIndex as any}
          stepsPrefix={t(
            TransKeys.DOCUMENT_VIEWER.FUNNEL_SEGMENTATION_FIGURE.MODAL.TITLE.STEPS_PREFIX
          )}
          selectedSegment={reviewedSegment?.key}
          segmentOptions={segmentOptions}
          onSelectSegment={onDeepDive}
          segmentHelperText={reviewedSegmentHelperText}
        />
        <div className={classes.Content}>
          {mode === SegmentationModalMode.DEFAULT && renderListContent()}
          {(mode === SegmentationModalMode.DEEP_DIVE || mode === SegmentationModalMode.COMPARE) &&
            renderDeepDive()}
        </div>
      </div>
    </ModalWrapper>
  );
};
