import * as React from 'react';
import classNames from 'classnames';
import classes from './toggle-switch.module.scss';
import {withStopPropagation} from 'front-core';

interface OwnProps {
  value: boolean;
  onChange: (value: boolean) => void;
  icon?: any;
  label?: string;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const ToggleSwitch: React.FC<AllProps> = (props: AllProps) => {
  const {value, onChange, label, icon: Icon, disabled, className} = props;

  return (
    <div
      onClick={!disabled ? withStopPropagation(() => onChange(!value)) : undefined}
      className={classNames(
        classes.ToggleSwitch,
        value && classes.Active,
        disabled && classes.Disabled,
        className
      )}
    >
      <div className={classes.Switch}>
        <div className={classes.Circle}>{Icon && <Icon className={classes.Icon} />}</div>
      </div>
      {label && <div className={classes.Label}>{label}</div>}
    </div>
  );
};
