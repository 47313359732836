import * as React from 'react';
import classNames from 'classnames';
import classes from './trend-chip.module.scss';
import {ForwardedRef, forwardRef, useMemo} from 'react';
import {ArrowNarrowUpIcon, InfinityRegularIcon} from '../../controls/icons/icons.component';
import {number2k} from 'front-core';

interface OwnProps {
  value: number;
  higherIsBetter?: boolean;
  isSignificant?: boolean;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  variant?: 'contained' | 'ghost';
  inf?: boolean;
  className?: string;
  ref?: any;
}

type AllProps = OwnProps;

export const calcIsPositiveTrend = (uplift: number, higherIsBetter: boolean = true) => {
  const res = uplift >= 0;
  return higherIsBetter ? res : !res;
};

export const TrendChip: React.FC<AllProps> = forwardRef(
  (props: AllProps, ref: ForwardedRef<any>) => {
    const {
      value: uplift,
      inf,
      higherIsBetter,
      size,
      isSignificant,
      variant = 'contained',
      className,
      ...restProps
    } = props;

    const renderValue = useMemo(() => number2k(uplift), [uplift]);
    const arrowDirectionUp = useMemo(() => uplift >= 0, [uplift]);
    const isPositiveTrend = useMemo(
      () => calcIsPositiveTrend(uplift, higherIsBetter),
      [uplift, higherIsBetter]
    );
    return (
      <div
        {...restProps}
        ref={ref}
        className={classNames(
          classes.TrendChip,
          isPositiveTrend && classes.Positive,
          !isPositiveTrend && classes.Negative,
          isSignificant && classes.Significant,
          variant === 'ghost' && classes.Ghost,
          classes[size],
          className
        )}
      >
        <ArrowNarrowUpIcon
          className={classNames(classes.Arrow, !arrowDirectionUp && classes.Flip)}
        />
        {!inf && <span className={classes.Value}>{renderValue}%</span>}
        {inf && (
          <span className={classes.Value}>
            <InfinityRegularIcon />
          </span>
        )}
      </div>
    );
  }
);
