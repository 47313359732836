import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {
  EnhancedCircle,
  EnhancedGroup,
  EnhancedLine,
  EnhancedRect,
} from '../../../../../core/konva/components';
import {NumberDataset} from '../../chart-data.types';
import {StageContext} from '../../../../../core/konva/stage-context.component';
import {DumbbellChartLayoutInjectedProps} from '../../layouts/dumbbell-chart-layout.component';
import {flatten, range as lodashRange, max, last} from 'lodash';
import {DumbbellLabel} from '../../components/dumbbell-label.component';
import {chartClasses} from '../../chart.consts';
import {ScrollableView} from '../../../../../core/konva/hoc/scrollable-view.hoc';
import {ChartContext} from '../../chart.context';
import {pixelInRangeFactory} from '../../chart.utils';
import {colorAlphaTransformer} from '../../../../../utils/colors';

interface OwnProps extends DumbbellChartLayoutInjectedProps {
  datasets: NumberDataset[];
  onDatasetClick?: (ds: NumberDataset) => void;
}

type AllProps = OwnProps;

const ROW_PADDING = 10;

export const PercentageLineChartDatasetContainer: React.FC<AllProps> = (props: AllProps) => {
  const {
    width,
    height,
    x,
    y,
    datasets: datasets_,
    onDatasetClick,
    xPixelRange,
    xPositions,
    labels,
    yRange,
  } = props;
  const {controller, style} = useContext(StageContext);
  const {darkMode} = useContext(ChartContext);
  const [tooltipState, setTooltipState] = useState(undefined);
  /**
   * Data computed properties
   */
  const maxX = useMemo(() => {
    const lengths = datasets_.map(d => d.data.length);
    return xPositions[max(lengths) - 1];
  }, [datasets_, xPositions]);
  const datasets = useMemo(
    () => datasets_.sort((a, b) => Number(a.highlight) - Number(b.highlight)),
    [datasets_]
  );
  const rowHeight = useMemo(() => max([height / datasets.length, 80]), [height, datasets]);
  const datasetHeight = useMemo(() => rowHeight - ROW_PADDING * 2, [rowHeight]);
  const verticalLinesY = useMemo(
    () => lodashRange(0, datasets.length).map(i => i * rowHeight),
    [datasets.length, rowHeight]
  );
  const yLabels = useMemo(
    () =>
      datasets.map(ds => ({
        value: ds.label,
        width: controller.measureTextWidth(ds.label, style.fontName, 12) + 16,
      })),
    [controller, datasets, style.fontName]
  );
  const datasetPoints = useMemo(
    () =>
      datasets.map((ds, dsIdx) =>
        ds.data.map((item, idx) => {
          const calcY = pixelInRangeFactory(yRange, datasetHeight, true);

          return {
            x: xPositions[idx],
            y: calcY(item) + ROW_PADDING,
            color: ds.color,
            highlight: ds.highlight,
            datasetName: ds.label,
            value: item,
          };
        })
      ),
    [datasetHeight, datasets, xPositions]
  );
  const datasetLines = useMemo(
    () =>
      datasetPoints.map((dsp, dspIdx) => {
        const points = flatten(dsp.map(p => [p.x, p.y]));
        return {
          points: points,
          color: datasets[dspIdx].color,
          stroke: colorAlphaTransformer(
            datasets[dspIdx].color,
            darkMode ? 0.6 : 0.8,
            true,
            '#000000'
          ),
          highlight: datasets[dspIdx].highlight,
        };
      }),
    [datasetPoints, datasets, darkMode]
  );
  const areas = useMemo(
    () =>
      datasetPoints.map((dsp, dspIdx) => {
        const fillLinearGradient = {
          fillLinearGradientStartPoint: {x: 0, y: 0},
          fillLinearGradientEndPoint: {x: maxX, y: 0},
          fillLinearGradientColorStops: [
            0,
            colorAlphaTransformer(datasets[dspIdx].color, 0.01),
            1,
            colorAlphaTransformer(datasets[dspIdx].color, 0.5),
          ],
        };
        return {
          x: 0,
          y: 0,
          height: rowHeight,
          width: maxX,
          ...fillLinearGradient,
        };
      }),
    [datasetPoints, datasets, rowHeight, maxX]
  );
  const verticalLine = useMemo(
    () => ({
      points: [maxX, 0, maxX, height],
      stroke: darkMode ? '#ffffff' : '#898EA8',
      strokeWidth: 2,
      dash: [8, 8],
    }),
    [darkMode, height, maxX]
  );
  const tooltipLine = useMemo(
    () =>
      tooltipState
        ? {
            ...verticalLine,
            points: [
              xPositions[tooltipState.pointIndex],
              0,
              xPositions[tooltipState.pointIndex],
              height,
            ],
          }
        : false,
    [height, tooltipState, verticalLine, xPositions]
  );
  const lastLabels = useMemo(
    () =>
      datasets.map((ds, idx) => {
        const lastPoint = last(datasetPoints[idx]);
        return {
          x: lastPoint.x,
          y: lastPoint.y,
          text: Number((lastPoint.value * 100).toFixed(2)) + '%',
          color: ds.color,
        };
      }),
    [datasets, datasetPoints]
  );
  const tooltipLabels = useMemo(
    () =>
      tooltipState
        ? datasets.map((ds, idx) => {
            const lastPoint = datasetPoints[idx][tooltipState.pointIndex];
            return {
              x: lastPoint.x,
              y: lastPoint.y,
              text: Number((lastPoint.value * 100).toFixed(2)) + '%',
              color: ds.color,
            };
          })
        : false,
    [datasets, tooltipState, datasetPoints]
  );

  const renderTooltip = (pointIndex: number) => {
    return (
      <div>
        {datasets.map(d => (
          <div key={d.id} className={chartClasses.ChartTooltipInfo}>
            <div className={chartClasses.DatasetInfo}>
              <div className={chartClasses.Dot} style={{backgroundColor: d.color}} />
              <div>{d.label}</div>
            </div>
            <div className={chartClasses.Value}>
              {Number((d.data[pointIndex] * 100).toFixed(2)) + '%'}
            </div>
          </div>
        ))}
      </div>
    );
  };
  const onTooltipChange = useCallback(
    (state: boolean, datasetIndex: number, pointIndex: number) =>
      setTooltipState(state ? {datasetIndex, pointIndex} : false),
    []
  );
  /**
   * Render
   */
  return (
    <ScrollableView
      x={x}
      y={y}
      width={width}
      height={height}
      childrenHeight={rowHeight * datasets.length}
    >
      {offsetY => (
        <EnhancedGroup offsetY={offsetY}>
          {verticalLinesY.map((y, datasetIndex) => (
            <React.Fragment key={`dataset_vertical_line_${datasetIndex}`}>
              <DumbbellLabel
                onClick={onDatasetClick ? e => onDatasetClick(datasets[datasetIndex]) : undefined}
                x={4}
                y={y + rowHeight / 2}
                height={28}
                text={yLabels[datasetIndex].value}
                bgColor={datasets[datasetIndex].color}
              />
              <EnhancedRect y={y + rowHeight - 1} width={width} height={1} fill={'#898EA8'} />
            </React.Fragment>
          ))}
          <EnhancedGroup x={xPixelRange[0]} width={width - xPixelRange[0]}>
            <EnhancedLine key={'static'} {...verticalLine} />

            {tooltipLine && <EnhancedLine key={'tooltip_related'} {...tooltipLine} />}

            {datasets.map((_, datasetIndex) => (
              <EnhancedGroup
                key={datasetIndex}
                centerY
                y={verticalLinesY[datasetIndex] + rowHeight / 2}
                height={rowHeight}
              >
                <EnhancedRect key={`rect_${datasetIndex}`} {...areas[datasetIndex]} />
                <EnhancedLine
                  onClick={onDatasetClick ? e => onDatasetClick(datasets[datasetIndex]) : undefined}
                  points={datasetLines[datasetIndex].points}
                  lineJoin={'round'}
                  lineCap={'round'}
                  roundedCorners
                  strokeWidth={8}
                  stroke={datasetLines[datasetIndex].color}
                />
                <EnhancedLine
                  onClick={onDatasetClick ? e => onDatasetClick(datasets[datasetIndex]) : undefined}
                  points={datasetLines[datasetIndex].points}
                  lineJoin={'round'}
                  lineCap={'round'}
                  roundedCorners
                  strokeWidth={4}
                  stroke={datasetLines[datasetIndex].stroke}
                />
                {datasetPoints[datasetIndex].map((p, pointIndex) => (
                  <EnhancedCircle
                    onClick={
                      onDatasetClick ? e => onDatasetClick(datasets[datasetIndex]) : undefined
                    }
                    key={`point_${datasetIndex}_${pointIndex}`}
                    x={p.x}
                    y={p.y}
                    radius={4}
                    fill={p.color}
                    strokeEnabled={p.highlight}
                    stroke={style.highlightColor}
                    strokeWidth={2}
                    center
                    tooltipEnabled
                    tooltipScale={2}
                    tooltipData={{
                      label: labels[pointIndex],
                      value: renderTooltip(pointIndex),
                    }}
                    onTooltipChange={state => onTooltipChange(state, datasetIndex, pointIndex)}
                  />
                ))}
                <DumbbellLabel
                  x={lastLabels[datasetIndex].x}
                  y={lastLabels[datasetIndex].y}
                  onClick={onDatasetClick ? e => onDatasetClick(datasets[datasetIndex]) : undefined}
                  key={`dataset_last_label_${datasetIndex}`}
                  text={lastLabels[datasetIndex].text}
                  bgColor={lastLabels[datasetIndex].color}
                  centerX
                />
                {tooltipLabels && (
                  <DumbbellLabel
                    x={tooltipLabels[datasetIndex].x}
                    y={tooltipLabels[datasetIndex].y}
                    onClick={
                      onDatasetClick ? e => onDatasetClick(datasets[datasetIndex]) : undefined
                    }
                    key={`dataset_tooltip_last_label_${datasetIndex}`}
                    text={tooltipLabels[datasetIndex].text}
                    bgColor={tooltipLabels[datasetIndex].color}
                    centerX
                  />
                )}
              </EnhancedGroup>
            ))}
          </EnhancedGroup>
        </EnhancedGroup>
      )}
    </ScrollableView>
  );
};
