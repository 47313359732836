import {
  MultiLoadResponse,
  SmartSelectorParameters,
  SmartSelectorSource,
  SmartSelector,
  useRemoteSourceStated,
} from 'ui-components';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {exists, HttpClientContext} from 'front-core';
import classes from './smart-selector.module.scss';
import {SmartSelectorLayout} from './smart-selector-layout.component';
import {SmartSelectorSharedProps} from './smart-selector.shared';
import {
  getSourceNetworkRequest,
  getSourcesNetworkRequest,
} from '../../../../http/sources.network-requests.ts';

interface OwnProps extends SmartSelectorSharedProps {}

type AllProps = OwnProps;

export const SourceTableSmartSelector = (props: AllProps) => {
  const {
    placeholder,
    value,
    onChange: onChangeFromProps,
    filters,
    className,
    disabled,
    clearable,
    emptyState,
    error,
    fullWidth,
  } = props;
  const selectorRef = useRef<any>(null);
  const http = useContext(HttpClientContext);
  const {source: source, exec} = useRemoteSourceStated({
    type: 'source',
    networkRequest: getSourceNetworkRequest,
    initialValue: null,
  });
  const selectedSource = useMemo(
    () => (value && source?.id === value ? source : null),
    [value, source]
  );
  const labelValue = useMemo(() => {
    if (value && selectedSource) {
      return selectedSource.name;
    }
  }, [value, selectedSource]);
  const onChange = useCallback(
    (value: number, item?: any) => {
      selectorRef.current.close();
      onChangeFromProps(value, item);
    },
    [onChangeFromProps]
  );
  const sources: SmartSelectorSource[] = useMemo(
    () => [
      {
        key: 'sources',
        name: `Sources`,
        onSelect: item => onChange(item.id, item),
      },
    ],
    [onChange]
  );
  const load = useCallback(
    async (keys: string[], parameters: SmartSelectorParameters): Promise<MultiLoadResponse> => {
      // @ts-ignore
      const sources: any = await http.exec(
        getSourcesNetworkRequest({
          ...(filters || {}),
          page: parameters.page,
          q: parameters.query,
          limit: 1000,
          orderBy: 'id',
          order: 'asc',
        })
      );

      return {
        sources: {
          data: sources.data,
          total: sources.data.length,
          hasNext: false,
        },
      };
    },
    [http, filters]
  );
  useEffect(() => {
    value && source?.id !== value && exec(value);
  }, [value, exec, source]);

  return (
    <SmartSelectorLayout
      id={'source-selector'}
      label={labelValue}
      disabled={disabled}
      error={error}
      clearable={clearable && exists(value)}
      onClear={() => onChange(null)}
      placeholder={placeholder}
      className={className}
      ref={selectorRef}
      fullWidth={fullWidth}
      withPreview={false}
    >
      <SmartSelector
        className={classes.Selector}
        sources={sources}
        load={load}
        emptyState={emptyState}
        withPreview={false}
      />
    </SmartSelectorLayout>
  );
};
