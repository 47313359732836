import {RCAMode} from 'ui-components';
import {RCASentence} from '../../../../../../../../../../insights/rca/rca-insights.model.ts';
import TransKeys from 'translations';
import classes from '../rca-insights-section.module.scss';
import {ChangeDescriptionAlias} from '../../rca-executive-summary-orchestrator/rca-executive-summary-orchestrator.component.tsx';
import * as React from 'react';
import {RCAInsightsSectionHeader} from './rca-insights-section-header.component.tsx';
import {RCAInsightSentenceRenderer} from './rca-insights-section-sentence-renderer.component.tsx';

interface OwnProps {
  explainableSentences: RCASentence[];
  rcaMode: RCAMode;
  granularity: string;
  changeDescriptionAlias: ChangeDescriptionAlias;
  onViewOnPage?: (titleId: string) => void;
}

type AllProps = OwnProps;

export const ExplainableSection: React.FC<AllProps> = (props: AllProps) => {
  const {explainableSentences, rcaMode, granularity, changeDescriptionAlias, onViewOnPage} = props;

  return (
    <div key={rcaMode} className={classes.RCAInsightsSectionContainer}>
      <RCAInsightsSectionHeader
        rcaMode={rcaMode}
        granularity={granularity}
        titlePartOneTxPath={
          TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.EXPLAINERS_TITLE.PART_ONE
        }
        titlePartOneProps={{
          changeDescription: changeDescriptionAlias.toUpperCase(),
        }}
      />
      {explainableSentences.map(sentence => (
        <RCAInsightSentenceRenderer
          key={sentence.key}
          sentence={sentence}
          onViewOnPage={onViewOnPage}
        />
      ))}
    </div>
  );
};
