import * as React from 'react';
import classNames from 'classnames';
import classes from './tree-label.module.scss';

interface OwnProps {
  label?: string;
  children: any;
  className?: string;
}

type AllProps = OwnProps;

export const TreeLabel: React.FC<AllProps> = (props: AllProps) => {
  const {label, children, className} = props;

  return (
    <div className={classNames(classes.TreeLabel, className)}>
      {label && <div className={classes.Label}>{label}</div>}
      {children}
    </div>
  );
};
