import * as React from 'react';
import {QueryBlock} from '../components/query-block/query-block.component';
import {SqlElementModel, TableColumn} from '../query-builder.types';
import {QueryBuilderComponent} from '../query-builder-ui.types';
import {concatPath} from '../query-builder.utils';
import {useQueryElement} from '../query-builder.hooks';
import {TextInput} from '../../../forms/inputs/text-input/text-input.component';
import {withViewMode} from '../core/with-view-mode.hoc';
import {InlineLabel} from '../components/inline-label/inline-label.component';
import {TableIcon} from '../../../simple/controls/icons/icons.component';
import {InlineText} from '../components/parts';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {HttpClientContext} from 'front-core';
import {SelectedOption} from '../../../forms/inputs.types';
import {extractValue} from '../../../../hooks/use-remote-source';
import {ModelSelector} from '../components/model-selector/model-selector.component';

interface OwnProps {
  data: TableColumn;
  inline?: boolean;
  box?: boolean;
}

type AllProps = OwnProps & QueryBuilderComponent;

const TableColumnBuilderComponent: React.FC<AllProps> = (props: AllProps) => {
  const {path, data, onChange, className, disabled, inline, box} = props;
  const {localErrors, actions} = useQueryElement(props);

  return (
    <QueryBlock
      disabled={disabled}
      className={className}
      label={'Table Column'}
      actions={actions}
      inline={inline}
      box={box}
    >
      <ModelSelector
        value={data.table_id}
        onChange={v => onChange(concatPath(path, 'table_id'), v)}
        error={Boolean(localErrors.table_id)}
        model={SqlElementModel.TABLE}
        fullWidth={false}
        disabled={disabled}
      />
      <TextInput
        placeholder={'Column Name'}
        value={data.column}
        onChange={v => onChange(concatPath(path, 'column'), v)}
        error={Boolean(localErrors.column)}
        fullWidth={false}
        disabled={disabled}
      />
    </QueryBlock>
  );
};

const TableColumnViewerComponent: React.FC<AllProps> = (props: AllProps) => {
  const {data, className, config} = props;
  const httpClient = useContext(HttpClientContext);
  const options = config.modelConfig?.[SqlElementModel.TABLE];
  const [selected, setSelected] = useState(null);
  const selectionKey = useMemo(() => (options as any).selectionKey || 'id', [options]);
  const getOption = useCallback(
    async (value: SelectedOption) => {
      if (!value || !('networkRequest' in options)) {
        return;
      }
      const nr = options.networkRequest(undefined, {
        [selectionKey]: [value],
      });
      const res: any = await httpClient.exec(nr);
      setSelected({
        label: extractValue(res[0], options.labelAttributePath),
        value: extractValue(res[0], options.valueAttributePath),
      });
    },
    [httpClient, options, selectionKey]
  );
  useEffect(() => {
    if (data.table_id === selected?.value) {
      return;
    }
    if (!selected) {
      getOption(data.table_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.table_id, getOption]);

  if (!options) {
    return <span>UNSUPPORTED! Missing model config for TABLE</span>;
  }

  return (
    <QueryBlock disabled={true} className={className} inline={true} box={false} fitContent>
      <InlineLabel icon={TableIcon} label={selected?.label} />
      <InlineText>{data.column}</InlineText>
    </QueryBlock>
  );
};

export const TableColumnBuilder = withViewMode(
  TableColumnBuilderComponent,
  TableColumnViewerComponent
);
