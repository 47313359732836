import {useCallback, useMemo, useState, useEffect} from 'react';
import {
  AnalysisAlgoMode,
  TreatmentAdoptionSimulationItemType,
  TreatmentAdoptionSimulationModeSwitchWrapperFigure,
  TreatmentAdoptionSimulationModeSwitchWrapperFigureData,
} from '../../../../../types';
import {useDocQuery} from '../../../../../hooks/use-doc-query.hook';
import {ChildRenderer} from '../../../../core/child-renderer.component';
import classes from './treatment-adoption-simulation-mode-switch-wrapper-viewer.module.scss';
import classNames from 'classnames';
import {NoticePopup} from '../../../../shared/general/notice-popup/notice-popup.component';
import {WarningIcon} from '../../../../../../../simple/controls/icons/icons.component';
import TransKeys from 'translations';
import {useDocumentTranslation} from '../../../../../hooks/use-document-translation.hook';

interface OwnProps extends TreatmentAdoptionSimulationModeSwitchWrapperFigure {
  className?: string;
}

type AllProps = OwnProps;

export const NO_CAUSAL_RESULTS_NOTICE_ELEMENT_ID = 'switch-treatment-adoption-mode';

const calcInitialAlgoMode = (data: TreatmentAdoptionSimulationModeSwitchWrapperFigureData) => {
  const items = data.causation.data.items;
  const showCorrelation =
    items.length === 0 ||
    items.every(item => item.type === TreatmentAdoptionSimulationItemType.NO_RECOMMENDATION);
  return showCorrelation ? AnalysisAlgoMode.CORRELATION : AnalysisAlgoMode.CAUSATION;
};

export const TreatmentAdoptionSimulationModeSwitchWrapperViewer: React.FC<AllProps> = (
  props: AllProps
) => {
  const {id, data, className} = props;
  const {t} = useDocumentTranslation();
  const [noticeAnchorEl, setNoticeAnchorEl] = useState<HTMLElement>(null);
  const initialAlgoMode = useMemo(() => calcInitialAlgoMode(data), [data.causation]);
  const {query: algoMode, setQuery: setAlgoMode} = useDocQuery<AnalysisAlgoMode>(
    id,
    initialAlgoMode,
    'w_mode'
  );

  const [showNoCausalResultsNotice, setShowNoCausalResultsNotice] = useState(
    initialAlgoMode === AnalysisAlgoMode.CORRELATION && algoMode === AnalysisAlgoMode.CORRELATION
  );
  const onNoCausalResultsNoticeClick = useCallback(
    switchToCausation => {
      setShowNoCausalResultsNotice(false);
      switchToCausation && setAlgoMode(AnalysisAlgoMode.CAUSATION);
    },
    [setAlgoMode, setShowNoCausalResultsNotice]
  );

  useEffect(() => {
    const el = document.getElementById(NO_CAUSAL_RESULTS_NOTICE_ELEMENT_ID);
    setNoticeAnchorEl(el);
  }, [setNoticeAnchorEl]);

  const childrenToRender = useMemo(() => {
    const children = algoMode === AnalysisAlgoMode.CAUSATION ? data.causation : data.correlation;
    return {
      ...children,
      algoMode,
      setAlgoMode,
    };
  }, [data, algoMode, setAlgoMode]);

  return (
    <div
      className={classNames(classes.TreatmentAdoptionSimulationModeSwitchWrapperViewer, className)}
    >
      <ChildRenderer children_={childrenToRender} />
      <NoticePopup
        anchorEl={noticeAnchorEl}
        placement={'top-start'}
        show={showNoCausalResultsNotice}
        icon={WarningIcon}
        message={t(
          TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.NO_CAUSAL_RESULTS.MESSAGE
        )}
        confirmLabel={t(
          TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.NO_CAUSAL_RESULTS.OK_BUTTON
        )}
        onConfirm={() => onNoCausalResultsNoticeClick(false)}
        secondaryLabel={t(
          TransKeys.DOCUMENT_VIEWER.TREATMENT_ADOPTION_SIMULATION_FIGURE.NO_CAUSAL_RESULTS
            .WHY_BUTTON
        )}
        onSecondary={() => onNoCausalResultsNoticeClick(true)}
      />
    </div>
  );
};
