import {ExperimentImpactSentenceExplainer} from '../rca-insights.model.ts';
import {
  RCAExplainersIcon,
  RCAExplainersIconVariant,
} from '../../components/rca-explainers-icon/rca-explainers-icon.component.tsx';
import {ChevronRightSolidIcon, RCAElementType} from 'ui-components';
import {
  SentencePart,
  ChangeInValueSentencePart,
  ScrollToSectionSentencePart,
  SentenceContainer,
} from '../../components/shared-sentence-parts/shared-sentence-parts.components.tsx';
import sharedClasses from '../../insights.module.scss';
import {RCAExplainersExplainabilityLabelDisplay} from '../../components/rca-explainers-explainability-label-display/rca-explainers-explainability-label-display.component.tsx';

interface OwnProps extends ExperimentImpactSentenceExplainer {}

type AllProps = OwnProps;

const ExperimentImpactSentenceFormatter = (props: AllProps) => {
  const {sentenceParts, formattedSentenceParts: fsp, onViewOnPage, explainabilityLabel} = props;

  return (
    <SentenceContainer className={sharedClasses.Spaced}>
      {explainabilityLabel && (
        <RCAExplainersExplainabilityLabelDisplay label={explainabilityLabel} />
      )}
      <RCAExplainersIcon variant={RCAExplainersIconVariant.EXPERIMENT_EXPLAINER} />
      <SentencePart bold text={fsp.experimentName} />
      <ChevronRightSolidIcon className={sharedClasses.ChevronRight} />
      <SentencePart bold text={fsp.experimentVariantName} />
      <SentencePart text={`impacted the ${fsp.sampledModelType} by`} />
      <ChangeInValueSentencePart
        higherIsBetter={true}
        changeInValue={sentenceParts.experimentVariantCtcPp}
      />
      {onViewOnPage && (
        <ScrollToSectionSentencePart
          onViewOnPage={() => onViewOnPage(RCAElementType.EXPERIMENTS_EXPLAINER)}
        />
      )}
    </SentenceContainer>
  );
};

export {ExperimentImpactSentenceFormatter};
