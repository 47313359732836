import * as React from 'react';
import {RCAMode} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import classNames from 'classnames';
import {
  SentenceContainer,
  SentencePart,
} from '../../../../../../../../../../insights/components/shared-sentence-parts/shared-sentence-parts.components';
import classes from '../rca-insights-section.module.scss';
import {useMemo} from 'react';

interface OwnProps {
  rcaMode: RCAMode;
  granularity: string;
  titlePartOneTxPath: string;
  titlePartOneProps?: Record<string, string>;
}

type AllProps = OwnProps;

export const RCAInsightsSectionHeader: React.FC<AllProps> = (props: AllProps) => {
  const {rcaMode, granularity, titlePartOneTxPath, titlePartOneProps} = props;
  const {t} = useTranslation();

  const partTwoText = useMemo(
    () =>
      rcaMode === RCAMode.LOOPS_ALGO
        ? t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.EXPLAINERS_TITLE.PART_TWO)
        : t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.HIGHLIGHTED_TITLE.PART_TWO_NEW, {
            granularity: granularity.toUpperCase(),
          }),
    [rcaMode, t, granularity]
  );

  return (
    <SentenceContainer>
      <SentencePart text={t(titlePartOneTxPath, titlePartOneProps)} className={classes.SubTitle} />
      <SentencePart text={partTwoText} className={classNames(classes.SubTitle, classes.Dark)} />
    </SentenceContainer>
  );
};
