import {TableEntity} from '../../../objects/models/table.model';
import {ExplainabilityLabel} from 'ui-components';
import {ModelSampleSeriesModel} from '../../../objects/models/model-sample-series.model.ts';
import {SampledModelXoXOverview} from './sampled-model-sentences/objects.ts';
import {ProductEntities} from '../../../objects/models/product-data.model.ts';

export enum ChangeDescription {
  NO_CHANGE = 'no_change',
  INCREASE = 'increase',
  DECREASE = 'decrease',
}

export enum ChangeSentiment {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
}

export enum RCAInsightsSectionKeys {
  POTENTIAL_DRIVERS = 'POTENTIAL_DRIVERS',
  INTERESTING_SEGMENTS_PERFORMANCE_VS_PRIOR_WEEK = 'INTERESTING_SEGMENTS_PERFORMANCE_VS_PRIOR_WEEK',
  POPULATION_SIZE_EXPLAINER = 'POPULATION_SIZE_EXPLAINER',
}

export enum RCAInsightsSentenceKeys {
  SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_INCREASE = 'SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_INCREASE',
  SPIKE_UNDERPERFORMING_SEGMENT_SHARE_DECREASE_AND_KPI_INCREASE = 'SPIKE_UNDERPERFORMING_SHARE_DECREASE_AND_KPI_INCREASE',
  DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_DECREASED = 'DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE_AND_KPI_DECREASED',
  DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE_AND_KPI_DECREASED = 'DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE_AND_KPI_DECREASED',

  SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE = 'SPIKE_OVERPERFORMING_SEGMENT_SHARE_INCREASE',
  SPIKE_UNDERPERFORMING_SEGMENT_SHARE_DECREASE = 'SPIKE_UNDERPERFORMING_SHARE_DECREASE',
  DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE = 'DROP_UNDERPERFORMING_SEGMENT_SHARE_INCREASE',
  DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE = 'DROP_OVERPERFORMING_SEGMENT_SHARE_DECREASE',

  SPIKE_OVERPERFORMING_SEGMENT_INCREASES_KPI = 'SPIKE_OVERPERFORMING_SEGMENT_INCREASES_KPI',
  SPIKE_UNDERPERFORMING_SEGMENT_INCREASED_KPI = 'SPIKE_UNDERPERFORMING_SEGMENT_INCREASED_KPI',
  DROP_UNDERPERFORMING_SEGMENT_DECREASED_KPI = 'DROP_UNDERPERFORMING_SEGMENT_DECREASED_KPI',
  DROP_OVERPERFORMING_SEGMENT_DECREASED_KPI = 'DROP_OVERPERFORMING_SEGMENT_DECREASED_KPI',

  NO_EXPLAINERS = 'NO_EXPLAINERS',

  NOT_SIGNIFICANT_CHANGE_KPI_AND_USERS = 'NOT_SIGNIFICANT_CHANGE_KPI_AND_USERS',
  NOT_SIGNIFICANT_CHANGE_KPI = 'NOT_SIGNIFICANT_CHANGE_KPI',
  NOT_SIGNIFICANT_USERS_CHANGE = 'NOT_SIGNIFICANT_USERS_CHANGE',

  SPIKE_POPULATION_SIZE_AND_SAMPLED_MODEL_DECREASE = 'SPIKE_POPULATION_SIZE_AND_SAMPLED_MODEL_DECREASE',
  SPIKE_POPULATION_SIZE_AND_SAMPLED_MODEL_INCREASE = 'SPIKE_POPULATION_SIZE_AND_SAMPLED_MODEL_INCREASE',
  DROP_POPULATION_SIZE_AND_SAMPLED_MODEL_DECREASED = 'DROP_POPULATION_SIZE_AND_SAMPLED_MODEL_DECREASED',
  DROP_POPULATION_SIZE_AND_SAMPLED_MODEL_INCREASE = 'DROP_POPULATION_SIZE_AND_SAMPLED_MODEL_INCREASE',

  EXPERIMENT_IMPACT = 'EXPERIMENT_IMPACT',

  INPUT_KPI_IMPACT = 'INPUT_KPI_IMPACT',
}

export interface RCAInsightSentenceSegmentViewData {
  name: string;
  groupName: string;
  isComposite: boolean;
  signalId: number;
}

export interface RCAInsightSegmentSentenceParts {
  changeDescription: ChangeDescription;
  isCountEntitiesMode?: boolean;
  entity?: TableEntity;
  segmentViewData?: RCAInsightSentenceSegmentViewData[];
  changeSentiment?: ChangeSentiment;
  changeInValue?: number;
  changeInShareOfEntities?: number;
  changeInTotalEntities?: number;
  higherIsBetter?: boolean;
  segmentChangeDescription?: ChangeDescription;
  entityChangeDescription?: ChangeDescription;
}

export interface RCAInsightPopulationSizeExplainerSentenceParts {
  trendChangeValue: number;
  explainabilityLabel: ExplainabilityLabel;
  populationChangeDescription: ChangeDescription;
  populationSizeExplainabilityScore: number;
  sampledModelChangeDescription: ChangeDescription;
  sampledModelType: ModelSampleSeriesModel;
  sampledModelEntity: TableEntity;
}

export interface RCAInsightExperimentImpactSentenceParts {
  experimentId: number;
  experimentName: string;
  experimentVariantName: string;
  experimentVariantCtcPp: number;
  sampledModelType: ModelSampleSeriesModel;
}

export interface RCAInsightExperimentImpactFormattedSentenceParts {
  experimentName: string;
  experimentVariantName: string;
  experimentVariantCtcPp: string;
  sampledModelType: string;
}

export interface RCAInsightInputKpiImpactSentenceParts {
  inputKpiName: string;
  inputKpiId: number;
  inputKpiSignalId: number;
  inputKpiValue: number;
  inputKpiExpectedValue: number;
  higherIsBetter: boolean;
  changeDescription: ChangeDescription;
  sampledModelType: ModelSampleSeriesModel;
  rcaId?: number;
  rcaResultId?: number;
}

export interface RCAInsightInputKpiImpactFormattedSentenceParts {
  inputKpiName: string;
  inputKpiUplift: string;
  changeDescription: string;
  sampledModelType: string;
}

// Potential Drivers formatted sentence parts
export interface SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecrease {
  segmentName: string;
  changeInValue: string;
  changeDescription: string;
  changeInShareOfEntities: string;
  entity: string;
  entityChangeDescription: string;
  entityChangeDescriptionSynonym: string;
  presentTensePerformanceVsAvg: string;
  pastTensePerformanceVsPriorPeriod: string;
  segmentChangeDescription: string;
}

export interface SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease {
  segmentName: string;
  entity: string;
  entityChangeDescription: string;
  changeInShareOfEntities: string;
  presentTensePerformanceVsAvg: string;
}

export interface SpikeDropOverUnderPerformingIncreasedDecreasedKPI {
  segmentName: string;
  pastTensePerformanceVsXox: string;
  changeInValue: string;
}

export interface NoExplainers {
  changeDescription: string;
}

type PotentialDriversFormattedSentenceParts =
  | SpikeDropOverUnderPerformingSegmentShareIncreaseDecreaseAndKpiIncreaseDecrease
  | SpikeDropOverUnderPerformingSegmentShareIncreaseDecrease
  | SpikeDropOverUnderPerformingIncreasedDecreasedKPI
  | NoExplainers;

// Interesting Segments Performance Vs Compared Period formatted sentence parts
export interface NotSignificantChangeKPI {
  segmentName: string;
  presentTensePerformance: string;
  changeInValue: string;
}

export interface NotSignificantChangeKPIAndUsers {
  segmentName: string;
  presentTensePerformance: string;
  changeInValue: string;
  changeInShareOfEntitiesDescription: string;
  changeInTotalEntities: string;
  entity: string;
}

export interface NotSignificantUsersChange {
  segmentName: string;
  changeInTotalEntities: string;
  entityChangeDescription: string;
  entity: string;
}

type InterestingSegmentsPerformanceVsPriorWeekFormattedSentenceParts =
  | NotSignificantChangeKPI
  | NotSignificantChangeKPIAndUsers
  | NotSignificantUsersChange;

export interface PopulationSizeExplainerFormattedSentenceParts {
  trendChangeValue: string;
  explainabilityLabelDescription: string;
  populationChangeDescription: string;
  populationSizeExplainabilityScore: string;
  sampledModelChangeDescription: string;
  sampledModelType: string;
  sampledModelEntity: string;
}

export type FormattedSentenceParts =
  | PotentialDriversFormattedSentenceParts
  | InterestingSegmentsPerformanceVsPriorWeekFormattedSentenceParts
  | PopulationSizeExplainerFormattedSentenceParts;

export type RCAInsightSentenceParts =
  | RCAInsightSegmentSentenceParts
  | RCAInsightPopulationSizeExplainerSentenceParts;

export interface RCAInsightSentence {
  key: RCAInsightsSentenceKeys;
  sentenceParts: RCAInsightSentenceParts;
  formattedSentenceParts: FormattedSentenceParts;
}

interface RCASentenceExplainer<T, U> {
  key: RCAInsightsSectionKeys;
  sentenceParts: T;
  formattedSentenceParts: U;
  productEntities?: ProductEntities;
  onViewOnPage?: (titleId: string) => void;
  explainabilityLabel?: ExplainabilityLabel;
}

export interface ExperimentImpactSentenceExplainer
  extends RCASentenceExplainer<
    RCAInsightExperimentImpactSentenceParts,
    RCAInsightExperimentImpactFormattedSentenceParts
  > {}

export interface InputKpiImpactSentenceExplainer
  extends RCASentenceExplainer<
    RCAInsightInputKpiImpactSentenceParts,
    RCAInsightInputKpiImpactFormattedSentenceParts
  > {}

export interface PopulationSizeSentenceExplainer
  extends RCASentenceExplainer<
    RCAInsightPopulationSizeExplainerSentenceParts,
    PopulationSizeExplainerFormattedSentenceParts
  > {}

export interface SegmentSentenceExplainer
  extends RCASentenceExplainer<
    RCAInsightSegmentSentenceParts,
    PotentialDriversFormattedSentenceParts
  > {}

export interface InterestingXoXSentence
  extends RCASentenceExplainer<
    RCAInsightSegmentSentenceParts,
    InterestingSegmentsPerformanceVsPriorWeekFormattedSentenceParts
  > {}

export type RCASentence =
  | ExperimentImpactSentenceExplainer
  | PopulationSizeSentenceExplainer
  | SegmentSentenceExplainer
  | InterestingXoXSentence;

export interface SegmentPerformanceSummaryItem {
  segmentName: string;
  segmentGroupName: string;
  segmentValue: number;
  segmentUplift: number;
  segmentFormattedUplift: string;
  segmentChangeDescription: ChangeDescription;
  xoxGranularity: string;
}

export interface SegmentPerformance {
  sampledModelXoxOverview: SampledModelXoXOverview;
  segmentsPerformanceSummaries: SegmentPerformanceSummaryItem[];
}
