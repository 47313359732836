import * as React from 'react';
import {useMemo} from 'react';
import {
  KPISegmentationType,
  RetentionOverviewFigureOptions,
  RetentionSegmentationItemType,
} from '../../../../../../types';
import {exists} from 'front-core';
import {KPISegmentationDataModeTable} from '../../../../kpi-analysis/kpi-segmentation-viewer/components/kpi-segmentation-tables/kpi-segmentation-data-mode-table.component';
import TransKeys from 'translations';
import {ExtendedRetentionSegmentationItem} from '../../retention-segmentation-viewer.component';

interface OwnProps {
  figureId: string;
  selectedBucketIndex: number;
  options: RetentionOverviewFigureOptions;
  items: ExtendedRetentionSegmentationItem[];
  totalEntities: number;
  onSeeAll: (segmentGroupName: string) => void;
  className?: string;
}

type AllProps = OwnProps;

export const RetentionSegmentationDataModeTable: React.FC<AllProps> = (props: AllProps) => {
  const {items, options, totalEntities, selectedBucketIndex, figureId, onSeeAll, className} = props;
  const tableData: any[] = useMemo(
    () =>
      items.map(i => {
        const isInformative = exists(i.isInformative) ? i.isInformative : true;
        const isSignificant =
          i.type[selectedBucketIndex] !== RetentionSegmentationItemType.INSIGNIFICANT;
        return {
          ...i,
          isInformative: isInformative,
          groupInsignificant: isSignificant === false || isInformative === false,
          uplift: i.uplift[selectedBucketIndex],
          goalInSegment: i.goalInSegment[selectedBucketIndex],
          goalInNonSegment: i.goalInNonSegment[selectedBucketIndex],
          type: isSignificant ? KPISegmentationType.POSITIVE : KPISegmentationType.INSIGNIFICANT,
          helperText: i.helperText[selectedBucketIndex],
        };
      }),
    [items, selectedBucketIndex]
  );

  return (
    <KPISegmentationDataModeTable
      className={className}
      figureId={figureId}
      options={{
        ...options,
        higherIsBetter: true,
        isPercentageValue: true,
      }}
      items={tableData}
      totalEntities={totalEntities}
      onSeeAll={onSeeAll}
      transKeysPrefix={TransKeys.DOCUMENT_VIEWER.RETENTION_SEGMENTATION_FIGURE.TABLE.HEADERS}
    />
  );
};
